import React from "react";
import { Popper, Box, Button } from "@mui/material";
import GroupFilterHandler from "../../core/performanceReports/advancedReport/filtersBoard/GroupFilterHandler";
import { defaultPopperStyle } from "../Dropdown/Dropdown";
import { colors } from "../../theme";
import PartnerAdditionalElements from "./PartnerAdditionalElements";
import { CategoriesType } from "../../parametersConstancy/parametersConstancyTypes";

export interface MultiLabelsPopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement?: "bottom-end" | "bottom-start" | "top" | "auto" | any;
  flipOptions?: {
    name: string;
    enabled: boolean;
    options: {
      altBoundary: boolean;
      rootBoundary: "document" | any;
    };
  };
  partnerbase: string;
  bepp?: boolean;
  filterGroupsPartner?: any[];
  currentMultiselectGroup?: any;
  dialogActionsType?: string;
  setDialogActionsType?: React.Dispatch<React.SetStateAction<string>>;
  openDialog?: boolean;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  groupToEdit?: any;
  setGroupToEdit?: React.Dispatch<React.SetStateAction<any>>;
  handleClickOpenFilterGroupDialogForm?: (dialogType: string) => void;
  expanded?: boolean;
  handleExpand?: () => void;
  isReportingPartnersSelected: boolean;
  partnersUpdatedValues: CategoriesType;
  setPartnersUpdatedValues: React.Dispatch<React.SetStateAction<CategoriesType>>;
  partnersUpdatedOptions: CategoriesType;
  setPartnersUpdatedOptions: React.Dispatch<React.SetStateAction<CategoriesType>>;
  textFieldStyle?: any;
  handleApply: () => void;
  handleClear: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  partnersSelectedLength: number;
  selectAllEnabled: boolean;
}

const MultiLabelsPopper: React.FC<MultiLabelsPopperProps> = ({
  open,
  anchorEl,
  placement = "bottom-end",
  flipOptions = {
    name: "flip",
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: "document",
    },
  },
  partnerbase,
  bepp = false,

  // Optional custom-group-related props (only used if !bepp)
  filterGroupsPartner,
  currentMultiselectGroup,
  dialogActionsType,
  setDialogActionsType,
  openDialog,
  setOpenDialog,
  groupToEdit,
  setGroupToEdit,
  handleClickOpenFilterGroupDialogForm,
  expanded,
  handleExpand,

  // General usage
  isReportingPartnersSelected,
  partnersUpdatedValues,
  setPartnersUpdatedValues,
  partnersUpdatedOptions,
  setPartnersUpdatedOptions,
  textFieldStyle,
  handleApply,
  handleClear,
  partnersSelectedLength,
  selectAllEnabled = true,
}) => {
  return (
    <Popper
      id="partners-popper"
      open={open}
      anchorEl={anchorEl}
      disablePortal={false}
      placement={placement}
      sx={defaultPopperStyle}
      modifiers={[flipOptions]}
    >
      {/* --- The filter panel itself --- */}
      <GroupFilterHandler
        base={partnerbase}
        options={partnersUpdatedOptions}
        values={partnersUpdatedValues}
        setFiltersOptions={setPartnersUpdatedOptions}
        setFiltersValues={setPartnersUpdatedValues}
        isReadOnlyView={false}
        withPartners
        isBepp={bepp}
        selectAllEnabled={selectAllEnabled}
        additionalFilterIsReporting={isReportingPartnersSelected}
        textFieldStyle={textFieldStyle}
        id="select-partner-modal"
        actionItemsTop={
          bepp ? null : (
            <PartnerAdditionalElements
              filterGroupsPartner={filterGroupsPartner || []}
              currentMultiselectGroup={currentMultiselectGroup || null}
              dialogActionsType={dialogActionsType || ""}
              setDialogActionsType={setDialogActionsType!}
              openDialog={!!openDialog}
              setOpenDialog={setOpenDialog!}
              groupToEdit={groupToEdit || {}}
              setGroupToEdit={setGroupToEdit!}
              handleClickOpenFilterGroupDialogForm={handleClickOpenFilterGroupDialogForm!}
              expanded={!!expanded}
              handleExpand={handleExpand!}
              partnersSelectedLength={partnersSelectedLength}
              partnersUpdatedValues={partnersUpdatedValues}
            />
          )
        }
      />
      <Box
        sx={{
          display: "flex",
          padding: "8px 16px 8px 0px",
          justifyContent: "space-between",
          alignItems: "flex-start",
          alignSelf: "stretch",
          borderRadius: "0px 0px 16px 16px",
          background: colors.blueishGrey75,
        }}
      >
        <Button onClick={handleClear} variant="clearAll" id="select-partner-modal-clear-all-btn">
          Clear all
        </Button>
        <Button
          disabled={partnersSelectedLength < 1}
          onClick={handleApply}
          variant="apply"
          id="select-partner-modal-apply-btn"
        >
          Apply
        </Button>
      </Box>
    </Popper>
  );
};

export default MultiLabelsPopper;
