import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SegmentControl, { ISegmentControlOption } from "../components/SegmentControl/SegmentControl";
import JSAgentCard from "./JSAgentCard";
import { AgentTags, JSAgent, useGetJSAgentsQuery } from "./jsAgentApi";

export const jsAgentTypes = {
  agent: { id: 1, name: "JS Agent" },
  module: { id: 2, name: "JS Sync agents", isDisabled: true },
  other: { id: 3, name: "JS TO RTB", isDisabled: true },
};

const jsAgentTypeOptions: ISegmentControlOption[] = Object.values(jsAgentTypes);

const JSAgentsOverviewPage: React.FC = () => {
  const [mainAgents, setMainAgents] = useState<JSAgent[]>([]);
  const [commonAgents, setCommonAgents] = useState<JSAgent[]>([]);
  const { data: jsAgentsData, isSuccess } = useGetJSAgentsQuery();

  const [activeOption, setActiveOption] = useState<ISegmentControlOption>(jsAgentTypeOptions[0]);

  useEffect(() => {
    if (isSuccess && jsAgentsData.length > 0) {
      const mainData: JSAgent[] = [];
      const commonData: JSAgent[] = [...jsAgentsData];
      const gaAgentVersion = commonData?.shift();
      if (gaAgentVersion) {
        mainData.push(gaAgentVersion);
      }
      //   jsAgentsData?.forEach((item) => {
      //     if (item.tag === AgentTags.GA) {
      //       mainData.unshift(item);
      //     } else if (item.tag === AgentTags.POC) {
      //       mainData.push(item);
      //     } else {
      //       commonData.push(item);
      //     }
      //   });
      setCommonAgents(commonData);
      setMainAgents(mainData);
    }
  }, [isSuccess, jsAgentsData]);

  const gapElements = "10px";
  const mainJSAgentVersionsContent = (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        gap: gapElements,
        boxSizing: "border-box",
      }}
    >
      {isSuccess &&
        mainAgents.map((item) => (
          <JSAgentCard key={item.version} item={item} sizeType="main" gapElements={gapElements} />
        ))}
    </Box>
  );

  const commonJSAgentVersionsContent = (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: gapElements,
      }}
    >
      {isSuccess &&
        commonAgents.map((item) => (
          <JSAgentCard key={item.version} item={item} sizeType="list" gapElements={gapElements} />
        ))}
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {mainJSAgentVersionsContent}

        <SegmentControl
          options={jsAgentTypeOptions}
          value={activeOption}
          onChange={(value: ISegmentControlOption) => {
            setActiveOption(value);
          }}
        />
        {commonJSAgentVersionsContent}
      </Box>
    </Box>
  );
};

export default JSAgentsOverviewPage;
