import React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../theme";

type ConfirmationDialogProps = {
  isOpen: boolean;
  title: string;
  body?: React.ReactNode;
  confirmButtonTitle: string;
  declineButtonTitle: string;
  onConfirm: () => void;
  onDecline: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  body,
  confirmButtonTitle,
  declineButtonTitle,
  onConfirm,
  onDecline,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onDecline}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      aria-modal={true}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(4px)",
        zIndex: 11111,
      }}
    >
      <Box
        id="confirmation-modal"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: "24px",
          borderRadius: "24px",
          //minWidth: 500,
          // gap: "36px",
          position: "relative",
          width: "400px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "16px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography textAlign="left" id="modal-title" variant="titleSmall">
              {title}
            </Typography>
          </Box>
          <IconButton
            id="modal-close-btn"
            onClick={onDecline}
            sx={{
              color: `${colors.foreground}`,
              padding: "6px",
            }}
          >
            <CloseIcon
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          </IconButton>
        </Box>

        {/* <Divider sx={{ width: "100%", mt: 1, mb: 1 }} /> */}
        <Typography
          id="modal-description"
          variant="bodyLargeRegular"
          sx={{
            width: "100%",
            textAlign: "left",
            marginBottom: "36px",
          }}
        >
          {body}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: `${
              declineButtonTitle && confirmButtonTitle
                ? "space-between"
                : confirmButtonTitle
                ? "flex-end"
                : "flex-start"
            }`,
            width: "100%",
          }}
        >
          {declineButtonTitle && (
            <Button
              id="modal-decline-btn"
              variant="clearAll"
              onClick={onDecline}
              sx={{ paddingLeft: "0", textAlign: "center", justifyContent: "flex-start", alignItems: "center" }}
            >
              {declineButtonTitle}
            </Button>
          )}
          {confirmButtonTitle && (
            <Button
              id="modal-apply-btn"
              variant="apply"
              onClick={onConfirm}
              autoFocus
              sx={{
                fontWeight: 600,
                padding: "12px 16px",
                background: colors.brandDefault,
                ":focus": {
                  background: colors.brandDefault,
                  border: `1px solid ${colors.text01}`,
                  padding: "11px 15px",
                },
              }}
            >
              {confirmButtonTitle}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationDialog;
