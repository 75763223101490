import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../../../auth/auth-helper";
import { CONSTANT } from "../../../constants/constants";
import { getReportPctModes } from "../../../report/utils";
import { setCurrencyPctModeData, resetSummaryCompleteDate } from "../../../report/reportSlice";
import { resetSavedCustomRawDataState } from "../../../savedRawData/savedRawDataSlice";
import {
  emptyFilters,
  setActiveAreaChartTypes,
  setActiveFilters,
  setActiveFiltersByApplyGroup,
  setActiveFiltersByQuickAction,
  setCombinedDataMode,
  setGetStatsBody,
  setGetStatsStatus,
  setSelectedQuickActionId,
  setTablePartnersIds,
} from "./advancedReportSlice";
import { checkVersion } from "../../../infrastructure/infrastructureHelpers";
import { transformColumnBaseToRowBase } from "./utils";
import { applyPartnersByIds, clearSelectedPartners, resetMultiselectGroup } from "../../../role/roleSlice";
import { getVisitorRecognitionStats } from "./getVisitorRecognitionStats";
const host = CONSTANT.path.host;

export const getValues = (arr, field, paramName) => {
  let res = [];
  for (let i of arr) {
    res.push(i[field]);
  }
  let str = res.join(",");
  return `&${paramName}=${str}`;
};

export const getStats = createAsyncThunk(
  "advancedReport/getStats",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    let { signal, isApplyQuickAction, quickActionId, areaChartType, cdm } = payload;
    const vrPayload = payload;
    let currentState = getState();
    const token = currentState.users.loggedUser.token;
    let target = "stats";

    const timeGrouping =
      payload.timeGrouping ??
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value;
    let dgm =
      payload.dgm ??
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode;
    let customDateRange =
      payload.customDateRange ??
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange;
    let rt =
      timeGrouping === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value && !customDateRange
        ? CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value
        : timeGrouping;

    let partnersSelected = currentState.role.partnersSelected;
    const partnersList = currentState.role.partnersList;
    if (auth.isAdminRole()) {
      target = "stats_observe";
    }
    let summaryBody = {};
    let summaryBodyStr = "";
    if (isApplyQuickAction) {
      summaryBody = {
        rt: rt,
        dgm: dgm,
        pid: "",
        client_type: "",
        device_type: "",
        action_termination: "",
        site_id: "",
        js_version: "",
        pb_version: "",
        country_id: "",
        biddercode: "",
        pct: "",
        currency: "",
        integrations: "",
        adType: "",
        dataSources: "",
        cstm_start: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateStart : null,
        cstm_end: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateEnd : null,
        cdm: cdm,
        quickActionId,
      };
    } else {
      dispatch(setSelectedQuickActionId(null));
      dispatch(setCombinedDataMode(1));
      if (!Boolean(partnersSelected.length)) {
        return false;
      }
      summaryBody = {
        rt: rt,
        dgm: dgm,
        pid: partnersSelected.map((p) => p.dummy_id).join(","),
        client_type:
          (!currentState.advancedReport.filtersIsLoading.browsers &&
            payload.browsers.length === currentState.advancedReport.filters.browsers.length &&
            payload.browsers.length > 0) ||
          payload.browsers[0]?.id === "*"
            ? "*"
            : payload.browsers
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        device_type:
          (!currentState.advancedReport.filtersIsLoading.deviceTypes &&
            payload.deviceTypes.length === currentState.advancedReport.filters.deviceTypes.length &&
            payload.deviceTypes.length > 0) ||
          payload.deviceTypes[0]?.id === "*"
            ? "*"
            : payload.deviceTypes
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        action_termination:
          (!currentState.advancedReport.filtersIsLoading.actionTerminations &&
            payload.actionTerminations.length === currentState.advancedReport.filters.actionTerminations.length &&
            payload.actionTerminations.length > 0) ||
          payload.actionTerminations[0]?.id === "*"
            ? "*"
            : payload.actionTerminations
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        site_id:
          (!currentState.advancedReport.filtersIsLoading.sites &&
            payload.sites.length === currentState.advancedReport.filters.sites.length &&
            payload.sites.length > 0) ||
          payload.sites[0]?.id === "*"
            ? "*"
            : payload.sites
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        js_version:
          (!currentState.advancedReport.filtersIsLoading.jsVersions &&
            payload.jsVersions.length === currentState.advancedReport.filters.jsVersions.length &&
            payload.jsVersions.length > 0) ||
          payload.jsVersions[0]?.id === "*"
            ? "*"
            : payload.jsVersions
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        pb_version:
          (!currentState.advancedReport.filtersIsLoading.pb_version &&
            payload.pbVersions.length === currentState.advancedReport.filters.pbVersions.length &&
            payload.pbVersions.length > 0) ||
          payload.pbVersions[0]?.id === "*"
            ? "*"
            : payload.pbVersions
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        country_id:
          (!currentState.advancedReport.filtersIsLoading.countries &&
            payload.countries.length === currentState.advancedReport.filters.countries.length &&
            payload.countries.length > 0) ||
          payload.countries[0]?.id === "*"
            ? "*"
            : payload.countries
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        biddercode:
          (!currentState.advancedReport.filtersIsLoading.biddercodes &&
            payload.biddercodes.length === currentState.advancedReport.filters.biddercodes.length &&
            payload.biddercodes.length > 0) ||
          payload.biddercodes[0]?.id === "*"
            ? "*"
            : payload.biddercodes
                .filter((unit) => unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        pct:
          payload.pct[0]?.id === "*"
            ? ""
            : payload.pct
                .filter((unit) => unit.id && unit.id !== "NaN" && unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        currency:
          payload.currencies[0]?.id === "*"
            ? ""
            : payload.currencies
                .filter((unit) => unit.id && unit.id !== "null" && unit.isSelected)
                .map((unit) => unit.id)
                .join(","),
        integrations: payload.integrations
          .filter((unit) => unit.isSelected)
          .map((unit) => unit.name)
          .join(","),
        adType: payload.adType
          .filter((unit) => unit.isSelected)
          .map((unit) => unit.id)
          .join(","),
        dataSources: payload.dataSources
          .filter((unit) => unit.isSelected)
          .map((unit) => unit.id)
          .join(","),
        cstm_start: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateStart : null,
        cstm_end: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateEnd : null,
        cdm: 1,
      };
    }
    summaryBodyStr = JSON.stringify(summaryBody);
    if (
      summaryBodyStr === currentState.advancedReport.getStatsBody &&
      currentState.advancedReport.stats.length > 0
    ) {
      console.log("Reading data from cache...");
      return { useCache: true };
    }
    vrPayload.dgm = dgm;
    vrPayload.rt = rt;
    vrPayload.customDateRange = customDateRange;
    vrPayload.token = token;
    vrPayload.pids = summaryBody.pid;

    try {
      let postResponse = await fetch(`${host}/statistics/${target}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        signal,
        body: JSON.stringify(summaryBody),
      });

      if (!postResponse.ok) {
        const responseJSON = await postResponse.json();
        alert(responseJSON.message);
        dispatch(setGetStatsStatus(responseJSON?.statusCode));
        throw new Error(responseJSON.message);
      }

      dispatch(setGetStatsStatus(null));
      dispatch(setGetStatsBody(summaryBodyStr));

      let result = {};
      const responseJSON = await postResponse.json();

      let activeFiltersPayload = {
        ...emptyFilters,
        calls: [{ id: 0, name: "All", isSelected: true }],
      };

      if (isApplyQuickAction && responseJSON.pids?.length) {
        const selectedPartnersIdsArray = responseJSON.pids;
        vrPayload.pids = selectedPartnersIdsArray.join(",");
        dispatch(resetMultiselectGroup());
        dispatch(applyPartnersByIds(selectedPartnersIdsArray));
        if (responseJSON.entity) {
          const filterName = responseJSON.entity.name;
          activeFiltersPayload[filterName] = responseJSON.entity.ids.map((id) => {
            let item = currentState.advancedReport.filters[filterName].find((item) => item.id === id);
            if (item) {
              return {
                ...item,
                isSelected: true,
              };
            } else {
              return {
                id,
                name: id,
                isSelected: true,
              };
            }
          });
        }

        dispatch(setActiveFiltersByQuickAction(activeFiltersPayload));
        // console.log(areaChartType);
        dispatch(setActiveAreaChartTypes([areaChartType]));

        const updPartnersList = [];
        partnersList.forEach((partner, i) => {
          if (selectedPartnersIdsArray.includes(+partner.dummy_id)) {
            updPartnersList.push({ ...partner, isSelected: true, partner_name: partner.name });
          }
        });
        partnersSelected = updPartnersList;
      } else if (isApplyQuickAction && !responseJSON.pids) {
        dispatch(setActiveFilters(activeFiltersPayload));
        dispatch(clearSelectedPartners());
        return [];
      }

      dispatch(getVisitorRecognitionStats(vrPayload));
      delete responseJSON.pids;
      result.data = responseJSON.data?.length > 0 ? transformColumnBaseToRowBase(responseJSON.data) : [];
      result.reactAppVersion = responseJSON.reactAppVersion;

      checkVersion(result.reactAppVersion);
      const tablePartnersIdsSet = new Set();
      const mapping = partnersSelected.reduce((acc, obj) => {
        if (obj.partner_name && obj.dummy_id) {
          acc[obj.dummy_id] = obj.partner_name;
          acc[obj.dummy_id] = obj;
        }
        return acc;
      }, {});
      for (const dat of result.data) {
        dat.dummy_id = dat.partner_id;
        tablePartnersIdsSet.add(dat.partner_id);
        dat.pid = mapping[dat.partner_id]?.pid ?? `${dat.partner_id}-Unrecognized`;
        dat.pName = mapping[dat.partner_id]?.partner_name ?? `${dat.partner_id}-Unrecognized`;
      }

      dispatch(setTablePartnersIds(Array.from(tablePartnersIdsSet)));
      let data = {
        result,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
      };

      //apply same date range to report slice
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        dispatch(resetSavedCustomRawDataState());
      }
      dispatch(resetSummaryCompleteDate());

      if (result.data.length === 0) {
        return data;
      }

      let reportSelectedPct = currentState.report.selectedPct;
      let reportSelectedCurrency = currentState.report.selectedCurrency;
      let shouldUpdateSelected = false;
      let { pctModes, currencyModes, uniqPairs, maxCurrencyPctCombination } = getReportPctModes(
        result.data,
        payload.pct,
        payload.currencies
      );

      let has = false;
      for (let i of uniqPairs) {
        if (i.currency === reportSelectedCurrency && i.pct === reportSelectedPct) has = true;
      }
      if (!has) {
        reportSelectedPct = uniqPairs[0].pct;
        reportSelectedCurrency = uniqPairs[0].currency;
        shouldUpdateSelected = true;
      }

      let payloadToSet = {
        pctModes,
        currencyModes,
        uniqPairs,
        shouldUpdateSelected,
        maxCurrencyPctCombination,
      };

      data.pctModes = pctModes;
      data.currencyModes = currencyModes;
      data.uniqPairs = uniqPairs;
      data.reportSelectedPct = reportSelectedPct;
      data.reportSelectedCurrency = reportSelectedCurrency;
      data.customDateRange = customDateRange;
      dispatch(setCurrencyPctModeData(payloadToSet));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getStatsType = (payload = {}) => getStats(payload);
