import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../theme";

interface ReusableDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  width?: string;
  height?: string;
}

export const ReusableDialog: React.FC<ReusableDialogProps> = ({
  open,
  onClose,
  title = "Dialog Title",
  children,
  width = "600px",
  height = "756px",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        background: `${colors.text01}99`,
      }}
      PaperProps={{
        sx: {
          display: "flex",
          width,
          borderRadius: "24px",
          gap: "16px",
          height,
          padding: "24px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "start",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "24px 24px 0 24px",
        }}
      >
        <Typography variant="titleSmall">{title}</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 24,
          top: 24,
          color: colors.foreground,
          padding: "6px",
        }}
      >
        <CloseIcon
          aria-hidden="false"
          sx={{
            width: "24px",
            height: "24px",
          }}
        />
      </IconButton>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: colors.foreground01,
          paddingBottom: "16px",
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
