import React from "react";
import { Box, Snackbar, Typography } from "@mui/material";
import { NotificationStatus } from "../../core/performanceReports/advancedReport/filtersBoard/FilterGroupDialogForm";
import { colors } from "../../theme";
import NotificationSuccessIcon from "../IconComponents/NotificationSuccessIcon";
import ErrorIcon from "@mui/icons-material/Error";

export interface ActionNotificationSnackbarProps {
  /** Whether the snackbar is open. */
  open: boolean;
  /** The current notification status (e.g., 'SUCCESS', 'FAILD', etc.). */
  status: NotificationStatus;
  /** The notification text to show. */
  message: string;
  /** Handler to call when the snackbar closes or auto-hides. */
  onClose: () => void;
  /** How long before auto-hide (ms). Set to null or undefined to disable auto-hide. */
  autoHideDuration?: number;
}

/**
 * A reusable snackbar that displays a message plus an icon based on the notification status.
 */
const ActionNotificationSnackbar: React.FC<ActionNotificationSnackbarProps> = ({
  open,
  status,
  message,
  onClose,
  autoHideDuration = 2000,
}) => {
  // Only open if not in idle status
  const isActuallyOpen = open && status !== NotificationStatus.IDLE;

  return (
    <Snackbar
      id="partner-group-upd-toast"
      open={isActuallyOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        borderRadius: "24px",
        background: `${colors.backgroundInverse}`,
        padding: "12px",
        boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        {status === NotificationStatus.SUCCESS && <NotificationSuccessIcon color={colors.statusSuccessDefault} />}
        {status === NotificationStatus.FAILD && <ErrorIcon color="error" />}
        <Typography variant="bodyMedium" sx={{ color: `${colors.text05}` }}>
          {message}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default ActionNotificationSnackbar;
