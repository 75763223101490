import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Box, Tabs, Tab, IconButton, InputAdornment } from "@mui/material";
import SideBoardWrapper from "./sideBoard/SideBoardWrapper";
import AdminElement from "../components/ElementWrappers/AdminElement";
import LoaderWithProgress from "../components/LoaderWithProgress/LoaderWithProgress";
import ForbiddenResourceNotification from "../components/notifications/ForbiddenResourceNotification";
import { useGetAccountsQuery } from "./accountsApi";
import { colors } from "../theme";
import { pageContainerStyle, sideBoardWrapperStl, tabsContainerStyle, textFieldInputStlRAMP } from "./styles";
import { CustomPagination } from "../core/performanceReports/advancedReport/table/pagination/CustomPagination";
import SessionTimeout from "../components/SessionTimeout/SessionTimeout";
import { setSearchTerms } from "./users/usersSlice";
import SearchIcon from "@mui/icons-material/Search";
import { AccountsTable } from "./AccountsTable";
import { CONSTANT } from "../constants/constants";
export const menuTabs = [
  {
    id: 1,
    title: "All accounts",
    isDisabled: false,
  },
  {
    id: 2,
    title: "Active accounts",
    isDisabled: false,
  },
  {
    id: 3,
    title: "Inactive accounts",
    isDisabled: false,
  },
  {
    id: 4,
    title: "Trial accounts",
    isDisabled: false,
  },
];

export const CustomPaginationWrapper: React.FC<{ rowsPerPageOptions: number[] }> = ({ rowsPerPageOptions }) => {
  // Directly return your JS CustomPagination component here, passing any required props
  return (
    <Box sx={{ padding: "10px" }}>
      <CustomPagination rowsPerPageOptions={rowsPerPageOptions} />
    </Box>
  );
};

export default function AccountsPage() {
  const dispatch = useDispatch();
  const [tabIdSelected, setTabIdSelected] = useState(1);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIdSelected(newValue);
  };
  const { data: accounts, isFetching, isLoading } = useGetAccountsQuery();
  const { loggedUser } = useSelector((state: any) => state.users);
  const { searchTerms } = useSelector((state: any) => state.users);
  const [values, setValues] = useState({
    message: "",
    error: "",
  });
  const [filterText, setFilterText] = useState(searchTerms.accounts ?? "");
  const handleFilterChange = (event: any) => {
    setFilterText(event.target.value);
    dispatch(setSearchTerms({ accounts: event.target.value }));
  };
  const filteredAccounts = React.useMemo(() => {
    let filteredAccounts = accounts?.list?.filter((account: any) => {
      return (
        account.company.toLowerCase().includes(filterText.toLowerCase()) ||
        account.partners.toLowerCase().includes(filterText.toLowerCase()) ||
        account.partnersPdidStr.toLowerCase().includes(filterText.toLowerCase()) ||
        account.partnersPidStr.toLowerCase().includes(filterText.toLowerCase()) ||
        (account.virtualAccount === 1 && "virtual".includes(filterText.toLowerCase()))
      );
    });
    switch (tabIdSelected) {
      case 0: {
        break;
      }
      case 1: {
        filteredAccounts = filteredAccounts?.filter(
          (account: any) => account.status === CONSTANT.ACCOUNT_STATUS.ACTIVE.value
        );
        break;
      }
      case 2: {
        filteredAccounts = filteredAccounts?.filter(
          (account: any) => account.status === CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value
        );
        break;
      }
      case 3: {
        filteredAccounts = filteredAccounts?.filter(
          (account: any) => account.status === CONSTANT.ACCOUNT_STATUS.TRIAL.value
        );
        break;
      }
    }

    return filteredAccounts;
  }, [accounts, filterText, tabIdSelected]);

  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  if (values.error) {
    return <Box>{(values.error as any) === 403 ? <ForbiddenResourceNotification /> : values.error}</Box>;
  }

  return (
    <Box sx={pageContainerStyle}>
      <Box sx={tabsContainerStyle}>
        <Tabs value={tabIdSelected} onChange={handleTabChange} aria-label="user management tabs">
          {menuTabs.map((tab, index) => (
            <Tab
              label={tab.title}
              disabled={tab.isDisabled}
              key={tab.id}
              id={`${tab.title.toLowerCase().replaceAll(" ", "-")}-tab`}
            />
          ))}
        </Tabs>
        <Box sx={{ marginRight: 0, display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <TextField
            id="accounts-search-input"
            type="search"
            placeholder="Search..."
            value={filterText}
            onChange={handleFilterChange}
            // onCancelSearch={() => cancelSearch()}
            sx={{
              fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
              ...textFieldInputStlRAMP,
              padding: "2px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <AdminElement canAdmin={loggedUser.canCreateAccount}>
            <Box>
              <SideBoardWrapper
                type="virtual_account"
                btnStyles={{
                  backgroundColor: colors.mainDarkPrimary10,
                  color: colors.mainDarkPrimary100,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                headerTitle="Create Multi-account"
                btnTitle="Add Multi-account"
                value={{ showUserSection: true }}
                id="add-multi-account"
              />
            </Box>
            <Box sx={sideBoardWrapperStl}>
              <SideBoardWrapper id="add-account" btnTitle="Add new account" btnStyles={{}} />
            </Box>
          </AdminElement>
        </Box>
      </Box>
      {filteredAccounts && (
        <AccountsTable
          showTrial={tabIdSelected === 3}
          filteredAccounts={filteredAccounts}
          showDeactivatedAt={tabIdSelected === 2}
        />
      )}
      <SessionTimeout />
    </Box>
  );
}
