export const handleReset = (setReset) => {
  setReset((current) => !current);
};

export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

export function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0);
}

export const monthPickChange = (item, setDateRange) => {
  let fd = getFirstDayOfMonth(item.start.year, item.start.month);
  let ld = getLastDayOfMonth(item.end.year, item.end.month);

  let newVal = [
    {
      startDate: fd,
      endDate: ld,
      key: "selection",
    },
  ];
  setDateRange(newVal);
};

export const getMaxSelectableDateWithPreviousPeriod = (fractionalYearsAgo = 1.25) => {
  const today = new Date();

  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - Math.floor(fractionalYearsAgo));
  maxDate.setMonth(maxDate.getMonth() - Math.round((fractionalYearsAgo % 1) * 12));

  // const daysInYear = 365.25;
  // const daysPeriod = Math.round(fractionalYearsAgo * daysInYear);

  // const previousPeriodStartDate = new Date(maxDate);
  // previousPeriodStartDate.setDate(maxDate.getDate() - daysPeriod);

  return {
    maxSelectableDate: maxDate.toISOString().split("T")[0],
    // previousPeriodStartDate: previousPeriodStartDate.toISOString().split("T")[0],
  };
};
