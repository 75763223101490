import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../theme";

interface CustomAxisTickProps {
  x?: number;
  y?: number;
  payload?: { value?: string };
  selected?: boolean;
}

const CustomAxisTick: React.FC<CustomAxisTickProps> = (props) => {
  const { x = 0, y = 0, payload, selected } = props;
  const name = payload?.value || "";
  const firstLetter = name.charAt(0).toUpperCase();
  const radius = 20;
  const h = 22;
  const w = 108;

  return (
    <g transform={`translate(${x}, ${y + radius - 4})`}>
      {selected && (
        <rect
          x={-w / 2}
          y={-h + 4}
          width={w}
          height={h}
          fill={colors.purpleLight}
          // stroke={colors.secRed100}
          strokeWidth={2}
          rx={8}
        />
      )}
      <foreignObject x={-radius / 2} y={-radius / 2 - 7} width={radius} height={radius}>
        <Box
          sx={{
            width: radius,
            height: radius,
            borderRadius: "50%",
            backgroundColor: selected ? colors.violetContent : colors.purpleDark,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1Small400" sx={{ color: "#FFF" }}>
            {firstLetter}
          </Typography>
        </Box>
      </foreignObject>
    </g>
  );
};

export default CustomAxisTick;
