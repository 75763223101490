import { Box, Typography } from "@mui/material";
import { colors } from "../../theme";

export const EmptyData: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "300px",
        width: "100%",
        gap: "8px",
      }}
    >
      <Typography color={colors.textBaseTertiary} variant="bodyXLarge700">
        Not enough data
      </Typography>
      <Typography variant="bodySmallRegular">To display statistics, select partners</Typography>
    </Box>
  );
};
