import { CONSTANT } from "../../constants/constants";
import { convertLocal } from "../../utils";
import getSymbolFromCurrency from "currency-symbol-map";

export const UNITS_LIMIT = 50;
export const CustomTooltipLineChart = (props) => {
  try {
    let { active, payload, currency = "$", active_chart = 1, apt } = props;
    if (active && payload && payload.length) {
      let list = [];
      let count = 0;
      let tooltipUnit = active_chart !== 3 ? getSymbolFromCurrency(currency) || currency : "%";
      const ITEMS_AMOUNT = 25;

      let stl = {
        wrapper: {
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: 8,
          border: "1px solid rgba(0, 56, 93, 0.075)",
          color: "#00385D",
        },
        container: {
          display: "flex",
          flexDirection: "column",
          // flexWrap: "wrap",
          minWidth: "170px",
          fontSize: "0.9rem",
          pointerEvents: "auto",
          // flexDirection: 'row',
        },
        innerItem: {
          display: "flex",
          margin: payload.length > ITEMS_AMOUNT ? "0px 30px 12px 0px" : "0px 10px 2px 0px",
          fontSize: payload.length > ITEMS_AMOUNT ? "18px" : "18px",
        },
      };

      if (payload.length > ITEMS_AMOUNT) {
        stl.container.flexDirection = "column";
        stl.container.width = "330px";
        stl.container.maxWidth = "750px";
        stl.container.height = "350px";
        stl.container.overflow = "auto";
      }

      payload.sort((a, b) => b.value - a.value);

      for (let i of payload) {
        if (i.name !== "nameQuarter") {
          list.push(
            <div id="tooltip-item" key={i + "_" + count++} style={stl.innerItem}>
              <p style={{ marginTop: 0, marginBottom: 4 }}>
                <span
                  id="tooltip-item-dot"
                  style={{
                    color: i.fill,
                    paddingRight: 5,
                  }}
                >
                  &#11044;
                </span>
                <span id="tooltip-item-title" style={{ fontWeight: "600" }}>
                  {`${i.name} `}{" "}
                </span>
              </p>

              {apt ? (
                <p id="tooltip-item-value" style={{ marginLeft: "5px", marginTop: 0, marginBottom: 0 }}>
                  {active_chart !== 3
                    ? `${tooltipUnit}${convertLocal(i.value.toFixed(2))}`
                    : `${convertLocal(i.value.toFixed(2))}${tooltipUnit}`}
                </p>
              ) : null}
            </div>
          );
        }
      }

      const d = new Date(payload[0].payload.date);
      let day = d.getUTCDate();
      let month = d.getUTCMonth() + 1;
      let year = d.getUTCFullYear();
      let text = `${day}/${month}`;

      if (props.active_range_date === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value) text += `/${year}`;
      return (
        <div id="line-chart-tooltip" style={stl.wrapper}>
          <div
            id="tooltip-title"
            style={{
              color: "#001738",
              marginTop: 0,
              marginBottom: "20px",
              fontWeight: "600",
            }}
          >
            {text}
          </div>
          <div style={stl.container}>{list}</div>
        </div>
      );
    }
    return null;
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export const renderColorfulLegendText = (value) => {
  return <span style={{ color: "#001738", fontSize: "1.125rem" }}>{value}</span>;
};

export const renderLegend = (props) => {
  const { payload } = props;
  let list = [];
  const ITEMS_AMOUNT = 50;

  let stl = {
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "90%",
    },
    innerItem: {
      margin: payload.length > ITEMS_AMOUNT ? "0px 0px 2px 0px" : "0px 10px 2px 0px",
      fontSize: payload.length > ITEMS_AMOUNT ? "16px" : "18px",
    },
  };

  if (payload.length > ITEMS_AMOUNT) {
    stl.container.flexDirection = "column";
    stl.container.height = "50px";
    stl.container.height = "50px";
    stl.container.overflow = "auto";
  }

  for (let entry of payload) {
    list.push(
      <div
        style={stl.innerItem}
        key={`item-${entry.value}`}
        onClick={() => props.onClick(entry)}
        onMouseEnter={() => props.onMouseEnter(entry)}
        onMouseLeave={() => props.onMouseLeave(entry)}
      >
        <span
          style={{
            color: entry.color,
            paddingRight: 5,
          }}
        >
          &#11044;
        </span>
        {entry.value}
      </div>
    );
  }

  return <div style={stl.container}>{list}</div>;
};

export const CustomizedDot = (props) => {
  try {
    const { cx, cy, stroke, payload, value } = props;

    let filledColor = props.fill;
    if ("customFillColor" in props) {
      filledColor = props.customFillColor;
    }

    return (
      <svg
        x={cx - 20}
        y={cy - 23}
        width="45"
        height="46"
        viewBox="0 0 45 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.2" cx="22.6463" cy="22.9998" r="16.283" fill={filledColor} />
        <circle cx="22.6477" cy="23.0002" r="7.00705" fill={filledColor} stroke="white" strokeWidth="2" />
      </svg>
    );
    // }

    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width="20px"
        height="20px"
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="custom-dot"
          cx="10.5"
          cy="10.5"
          fill="#05000"
          r="3"
          strokeWidth="1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } catch (err) {
    console.log("the err", err);
    return (
      <svg x={0} y={0} width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <circle
          className="custom-dot"
          cx="10.5"
          cy="10.5"
          fill="#05000"
          r="3"
          strokeWidth="1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};
