import React from "react";
import { Box, ListItemButton, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { partnerDropdownTextFieldStyle } from "../SelectLabels/SelectMultiLabelsWrapper";

export interface ISimpleListOption {
  id: number;
  name: string;
  isDisabled?: boolean;
  isHidden?: boolean;
}

export interface SimpleListProps {
  options: ISimpleListOption[];
  value: number | null;
  onChange: (id: number) => void;
  onSearch?: (value: string) => void | null;
}

const LIST_ITEM_HEIGHT = 40;

export const SimpleList: React.FC<SimpleListProps> = ({ options, value, onChange, onSearch = null }) => {
  const visibleOptions = options.filter((option) => !option.isHidden);
  const dropdownHeight = Math.min(visibleOptions.length * LIST_ITEM_HEIGHT, 200);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: "16px",
        padding: "6px",
        maxHeight: "calc(100vh - 20px)",
      }}
    >
      {onSearch && (
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search"
          onChange={(e) => onSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={partnerDropdownTextFieldStyle}
        />
      )}

      <Box sx={{ maxHeight: `${dropdownHeight}px`, overflow: "auto" }}>
        {visibleOptions.map((option) => {
          const isSelected = value === option.id;
          const isDisabled = option.isDisabled ?? false; // Default to false

          return (
            <ListItemButton
              key={option.id}
              onClick={() => !isDisabled && onChange(option.id)}
              disabled={isDisabled}
              sx={{
                padding: "8px",
                gap: "12px",
                backgroundColor: isSelected ? "#E3F2FD" : "transparent",
                opacity: isDisabled ? 0.5 : 1,
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
              id="dropdown-item"
            >
              <Typography
                variant="body1Small400"
                sx={{
                  fontWeight: isSelected ? "bold" : "normal",
                  color: isDisabled ? "text.disabled" : "text.primary",
                }}
              >
                {option.name}
              </Typography>
            </ListItemButton>
          );
        })}
      </Box>
    </Box>
  );
};
