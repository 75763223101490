import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, TextField, InputAdornment } from "@mui/material";
import { columnsModelConfigurations } from "./columnsModel";
import SegmentControl, { ISegmentControlOption } from "../../components/SegmentControl/SegmentControl";
import BidderConfigurationForm from "./BidderConfigurationForm";

import { ConfigurationFormData, IBidderConfig } from "./interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ConfigurationStatusMenu from "./ConfigurationStatusMenu";
import StyledDataGrid from "../../components/StyledDataGrid/StyledDataGrid";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import { textFieldInputStlBepp } from "../../accounts/styles";
import SearchIcon from "@mui/icons-material/Search";
import { useCountriesMapping, usePartnerMapping } from "./useMappings";
import { useBiddersMapping } from "./useMappings";
import { ReusableDialog } from "./ReusableDialog";
import {
  useDeletePartnersConfigurationByIdMutation,
  useGetAllConfigurationQuery,
  useUpdatePartnersConfigurationByIdMutation,
  useUpdatePartnersIsactiveByIdMutation,
} from "./configurationsApi";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
export type IBidderConfigKey = keyof IBidderConfig;

const segmentOptions: ISegmentControlOption[] = [
  { id: 1, name: "Active bidders" },
  { id: 2, name: "All bidders" },
];

const ConfigurationPage: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [configToEdit, setConfigToEdit] = useState<IBidderConfig | null>(null);
  const [configFormToEdit, setConfigFormToEdit] = useState<ConfigurationFormData | null>(null);
  const [configByPartner, setConfigByPartner] = useState<IBidderConfig[] | undefined>(undefined);
  const {
    data: configuration,
    isSuccess: isSuccessConfigurations,
    isFetching: configurationIsFetching,
  } = useGetAllConfigurationQuery();
  const [deletePartnersConfigurationById] = useDeletePartnersConfigurationByIdMutation();
  const [updatePartnersConfigurationById, { isLoading: updateConfigIsLoading, isSuccess }] =
    useUpdatePartnersConfigurationByIdMutation();
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [filterText, setFilterText] = useState("");

  const [updatePartnersIsactiveById, { isLoading: updatePartnersIsactiveByIdIsLoading }] =
    useUpdatePartnersIsactiveByIdMutation();

  const handleFilterChange = (event: any) => {
    setFilterText(event.target.value);
  };
  const { selectedPartnerIds } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.configurationParameters
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const [configId, setConfigId] = useState<number | null>(null);
  const [segmentId, setSegmentId] = useState<number | null>(2);
  const [statusPropName, setStatusPropName] = useState<IBidderConfigKey | null>(null);
  const { partnerMapping, isSuccess: isSuccessPaetnerMapping } = usePartnerMapping();
  const { biddersMapping, isSuccess: isSuccessBidderMapping } = useBiddersMapping();
  const { countriesMapping, isSuccess: isSuccessCountriesMapping } = useCountriesMapping();
  // console.log("Rerendering ConfigurationPage");

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };

  const handleOpenDialog = () => {
    setConfigToEdit(null);
    setConfigFormToEdit(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleStatusClick = (
    configId: any,
    selectedStatus: number,
    statusKey: IBidderConfigKey,
    anchorEl: HTMLElement
  ) => {
    setSelectedStatus(selectedStatus);
    setConfigId(configId);
    setConfigToEdit(configuration?.find((c) => c.id === configId) || null);
    setStatusPropName(statusKey);
    setAnchorEl(anchorEl);
  };
  const handleOpenDeleteConfirmationDialog = (config: IBidderConfig | undefined) => {
    if (config) {
      setConfigToEdit(config);
      setOpenDeleteConfirmationDialog(true);
    }
  };
  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
  };

  const handleNewStatusClick = async (newStatus: number) => {
    if (configId && statusPropName)
      try {
        await updatePartnersConfigurationById({
          id: configId,
          data: { ...configToEdit, [statusPropName]: newStatus },
        }).unwrap();
      } catch (error) {
        console.error("Failed to update status:", error);
      }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isSuccessConfigurations && isSuccessPaetnerMapping && isSuccessBidderMapping) {
      const newconfigurations = configuration
        ?.filter((c) => selectedPartnerIds.some((id: any) => id === c.partnerDummyId))
        .filter((c) => c.isActive || segmentId === 2)
        .filter(
          (c) =>
            c.name.toLowerCase().includes(filterText.toLowerCase()) ||
            biddersMapping[c.bidderAdapterId].includes(filterText.toLowerCase()) ||
            partnerMapping[c.partnerDummyId || 0].name.includes(filterText.toLowerCase())
        );
      setConfigByPartner(newconfigurations);
    }
  }, [
    selectedPartnerIds,
    segmentId,
    filterText,
    isSuccessConfigurations,
    isSuccessPaetnerMapping,
    isSuccessBidderMapping,
    configurationIsFetching,
  ]);

  if (
    !isSuccessConfigurations ||
    !isSuccessPaetnerMapping ||
    !isSuccessBidderMapping ||
    updatePartnersIsactiveByIdIsLoading ||
    configurationIsFetching
  ) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: "12px", width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "12px" }}>
          <SegmentControl
            options={segmentOptions}
            value={segmentOptions.find((o) => o.id === segmentId) || segmentOptions[0]}
            onChange={(value) => {
              setSegmentId(+value.id);
            }}
          />
          <TextField
            id="search-input"
            placeholder="Search bidder"
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            sx={{
              fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
              ...textFieldInputStlBepp,
              padding: "2px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={"12px"}>
          <Box>
            <Button
              disabled={selectedPartnerIds.map((id: any) => partnerMapping[id].canAdmin).includes(false)}
              onClick={() => {
                updatePartnersIsactiveById({ data: { isServiceActive: 1, pdids: selectedPartnerIds } });
              }}
              variant="outlinedFullRoundFullWidth"
            >
              Turn bidders on
            </Button>
          </Box>
          <Box>
            <Button
              disabled={selectedPartnerIds.map((id: any) => partnerMapping[id].canAdmin).includes(false)}
              onClick={() => {
                updatePartnersIsactiveById({ data: { isServiceActive: 0, pdids: selectedPartnerIds } });
              }}
              variant="outlinedFullRoundFullWidth"
            >
              Turn bidders off
            </Button>
          </Box>
          <Box>
            <Button
              disabled={selectedPartnerIds.filter((id: any) => partnerMapping[id].canAdmin).length === 0}
              onClick={handleOpenDialog}
              variant="apply"
            >
              Add new
            </Button>
          </Box>
        </Box>
      </Box>

      <Box style={{ height: 400, width: "100%" }}>
        <StyledDataGrid
          rows={configByPartner || []}
          rowHeight={40}
          columns={columnsModelConfigurations({
            onEdit: (id) => {
              const configToEdit = configuration?.find((c) => c.id === id) || null;
              if (!configToEdit) return;
              setConfigToEdit(configToEdit);
              setConfigFormToEdit({
                ...configToEdit,
                isCountriesModeInclude: configToEdit.geo.isCountriesModeInclude,
                geoCountriesIds: configToEdit.geo.countriesIds,
              });
              setOpenDialog(true);
            },
            onDelete: async (id) => {
              handleOpenDeleteConfirmationDialog(configByPartner?.filter((c) => c.id === id)[0]);
            },
            onServiceActiveStatusClick: handleStatusClick,
            onBidderenabledStatusClick: handleStatusClick,
            partnerMapping,
            biddersMapping,
            countriesMapping,
          })}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 50, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
        />
      </Box>
      <ReusableDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={(configToEdit ? `Edit ` : `Create `) + `bidder configuration`}
      >
        <BidderConfigurationForm onSubmit={handleCloseDialog} initialFormData={configFormToEdit} />
      </ReusableDialog>
      <ConfigurationStatusMenu
        currentStatus={selectedStatus}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onStatusClick={handleNewStatusClick}
        isLoading={updateConfigIsLoading}
      />
      <ConfirmationDialog
        isOpen={openDeleteConfirmationDialog}
        title={`Do you want to delete ${configToEdit?.name} from the bidders list?`}
        confirmButtonTitle="Delete bidder"
        declineButtonTitle="Cancel"
        onConfirm={() => {
          if (configToEdit) {
            deletePartnersConfigurationById(configToEdit.id.toString())
              .unwrap()
              .then(() => {
                alert(`Configuration with id ${configToEdit.id} deleted`);
                handleCloseDeleteConfirmationDialog();
              });
          }
        }}
        onDecline={handleCloseDeleteConfirmationDialog}
      />
    </Box>
  );
};

export default ConfigurationPage;
