import { emptyRegionId } from "./BidderAdapterForm";
import {
  ConfigurationFormData,
  IBidderAdapter,
  IBidderConfig,
  ICountryBepp,
  IMediaType,
  IPartnerBepp,
} from "./interfaces";

export interface IFormErrors {
  [key: string]: string;
}

export interface IPartnerMapping {
  name: string;
  canAdmin: boolean;
}

export const validateConfigurationFormData = (
  formData: ConfigurationFormData,
  activeStepIndex: number,
  existingConfigs: IBidderConfig[]
): IFormErrors => {
  const errors: IFormErrors = {};
  if (activeStepIndex === 0) {
    if (!formData.partnerDummyId) {
      errors.partnerDummyId = "Partner is required";
    }
    if (formData.isActive === null || formData.isActive === undefined) {
      errors.isActive = "Status is required";
    }
    if (!formData.name?.trim()) {
      errors.bidderAlias = "Configuration name is required";
    } else {
      const duplicateConfig = existingConfigs.find(
        (conf) =>
          conf.id !== formData.id &&
          conf.partnerDummyId === formData.partnerDummyId &&
          conf.bidderAdapterId === formData.bidderAdapterId &&
          conf.name.trim().toLowerCase() === formData.name.trim().toLowerCase()
      );
      if (duplicateConfig) {
        errors.bidderAlias = "This configuration name is already used for this partner-bidder pair";
      }
    }
    if (!formData.bidderAdapterId) {
      errors.bidderAdapterId = "Bidder is required";
    }
  }

  if (activeStepIndex === 1) {
    if (
      formData.isCountriesModeInclude !== null &&
      (!formData.geoCountriesIds || formData.geoCountriesIds?.length === 0)
    ) {
      errors.geoCountriesIds = "No countries selected";
    }
    if (formData.qpsLimit != null) {
      const qpsNum = Number(formData.qpsLimit);
      if (isNaN(qpsNum) || qpsNum <= 0) {
        errors.qpsLimit = "QPS limit must be a number greater than 0";
      }
    }
  }

  return errors;
};

const isValidUrl = (url: string): boolean => {
  const urlRegex = /^(http(s)?:\/\/)([\w\-]+\.)+[\w\-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=.]*)*$/;
  return urlRegex.test(url.trim());
};

export const validateBidderAdapterForm = (formData: IBidderAdapter): IFormErrors => {
  const errors: IFormErrors = {};
  if (!formData.bidderName.trim()) {
    errors.bidderName = "Bidder name is required";
  }
  if (!formData.mediatypesIds || formData.mediatypesIds.length === 0) {
    errors.mediatypesIds = "At least one mediatype is required";
  }
  if (formData.regions) {
    formData.regions.forEach((item, idx) => {
      //Region ID required
      if (item.id === emptyRegionId) {
        errors[`endpoint_region_${idx}`] = "Region is required";
      }
      if (formData.regions.filter((region) => region.id === item.id).length > 1) {
        errors[`endpoint_region_${idx}`] = "Region is duplicated";
      }
      // Endpoint URL required
      if (!item.endpoint.trim()) {
        errors[`endpoint_url_${idx}`] = "Endpoint URL is required";
      } else if (!isValidUrl(item.endpoint)) {
        errors[`endpoint_url_${idx}`] = "Endpoint URL is invalid";
      }
    });
  }

  return errors;
};

export const createPartnerMapping = (partners: IPartnerBepp[]) => {
  return partners.reduce((map, partner) => {
    map[partner.dummyId] = {
      name: partner.name,
      canAdmin: partner?.canAdmin || false,
    };
    return map;
  }, {} as Record<string, IPartnerMapping>);
};

export const createBiddersMapping = (bidders: IBidderAdapter[]) => {
  return bidders.reduce((map, bidder) => {
    map[bidder.id] = bidder.bidderName;
    return map;
  }, {} as Record<string, string>);
};

export const createMediatypesMapping = (mediaTypes: IMediaType[]) => {
  return mediaTypes.reduce((map, mediaType) => {
    map[mediaType.id] = mediaType.name;
    return map;
  }, {} as Record<string, string>);
};

export const createCountriesMapping = (countries: ICountryBepp[]) => {
  return countries.reduce((map, country) => {
    map[country.id] = country;
    return map;
  }, {} as Record<string, ICountryBepp>);
};
