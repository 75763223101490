import { CONSTANT } from "../../constants/constants";
import { getLastDayOfMonth } from "./monthPicker-utils";

const datePickerValidationErrors = {
  monthly:
    'Please, adjust selection range - selected dates are not in full calendar month range or change date grouping mode to "Day" to continue with this one',
  quarterly:
    'Please, adjust selection range - selected dates are not in full calendar quarter range or change date grouping mode to "Day" or "Month" to continue with this one',
};

interface DateRange {
  startDate: Date | string;
  endDate: Date | string;
}

interface ValidationResult {
  isValid: boolean;
  warnMessage?: string;
}

export const validateSelection = (dateGroupingMode: number, dateRange: DateRange[]): ValidationResult => {
  switch (dateGroupingMode) {
    case CONSTANT.DATE_GROUP_MODES.DAY: {
      return { isValid: true };
    }
    case CONSTANT.DATE_GROUP_MODES.HOUR: {
      return { isValid: true };
    }
    case CONSTANT.DATE_GROUP_MODES.AGGREGATED: {
      return { isValid: true };
    }
    case CONSTANT.DATE_GROUP_MODES.MONTH: {
      let { startDate, endDate } = dateRange[0];
      let start = new Date(startDate);
      let end = new Date(endDate);
      let lastDay = getLastDayOfMonth(end.getFullYear(), end.getMonth()).getDate();
      if (start.getDate() === 1 && end.getDate() === lastDay) {
        return { isValid: true };
      } else {
        return { isValid: false, warnMessage: datePickerValidationErrors.monthly };
      }
    }
    case CONSTANT.DATE_GROUP_MODES.QUARTER: {
      let { startDate, endDate } = dateRange[0];
      let start = new Date(startDate);
      let end = new Date(endDate);

      if (isQuarterStart(start) && isQuarterEnd(end)) {
        // Additionally, you could check if the end date is exactly three months after the start date for a single quarter,
        // or if you want to allow spans of multiple quarters, check if the end of one quarter aligns with the start of another.
        return { isValid: true };
      }

      return { isValid: false, warnMessage: datePickerValidationErrors.quarterly };
    }
    default: {
      return { isValid: false };
    }
  }
};
function isQuarterStart(date: Date) {
  // Checks if the date is the start of a quarter in local time
  return date.getDate() === 1 && [0, 3, 6, 9].includes(date.getMonth());
}

function isQuarterEnd(date: Date) {
  // Generate a new date object at the end of the day (23:59:59) to ensure we cover the whole day
  let endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

  // Checks if the date is the last day of a quarter in local time
  let lastDayOfQuarter = new Date(endOfDay.getFullYear(), endOfDay.getMonth() + 1, 0);
  return endOfDay.getDate() === lastDayOfQuarter.getDate() && [2, 5, 8, 11].includes(endOfDay.getMonth());
}
