import { List, ListItem, ListItemText, Link, Box, ListItemIcon } from "@mui/material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { useGetTechDocsQuery } from "../../accounts/accountsApi";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";

const TechDocsPage = () => {
  const { data: links, isFetching, isLoading } = useGetTechDocsQuery();

  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }
  if (!links?.length) return <>No data</>;

  return (
    <List
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "0.5rem",
      }}
    >
      {links.map((linkItem, index) => (
        <Box
          key={index}
          sx={{
            padding: "1.5rem",
            border: "1px solid #ccc",
            borderRadius: "0.5rem",
            marginBottom: "0.5rem",
          }}
        >
          <ListItem>
            <ListItemIcon>
              <TextSnippetOutlinedIcon />
            </ListItemIcon>
            <Link href={linkItem.url} target="_blank" rel="noopener noreferrer">
              <ListItemText primary={linkItem.description} sx={{ color: "#000" }} />
            </Link>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default TechDocsPage;
