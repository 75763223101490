import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  ListItemButton,
  PopoverVirtualElement,
  Popper,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckboxWrapper from "../CustomCheckbox/Checkbox";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { colors } from "../../theme";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CONSTANT } from "../../constants/constants";
import SearchIconText from "../IconComponents/SearchIconText";
import { CategoryType } from "../../parametersConstancy/parametersConstancyTypes";
import { FolderSpecialOutlined } from "@mui/icons-material";

export interface DropdownProps {
  popperStyle?: SxProps;
  textFieldStyle?: SxProps;
  dropdownOpened: boolean;
  dropdownAnchorEl: HTMLElement | PopoverVirtualElement | null;
  allOptionsItemsLength: number;
  options: CategoryType[];
  values: CategoryType[];
  checkAll: boolean;
  checkAllVisible: boolean;
  isMultiSelection: boolean;
  base: string;
  clearButtonClickCallback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  applyButtomClickCallback: (base: string, selectedItems: any[]) => void;
  selectItemCallback: (option: any) => void;
  searchCallback: (value: string) => void;
  checkAllChangeCallback: (checked: boolean) => void;
  checkAllVisibleChangeCallback: (checked: boolean) => void;
  handleClickAwayCallback: () => void;
  [key: string]: any;
  isApplyDisabled?: boolean;
  notification?: string;
  matchedItemsNumber: number | null;
  specialItem?: CategoryType;
  toggleSpecialItem?: (checked: boolean) => void;
  checkSpecialItem?: boolean;
  isPresentationModeEnabled: boolean;
}

export const defaultPopperStyle: SxProps = {
  zIndex: 10110,
  overflow: "hidden",
  width: "388px",
  marginTop: "8px !important",
};
export const defaultTextFieldStyle: SxProps = {
  paddingLeft: "4px",
  height: "48px",
  "& .MuiInputBase-root": {
    height: "48px",
    border: "none",
    borderRadius: "0",
    "& > fieldset": {
      height: "48px",
      top: "0px",
      border: "none",
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    height: "48px",
  },
  input: {
    height: "48px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px", // Set icon size
  },
};

export const selectAllBoxStyle: SxProps = {
  display: "flex",

  height: "48px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: colors.text01,
  alignItems: "center",
  cursor: "pointer",
  marginTop: "8px",
  marginBottom: "4px",
  gap: "12px",
  backgroundColor: colors.foreground01,
  "&:hover": {
    backgroundColor: colors.gray75,
  },
  "&:active": {
    backgroundColor: colors.gray200,
  },
  "&.Mui-focused": {
    backgroundColor: colors.foreground01,
    border: `1px solid ${colors.borderPressed}`,
  },
  "&.Mui-disabled": {
    backgroundColor: colors.foreground01,
    color: colors.gray300,
  },
};

const Dropdown: React.FC<DropdownProps> = ({
  popperStyle = defaultPopperStyle,
  textFieldStyle = defaultTextFieldStyle,
  dropdownOpened = false,
  dropdownAnchorEl = null,
  allOptionsItemsLength = 0,
  options = [],
  values = [],
  checkAll = false,
  checkAllVisible = false,
  isMultiSelection = true,
  base = "",
  clearButtonClickCallback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {},
  applyButtomClickCallback = () => {},
  selectItemCallback = (option: any) => {},
  searchCallback = (value: string) => {},
  checkAllChangeCallback = (checked: boolean) => {},
  checkAllVisibleChangeCallback = (checked: boolean) => {},
  handleClickAwayCallback = () => {},
  isApplyDisabled = false,
  notification = "",
  matchedItemsNumber = null,
  specialItem = null,
  toggleSpecialItem = (action: any) => {},
  checkSpecialItem = false,
  isPresentationModeEnabled = false,
}) => {
  const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
    const option: CategoryType = options[index];
    const isCheckedAll = checkAll;
    const checked = values.map((item: any) => item.id).indexOf(option?.id) !== -1;
    if (!option || option.id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
      return null;
    }
    return (
      <ListItemButton
        disabled={isCheckedAll && allOptionsItemsLength > CONSTANT.FILTER_ITEMS_TRESHOLD}
        id="dropdown-item"
        key={`dropdown-item-key-${option.id}`}
        onClick={() => {
          if (!isCheckedAll || allOptionsItemsLength < CONSTANT.FILTER_ITEMS_TRESHOLD) {
            selectItemCallback(option);
            specialItem?.isChecked && toggleSpecialItem(false);
          }
        }}
        style={{
          ...style,
          padding: "6px 0",
          gap: "12px",
        }}
      >
        <CheckboxWrapper id="dropdown-item-checkbox" checked={checked} />
        {isPresentationModeEnabled && base === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base ? option.id : option.name}
      </ListItemButton>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayCallback}>
      <Popper
        id="dropdown-popup"
        open={dropdownOpened}
        anchorEl={dropdownAnchorEl}
        disablePortal={false}
        placement="bottom-start"
        sx={popperStyle}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
            },
          },
        ]}
      >
        <TextField
          id="dropdown-search-field"
          disabled={checkAll && allOptionsItemsLength > CONSTANT.FILTER_ITEMS_TRESHOLD}
          fullWidth
          sx={textFieldStyle}
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            searchCallback(e.target.value)
          }
          InputProps={{
            startAdornment: <SearchIconText />,
          }}
          placeholder={`Search`}
          autoFocus
        />
        <Divider />
        <Box
          sx={{
            flexDirection: "column",
            padding: "0 16px",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          {isMultiSelection &&
            allOptionsItemsLength > 0 &&
            (matchedItemsNumber !== null ? (
              <Box
                onClick={() => checkAllVisibleChangeCallback(!checkAllVisible)}
                sx={{ ...selectAllBoxStyle, justifyContent: "space-between" }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <CheckboxWrapper
                    checked={checkAllVisible}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      checkAllVisibleChangeCallback(e.target.checked)
                    }
                    id="dropdown-select-all-checkbox"
                    onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
                  />
                  {"Select All Visible"}
                </Box>
                <Box>{`${values.length} / ${matchedItemsNumber}`}</Box>
              </Box>
            ) : (
              <Box onClick={() => checkAllChangeCallback(!checkAll)} sx={selectAllBoxStyle}>
                <CheckboxWrapper
                  checked={checkAll}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkAllChangeCallback(e.target.checked)}
                  id="dropdown-select-all-checkbox"
                  onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
                />
                Select All
              </Box>
            ))}
          {specialItem && (
            <Box
              onClick={() => {
                toggleSpecialItem(!checkSpecialItem);
              }}
              sx={selectAllBoxStyle}
            >
              <>
                <CheckboxWrapper id="dropdown-special-item-checkbox" checked={checkSpecialItem} />
                {specialItem.name}
              </>
            </Box>
          )}
          <FixedSizeList //FIXME add Autosizer to manage List size by item count
            height={options.length * 48 > 249 ? 249 : options.length * 48}
            itemCount={options.length}
            itemSize={48} // Adjust this based on your ListItem height
            style={{
              display: "flex",
              alignItems: "center",
            }}
            width={"100%"}
          >
            {Row}
          </FixedSizeList>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "8px 16px 8px 0px",
            justifyContent: "space-between",
            alignItems: "flex-start",
            alignSelf: "stretch",
            borderRadius: "0px 0px 16px 16px",
            background: colors.blueishGrey75,
          }}
        >
          <Button id="dropdown-clear-all-btn" onClick={clearButtonClickCallback} variant="clearAll">
            Clear all
          </Button>
          <Button
            id="dropdown-apply-btn"
            onClick={() => applyButtomClickCallback(base, values)}
            variant="apply"
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
        </Box>
        {notification && (
          <Box
            sx={{
              padding: "1rem",
              fontSize: "1rem",
              color: "coral",
              width: "75%",
            }}
          >
            <Typography id="dropdown-notification">{notification}</Typography>
          </Box>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default Dropdown;
