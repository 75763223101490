import React, { ReactNode } from "react";
import { Box, Button, Divider, ListItemButton, SxProps, TextField, Typography } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import CheckboxWrapper from "../../../../components/CustomCheckbox/Checkbox";
import { CONSTANT } from "../../../../constants/constants";
import { colors } from "../../../../theme";
import { CategoryType } from "../../../../parametersConstancy/parametersConstancyTypes";
import SearchIconText from "../../../../components/IconComponents/SearchIconText";

const defaultTextFieldStyle: SxProps = {
  marginTop: "16px",
  "& .MuiOutlinedInput-root": {
    height: "48px",
    borderRadius: "8px",
    "& fieldset": {
      border: `1px solid ${colors.borderDefault}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${colors.brandPressed}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${colors.brandPressed}`,
    },
  },
  "& .MuiInputBase-input": {
    height: "48px",
    paddingLeft: "4px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px",
  },
};

export interface DropdownFilterGrouprProps {
  isReadOnlyView?: boolean;
  options: CategoryType[];
  values: CategoryType[];
  base: string;
  handleToggleOpen: () => void;
  buttonTitle: string;
  openedFilter: string;
  handleSelectItems: (selectedItem: CategoryType) => void;
  handleSearch: (value: string) => void;
  isMultiSelection?: boolean;
  shouldBeSelected?: boolean;
  isDisabled?: boolean;
  selectAllEnabled?: boolean;
  // Now two separate handlers:
  handleSelectAll: (select: boolean) => void;
  handleSelectAllVisible: (select: boolean) => void;
  // And two separate checkbox states:
  selectAllSelected: boolean;
  selectAllVisible: boolean;
  matchedItemsNumber?: number | null;
  actionItemsTop?: ReactNode;
  textFieldStyle?: SxProps;
  id?: string;
}
const LIST_ITEM_HEIGHT = 40;
const ITEMS_LIST_MAX_HEIGHT = 278;

const GroupFilter: React.FC<DropdownFilterGrouprProps> = ({
  isReadOnlyView = false,
  options,
  values,
  base = "",
  handleToggleOpen,
  handleSelectItems,
  handleSearch,
  buttonTitle,
  openedFilter,
  isDisabled = false,
  selectAllEnabled = false,
  selectAllSelected,
  selectAllVisible,
  handleSelectAll,
  handleSelectAllVisible,
  actionItemsTop = <></>,
  textFieldStyle = defaultTextFieldStyle,
  id,
  matchedItemsNumber = null,
}) => {
  const { isPresentationModeEnabled } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );

  const dividerItem = { id: "divider", name: "divider" };
  let combinedOptions = values;
  if (!isReadOnlyView) {
    combinedOptions =
      values.length === 0
        ? [...options]
        : options.length === 0
        ? [...values]
        : [...values, dividerItem, ...options];
  }
  const isPartnersFilter =
    base === CONSTANT.REPORTS.PARTNERS.base || base === CONSTANT.REPORTS.BE_PP.PARTNERS_CONFIG.base;
  const isOpened = openedFilter === base || isPartnersFilter;
  const valuesCount =
    values[0]?.id === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id
      ? `(${CONSTANT.REPORTS.SELECTED_ALL_OBJECT.name})`
      : values?.length > 0
      ? `(${values.length})`
      : "";

  const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
    const option = combinedOptions[index];
    if (option.id === "divider") {
      return <Divider style={{ ...style, height: LIST_ITEM_HEIGHT / 2 }} />;
    }
    const isCheckedAll = options.length === 0;
    const isEdit = !(isCheckedAll && base === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base);
    const checked = values.map((item: any) => item.id).indexOf(option?.id) !== -1;
    return (
      option && (
        <ListItemButton
          disabled={!isReadOnlyView && base === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base && isCheckedAll}
          id={`${id}-list-item`}
          key={`${option.id}`}
          onClick={() => (!isEdit || isReadOnlyView ? null : handleSelectItems(option))}
          style={{
            ...style,
            padding: "0",
            gap: "12px",
            pointerEvents: !isEdit || isReadOnlyView ? "none" : "auto",
          }}
        >
          {!isReadOnlyView && <CheckboxWrapper id={`${id}-list-item-checkbox`} checked={checked} />}
          <Typography variant="body1Small400">
            {(isPresentationModeEnabled && base === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base) ||
            (isPresentationModeEnabled && base === CONSTANT.REPORTS.PARTNERS.base)
              ? option.id
              : option.name}
          </Typography>
        </ListItemButton>
      )
    );
  };

  const isVisibleFilter = matchedItemsNumber !== null;

  return (
    <Box>
      {!isPartnersFilter && (
        <Button
          disabled={isDisabled}
          sx={{
            background: `${isOpened ? colors.backgroundBaseTertiary : colors.mainWhite}`,
            boxShadow: "none",
            borderRadius: "8px",
            justifyContent: "space-between",
            ":hover": {
              background: `${colors.backgroundBaseTertiary}`,
              boxShadow: "none",
            },
            ":focus": {
              background: `${isOpened ? colors.backgroundBaseTertiary : colors.mainWhite}`,
            },
          }}
          fullWidth
          variant="contained"
          onClick={handleToggleOpen}
          id={`${id}-${buttonTitle.toLowerCase().replaceAll(" ", "-")}-btn`}
        >
          <Typography variant="bodyLargeRegular" color={isDisabled ? colors.textDisabled : ""}>
            {`${buttonTitle} ${valuesCount}`}
          </Typography>
        </Button>
      )}
      {isOpened && (
        <Box>
          {!isReadOnlyView && (
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                sx={textFieldStyle}
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  handleSearch(e.target.value)
                }
                InputProps={{
                  startAdornment: <SearchIconText />,
                }}
                placeholder="Search"
                autoFocus
                id={`${id}-search-input`}
              />
            </Box>
          )}
          {actionItemsTop}
          <Box sx={{ paddingLeft: "8px", marginTop: "8px" }}>
            {selectAllEnabled && (
              <>
                {isVisibleFilter && (
                  <ListItemButton
                    disabled={false}
                    key={`select-all-visible-id`}
                    id={`${id}-select-all-visible-item`}
                    onClick={() => {
                      handleSelectAllVisible(!selectAllVisible);
                    }}
                    style={{
                      padding: "0",
                      gap: "12px",
                    }}
                  >
                    {!isReadOnlyView && (
                      <CheckboxWrapper id={`${id}-select-all-visible-checkbox`} checked={selectAllVisible} />
                    )}
                    <Typography variant="body1Small400">
                      {`Select All Visible ${values.length} / ${matchedItemsNumber}`}
                    </Typography>
                  </ListItemButton>
                )}
                {!isVisibleFilter && (
                  <ListItemButton
                    disabled={false}
                    key={`select-all-id`}
                    id={`${id}-select-all-item`}
                    onClick={() => {
                      handleSelectAll(!selectAllSelected);
                    }}
                    style={{
                      padding: "0",
                      gap: "12px",
                    }}
                  >
                    {!isReadOnlyView && (
                      <CheckboxWrapper id={`${id}-select-all-checkbox`} checked={selectAllSelected} />
                    )}
                    <Typography variant="body1Small400">Select All</Typography>
                  </ListItemButton>
                )}
              </>
            )}
            <FixedSizeList
              height={
                combinedOptions.length * LIST_ITEM_HEIGHT > ITEMS_LIST_MAX_HEIGHT
                  ? ITEMS_LIST_MAX_HEIGHT + (isPartnersFilter ? 40 : 0)
                  : combinedOptions.length * LIST_ITEM_HEIGHT
              }
              itemCount={combinedOptions.length}
              itemSize={LIST_ITEM_HEIGHT}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              width="100%"
            >
              {Row}
            </FixedSizeList>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GroupFilter;
