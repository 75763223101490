import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { checkVersion } from "../../infrastructure/infrastructureHelpers";
import { CONSTANT } from "../../constants/constants";
import { IBidderAdapter, IUpdateBidderAdapterDto } from "./interfaces";
import { configurationsApi } from "./configurationsApi";

const SERVER_URL = CONSTANT.path.host;

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { getState } = api;
  const state = getState() as RootState;
  const token = state.users?.loggedUser?.token;

  if (!token) {
    return {
      error: {
        status: 401,
        data: { message: "No token available for authentication." },
      },
    } as { error: FetchBaseQueryError };
  }

  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/bidders-config`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  });
  const result = await baseQuery(args, api, extraOptions);
  if (result.data && typeof result.data === "object" && "reactAppVersion" in result.data) {
    checkVersion((result.data as any).reactAppVersion);
  }
  return result;
};

export const bidderAdapterApi = createApi({
  reducerPath: "bidderAdapterApi",
  baseQuery: customBaseQuery,
  tagTypes: ["BidderAdapter"],
  endpoints: (builder) => ({
    getAllBidders: builder.query<IBidderAdapter[], void>({
      query: () => "bidadapters",
      providesTags: ["BidderAdapter"],
    }),
    createBidderAdapter: builder.mutation<IBidderAdapter, IUpdateBidderAdapterDto>({
      query: (body) => ({
        url: "bidders",
        method: "POST",
        body,
      }),
      invalidatesTags: ["BidderAdapter"],
    }),

    updateBidderAdapter: builder.mutation<IBidderAdapter, { id: number; data: IUpdateBidderAdapterDto }>({
      query: ({ id, data }) => ({
        url: `bidders/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BidderAdapter"],
    }),
    deleteBidderAdapter: builder.mutation<void, number>({
      query: (id) => ({
        url: `bidders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BidderAdapter"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(configurationsApi.util.invalidateTags(["BidderConfig"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useGetAllBiddersQuery,
  useCreateBidderAdapterMutation,
  useUpdateBidderAdapterMutation,
  useDeleteBidderAdapterMutation,
} = bidderAdapterApi;
