import { colors } from "../../theme";

interface IconProps {
  color?: string;
  fontSize?: "small" | "medium" | "large" | string; // Allow predefined or custom font sizes
}

export const StarIcon: React.FC<IconProps> = ({ color, fontSize = "medium" }) => {
  const fillColor = color === "inherit" ? "currentColor" : color ?? colors.text02;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.85 16.825L12 14.925L15.15 16.85L14.325 13.25L17.1 10.85L13.45 10.525L12 7.125L10.55 10.5L6.9 10.825L9.675 13.25L8.85 16.825ZM5.825 21L7.45 13.975L2 9.25L9.2 8.625L12 2L14.8 8.625L22 9.25L16.55 13.975L18.175 21L12 17.275L5.825 21Z"
        fill={fillColor}
      />
    </svg>
  );
};
export const FireIcon: React.FC<IconProps> = ({ color, fontSize = "medium" }) => {
  const fillColor = color === "inherit" ? "currentColor" : color ?? colors.text02;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22C9.76667 22 7.875 21.225 6.325 19.675C4.775 18.125 4 16.2333 4 14C4 12.1167 4.55833 10.3083 5.675 8.57501C6.79167 6.84167 8.325 5.32501 10.275 4.02501C10.6417 3.77501 11.0208 3.76251 11.4125 3.98751C11.8042 4.21251 12 4.55001 12 5.00001V6.30001C12 6.86667 12.1958 7.34167 12.5875 7.72501C12.9792 8.10834 13.4583 8.30001 14.025 8.30001C14.3083 8.30001 14.5792 8.23751 14.8375 8.11251C15.0958 7.98751 15.325 7.80834 15.525 7.57501C15.6583 7.40834 15.8292 7.30417 16.0375 7.26251C16.2458 7.22084 16.4417 7.26667 16.625 7.40001C17.675 8.15001 18.5 9.10834 19.1 10.275C19.7 11.4417 20 12.6833 20 14C20 16.2333 19.225 18.125 17.675 19.675C16.125 21.225 14.2333 22 12 22ZM6 14C6 14.8667 6.175 15.6875 6.525 16.4625C6.875 17.2375 7.375 17.9167 8.025 18.5C8.00833 18.4167 8 18.3417 8 18.275V18.05C8 17.5167 8.1 17.0167 8.3 16.55C8.5 16.0833 8.79167 15.6583 9.175 15.275L12 12.5L14.825 15.275C15.2083 15.6583 15.5 16.0833 15.7 16.55C15.9 17.0167 16 17.5167 16 18.05V18.275C16 18.3417 15.9917 18.4167 15.975 18.5C16.625 17.9167 17.125 17.2375 17.475 16.4625C17.825 15.6875 18 14.8667 18 14C18 13.1667 17.8458 12.3792 17.5375 11.6375C17.2292 10.8958 16.7833 10.2333 16.2 9.65001C15.8667 9.86667 15.5167 10.0292 15.15 10.1375C14.7833 10.2458 14.4083 10.3 14.025 10.3C12.9917 10.3 12.0958 9.95834 11.3375 9.27501C10.5792 8.59167 10.1417 7.75001 10.025 6.75001C8.725 7.85001 7.72917 9.02084 7.0375 10.2625C6.34583 11.5042 6 12.75 6 14ZM12 15.3L10.575 16.7C10.3917 16.8833 10.25 17.0917 10.15 17.325C10.05 17.5583 10 17.8 10 18.05C10 18.5833 10.1958 19.0417 10.5875 19.425C10.9792 19.8083 11.45 20 12 20C12.55 20 13.0208 19.8083 13.4125 19.425C13.8042 19.0417 14 18.5833 14 18.05C14 17.7833 13.95 17.5375 13.85 17.3125C13.75 17.0875 13.6083 16.8833 13.425 16.7L12 15.3Z"
        fill={fillColor}
      />
    </svg>
  );
};
export const VectorArrowDownIcon: React.FC<IconProps> = ({ color, fontSize = "medium" }) => {
  const fillColor = color === "inherit" ? "currentColor" : color ?? colors.text02;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 21.9C11.8667 21.9 11.7417 21.875 11.625 21.825C11.5084 21.775 11.4 21.7 11.3 21.6L5.70005 16.025C5.51672 15.8416 5.42505 15.6083 5.42505 15.325C5.42505 15.0416 5.51672 14.8 5.70005 14.6C5.90005 14.4 6.13755 14.3 6.41255 14.3C6.68755 14.3 6.92505 14.4 7.12505 14.6L11 18.5V12.325C11 12.0416 11.0959 11.8041 11.2875 11.6125C11.4792 11.4208 11.7167 11.325 12 11.325C12.2834 11.325 12.5209 11.4208 12.7125 11.6125C12.9042 11.8041 13 12.0416 13 12.325V18.5L16.9 14.6C17.0834 14.4166 17.3125 14.325 17.5875 14.325C17.8625 14.325 18.1 14.425 18.3 14.625C18.4834 14.8083 18.575 15.0416 18.575 15.325C18.575 15.6083 18.4834 15.8416 18.3 16.025L12.7 21.6C12.6 21.7 12.4917 21.775 12.375 21.825C12.2584 21.875 12.1334 21.9 12 21.9ZM12 9.32495C11.7167 9.32495 11.4792 9.22912 11.2875 9.03745C11.0959 8.84578 11 8.60828 11 8.32495V7.32495C11 7.04162 11.0959 6.80412 11.2875 6.61245C11.4792 6.42078 11.7167 6.32495 12 6.32495C12.2834 6.32495 12.5209 6.42078 12.7125 6.61245C12.9042 6.80412 13 7.04162 13 7.32495V8.32495C13 8.60828 12.9042 8.84578 12.7125 9.03745C12.5209 9.22912 12.2834 9.32495 12 9.32495ZM12 4.32495C11.7167 4.32495 11.4792 4.22912 11.2875 4.03745C11.0959 3.84578 11 3.60828 11 3.32495C11 3.04162 11.0959 2.80412 11.2875 2.61245C11.4792 2.42078 11.7167 2.32495 12 2.32495C12.2834 2.32495 12.5209 2.42078 12.7125 2.61245C12.9042 2.80412 13 3.04162 13 3.32495C13 3.60828 12.9042 3.84578 12.7125 4.03745C12.5209 4.22912 12.2834 4.32495 12 4.32495Z"
        fill={fillColor}
      />
    </svg>
  );
};
export const GraphArrowUpIcon: React.FC<IconProps> = ({ color, fontSize = "medium" }) => {
  const fillColor = color === "inherit" ? "currentColor" : color ?? colors.text02;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.70011 17.625C2.50011 17.425 2.40428 17.1875 2.41261 16.9125C2.42094 16.6375 2.51678 16.4083 2.70011 16.225L7.97511 10.875C8.35844 10.4916 8.83344 10.3 9.40011 10.3C9.96678 10.3 10.4418 10.4916 10.8251 10.875L13.4001 13.475L18.6001 8.32495H17.0001C16.7168 8.32495 16.4793 8.22912 16.2876 8.03745C16.0959 7.84578 16.0001 7.60828 16.0001 7.32495C16.0001 7.04162 16.0959 6.80412 16.2876 6.61245C16.4793 6.42078 16.7168 6.32495 17.0001 6.32495H21.0001C21.2834 6.32495 21.5209 6.42078 21.7126 6.61245C21.9043 6.80412 22.0001 7.04162 22.0001 7.32495V11.325C22.0001 11.6083 21.9043 11.8458 21.7126 12.0375C21.5209 12.2291 21.2834 12.325 21.0001 12.325C20.7168 12.325 20.4793 12.2291 20.2876 12.0375C20.0959 11.8458 20.0001 11.6083 20.0001 11.325V9.72495L14.8251 14.9C14.4418 15.2833 13.9668 15.475 13.4001 15.475C12.8334 15.475 12.3584 15.2833 11.9751 14.9L9.40011 12.325L4.10011 17.625C3.91678 17.8083 3.68344 17.9 3.40011 17.9C3.11678 17.9 2.88344 17.8083 2.70011 17.625Z"
        fill={fillColor}
      />
    </svg>
  );
};
export const GraphArrowDownIcon: React.FC<IconProps> = ({ color, fontSize = "medium" }) => {
  const fillColor = color === "inherit" ? "currentColor" : color ?? colors.text02;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.6001 16L13.4001 10.85L10.8251 13.425C10.4418 13.8084 9.96678 14 9.40011 14C8.83344 14 8.35844 13.8084 7.97511 13.425L2.70011 8.10005C2.51678 7.91672 2.42094 7.68755 2.41261 7.41255C2.40428 7.13755 2.50011 6.90005 2.70011 6.70005C2.88344 6.51672 3.11678 6.42505 3.40011 6.42505C3.68344 6.42505 3.91678 6.51672 4.10011 6.70005L9.40011 12L11.9751 9.42505C12.3584 9.04172 12.8334 8.85005 13.4001 8.85005C13.9668 8.85005 14.4418 9.04172 14.8251 9.42505L20.0001 14.6V13C20.0001 12.7167 20.0959 12.4792 20.2876 12.2875C20.4793 12.0959 20.7168 12 21.0001 12C21.2834 12 21.5209 12.0959 21.7126 12.2875C21.9043 12.4792 22.0001 12.7167 22.0001 13V17C22.0001 17.2834 21.9043 17.5209 21.7126 17.7125C21.5209 17.9042 21.2834 18 21.0001 18H17.0001C16.7168 18 16.4793 17.9042 16.2876 17.7125C16.0959 17.5209 16.0001 17.2834 16.0001 17C16.0001 16.7167 16.0959 16.4792 16.2876 16.2875C16.4793 16.0959 16.7168 16 17.0001 16H18.6001Z"
        fill={fillColor}
      />
    </svg>
  );
};
