import React, { useState, Dispatch, SetStateAction } from "react";
import {
  Box,
  Divider,
  Typography,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { setIsReportingPartnersSelected, setCurrentMultiselectGroup } from "../../role/roleSlice";
import { MultiselectGroupButton } from "../Button/Button";
import FilterGroupDialogForm, {
  DialogTypes,
} from "../../core/performanceReports/advancedReport/filtersBoard/FilterGroupDialogForm";
import { CONSTANT } from "../../constants/constants";
import { colors } from "../../theme";
import { CategoriesType, FilterGroup } from "../../parametersConstancy/parametersConstancyTypes";

interface IGroup {
  id: number;
  name: string;
  partners: Array<{ id: number; name: string }>;
}

interface IPartnerAdditionalElementsProps {
  filterGroupsPartner: FilterGroup[] | undefined;
  currentMultiselectGroup: FilterGroup | null;
  dialogActionsType: string;
  setDialogActionsType: Dispatch<SetStateAction<string>>;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  groupToEdit: FilterGroup;
  setGroupToEdit: Dispatch<SetStateAction<any>>;
  handleClickOpenFilterGroupDialogForm: (dialogActionsType: string) => void;
  expanded: boolean;
  handleExpand: () => void;
  partnersSelectedLength: number; // used to enable/disable "New custom group" button
  partnersUpdatedValues: CategoriesType;
}

const PartnerAdditionalElements: React.FC<IPartnerAdditionalElementsProps> = ({
  filterGroupsPartner,
  currentMultiselectGroup,
  dialogActionsType,
  setDialogActionsType,
  openDialog,
  setOpenDialog,
  groupToEdit,
  setGroupToEdit,
  handleClickOpenFilterGroupDialogForm,
  expanded,
  handleExpand,
  partnersSelectedLength,
  partnersUpdatedValues,
}) => {
  const dispatch = useDispatch();
  const { isReportingPartnersSelected } = useSelector((state: any) => state.role);

  return (
    <React.Fragment>
      {/* --- Switch + "Only reporting partners" --- */}
      <Box sx={{ padding: "0 8px 0 8px" }}>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            border: "none",
          }}
        >
          <Typography variant="body1Small400">Only reporting partners</Typography>
          <Switch
            checked={isReportingPartnersSelected}
            onChange={(e) => {
              dispatch(setIsReportingPartnersSelected(e.target.checked));
            }}
            inputProps={{}}
            id="only-reported-partners-switch"
          />
        </Box>

        {/* --- Accordion listing "Custom group" --- */}
        {filterGroupsPartner && filterGroupsPartner.length > 0 && (
          <Accordion
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            expanded={expanded}
            onChange={handleExpand}
            elevation={0}
            disableGutters
          >
            <AccordionSummary
              sx={{ padding: "0 8px 0 8px", border: "none" }}
              expandIcon={<ExpandMoreIcon />}
              id="select-partner-modal-custom-group-accordion"
            >
              <Typography variant="body1Small600">{`Custom group (${filterGroupsPartner.length})`}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 8px 0 8px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "4px",
                  overflow: "auto",
                  maxHeight: "112px",
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                {filterGroupsPartner.map((group) => (
                  <MultiselectGroupButton
                    partnersString={group.partners.map((p) => p.name).join(", ")}
                    isActive={group.id === currentMultiselectGroup?.id}
                    name={group.name}
                    groupId={group.id}
                    key={group.id}
                    onClick={() => {
                      dispatch(setCurrentMultiselectGroup(group));
                      // Typically you'd also close the Popper here if needed
                    }}
                    onEdit={() => {
                      setGroupToEdit(group);
                      handleClickOpenFilterGroupDialogForm(DialogTypes.EDIT);
                    }}
                    id="custom-group-item"
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>

      {/* --- "New custom group" button --- */}
      <Box sx={{ display: "flex", justifyContent: "center", padding: "12px 16px 12px 16px" }}>
        <Button
          onClick={() => {
            handleClickOpenFilterGroupDialogForm(DialogTypes.CREATE);
          }}
          variant={
            partnersSelectedLength > 0 ? "outlinedFullRoundFullWidth" : "outlinedFullRoundFullWidthDisabled"
          }
          disabled={partnersSelectedLength === 0}
          id="select-partner-modal-new-group-btn"
        >
          <Typography
            variant="body1Small600"
            color={partnersSelectedLength > 0 ? colors.brandDefault : colors.backgroudDisabled}
          >
            + New custom group
          </Typography>
        </Button>
      </Box>

      {/* --- The modal for creating/editing custom groups --- */}
      <FilterGroupDialogForm
        title={""}
        open={openDialog}
        closeDialog={() => setOpenDialog(false)}
        dialogActionsType={dialogActionsType}
        filterGroup={groupToEdit}
        enableFilters={false}
        type={CONSTANT.FILTER_GROUP_TYPE.PARTNER}
        id="new-partner-group-modal"
        partnersSelected={partnersUpdatedValues?.partners ?? []}
      />
    </React.Fragment>
  );
};

export default PartnerAdditionalElements;
