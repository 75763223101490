import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { colors, graphColors } from "../../../theme";
import { convertLocal } from "../../../utils";

interface CustomTooltipLegendProps {
  tooltipData: { label: string; payload: any[] } | null;
  isAB?: boolean;
  isCPM?: boolean;
  currencySymbol?: string;
}

const CustomTooltipLegend: React.FC<CustomTooltipLegendProps> = ({
  tooltipData,
  isAB = false,
  isCPM = false,
  currencySymbol,
}) => {
  if (!tooltipData || !tooltipData?.payload) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Typography variant="bodyLargeRegular">Hover over the chart to see details</Typography>
      </Box>
    );
  }
  const tooltipToDisplay = !isAB
    ? tooltipData.payload.sort((a: any, b: any) => b.value - a.value)
    : isCPM
    ? [
        {
          name: "With IIQ",
          value: currencySymbol + " " + convertLocal(tooltipData.payload[0].payload.bar1),
          color: colors.peaceBlue,
        },
        {
          name: "Without IIQ",
          value: currencySymbol + " " + convertLocal(tooltipData.payload[0].payload.bar3),
          color: graphColors.graphPurple,
        },
      ]
    : [
        {
          name: "Imp with IIQ",
          value: convertLocal(tooltipData.payload[0].payload.totalRows1 ?? 0),
          color: colors.peaceBlue,
        },
        {
          name: "Imp without IIQ",
          value: convertLocal(tooltipData.payload[0].payload.totalRows2 ?? 0),
          color: graphColors.graphPurple,
        },
        {
          name: "Revenue with IIQ",
          value: currencySymbol + " " + convertLocal(tooltipData.payload[0].payload.bar1),
          color: colors.peaceBlue,
        },
        {
          name: "Revenue without IIQ",
          value: currencySymbol + " " + convertLocal(tooltipData.payload[0].payload.totalCpm2),
          color: graphColors.graphPurple,
        },
        {
          name: "Revenue extrapolation 100% without IIQ",
          value: currencySymbol + " " + convertLocal(tooltipData.payload[0].payload.bar3),
          color: graphColors.graphPurpleHover,
        },
      ];

  return (
    <Box>
      <Typography variant="body1Small600">{tooltipData.label}</Typography>
      <Divider sx={{ margin: "2px 0" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
        }}
      >
        {tooltipToDisplay?.map((entry: any, index: any) => (
          <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography textAlign={"left"} variant="bodyMedium" width={"60%"}>
              {entry.name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: colors.backgroundBaseTertiary,
                padding: "0px 2px",
                borderRadius: "8px",
                gap: "2px",
                height: "24px",
              }}
            >
              <Typography>{isAB ? entry.value : `${currencySymbol} ${convertLocal(entry.value)}`}</Typography>
              <CircleIcon style={{ color: entry.color, width: "10px", height: "10px" }} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomTooltipLegend;
