// src/components/SelectMultiLabelsWrapper.tsx

import React, { useState, useEffect, useMemo } from "react";
import { ClickAwayListener } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from "../../constants/constants";
import { useGetFilterGroupsQuery } from "../../parametersConstancy/parametersContancyFetches";
import { setCurrentPlatformParameter } from "../../parametersConstancy/platformParametersSlice";
import { setParametersConstancyWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { getPartnersListType, setCurrentMultiselectGroup, applyPartners } from "../../role/roleSlice";
import DropdownButton from "../Button/DropdownButton";
import MultiLabelsPopper from "./MultiLabelsPopper";
import { CategoriesType } from "../../parametersConstancy/parametersConstancyTypes";
import { useGetAllPartnersConfigurationQuery } from "../../core/configuration/configurationsApi";

export const partnerDropdownTextFieldStyle = {
  marginTop: 0,
  "& .MuiOutlinedInput-root": {
    height: "48px",
    borderRadius: "18px",
    "& fieldset": {
      border: `none`,
    },
    "&:hover fieldset": {
      border: `none`,
    },
  },
  "& .MuiInputBase-input": {
    height: "48px",
    paddingLeft: "4px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px",
  },
};

export interface IPartner {
  id: number;
  name: string;
  isSelected: boolean;
  pid?: number;
  dummy_id?: number;
}

interface ISelectMultiLabelsWrapperProps {
  bepp?: boolean;
}

const SelectMultiLabelsWrapper: React.FC<ISelectMultiLabelsWrapperProps> = ({ bepp = false }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { loggedUser } = useSelector((state: any) => state.users);
  const {
    isReportingPartnersSelected,
    partnersList: partnersDashboard,
    currentMultiselectGroup,
    partnersSelected,
  } = useSelector((state: any) => state.role);

  const { data: filterGroupsRaw } = useGetFilterGroupsQuery();
  const { data: partnersConfigurations } = useGetAllPartnersConfigurationQuery(undefined, { skip: !bepp });

  const partnerbase = bepp ? CONSTANT.REPORTS.BE_PP.PARTNERS_CONFIG.base : CONSTANT.REPORTS.PARTNERS.base;

  const filterGroupsPartner = filterGroupsRaw?.filter(
    (group: any) => group.type === CONSTANT.FILTER_GROUP_TYPE.PARTNER
  );

  // Derive the partners list from either the normal partners or from configuration (bepp)
  const partnersList: IPartner[] = useMemo(() => {
    if (bepp && partnersConfigurations) {
      dispatch(
        setCurrentPlatformParameter(
          setParametersConstancyWrapper(
            "configurationParameters",
            "selectedPartnerIds",
            partnersConfigurations?.map((p) => +p.dummyId) || []
          )
        )
      );

      return (
        partnersConfigurations?.map((pc: any) => ({
          id: pc.dummyId,
          name: pc.name,
          isSelected: true,
        })) || []
      );
    } else {
      return partnersDashboard || [];
    }
  }, [bepp, partnersConfigurations, partnersDashboard]);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogActionsType, setDialogActionsType] = useState("");
  const [groupToEdit, setGroupToEdit] = useState<any>({
    name: "",
    type: "partner",
    partners: [],
  });

  const [partnersUpdatedValues, setPartnersUpdatedValues] = useState<CategoriesType>({});
  const [partnersUpdatedOptions, setPartnersUpdatedOptions] = useState<CategoriesType>({});

  const [title, setTitle] = useState("Select partners");

  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const handleClickOpenFilterGroupDialogForm = (dialogType: string) => {
    setDialogActionsType(dialogType);
    //setOpen(false);
    setOpenDialog(true);
  };

  useEffect(() => {
    setPartnersUpdatedValues({
      [partnerbase]: partnersList.filter((p) => p.isSelected),
    });
    setPartnersUpdatedOptions({
      [partnerbase]: partnersList.filter((p) => !p.isSelected),
    });
  }, [partnersList, partnerbase]);

  useEffect(() => {
    if (currentMultiselectGroup) {
      setTitle(`${currentMultiselectGroup.name} group selected`);
    } else {
      const numberOfPartners = partnersUpdatedValues[partnerbase]?.length || 0;
      switch (numberOfPartners) {
        case 0:
          setTitle("Select partners");
          break;
        case 1:
          setTitle("1 partner selected");
          break;
        default:
          setTitle(`${numberOfPartners} partners selected`);
      }
    }
  }, [partnersSelected, currentMultiselectGroup, partnersUpdatedValues, partnerbase]);

  useEffect(() => {
    if (!bepp && loggedUser?.token && partnersDashboard?.length === 0) {
      const abortController = new AbortController();
      dispatch(getPartnersListType({ signal: abortController.signal }));
      return () => {
        abortController.abort();
      };
    }
  }, [bepp, dispatch]);

  const handleApply = () => {
    if (bepp && partnersUpdatedValues) {
      dispatch(
        setCurrentPlatformParameter(
          setParametersConstancyWrapper(
            "configurationParameters",
            "selectedPartnerIds",
            partnersUpdatedValues[partnerbase]?.map((p) => +p.id) || []
          )
        )
      );
    } else {
      dispatch(applyPartners(partnersUpdatedValues[partnerbase] || []));
      dispatch(setCurrentMultiselectGroup(null));
    }
    setOpen(false);
  };

  const handleClear = (event?: any) => {
    event?.stopPropagation();
    setPartnersUpdatedValues({
      [partnerbase]: [],
    });
    setPartnersUpdatedOptions({
      [partnerbase]: partnersList,
    });
    dispatch(setCurrentMultiselectGroup(null));
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      setOpen(false);
      setAnchorEl(null);
      // revert local changes
      setPartnersUpdatedValues({
        [partnerbase]: partnersList.filter((p) => p.isSelected),
      });
      setPartnersUpdatedOptions({
        [partnerbase]: partnersList.filter((p) => !p.isSelected),
      });
    } else {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Enter" && open && partnersUpdatedValues[partnerbase]?.length) {
        handleApply();
      }
    };
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [open, partnersUpdatedValues]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          setOpen(false);
          setAnchorEl(null);
          // revert local changes if user clicks away
          setPartnersUpdatedValues({
            [partnerbase]: partnersList.filter((p) => p.isSelected),
          });
          setPartnersUpdatedOptions({
            [partnerbase]: partnersList.filter((p) => !p.isSelected),
          });
        }
      }}
    >
      <div>
        <DropdownButton
          title={title}
          id="partners-select"
          variant="filter"
          dropdownOpened={open}
          isDisabled={false}
          buttonCallback={handleButtonClick}
          clearIconCallback={handleClear}
          shouldBeSelected={true}
          base={partnerbase}
          selectedItemsLength={partnersUpdatedValues[partnerbase]?.length || 0}
        />

        <MultiLabelsPopper
          open={open}
          anchorEl={anchorEl}
          partnerbase={partnerbase}
          bepp={bepp}
          // pass your "flip" modifier if needed
          flipOptions={{
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
            },
          }}
          filterGroupsPartner={filterGroupsPartner}
          currentMultiselectGroup={currentMultiselectGroup}
          dialogActionsType={dialogActionsType}
          setDialogActionsType={setDialogActionsType}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          groupToEdit={groupToEdit}
          setGroupToEdit={setGroupToEdit}
          handleClickOpenFilterGroupDialogForm={handleClickOpenFilterGroupDialogForm}
          expanded={expanded}
          handleExpand={handleExpand}
          isReportingPartnersSelected={isReportingPartnersSelected}
          partnersUpdatedValues={partnersUpdatedValues}
          setPartnersUpdatedValues={setPartnersUpdatedValues}
          partnersUpdatedOptions={partnersUpdatedOptions}
          setPartnersUpdatedOptions={setPartnersUpdatedOptions}
          textFieldStyle={partnerDropdownTextFieldStyle}
          handleApply={handleApply}
          handleClear={handleClear}
          partnersSelectedLength={partnersUpdatedValues[partnerbase]?.length || 0}
          selectAllEnabled={partnersList?.length > 1}
        />
      </div>
    </ClickAwayListener>
  );
};

export default SelectMultiLabelsWrapper;
