import getSymbolFromCurrency from "currency-symbol-map";
import React, { useMemo } from "react";
import { AggregateCustomBaseDataOutput, CustomChartIds, ICustomChartItem } from "../types";
import CustomChart from "./CustomChart";

interface CustomChartWrapperProps {
  chartItem: ICustomChartItem;
  getAggregatedData: (chartId: string) => AggregateCustomBaseDataOutput;
  isLoading: boolean;
  uniqueCurrencyPctCombinations: any;
}

export const CustomChartWrapper: React.FC<CustomChartWrapperProps> = React.memo(
  ({ chartItem, getAggregatedData, isLoading, uniqueCurrencyPctCombinations }) => {
    const aggregatedData = useMemo(() => getAggregatedData(chartItem.id), [chartItem.id, getAggregatedData]);
    const currencySymbol =
      chartItem.id !== CustomChartIds.IMPRESSIONS
        ? getSymbolFromCurrency(uniqueCurrencyPctCombinations.find((c: any) => c.isSelected)?.currency || "NR") ||
          "NR"
        : "";

    if (!aggregatedData.items) return null;

    return (
      <CustomChart
        chartItem={chartItem}
        aggregatedData={aggregatedData}
        isLoading={isLoading}
        currencySymbol={currencySymbol}
      />
    );
  }
);
