import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, TextField, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { columnsModelBidders } from "./columnsModel";
import { IBidderAdapter } from "./interfaces";
import StyledDataGrid, { datagridCSS } from "../../components/StyledDataGrid/StyledDataGrid";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import { textFieldInputStlBepp } from "../../accounts/styles";
import BidderAdapterForm from "./BidderAdapterForm";
import { ReusableDialog } from "./ReusableDialog";
import { useGetMediaTypesQuery, useGetRegionsQuery } from "./configurationsApi";
import {
  useCreateBidderAdapterMutation,
  useDeleteBidderAdapterMutation,
  useGetAllBiddersQuery,
  useUpdateBidderAdapterMutation,
} from "./bidderAdaptersApi";
import { useMediaTypesMapping } from "./useMappings";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { CONSTANT } from "../../constants/constants";
import { DataGrid } from "@mui/x-data-grid";

const BiddersPage: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { loggedUser } = useSelector((state: RootState) => state.users);

  const [bidderAdapterToEdit, setBidderAdapterToEdit] = useState<IBidderAdapter | null>(null);
  const { data: bidderAdapters } = useGetAllBiddersQuery();
  const { data: regions } = useGetRegionsQuery();
  const { data: mediaTypes } = useGetMediaTypesQuery();
  const [createBidderAdapter, { isLoading: createBidderAdapterIsLoading }] = useCreateBidderAdapterMutation();
  const [updateBidderAdapter, { isLoading: updateBidderAdapterIsLoading }] = useUpdateBidderAdapterMutation();
  const [deleteBidderAdapter, { isLoading: deleteBidderAdapterIsLoading }] = useDeleteBidderAdapterMutation();
  const [filteredBidders, setFilteredBidders] = useState<IBidderAdapter[] | null>(null);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const [isBidderAdapterFormLoading, setIsBidderAdapterFormLoading] = useState(false);
  const { mediatypesMapping } = useMediaTypesMapping();

  useEffect(() => {
    setIsBidderAdapterFormLoading(
      createBidderAdapterIsLoading || updateBidderAdapterIsLoading || deleteBidderAdapterIsLoading
    );
  }, [createBidderAdapterIsLoading, updateBidderAdapterIsLoading, deleteBidderAdapterIsLoading]);

  // Filter logic
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  // Menu close
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
  };

  // Dialog open/close
  const handleOpenDialog = () => {
    // Ensure we clear any "edit" item when the user explicitly wants to add a new one
    setBidderAdapterToEdit(null);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Optionally clear `bidderAdapterToEdit` again
    setBidderAdapterToEdit(null);
  };

  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
  };

  // Filter the bidders when filterText or bidderAdapters change
  useEffect(() => {
    if (filterText && bidderAdapters) {
      setFilteredBidders(
        bidderAdapters.filter((c) => c.bidderName.toLowerCase().includes(filterText.toLowerCase()))
      );
    } else {
      // If no filter text, show all
      setFilteredBidders(bidderAdapters || []);
    }
  }, [filterText, bidderAdapters]);

  const handleFormSubmit = async (formData: IBidderAdapter) => {
    try {
      if (bidderAdapterToEdit) {
        // We are editing an existing bidder
        await updateBidderAdapter({ id: bidderAdapterToEdit.id, data: formData }).unwrap();
      } else {
        // We are creating a new bidder
        await createBidderAdapter(formData).unwrap();
      }
    } catch (err) {
      console.error("Error saving bidder adapter:", err);
    } finally {
      handleCloseDialog();
    }
  };

  const handleConfirmDelete = async () => {
    try {
      if (bidderAdapterToEdit) {
        await deleteBidderAdapter(bidderAdapterToEdit.id).unwrap();
      }
    } catch (err) {
      console.error("Error deleting bidder adapter:", err);
    } finally {
      handleCloseDeleteConfirmationDialog();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {/* Top bar: Filter, Add new */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* Search input */}
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "12px" }}>
          <TextField
            id="search-input"
            placeholder="Search bidder"
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            sx={{
              fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
              ...textFieldInputStlBepp,
              padding: "2px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {loggedUser.modulesVisibility.includes(CONSTANT.MODULE.BID_ADAPTER_ADMIN) && (
          <Box>
            <Button disabled={false} onClick={handleOpenDialog} variant="apply" startIcon={<AddIcon />}>
              Add new bidder
            </Button>
          </Box>
        )}
      </Box>

      {/* Bidders list table */}
      <Box style={{ height: 400, width: "100%" }}>
        <DataGrid
          rowHeight={40}
          sx={datagridCSS}
          rows={filteredBidders || []}
          columns={columnsModelBidders({
            onEdit: (id) => {
              const toEdit = bidderAdapters?.find((c) => c.id === id) || null;
              setBidderAdapterToEdit(toEdit);
              setOpenDialog(true);
            },
            onDelete: async (id) => {
              const toDelete = bidderAdapters?.find((c) => c.id === id) || null;
              if (toDelete) {
                setBidderAdapterToEdit(toDelete);
                setOpenDeleteConfirmationDialog(true);
              }
            },
            isAdmin: loggedUser.modulesVisibility.includes(CONSTANT.MODULE.BID_ADAPTER_ADMIN),
            mediatypesMapping,
          })}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 50, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
        />
      </Box>

      {/* Dialog for Create/Edit */}
      <ReusableDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={bidderAdapterToEdit ? "Edit bidder configuration" : "Add new bidder"}
      >
        <Box sx={{ width: "100%", paddingRight: "16px" }}>
          <BidderAdapterForm
            initialFormData={bidderAdapterToEdit || undefined}
            onSubmit={handleFormSubmit}
            availableMediatypes={mediaTypes || []}
            availableRegions={regions || []}
            isBidderAdapterFormLoading={isBidderAdapterFormLoading}
            existingBidderAdapters={bidderAdapters || []}
          />
        </Box>
      </ReusableDialog>

      <ConfirmationDialog
        isOpen={openDeleteConfirmationDialog}
        title={`Do you want to delete ${bidderAdapterToEdit?.bidderName} from the bidders list?`}
        confirmButtonTitle="Delete bidder"
        declineButtonTitle="Cancel"
        onConfirm={handleConfirmDelete}
        onDecline={handleCloseDeleteConfirmationDialog}
      />
    </Box>
  );
};

export default BiddersPage;
