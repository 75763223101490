import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
  Customized,
} from "recharts";
import { Box, Typography } from "@mui/material";
import { AggregateCustomBaseDataOutput, ICustomChart, ICustomChartItem } from "./../types";
import { colors, graphColors } from "../../../theme";
import CustomTooltipLegend from "./CustomTooltipLegend";
import CustomAxisTick from "./CustomAxisTick";
import { formatYAxis } from "../utils";
import { convertLocal } from "../../../utils";
import { CustomizedAxisTick } from "../../../components/chartTemplates/utils";

interface ABBarChartProps {
  aggregatedData: AggregateCustomBaseDataOutput;
  groups: string[];
  chartType: ICustomChart;
  currencySymbol: string;
  chartItem: ICustomChartItem;
}

const ABBarChart: React.FC<ABBarChartProps> = ({
  aggregatedData,
  groups,
  chartType,
  currencySymbol,
  chartItem,
}) => {
  const [tooltipData, setTooltipData] = useState<{ label: any; payload: any[] } | null>(null);
  const [selectedData, setSelectedData] = useState<{ label: any; payload: any[] } | null>(null);

  useEffect(() => {
    setSelectedData(null);
    setTooltipData(null);
  }, [groups]);

  if (!aggregatedData || aggregatedData.items.length === 0) {
    return <Typography>No data for AB chart</Typography>;
  }

  const barRadius = 8;
  const BAR_WIDTH = 150;

  // Format data for the bar chart.
  const data = aggregatedData.items
    .filter((p) => groups.includes(p.entity))
    .map((p) => ({
      name: p.entity,
      bar1: p[chartType.bar1Base as string],
      bar2: p[chartType.bar2Base as string],
      bar3: p[chartType.bar3Base as string],
      totalRows1: p.totalRows1,
      totalRows2: p.totalRows2,
      totalCpm2: p.totalCpm2,
      currencySymbol,
    }))
    .sort((a, b) => b.bar1 - a.bar1);

  const computedWidth = data.length * BAR_WIDTH * 0.95;
  const barsNumThreshold = 5;

  const handleMouseMove = (state: any) => {
    if (!state) return;
    if (!selectedData && state.isTooltipActive) {
      setTooltipData({
        label: state.activeLabel,
        payload: state.activePayload,
      });
    }
  };

  const handleMouseLeave = () => {
    if (!selectedData) {
      setTooltipData(null);
    }
  };

  const handleChartClick = (state: any) => {
    if (state && state.activeLabel) {
      if (selectedData && selectedData.label === state.activeLabel) {
        setSelectedData(null);
      } else {
        setSelectedData({
          label: state.activeLabel,
          payload: state.activePayload,
        });
      }
    }
  };

  const barValueFormatter = (value: number) => {
    return currencySymbol + " " + convertLocal(value, 1);
  };

  // Custom label for bars.
  const CustomMuiLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <foreignObject x={x} y={y - 20} width={50} height={20}>
        <Typography variant="bodyXSmallMedium">{barValueFormatter(value)}</Typography>
      </foreignObject>
    );
  };

  // Updated render function that removes any key prop from the spread.
  const renderCustomAxisTick = (props: any) => {
    const { key, ...rest } = props;
    const { payload } = rest;
    const isSelected = selectedData && selectedData.label === payload.value;
    return <CustomAxisTick {...rest} selected={isSelected} />;
  };

  const renderSelectionRect = (chartProps: any) => {
    if (!selectedData) return null;
    const { xAxisMap, offset, height } = chartProps;
    const xAxis = xAxisMap[Object.keys(xAxisMap)[0]];
    const { scale, bandSize } = xAxis;
    const category = selectedData.label;
    const bandX = scale(category);
    if (bandX === undefined || bandSize === undefined) return null;

    const rectWidth = 112; // Fixed width
    const rectX = bandX + (bandSize - rectWidth) / 2;

    return (
      <rect
        x={rectX}
        y={offset.top}
        width={rectWidth}
        height={height - offset.top - offset.bottom}
        fill={"rgba(245, 238, 252, 0.45)"}
        stroke={colors.navy50}
        strokeWidth={1}
        rx={"8px"}
      />
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: chartItem.liftVisible ? "column" : "row",
        width: "100%",
      }}
    >
      <Box sx={{ overflowX: "auto", width: "100%", overflowY: "hidden", height: "320px" }}>
        <Box sx={{ width: groups.length > barsNumThreshold ? computedWidth : "auto" }}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              margin={{
                top: 20,
                right: 15,
                bottom: 0,
                left: 0,
              }}
              data={data}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onClick={handleChartClick}
              barGap={10}
              barCategoryGap={15}
            >
              <CartesianGrid strokeWidth={1} stroke={colors.navy50} />
              <XAxis
                dataKey="name"
                tick={renderCustomAxisTick}
                tickLine={false}
                axisLine={false}
                stroke={colors.navy50}
                interval={0}
              />
              <YAxis
                tickFormatter={formatYAxis}
                stroke={colors.navy50}
                tick={<CustomizedAxisTick />}
                // tick={{ fill: colors.text02 }}
              />
              <Tooltip content={() => null} cursor={false} />
              <Customized component={renderSelectionRect} />
              <Bar
                barSize={BAR_WIDTH / 4}
                dataKey="bar2"
                stackId="a"
                fill={graphColors.graphPurple}
                radius={[0, 0, barRadius, barRadius]}
              />
              <Bar dataKey="bar1" stackId="a" fill={colors.peaceBlue} radius={chartType.bar3Base !== "avgCpmb" ? [barRadius, barRadius, 0, 0] : barRadius}>
                <LabelList dataKey="bar1" position="top" offset={10} content={CustomMuiLabel} />
              </Bar>
              <Bar
                barSize={BAR_WIDTH / 4}
                dataKey="bar3"
                stackId="b"
                fill={chartType.bar3Base !== "avgCpmb" ? graphColors.graphPurpleHover : graphColors.graphPurple}
                radius={[barRadius, barRadius, barRadius, barRadius]}
              >
                <LabelList dataKey="bar3" position="top" offset={10} content={CustomMuiLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Box
        sx={{
          height: chartItem.liftVisible ? "168px" : "auto",
          borderTop: `1px solid ${colors.navy50}`,
          padding: "8px 12px",
          overflowY: "hidden",
          backgroundColor: colors.gray50,
          width: !chartItem.liftVisible ? "380px" : "auto",
        }}
      >
        <CustomTooltipLegend
          tooltipData={selectedData || tooltipData}
          isAB={true}
          isCPM={chartType.bar3Base === "avgCpmb"}
          currencySymbol={
            (selectedData?.payload && selectedData?.payload[0]?.payload?.currencySymbol) ||
            (tooltipData?.payload && tooltipData?.payload[0].payload.currencySymbol) ||
            ""
          }
        />
      </Box>
    </Box>
  );
};

export default ABBarChart;
