export const styleDatePicker = {
  position: "absolute",
  top: "50px",
  // left: "-85px",
  zIndex: 10000,
  boxShadow: "0px 40px 40px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "0.5rem",
};

export const styleDatePickerFooter = {
  textAlign: "center",
  background: "#fff",
  paddingBottom: "0.75rem",
  display: "flex",
  justifyContent: "center",
};

export const styleDatePickerBtn = {
  width: "3rem",
  padding: 0,
  color: "#1BB2FF",
};

export const styleMonthPickerWrapper = {
  width: "20rem",
};

export const adaptStyleDatePicker = (checked, matches) => {
  return {
    width: checked ? "38.43rem" : "62.5rem",
    backgroundColor: "#fff",
    right: "-30vw",
  };
};

export const adaptColorBtn = (isOpened) => {
  return {
    background: isOpened ? "rgb(239, 242, 247)" : "none",
  };
};
