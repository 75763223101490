import { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { ModulePermissionsCheckboxes, modulesTypesArray } from "./ModulePermissionsCheckboxes";
import { boardWrapperStl, titleStl } from "../sideBoard/boardStl";
import { useGetAccountsQuery } from "../accountsApi";
import { formatDate } from "../../utils";
import { getRoleDetails } from "../api/accounts-api";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import { useUpdateRoleEntityMutation } from "../accountsApi";
import Loader from "../../components/Loader";
import { SelectItemsWrapper } from "../../components/SelectItems/SelectItemsWrapper";
import { CONSTANT } from "../../constants/constants";
import { useSelector } from "react-redux";

const getArrayUniqueObjectsByKey = (array, key) => {
  const arrayUniqueByKey = [...new Map(array.map((item) => [item[key], item])).values()];
  return arrayUniqueByKey;
};

export default function EditRoleEntitiyBoardHandler(props) {
  const { loggedUser } = useSelector((state) => state.users);
  const [showLoader, setShowLoader] = useState(false);
  const [updateRoleEntity, { isError: addError }] = useUpdateRoleEntityMutation();
  const { data: accountsFetched, refetch: refetchAccountsAndRoles } = useGetAccountsQuery();
  const [formData, setFormData] = useState({
    name: props.value.row.name,
    // roleDescription: "",
    target: props.value.row.id,
    accounts: [],
    partners: [],
    permissions: {},
  });
  const [accountsWithSelection, setAccountsWithSelection] = useState();
  const [partnersWithSelection, setPartnersWithSelection] = useState();
  const [targetAccountId, setTargetAccountId] = useState(props.value.row);
  const [canSubmit, setCanSubmit] = useState(true);
  const [value, setValue] = useState({
    rmp: null,
    dashboard: null,
    billing: null,
  });
  let isHidden = false;

  useEffect(() => {
    if (
      (formData?.accounts.filter((a) => a.isSelected)?.length === 0 &&
        formData?.partners.filter((p) => p.isSelected)?.length === 0) ||
      Object.values(formData?.permissions).every((k) => k === null) ||
      formData?.name?.length === 0
    )
      setCanSubmit(false);
    else setCanSubmit(true);
  }, [formData]);

  useEffect(() => {
    setShowLoader(true);
    getRoleDetails(props.value.row.id).then((data) => {
      if (data.statusCode === 403) {
        setShowLoader(false);
        props.onClose();
        alert("Action stopped: " + data.error);
        throw new Error("Forbidden_resource");
      }

      let accounts = [];
      let partners = [];
      let partnersWS = [];
      let s = new Set();

      data.forEach((i) => {
        if (i.accountId !== null) accounts.push({ acctName: i.accountName, acctId: i.accountId });
        if (i.partnerId !== null) partners.push(i);
        s.add(i.moduleId);
      });

      let m = Array.from(s);
      let objOfPermissions = {};

      for (let i of m) {
        for (let mod of modulesTypesArray) {
          if (mod[1] === i || mod[2] === i) objOfPermissions[mod[0]] = i;
        }
      }

      let uniqAccounts = getArrayUniqueObjectsByKey(accounts, "acctId");
      let uniqPartners = getArrayUniqueObjectsByKey(partners, "partnerId");
      let accountsWS = accountsFetched.list.map((acc) => {
        return { ...acc, isSelected: uniqAccounts.some((a) => a.acctId === acc.id) };
      });
      //TODO fix the temporary solution
      if (!!targetAccountId.virtualAccount) {
        accountsWS = accountsWS.filter(
          (i) =>
            i.parentAccount === targetAccountId.accountId ||
            i.id === targetAccountId.accountId ||
            targetAccountId.accountId === CONSTANT.IIQ_ACCOUNT_ID
        );
      } else if (targetAccountId.accountId == CONSTANT.IIQ_ACCOUNT_ID) {
        accountsWS = accountsWS.filter((i) => i.parentAccount >= targetAccountId.accountId);
      } else {
        accountsWS = accountsWS.filter((i) => i.id === targetAccountId.accountId);
      }
      for (let i of accountsWS) {
        for (let k of i.parnterData)
          partnersWS.push({
            ...k,
            id: k.partnerId,
            isSelected: uniqPartners.some((p) => p.partnerId === k.partnerId),
          });
      }
      setAccountsWithSelection(accountsWS);
      setPartnersWithSelection(partnersWS);
      setValue(objOfPermissions);
      setFormData({ ...formData, accounts: accountsWS, partners: partnersWS, permissions: objOfPermissions });
      setShowLoader(false);
    });
  }, []);

  const handleChangeCheckbox = (moduleKey, permissionValue) => {
    const newValue = {
      ...value,
      [moduleKey]: value[moduleKey] === permissionValue ? null : permissionValue,
    };
    setValue(newValue);
    setFormData({ ...formData, permissions: newValue });
  };

  const handleChangeRoleName = (e) => {
    let name = e.target.value;
    setFormData({ ...formData, name });
  };

  const handleSetSelectedAccounts = (newSelectedItems) => {
    setAccountsWithSelection(newSelectedItems);
    setFormData({
      ...formData,
      accounts: newSelectedItems.filter((i) => i.isSelected),
    });
  };

  const handleSetSelectedPartners = (newSelectedItems) => {
    setPartnersWithSelection(newSelectedItems);
    setFormData({
      ...formData,
      partners: newSelectedItems.filter((i) => i.isSelected),
    });
  };

  const handleRetrieve = async () => {
    let payload = {
      name: formData.name,
      // roleDescription: formData.roleDescription,
      id: props.value.id,
      permissions: Object.keys(formData.permissions)
        .map((i) => formData.permissions[i])
        .filter((i) => i !== null),
    };
    const selectedAccounts = formData.accounts.filter((a) => a.isSelected);
    const selectedPartners = formData.partners.filter((p) => p.isSelected);
    if (selectedAccounts?.length > 0)
      payload.entities = {
        ...payload.entities,
        2: selectedAccounts.map((i) => i.id),
      };
    if (selectedPartners.length > 0)
      payload.entities = {
        ...payload.entities,
        1: selectedPartners.map((i) => i.partnerId),
      };

    setShowLoader(true);
    updateRoleEntity(payload).then((data) => {
      setShowLoader(false);
      if (data.error) {
        console.log(data.error);

        let errMessage = data?.error?.data?.message || "";
        let errStr = errMessage ? errMessage : data.error;
        alert("Action failed, something went wrong... " + errStr);
      } else {
        refetchAccountsAndRoles();
        props.onClose();
      }
    });
  };
  if (showLoader) {
    return <Loader sx={{ height: "80vh" }} />;
  }

  return (
    <div id="edit-role-popup">
      <div style={boardWrapperStl}>
        <Box sx={{ ...titleStl, marginBottom: "1rem" }}>Role name: </Box>
        <TextField
          id="role-name-input"
          sx={{ ...titleStl, marginBottom: "1rem" }}
          placeholder="Role name..."
          variant="outlined"
          value={formData.name}
          onChange={handleChangeRoleName}
          fullWidth
        />
        <Box id="belongs-to-field" sx={{ ...titleStl, marginBottom: "1rem" }}>
          Belongs to: {props.value.row.accountName}
        </Box>
        <Box id="created-field" sx={{ ...titleStl, marginBottom: "1rem" }}>
          Created: {formatDate(new Date(props.value.row.dateCreation)).fullFormat}
        </Box>
        <Box id="users-amoun-field" sx={{ ...titleStl, marginBottom: "1rem" }}>
          Featuring users amount: {props.value.row.usersAmountPerRole}
        </Box>
        <hr />
        <br />

        <Box sx={{ ...titleStl, marginBottom: "1rem" }}>Selected accounts:</Box>
        <SelectItemsWrapper
          id="accounts-select"
          itemName="account"
          itemFieldName="company"
          items={accountsWithSelection}
          setSelectedItems={handleSetSelectedAccounts}
        />

        <br />
        <Box sx={{ ...titleStl, marginBottom: "1rem" }}>Partners of accounts:</Box>
        <SelectItemsWrapper
          id="partner-select"
          itemName="partner"
          itemFieldName="partnerName"
          items={partnersWithSelection}
          setSelectedItems={handleSetSelectedPartners}
        />
        <br />
        <ModulePermissionsCheckboxes
          value={value}
          handleChangeCheckbox={handleChangeCheckbox}
          loggedUser={loggedUser}
          targetAccountId={targetAccountId.accountId}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        {!isHidden ? (
          <Button id="save-btn" disabled={!canSubmit} sx={buttonSx} onClick={handleRetrieve}>
            Save changes
          </Button>
        ) : null}
      </div>
    </div>
  );
}
