import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, ButtonBase } from "@mui/material";
import { validateConfigurationFormData } from "./utils";
import CustomStepper from "../../components/CustomStepper/CustomStepper";
import { ConfigurationFormData, IBidderConfig, IOverrideRegion } from "./interfaces";
import LabeledTextField from "./LabeledTextField";
import SegmentControl from "../../components/SegmentControl/SegmentControl";

import {
  useCreatePartnersConfigurationMutation,
  useGetAllConfigurationQuery,
  useGetAllPartnersConfigurationQuery,
  useGetCountriesQuery,
  useUpdatePartnersConfigurationByIdMutation,
} from "./configurationsApi";
import { CategoryType } from "../../parametersConstancy/parametersConstancyTypes";
import DropdownHandler from "../../components/Dropdown/DropdownHandler";
import { useGetAllBiddersQuery } from "./bidderAdaptersApi";
import { CONSTANT } from "../../constants/constants";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { colors } from "../../theme";
import { SimpleDropdown } from "../../components/SimpleDropdown/SimpleDropdown";

const steps = [
  { id: 0, name: "Configuration" },
  { id: 1, name: "Additional parameters" },
];
export const geoModesOptions = [
  { id: -1, name: "None" },
  { id: 0, name: "Exclude" },
  { id: 1, name: "Include" },
];

const noOverridingRegion: IOverrideRegion = {
  id: -1,
  name: "No overriding",
  endpoint: "",
};

interface BidderConfigurationFormProps {
  initialFormData?: ConfigurationFormData | null;
  onSubmit: () => void;
}

const defaultFormData: Partial<ConfigurationFormData> = {
  isActive: 1,
  partnerDummyId: null,
  isIdBridgingActive: 0,
  isEnrichmentActive: 1,
  isCountriesModeInclude: null,
  geoCountriesIds: [],
  overrideRegionId: -1,
};

const BidderConfigurationForm: React.FC<BidderConfigurationFormProps> = ({ initialFormData, onSubmit }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [countryOptions, setCountryOptions] = useState<CategoryType[]>([]);
  const [regionOptions, setRegionOptions] = useState<IOverrideRegion[]>([]);
  const [regionValue, setRegionValue] = useState<IOverrideRegion | null>(null);
  const [countryValues, setCountryValues] = useState<CategoryType[]>([]);
  const { data: countries } = useGetCountriesQuery();
  const [formData, setFormData] = useState<ConfigurationFormData>(
    initialFormData || ({ ...defaultFormData } as ConfigurationFormData)
  );
  const { data: configurations } = useGetAllConfigurationQuery();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [createPartnersConfiguration, { isLoading: isCreateConfigurationLoading }] =
    useCreatePartnersConfigurationMutation();
  const [updatePartnersConfigurationById, { isLoading: isUpdateConfigurationLoading }] =
    useUpdatePartnersConfigurationByIdMutation();
  const { data: allBidders } = useGetAllBiddersQuery();
  const { data: partnersConfigurations, isLoading: isPartnersConfigurationsLoading } =
    useGetAllPartnersConfigurationQuery();
  const [partnerOptions, setPartnerOptions] = useState<any>();

  const handleStep = (stepIndex: number) => () => {
    setActiveStepIndex(stepIndex);
  };
  const [openFilter, setOpenFilter] = useState("");

  useEffect(() => {
    const newPartnerOptions =
      partnersConfigurations
        ?.filter((p) => p.canAdmin)
        .map((p) => {
          return { name: p.name, id: +p.dummyId };
        }) || [];
    if (newPartnerOptions.length === 1) {
      setFormData((prev) => ({
        ...prev,
        partnerDummyId: newPartnerOptions[0].id,
      }));
    }
    setPartnerOptions(newPartnerOptions);
  }, [isPartnersConfigurationsLoading]);

  // useEffect(() => {
  //   if (activeStepIndex === 0) {
  //     const validationErrors = validateConfigurationFormData(formData, activeStepIndex);
  //     console.log("validationErrors", validationErrors);
  //     setErrors(validationErrors);
  //   }
  // }, [activeStepIndex, formData?.partnerDummyId, formData?.bidderAdapterId, formData?.name]);

  // useEffect(() => {
  //   console.log("errors", errors);
  // }, [errors]);

  const handleNext = async () => {
    const validationErrors = validateConfigurationFormData(formData, activeStepIndex, configurations || []);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (activeStepIndex < steps.length - 1) {
        setActiveStepIndex((prev) => prev + 1);
      } else {
        const bidderConfiguration: Partial<IBidderConfig> = {
          id: formData.id,
          name: formData.name,
          configParams: formData.configParams,
          isEnrichmentActive: formData.isEnrichmentActive,
          isActive: formData.isActive,
          qpsLimit: formData.qpsLimit,
          bidderAdapterId: formData.bidderAdapterId,
          partnerDummyId: formData.partnerDummyId,
          geo: {
            isCountriesModeInclude: formData.isCountriesModeInclude,
            countriesIds: formData.geoCountriesIds,
          },
          overrideRegionId: formData.overrideRegionId === -1 ? null : formData.overrideRegionId,
          isIdBridgingActive: formData.isIdBridgingActive,
        };
        if (!initialFormData)
          createPartnersConfiguration(bidderConfiguration)
            .unwrap()
            .then(() => {
              alert("Configuration created successfully!");
              onSubmit();
            })
            .catch((error) => console.error("Failed to create configuration:", error));
        else {
          updatePartnersConfigurationById({ data: bidderConfiguration, id: formData.id })
            .unwrap()
            .then(() => {
              alert("Configuration updated successfully!");
              onSubmit();
            })
            .catch((error) => console.error("Failed to updated configuration:", error));
        }
      }
    }
  };

  const handleInputChange = (field: keyof ConfigurationFormData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  useEffect(() => {
    if (countries) {
      const mapped = countries.map((c) => ({
        id: c.id,
        name: c.name,
      }));
      setCountryOptions(mapped);
      setCountryValues(mapped.filter((c) => formData.geoCountriesIds?.includes(c.id)));
    }
  }, [countries]);

  useEffect(() => {
    const bidder = allBidders?.find((b) => b.id === formData.bidderAdapterId);
    setRegionOptions([...(bidder?.regions || []), noOverridingRegion]);
  }, [formData.bidderAdapterId, allBidders]);

  useEffect(() => {
    const regionId = formData.overrideRegionId || -1;
    const region = regionOptions.find((r) => r.id === regionId) || regionOptions[0] || null;
    setRegionValue(region);
  }, [formData.overrideRegionId, regionOptions]);

  const handleApply = (base: string, selectedItems: CategoryType[]) => {
    if (base === CONSTANT.REPORTS.BE_PP.COUNTRIES.base) {
      setCountryValues(selectedItems);
      setFormData((prev) => ({
        ...prev,
        geoCountriesIds: selectedItems.map((s) => +s.id),
      }));
    }
    setOpenFilter("");
  };

  const handleToggleOpen = (base: string) => {
    if (openFilter !== base) setOpenFilter((prev) => (prev === base ? "" : base));
  };

  useEffect(() => {
    setErrors({});
  }, [formData]);

  if (isCreateConfigurationLoading || isUpdateConfigurationLoading) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <CustomStepper
      steps={steps}
      activeStepIndex={activeStepIndex}
      nextDisabled={Object.keys(errors).length > 0 && false}
      handleStepAction={handleNext}
      handleStep={handleStep}
      finalStepActionButtonTitle="Save"
      immidiateSaveEnabled={Object.keys(errors).length === 0 && false}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {activeStepIndex === 0 && (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography variant="body1Small600">Bidder status</Typography>
              <SegmentControl
                options={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                ]}
                value={{
                  id: formData.isActive,
                  name: formData.isActive === 1 ? "Active" : "Inactive",
                }}
                onChange={(status) => handleInputChange("isActive", status.id)}
              />
            </Box>
            <SimpleDropdown
              label="Partner"
              value={formData?.partnerDummyId}
              onChange={(value) => handleInputChange("partnerDummyId", value)}
              options={partnerOptions || []}
              error={!!errors.partnerDummyId}
              helperText={errors.partnerDummyId}
              id="dropdown-partner"
              required
            />
            <SimpleDropdown
              label="Bidder"
              value={formData?.bidderAdapterId}
              onChange={(value) => handleInputChange("bidderAdapterId", value)}
              options={
                allBidders?.map((b) => {
                  return { id: b.id, name: b.bidderName };
                }) || []
              }
              error={!!errors.bidderAdapterId}
              helperText={errors.bidderAdapterId}
              id={"dropdown-bidder"}
              required
            />
            <LabeledTextField
              label="Bidder config name"
              value={formData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!!errors.bidderAlias}
              helperText={errors.bidderAlias}
              id="text-bidder-config-name"
              required
            />
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography variant="body1Small600">ID Bridging</Typography>
              <SegmentControl
                options={[
                  { id: 1, name: "On" },
                  { id: 0, name: "Off" },
                ]}
                value={{
                  id: formData.isIdBridgingActive,
                  name: formData.isIdBridgingActive === 1 ? "Active" : "Inactive",
                }}
                onChange={(status) => handleInputChange("isIdBridgingActive", status.id)}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography variant="body1Small600">Enrichment</Typography>
              <SegmentControl
                options={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                ]}
                value={{
                  id: formData.isEnrichmentActive,
                  name: formData.isEnrichmentActive === 1 ? "Active" : "Inactive",
                }}
                onChange={(status) => handleInputChange("isEnrichmentActive", status.id)}
              />
            </Box>
          </>
        )}

        {activeStepIndex === 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body1Small600">Geo</Typography>
                  <SimpleDropdown
                    label="Mode"
                    value={formData?.isCountriesModeInclude === null ? -1 : formData?.isCountriesModeInclude}
                    onChange={(value) => {
                      const newValue = value === -1 ? null : value;
                      handleInputChange("isCountriesModeInclude", newValue);
                    }}
                    options={
                      geoModesOptions?.map((b) => {
                        return { id: b.id, name: b.name };
                      }) || []
                    }
                    error={false}
                    helperText={""}
                    id={"dropdown-geo-mode"}
                  />
                </Box>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: "4px" }}>
                  Country
                </Typography>
                <DropdownHandler
                  base={CONSTANT.REPORTS.BE_PP.COUNTRIES.base}
                  title={CONSTANT.REPORTS.BE_PP.COUNTRIES.title}
                  isDisabled={formData?.isCountriesModeInclude === null}
                  optionsData={countryOptions}
                  valuesData={countryValues}
                  openFilter={openFilter}
                  handleToggleOpen={handleToggleOpen}
                  handleApply={handleApply}
                  isMultiSelection={true}
                  filtersIsLoading={{}}
                  shouldBeSelected={false}
                  isApplyDisabled={false}
                  notification=""
                  id="countries-dropdown-bepp"
                />
                <Typography variant="body1Small400" sx={{ color: colors.error }}>
                  {errors.geoCountriesIds}
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography variant="body1Small600">QPS</Typography>
              <LabeledTextField
                label="Amount"
                value={formData.qpsLimit?.toString() || ""}
                onChange={(e) => handleInputChange("qpsLimit", e.target.value)}
                error={!!errors.qpsLimit}
                helperText={errors.qpsLimit}
                id={"text-qps-limit"}
              />
            </Box>
            <Box>
              <Typography variant="body1Small600">Parameters</Typography>
              <LabeledTextField
                label="Bidder parameters"
                value={formData.configParams?.toString() || ""}
                onChange={(e) => handleInputChange("configParams", e.target.value)}
                error={!!errors.configParams}
                helperText={errors.configParams}
                id={"text-bidder-parameters"}
              />
            </Box>
            <Box>
              <Typography variant="body1Small600">Endpoint</Typography>
              <SimpleDropdown
                label="Region"
                value={regionValue?.id || -1}
                onChange={(value) => handleInputChange("overrideRegionId", value)}
                options={
                  regionOptions?.map((b) => {
                    return { id: b.id, name: b.name };
                  }) || []
                }
                error={!!errors.bidderId}
                helperText={errors.bidderId}
                id="dropdown-region"
              />
              {regionValue?.id !== noOverridingRegion.id && (
                <LabeledTextField
                  label="Endpoint URL"
                  value={regionValue?.endpoint || ""}
                  onChange={(e) => {}}
                  error={!!errors.configParams}
                  helperText={errors.configParams}
                  disabled={true}
                  id={"text-endpoint-url"}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </CustomStepper>
  );
};

export default BidderConfigurationForm;
