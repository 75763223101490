import { Box, Typography, Avatar, ButtonBase, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import UserStatusChip from "../../components/Chips/UserStatusChip";
import ActionItem, { ActionType } from "../../components/ActionItemsEditDelete/ActionItem";
import { IBidderConfigKey } from "./ConfigurationPage";
import { geoModesOptions } from "./BidderConfigurationForm";
import { ICountryBepp } from "./interfaces";
import { colors } from "../../theme";
import { IPartnerMapping } from "./utils";

interface ColumnsModelConfigurationProps {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onServiceActiveStatusClick: (
    configId: any,
    status: number,
    statusKey: IBidderConfigKey,
    anchorEl: HTMLElement
  ) => void;
  onBidderenabledStatusClick: (
    configId: any,
    status: number,
    statusKey: IBidderConfigKey,
    anchorEl: HTMLElement
  ) => void;
  partnerMapping: Record<string, IPartnerMapping>;
  biddersMapping: Record<string, string>;
  countriesMapping: Record<string, ICountryBepp>;
}
interface ColumnsModelBiddersProps {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  mediatypesMapping: Record<string, string>;
  isAdmin: boolean;
}

export const columnsModelConfigurations = ({
  onEdit,
  onDelete,
  onServiceActiveStatusClick,
  onBidderenabledStatusClick,
  partnerMapping,
  biddersMapping,
  countriesMapping,
}: ColumnsModelConfigurationProps): GridColDef[] => {
  return [
    {
      field: "partnerDummyId",
      headerName: "Partner name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        // Use the updated mapping to get partner name
        const partnerInfo = partnerMapping[params.value];
        return <Box>{partnerInfo ? partnerInfo.name : params.value}</Box>;
      },
    },
    { field: "name", headerName: "Bidder config name", flex: 1 },
    {
      field: "isActive",
      headerName: "Bidder Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const partnerInfo = partnerMapping[params.row.partnerDummyId];
        if (partnerInfo?.canAdmin) {
          return (
            <ButtonBase
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                onBidderenabledStatusClick(params.row.id, params.row.isActive, "isActive", event.currentTarget);
                event.stopPropagation();
              }}
            >
              <UserStatusChip status={params.value} />
            </ButtonBase>
          );
        } else {
          return (
            <ButtonBase>
              <UserStatusChip status={params.value} />
            </ButtonBase>
          );
        }
      },
    },
    {
      field: "bidderAdapterId",
      headerName: "Bidder",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <Box>{biddersMapping[params.value] || params.value}</Box>,
    },
    {
      field: "isIdBridgingActive",
      headerName: "ID Bridging",
      flex: 1,
      valueGetter: ({ value }) => (value === 1 ? "Yes" : "No"),
    },
    {
      field: "geo",
      headerName: "Geo",
      flex: 1.5,
      sortComparator: (v1, v2) => {
        const modeId1 = v1?.isCountriesModeInclude === null ? -1 : v1?.isCountriesModeInclude;
        const modeId2 = v2?.isCountriesModeInclude === null ? -1 : v2?.isCountriesModeInclude;
        return modeId1 - modeId2;
      },
      renderCell: (params: GridRenderCellParams) => {
        const modeId = params.value?.isCountriesModeInclude === null ? -1 : params.value?.isCountriesModeInclude;
        let modeText = geoModesOptions.find((m) => m.id === modeId)?.name + "" || "";
        modeText = modeText + (modeId !== -1 ? ": " : "");
        const countriesCount = params.value?.countriesIds?.length;
        const countriesNumberLimit = 1;
        const firstCountryShortName = countriesMapping[params.value?.countriesIds[0]]?.shortName || "";
        const allCountries = params.value?.countriesIds?.map((c: any) => countriesMapping[c]?.name).join(", ");
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="body2">{modeText + firstCountryShortName}</Typography>
            {countriesCount > countriesNumberLimit && (
              <Tooltip title={allCountries} arrow>
                <Typography
                  variant="body2"
                  color={colors.brandDefault}
                  sx={{
                    cursor: "pointer",
                    marginLeft: 1,
                    textDecoration: "underline",
                  }}
                >
                  {`+${countriesCount - 1} more`}
                </Typography>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "qpsLimit",
      headerName: "QPS",
      flex: 1,
      valueGetter: ({ value }) => value || "-",
    },
    {
      field: "isEnrichmentActive",
      headerName: "Enrichment",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const partnerInfo = partnerMapping[params.row.partnerDummyId];
        if (partnerInfo?.canAdmin) {
          return (
            <ButtonBase
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                onServiceActiveStatusClick(
                  params.row.id,
                  params.row.isEnrichmentActive,
                  "isEnrichmentActive",
                  event.currentTarget
                );
                event.stopPropagation();
              }}
            >
              <UserStatusChip status={params.value} />
            </ButtonBase>
          );
        } else {
          return (
            <ButtonBase>
              <UserStatusChip status={params.value} />
            </ButtonBase>
          );
        }
      },
    },
    {
      field: "configParams",
      headerName: "Parameters",
      flex: 1,
      // You can add a custom renderCell here if needed.
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        // Do not render any action buttons if partner.canAdmin is false
        const partnerInfo = partnerMapping[params.row.partnerDummyId];
        if (!partnerInfo?.canAdmin) {
          return null;
        }
        return (
          <Box display="flex" alignItems="center">
            <ActionItem actionType={ActionType.Edit} onAction={() => onEdit(params.row.id)} />
            {/* Uncomment the delete action if needed */}
            {/* <ActionItem actionType={ActionType.Delete} onAction={() => onDelete(params.row.id)} /> */}
          </Box>
        );
      },
    },
  ];
};
export const columnsModelBidders = ({
  onEdit,
  onDelete,
  mediatypesMapping,
  isAdmin,
}: ColumnsModelBiddersProps): GridColDef[] => {
  const columns: GridColDef[] = [
    { field: "bidderName", headerName: "Bidder name", flex: 1 },
    {
      field: "mediatypesIds",
      headerName: "Mediatypes",
      flex: 1,
      valueGetter: ({ value }) => {
        return value?.map((m: any) => mediatypesMapping[m]).join(", ");
      },
    },
    {
      field: "regions",
      headerName: "Region",
      flex: 1,
      valueGetter: ({ value }) => {
        return value?.map((r: any) => r.name).join(", ");
      },
    },
  ];
  if (isAdmin)
    columns.push({
      field: "actions",
      headerName: "Action",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Box display="flex" alignItems="center">
          <ActionItem actionType={ActionType.Edit} onAction={() => onEdit(params.row.id)} />
          <ActionItem actionType={ActionType.Delete} onAction={() => onDelete(params.row.id)} />
        </Box>
      ),
    });
  return columns;
};
