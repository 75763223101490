import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  Typography,
  TextField,
} from "@mui/material";
import { titleStlAsterics } from "../../accounts/sideBoard/boardStl";
import SearchIcon from "../IconComponents/SearchIcon";
import { partnerDropdownTextFieldStyle } from "../SelectLabels/SelectMultiLabelsWrapper";
import { colors } from "../../theme";

export interface ISimpleDropdownProps {
  label: string;
  value: number | null;
  onChange: (value: number) => void;
  options: { id: number; name: string }[];
  error?: boolean;
  helperText?: string;
  id?: string;
  renderCustomItem?: () => React.ReactNode;
  onSearch?: (value: string) => void | null;
  required?: boolean;
}

export const SimpleDropdown: React.FC<ISimpleDropdownProps> = ({
  label,
  value,
  onChange,
  options,
  error = false,
  helperText = "",
  id,
  renderCustomItem,
  onSearch,
  required = false,
}) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      {onSearch && (
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search"
          onChange={(e) => onSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={partnerDropdownTextFieldStyle}
        />
      )}
      <Typography
        variant="body1"
        sx={{ fontWeight: 500, marginBottom: "4px", ...(required ? titleStlAsterics : null) }}
      >
        {label}
      </Typography>
      <Select
        value={value ?? ""}
        onChange={handleChange}
        displayEmpty
        id={id}
        MenuProps={{
          disableAutoFocusItem: true,
          PaperProps: {
            onMouseDown: (e: any) => e.stopPropagation(),
            onMouseUp: (e: any) => e.stopPropagation(),
          },
          MenuListProps: {
            onMouseDown: (e) => e.stopPropagation(),
            onMouseUp: (e) => e.stopPropagation(),
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id} sx={{ minHeight: 48 }}>
            {option.name}
          </MenuItem>
        ))}
        {renderCustomItem && renderCustomItem()}
      </Select>
      {helperText && (
        <Typography variant="body1Small400" sx={{ color: colors.error }}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};
