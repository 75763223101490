import { Box, Typography } from "@mui/material";

interface LegendItemProps {
  color: string;
  label: string;
}

const LegendItem: React.FC<LegendItemProps> = ({ color, label }) => (
  <Box display="flex" alignItems="center" gap={1}>
    <Box
      sx={{
        width: 4,
        height: 16,
        borderRadius: 2,
        backgroundColor: color,
      }}
    />
    <Typography variant="bodyMediumSemiboldNormal">{label}</Typography>
  </Box>
);

interface LegendProps {
  items: LegendItemProps[];
}

const SummaryLegend: React.FC<LegendProps> = ({ items }) => {
  return (
    <Box display="flex" alignItems="center" gap={3}>
      {items.map((item, index) => (
        <LegendItem key={index} {...item} />
      ))}
    </Box>
  );
};

export default SummaryLegend;
