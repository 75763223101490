import React, { FC } from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors, radius } from "../../theme";

export interface ISegmentControlOption {
  id: number | string;
  name: string;
  isDisabled?: boolean;
}

interface SegmentControlProps {
  options: ISegmentControlOption[];
  value: ISegmentControlOption;
  onChange: (value: ISegmentControlOption) => void;
  disabled?: boolean;
  id?: string;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  backgroundColor: colors.backgroundBaseSecondary,
  borderRadius: radius.r100,
  display: "flex",
  alignItems: "center",
  boxShadow: "none",
  height: "40px",
  padding: "4px",
  justifyContent: "space-between",
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  // marginLeft: "100px",
  width: "100%",
  textTransform: "none",
  height: "32px",
  border: "none",
  borderRadius: radius.r25,
  "&:first-of-type": {
    marginLeft: 0,
  },
  "&.Mui-selected": {
    backgroundColor: "white",
    borderRadius: radius.r25,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
      color: colors.text01,
      "& *": {
        color: colors.text01,
      },
    },
  },
  "&:hover": {
    backgroundColor: colors.backgroundBaseHover + " !important",
    color: "red",
    borderRadius: radius.r25,
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "transparent",
    borderRadius: radius.r25,
  },
  "&.Mui-disabled": {
    border: "none",
    "& *": {
      color: colors.navy50,
      borderRadius: radius.r25,
    },
  },
}));

const SegmentControl: FC<SegmentControlProps> = ({ options, value, onChange, disabled = false, id }) => {
  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, newId: any) => {
    if (newId === null) {
      onChange(value);
      return;
    }
    const selectedOption = options.find((option) => option.id === newId);
    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  return (
    <StyledToggleButtonGroup
      disabled={disabled}
      value={value.id}
      exclusive
      onChange={handleAlignment}
      aria-label="segment control"
    >
      {options.map((option) => (
        <StyledToggleButton
          key={option.id}
          value={option.id}
          id={`${option.name.toLowerCase().replaceAll(/[ /]/g, "-")}-btn`}
          disabled={option.isDisabled}
        >
          {value.id === option.id ? (
            <Typography noWrap variant="bodyMediumSemiboldNormal">
              {option.name}
            </Typography>
          ) : (
            <Typography noWrap variant="body2XSmallMain400" color={colors.text02}>
              {option.name}
            </Typography>
          )}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default SegmentControl;
