import React, { ReactNode, useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import GroupFilter from "./GroupFilter";
import { CONSTANT, getCurrentPageIdByLocationPathname } from "../../../../constants/constants";
import { useLocation } from "react-router-dom";
import { CategoriesType, CategoryType } from "../../../../parametersConstancy/parametersConstancyTypes";

export interface FilterGroupHandlerProps {
  options: CategoriesType;
  values: CategoriesType;
  isReadOnlyView?: boolean;
  setFiltersOptions: React.Dispatch<React.SetStateAction<CategoriesType>>;
  setFiltersValues: React.Dispatch<React.SetStateAction<CategoriesType>>;
  withPartners?: boolean;
  isBepp?: boolean;
  selectAllEnabled?: boolean;
  actionItemsTop?: ReactNode;
  base?: string;
  additionalFilterIsReporting?: boolean;
  textFieldStyle?: SxProps;
  id?: string;
}

const getSearchPredicate =
  (filterBase: string, searchValue: string, additionalFilterIsReporting: boolean) => (option: CategoryType) => {
    const lowerSearch = searchValue.toLowerCase();

    // Safely handle possible undefined fields with optional chaining or fallback
    const name = (option.name ?? "").toString().toLowerCase();
    const id = (option.pid ?? "").toString().toLowerCase();
    const dummy_id = (option.dummy_id ?? "").toString().toLowerCase();
    const isReporting = option.isReporting ?? false; // or true, depending on your logic

    if (filterBase === CONSTANT.REPORTS.PARTNERS.base) {
      // For 'partners' we allow name, pid, or dummy_id to match:
      // and also require isReporting if additionalFilterIsReporting is true
      return (
        (name.includes(lowerSearch) || id.includes(lowerSearch) || dummy_id.includes(lowerSearch)) &&
        (!additionalFilterIsReporting || isReporting)
      );
    }

    // For other filterBases, just match name + optional isReporting check
    return name.includes(lowerSearch) && (!additionalFilterIsReporting || isReporting);
  };

const searchPredicate = (filterBase: string, searchValue: string, additionalFilterIsReporting: boolean) =>
  getSearchPredicate(filterBase, searchValue, additionalFilterIsReporting);

const GroupFilterHandler: React.FC<FilterGroupHandlerProps> = ({
  options,
  values,
  isReadOnlyView = false,
  setFiltersOptions,
  setFiltersValues,
  withPartners = false,
  isBepp = false,
  selectAllEnabled = false,
  actionItemsTop,
  base = CONSTANT.REPORTS.PARTNERS.base,
  additionalFilterIsReporting = false,
  textFieldStyle,
  id,
}) => {
  const location = useLocation();
  const [openFilter, setOpenFilter] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectAllSelected, setSelectAllSelected] = useState<Record<string, boolean>>({});
  const [selectAllVisible, setSelectAllVisible] = useState<Record<string, boolean>>({});

  const handleToggleOpen = (filterBase: string) => () => {
    setOpenFilter((prev) => (prev === filterBase ? "" : filterBase));
    setSearchValue("");
  };

  const handleSelectAll = (filterBase: string) => (select: boolean) => {
    // Compute the full set: union of currently selected and unselected items.
    const fullSet = [...(values[filterBase] || []), ...(options[filterBase] || [])];
    if (select) {
      // Select all: set values to the full set and clear options.
      setFiltersValues((prev) => ({ ...prev, [filterBase]: fullSet }));
      setFiltersOptions((prev) => ({ ...prev, [filterBase]: [] }));
      setSelectAllSelected((prev) => ({ ...prev, [filterBase]: true }));
    } else {
      // Unselect all: clear values and restore the full set to options.
      setFiltersValues((prev) => ({ ...prev, [filterBase]: [] }));
      setFiltersOptions((prev) => ({ ...prev, [filterBase]: fullSet }));
      setSelectAllSelected((prev) => ({ ...prev, [filterBase]: false }));
    }
  };

  const handleSelectAllVisible = (filterBase: string) => (checked: boolean) => {
    if (searchValue) {
      if (checked) {
        const visibleUnselected = (options[filterBase] || []).filter(
          searchPredicate(filterBase, searchValue, additionalFilterIsReporting)
        );
        const currentValues = values[filterBase] || [];
        const newValues = [
          ...currentValues,
          ...visibleUnselected.filter((opt) => !currentValues.some((v) => v.id === opt.id)),
        ];
        const currentOptions = options[filterBase] || [];
        const newOptions = currentOptions.filter((o) => !visibleUnselected.some((v) => v.id === o.id));
        setFiltersValues((prev) => ({ ...prev, [filterBase]: newValues }));
        setFiltersOptions((prev) => ({ ...prev, [filterBase]: newOptions }));
        setSelectAllVisible((prev) => ({ ...prev, [filterBase]: true }));
      } else {
        const visibleSelected = (values[filterBase] || []).filter(
          searchPredicate(filterBase, searchValue, additionalFilterIsReporting)
        );
        const currentValues = values[filterBase] || [];
        const newValues = currentValues.filter((item) => !visibleSelected.some((v) => v.id === item.id));
        // And add them back to options (if not already present)
        const currentOptions = options[filterBase] || [];
        const newOptions = [
          ...currentOptions,
          ...visibleSelected.filter((v) => !currentOptions.some((o) => o.id === v.id)),
        ];
        setFiltersValues((prev) => ({ ...prev, [filterBase]: newValues }));
        setFiltersOptions((prev) => ({ ...prev, [filterBase]: newOptions }));
        setSelectAllVisible((prev) => ({ ...prev, [filterBase]: false }));
      }
    }
  };

  const handleSelectItems = (filterBase: string) => (option: CategoryType) => {
    const currentValues: CategoryType[] = values[filterBase] || [];
    const currentOptions: CategoryType[] = options[filterBase] || [];
    let newValues = [...currentValues];
    let newOptions = [...currentOptions];

    const selectedIndex = currentValues.findIndex((item) => item.id === option.id);
    const optionIndex = currentOptions.findIndex((item) => item.id === option.id);

    if (selectedIndex === -1) {
      newValues.push(option);
      if (optionIndex !== -1) {
        newOptions.splice(optionIndex, 1);
      }
    } else {
      newValues.splice(selectedIndex, 1);
      if (optionIndex === -1) {
        newOptions.push(option);
      }
    }
    const totalCount = newValues.length + newOptions.length;
    setSelectAllSelected((prev) => ({
      ...prev,
      [filterBase]: totalCount > 0 && newValues.length === totalCount,
    }));

    setFiltersValues((prev) => ({ ...prev, [filterBase]: newValues }));
    setFiltersOptions((prev) => ({ ...prev, [filterBase]: newOptions }));
  };

  useEffect(() => {
    const groupFilters = withPartners
      ? {
          PARTNERS: CONSTANT.REPORTS.PARTNERS,
          PARTNERS_CONFIG: CONSTANT.REPORTS.BE_PP.PARTNERS_CONFIG,
        }
      : CONSTANT.REPORTS.FILTERS_KEYS;
    Object.values(groupFilters).forEach((category) => {
      const filterBase = category.base;
      setSelectAllSelected((prev) => ({
        ...prev,
        [filterBase]: (options[filterBase] || []).length === 0,
      }));
      if (searchValue) {
        const unionItems = [...(options[filterBase] || []), ...(values[filterBase] || [])];
        const filteredVisible = unionItems.filter(
          searchPredicate(filterBase, searchValue, additionalFilterIsReporting)
        );
        const currentValues = values[filterBase] || [];
        const allVisibleSelected =
          filteredVisible.length > 0 &&
          filteredVisible.every((item) => currentValues.some((v) => v.id === item.id));
        setSelectAllVisible((prev) => ({ ...prev, [filterBase]: allVisibleSelected }));
      }
    });
  }, [options, values, searchValue, withPartners, additionalFilterIsReporting]);

  const curentPageId: number = getCurrentPageIdByLocationPathname(location.pathname) ?? -1;
  const groupFilters = isBepp
    ? { PARTNERS_CONFIG: CONSTANT.REPORTS.BE_PP.PARTNERS_CONFIG }
    : withPartners
    ? { PARTNERS: CONSTANT.REPORTS.PARTNERS }
    : CONSTANT.REPORTS.FILTERS_KEYS;

  const filters = Object.values(groupFilters).map((category) => {
    const title = category.title;
    const filterBase = category.base;
    let isDisabled = false;
    if (curentPageId === CONSTANT.PAGES.VISITOR_RECOGNITION.id) {
      isDisabled =
        (filterBase === CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base && values.platforms?.length > 0) ||
        (filterBase === CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base && values.actionTerminations?.length > 0) ||
        (filterBase === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && values.platforms?.length > 0) ||
        (filterBase === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base && values.countries?.length > 0) ||
        (filterBase === CONSTANT.REPORTS.FILTERS_KEYS.COUNTRIES.base && values.sites?.length > 0);
    }
    const renderCondition = isReadOnlyView
      ? (values[filterBase] || []).length > 0
      : (options[filterBase] || []).length >= 0;
    if (renderCondition && (openFilter === "" || openFilter === filterBase)) {
      const filteredOptions = (options[filterBase] || []).filter(
        searchPredicate(filterBase, searchValue, additionalFilterIsReporting)
      );
      const filteredValues = (values[filterBase] || []).filter(
        searchPredicate(filterBase, searchValue, additionalFilterIsReporting)
      );

      const isVisibleFilter = searchValue !== "";
      const currentSelectAllVisible = isVisibleFilter ? selectAllVisible[filterBase] || false : false;
      const currentSelectAllSelected = !isVisibleFilter ? selectAllSelected[filterBase] || false : false;

      const unionItems = [...(options[filterBase] || []), ...(values[filterBase] || [])];
      const filteredUnion = unionItems.filter(
        searchPredicate(filterBase, searchValue, additionalFilterIsReporting)
      );

      const matchedItemsNumber = searchValue ? filteredUnion.length : null;

      return (
        <GroupFilter
          key={filterBase}
          isReadOnlyView={isReadOnlyView}
          options={filteredOptions}
          values={filteredValues}
          handleToggleOpen={handleToggleOpen(filterBase)}
          openedFilter={openFilter}
          base={filterBase}
          buttonTitle={title}
          handleSelectItems={handleSelectItems(filterBase)}
          handleSearch={(value) => setSearchValue(value)}
          isMultiSelection={filterBase !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base}
          shouldBeSelected={
            filterBase === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && (options.platforms || []).length === 0
          }
          isDisabled={isDisabled}
          selectAllEnabled={selectAllEnabled}
          selectAllSelected={currentSelectAllSelected}
          selectAllVisible={currentSelectAllVisible}
          matchedItemsNumber={matchedItemsNumber}
          handleSelectAll={handleSelectAll(filterBase)}
          handleSelectAllVisible={handleSelectAllVisible(filterBase)}
          actionItemsTop={actionItemsTop}
          textFieldStyle={textFieldStyle}
          id={id}
        />
      );
    }
    return null;
  });
  return <Box>{filters}</Box>;
};

export default GroupFilterHandler;
