import moment from "moment";
import { CONSTANT } from "../../constants/constants";
import { prepareReportDates } from "../../report/utils";
import {
  formatDateToStr,
  getPreviousMonthName,
  getPrevMonthDates,
  prepareDateFormat,
  convertLocal,
} from "../../utils";
import { AggregateCustomBaseDataOutput, CustomChartTypes, Item } from "./types";
import { colors } from "../../theme";
import { nameToColor } from "../performanceReports/common-reports-helpers";

const FIXED_IMPRESSIONS_COLOR = colors.lightBlue;

//const getRandomColor = () => `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`;

export const getColumnIndices = (headers: string[]) => ({
  submittedDateIndex: headers.indexOf("submitted_date"),
  totalCpm1Index: headers.indexOf("total_cpm1"),
  totalCpm2Index: headers.indexOf("total_cpm2"),
  pct: headers.indexOf("pct"),
  currency: headers.indexOf("currency"),
});

export const renderCompareDatePeriodStr = (active_range_date: number, customDateRangePreviousPeriod: any) => {
  return active_range_date === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value
    ? ` to ${formatDateToStr(customDateRangePreviousPeriod.customDateStart, {
        printYYFormat: true,
      })} till ${formatDateToStr(customDateRangePreviousPeriod.customDateEnd, {
        printYYFormat: true,
      })}`
    : active_range_date === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value
    ? ` to ${getPreviousMonthName({ monthsOffset: 2, fullMonthName: true })}`
    : ` to previous ${active_range_date} days`;
};

// Returns the start and end dates for aggregation.
export const getAggregationTimeRange = (dateRange: any, dateGroupingMode: any, customDateRange: any) => {
  if (
    dateRange.value === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value &&
    dateGroupingMode !== CONSTANT.DATE_GROUP_MODES.MONTH
  ) {
    const { start, end } = getPrevMonthDates();
    return { startDate: start, endDate: end };
  }
  const actionPayload = {
    payload: {
      result: {},
      dateGroupingMode,
      activeRangeDate: dateRange.value,
      customDateRange,
    },
  };
  const reduxState = { customDateRangePreviousPeriod: customDateRange };
  const { reportDateStart, chartLastDate } = prepareReportDates(dateRange.value, actionPayload, reduxState, false);
  const formattedChartLastDate = prepareDateFormat(new Date(Number(chartLastDate)));
  return { startDate: reportDateStart.startDate, endDate: formattedChartLastDate };
};

// ================================================
// Aggregation of Data for Chart Columns
// ================================================

export const aggregateDataForChartColumns = (
  dataColumns: any,
  startDate: string,
  endDate: string,
  options: { metrics?: string[]; customChartType?: { base: string; metric: string } },
  dateGroupingMode: number
): any => {
  // Guard: if dataColumns or its submitted_date array is missing, return an empty structure.
  if (!dataColumns || !dataColumns.submitted_date || !dataColumns.submitted_date.length) {
    return { date: [] };
  }

  // Build timeline based on grouping mode.
  let timeline: string[] = [];
  if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.DAY) {
    let current = new Date(startDate);
    const end = new Date(endDate);
    while (current <= end) {
      timeline.push(prepareDateFormat(current));
      current.setDate(current.getDate() + 1);
    }
  } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
    let current = new Date(startDate);
    current.setDate(1); // start at first of month
    const end = new Date(endDate);
    end.setDate(1);
    while (current <= end) {
      const monthStr = moment(current).format("YYYY-MMM");
      timeline.push(monthStr);
      current.setMonth(current.getMonth() + 1);
    }
  } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER) {
    let current = new Date(startDate);
    current.setDate(1);
    const end = new Date(endDate);
    let currentQuarter = Math.floor(current.getMonth() / 3) + 1;
    timeline.push(`${current.getFullYear()}-Q${currentQuarter}`);
    while (true) {
      current.setMonth(current.getMonth() + 3);
      if (current > end) break;
      currentQuarter = Math.floor(current.getMonth() / 3) + 1;
      timeline.push(`${current.getFullYear()}-Q${currentQuarter}`);
    }
  } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.AGGREGATED) {
    const aggDate = prepareDateFormat(new Date(dataColumns.submitted_date[0]));
    timeline.push(aggDate);
  }

  // Create index mapping for timeline.
  const dateIndex: Record<string, number> = {};
  timeline.forEach((d, idx) => {
    dateIndex[d] = idx;
  });

  // Aggregation by metrics.
  if (options.metrics) {
    const result: any = { date: timeline };
    options.metrics.forEach((metric) => {
      result[metric] = Array(timeline.length).fill(0);
    });
    const len = dataColumns.submitted_date.length;
    for (let i = 0; i < len; i++) {
      const dateObj = new Date(dataColumns.submitted_date[i]);
      let bucketKey = "";
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.DAY) {
        bucketKey = prepareDateFormat(dateObj);
      } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
        bucketKey = moment(dateObj).format("YYYY-MMM");
      } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER) {
        const quarter = Math.floor(dateObj.getMonth() / 3) + 1;
        bucketKey = `${dateObj.getFullYear()}-Q${quarter}`;
      } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.AGGREGATED) {
        bucketKey = prepareDateFormat(new Date(dataColumns.submitted_date[0]));
      }
      const idx = dateIndex[bucketKey];
      if (idx !== undefined) {
        options.metrics.forEach((metric) => {
          let valueToAdd = +dataColumns[metric][i] || 0;
          if (!isFinite(valueToAdd)) {
            console.warn(
              `Non-finite value encountered for metric '${metric}' at index ${i}:`,
              dataColumns[metric][i]
            );
            valueToAdd = 0;
          }
          result[metric][idx] = result[metric][idx] + valueToAdd;
        });
      }
    }
    return result;
  }
  // Aggregation for custom chart types (non-SUMMARY).
  else if (options.customChartType && options.customChartType.base !== CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) {
    const { base, metric } = options.customChartType;
    const groups = Array.from(new Set(dataColumns[base]));
    const result: any = { date: timeline };
    groups.forEach((group: any) => {
      result[group] = Array(timeline.length).fill(0);
    });
    const len = dataColumns.submitted_date.length;
    for (let i = 0; i < len; i++) {
      const dateObj = new Date(dataColumns.submitted_date[i]);
      let bucketKey = "";
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.DAY) {
        bucketKey = prepareDateFormat(dateObj);
      } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
        bucketKey = moment(dateObj).format("YYYY-MMM");
      } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER) {
        const quarter = Math.floor(dateObj.getMonth() / 3) + 1;
        bucketKey = `${dateObj.getFullYear()}-Q${quarter}`;
      } else if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.AGGREGATED) {
        bucketKey = prepareDateFormat(new Date(dataColumns.submitted_date[0]));
      }
      const idx = dateIndex[bucketKey];
      if (idx !== undefined) {
        const groupKey = dataColumns[base][i];
        let valueToAdd = dataColumns[metric][i] || 0;
        if (!isFinite(valueToAdd)) {
          console.warn(
            `Non-finite value encountered for metric '${metric}' at index ${i}:`,
            dataColumns[metric][i]
          );
          valueToAdd = 0;
        }
        result[groupKey][idx] = result[groupKey][idx] + valueToAdd;
      }
    }
    return result;
  }
  return {};
};

// ================================================
// Filter Data Columns
// ================================================

export const filterDataColumns = (data: any, conditionFn: (row: any) => boolean) => {
  if (!data || Object.keys(data).length === 0) return data;
  const keys = Object.keys(data);
  const len = data[keys[0]].length;
  const filteredIndices: number[] = [];
  for (let i = 0; i < len; i++) {
    const row: any = {};
    keys.forEach((key) => {
      row[key] = data[key][i];
    });
    if (conditionFn(row)) {
      filteredIndices.push(i);
    }
  }
  const filtered: any = {};
  keys.forEach((key) => {
    filtered[key] = filteredIndices.map((i) => data[key][i]);
  });
  return filtered;
};

// ================================================
// Aggregate Custom Base Data for Custom Charts
// ================================================

export const aggregateCustomBaseData = (
  rawData: any,
  base: string,
  startDate: string,
  endDate: string,
  uniqueCurrencyPctCombinations: any,
  selectedCustomCharts: string[],
  crpWithIIQ: number,
  deselectionPercentage: number,
  dateGroupingMode: number
): AggregateCustomBaseDataOutput => {
  // 1. Filter raw data by selected currency combo if applicable.
  let baseData = rawData;
  const selectedCombo = uniqueCurrencyPctCombinations.find((combo: any) => combo.isSelected);
  if (selectedCombo) {
    baseData = filterDataColumns(
      baseData,
      (row) => row.currency === selectedCombo.currency && row.pct === selectedCombo.pct
    );
  }

  // 2. Determine effective custom chart keys for this base.
  const effectiveCustomChartsForBase =
    selectedCustomCharts.length > 0
      ? selectedCustomCharts.filter((chartKey) => CustomChartTypes[chartKey]?.base === base)
      : Object.keys(CustomChartTypes).filter((chartKey) => CustomChartTypes[chartKey]?.base === base);

  // 3. Aggregate each custom metric.
  const aggregatedData: Record<string, any> = {};
  effectiveCustomChartsForBase.forEach((chartKey) => {
    const chartType = CustomChartTypes[chartKey];
    const options =
      base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY
        ? { metrics: ["total_rows_sum"] }
        : { customChartType: { base: chartType.base, metric: chartType.metric } };
    if (chartType && chartType.base === base) {
      aggregatedData[chartType.metric] = aggregateDataForChartColumns(
        baseData,
        startDate,
        endDate,
        options,
        dateGroupingMode
      );
    }
  });

  // 4. Compute overall impressions and per-entity metrics.
  let overallImpressions = 0;
  let overallCmp = 0;
  const impressionsByEntity: Record<string, number> = {};
  const cmp1ByEntity: Record<string, number> = {};
  const cmp2ByEntity: Record<string, number> = {};
  const cmp2NormByEntity: Record<string, number> = {};
  const rows1ByEntity: Record<string, number> = {};
  const rows2ByEntity: Record<string, number> = {};
  const rowsSumByEntity: Record<string, number> = {};

  const startDt = new Date(startDate);
  const endDt = new Date(endDate);
  const lenData = baseData.submitted_date?.length || 0;
  for (let i = 0; i < lenData; i++) {
    const d = new Date(baseData.submitted_date[i]);
    if (
      d.setHours(0, 0, 0, 0) >= startDt.setHours(0, 0, 0, 0) &&
      d.setHours(0, 0, 0, 0) <= endDt.setHours(0, 0, 0, 0)
    ) {
      const imp = Number(baseData["total_rows1"][i]) || 0;
      overallImpressions += imp;
      const entity = base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY ? "Impressions" : baseData[base][i];
      impressionsByEntity[entity] = (impressionsByEntity[entity] || 0) + imp;

      const total_cpm1 = Number(baseData["total_cpm1"][i]) || 0;
      const total_cpm2 = Number(baseData["total_cpm2"][i]) || 0;
      const total_rows1 = Number(baseData["total_rows1"][i]) || 0;
      const total_rows2 = Number(baseData["total_rows2"][i]) || 0;
      const total_rows_sum = Number(baseData["total_rows_sum"][i]) || 0;
      const total_cpm2Normalized = (total_cpm2 * 100) / (100 - baseData["pct"][i]);
      overallCmp += total_cpm1;
      cmp1ByEntity[entity] = (cmp1ByEntity[entity] || 0) + total_cpm1;
      cmp2ByEntity[entity] = (cmp2ByEntity[entity] || 0) + total_cpm2;
      rows1ByEntity[entity] = (rows1ByEntity[entity] || 0) + total_rows1;
      rows2ByEntity[entity] = (rows2ByEntity[entity] || 0) + total_rows2;
      cmp2NormByEntity[entity] = (cmp2NormByEntity[entity] || 0) + total_cpm2Normalized;
      rowsSumByEntity[entity] = (rowsSumByEntity[entity] || 0) + total_rows_sum;
    }
  }

  // 5. Allowed groups: those with impressions >= overallImpressions * crpWithIIQ (or always include for SUMMARY).
  const allowedGroups = Object.keys(impressionsByEntity).filter(
    (entity) =>
      impressionsByEntity[entity] >= (overallImpressions * crpWithIIQ) / 100 ||
      base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY
  );

  // 6. Build preselection array.
  const items: Item[] = allowedGroups.map((entity) => {
    const totalCpm1 = cmp1ByEntity[entity] || 0;
    const totalCpm2 = cmp2ByEntity[entity] || 0;
    const totalRows1 = rows1ByEntity[entity] || 0;
    const totalRows2 = rows2ByEntity[entity] || 0;
    const totalCpm2Norm = cmp2NormByEntity[entity] || 0;
    const totalRowsSum = rowsSumByEntity[entity] || 0;
    const isPreselected = totalCpm1 >= (overallCmp * deselectionPercentage) / 100;
    return {
      entity,
      isPreselected,
      totalCpm1,
      totalCpm2,
      totalCpm2Norm,
      totalRows2,
      totalRows1,
      totalRowsSum,
      avgCpma: (totalCpm1 * 1000) / totalRows1,
      avgCpmb: (totalCpm2 * 1000) / totalRows2 || 0,
      color: base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY ? FIXED_IMPRESSIONS_COLOR : nameToColor(entity),
    };
  });

  // 7. Get top 5 entities based on total_cpm1.
  const topEntities = [...items]
    .sort((a, b) => b.totalCpm1 - a.totalCpm1)
    .slice(0, 5)
    .map((item) => item.entity);

  // 8. Filter aggregatedData for each metric to include only allowed groups.
  // If allowedGroups is empty for non-SUMMARY, include all available groups.
  const filteredAggData: Record<string, any> = {};
  Object.keys(aggregatedData).forEach((metric) => {
    const metricData = aggregatedData[metric];
    const filteredMetricData: Record<string, any> = {};
    if (metricData.date) {
      filteredMetricData.date = metricData.date;
    }
    const availableGroups = Object.keys(metricData).filter((key) => key !== "date");
    const groupsToInclude =
      base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY || allowedGroups.length > 0 ? allowedGroups : availableGroups;
    availableGroups.forEach((groupKey) => {
      if (groupsToInclude.includes(groupKey) || base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) {
        // For SUMMARY, use "Impressions" as the key.
        const finalKey = base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY ? "Impressions" : groupKey;
        filteredMetricData[finalKey] = metricData[groupKey];
      }
    });
    filteredAggData[metric] = filteredMetricData;
  });

  return {
    filteredData: filteredAggData,
    items,
    topEntities,
  };
};

export const formatYAxis = (value: number) => convertLocal(value);
export const formatXAxis = (value: string) => value;
