// ScrollToTopButton.tsx
import React, { FC, useEffect, useState, RefObject } from "react";
import Box from "@mui/material/Box";
import { colors } from "../../theme";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface ScrollToTopButtonProps {
  containerRef?: RefObject<HTMLElement>; // optional
  threshold?: number;
}

const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({ containerRef, threshold = 200 }) => {
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  useEffect(() => {
    // If no containerRef is provided, we default to using 'window'
    const scrollableElement = containerRef?.current || window;

    const handleScroll = () => {
      let scrollTopPosition: number;
      if (scrollableElement instanceof Window) {
        // measure from window
        scrollTopPosition = window.scrollY;
      } else {
        // measure from a container
        scrollTopPosition = scrollableElement.scrollTop;
      }

      setShowScrollBtn(scrollTopPosition > threshold);
    };

    scrollableElement.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, threshold]);

  const handleScrollUp = () => {
    // If using a container, scroll that container; otherwise scroll window
    if (containerRef?.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  if (!showScrollBtn) return null;

  return (
    <Box
      onClick={handleScrollUp}
      sx={{
        position: "fixed",
        bottom: "4rem",
        right: "4rem",
        cursor: "pointer",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
        width: "50px",
        height: "50px",
        backgroundColor: "#fff",
        opacity: 0.6,

        zIndex: 9999,
        "&:hover": {
          backgroundColor: colors.blue100,
          color: colors.text01,
          opacity: 0.75,
        },
      }}
    >
      <ExpandLessIcon sx={{ fontSize: "3rem" }} />
    </Box>
  );
};

export default ScrollToTopButton;
