import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import DashboardWidgetCard, { DashboardWidgetCardProps, WidgetMetricNames } from "./DashboardWidgetCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { renderCompareDatePeriodStr } from "./utils";
import { calcCustomDateforPreviousReport, convertLocal } from "../../utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { EmptyData } from "./EmptyData";

interface WidgetsProps {
  isLoading: boolean;
}

const Widgets: React.FC<WidgetsProps> = ({ isLoading = false }) => {
  const summaryWidgets = useSelector((state: RootState) => state.revenue.summaryWidgets);
  const uniqueCombinations = useSelector((state: RootState) => state.revenue.uniqueCurrencyPctCombinations);
  const { dateRange, customDateRange, dateGroupingMode } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );

  let resPrevPeriod = calcCustomDateforPreviousReport(
    customDateRange.customDateStart,
    customDateRange.customDateEnd,
    dateGroupingMode
  );

  const timeRangeString = renderCompareDatePeriodStr(dateRange.value, resPrevPeriod);

  // Check if summaryWidgets is loaded.
  const selectedCombination = uniqueCombinations.find((combo) => combo.isSelected) || uniqueCombinations[0];

  if (!summaryWidgets || !selectedCombination) {
    return <EmptyData />;
  }

  const widgetSummary = summaryWidgets[selectedCombination.id];
  if (!widgetSummary || !widgetSummary.revenue) {
    return <Typography>No widget data available for {selectedCombination.id}</Typography>;
  }

  // Prepare the data to pass into DashboardWidgetCard.
  const widgetData: DashboardWidgetCardProps[] = [
    {
      metricName: WidgetMetricNames.Revenue,
      timeRangeString,
      value:
        (getSymbolFromCurrency(selectedCombination.currency) || "NR") +
        convertLocal(widgetSummary.revenue?.currentPeriod ?? 0),
      previousPeriodChangePercents: widgetSummary.revenue?.previousPeriodChangePercents ?? 0,
    },
    {
      metricName: WidgetMetricNames.RevenueLift,
      timeRangeString,
      value:
        (getSymbolFromCurrency(selectedCombination.currency) || "NR") +
        convertLocal(widgetSummary.revenueLift?.currentPeriod ?? 0),
      previousPeriodChangePercents: widgetSummary.revenueLift?.previousPeriodChangePercents ?? 0,
      currentPeriodPercents: widgetSummary.revenueLift?.currentPeriodPercents,
    },
    {
      metricName: WidgetMetricNames.TotalImpressions,
      timeRangeString,
      value: convertLocal(widgetSummary.totalImpressions?.currentPeriod ?? 0),
      previousPeriodChangePercents: widgetSummary.totalImpressions?.previousPeriodChangePercents ?? 0,
    },
    {
      metricName: WidgetMetricNames.FillRateLift,
      timeRangeString,
      value: convertLocal(widgetSummary.abFillRateLift?.currentPeriod ?? 0) + "%",
      previousPeriodChangePercents: widgetSummary.abFillRateLift?.previousPeriodChangePercents ?? 0,
    },
    {
      metricName: WidgetMetricNames.CPMLift,
      timeRangeString,
      value:
        (getSymbolFromCurrency(selectedCombination.currency) || "NR") +
        convertLocal(widgetSummary.cpmLift?.currentPeriod ?? 0),
      previousPeriodChangePercents: widgetSummary.cpmLift?.previousPeriodChangePercents ?? 0,
      currentPeriodPercents: widgetSummary.cpmLift?.currentPeriodPercents,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Header row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <Typography variant="body1Small600">Widgets</Typography>
        <FilterListIcon />
      </Box>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 600 }}>
          <CircularProgress />
        </Box>
      ) : (
        widgetData.map((widget, idx) => <DashboardWidgetCard key={idx} {...widget} />)
      )}
    </Box>
  );
};

export default Widgets;
