import React, { useMemo } from "react";
import { Box, Grid, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useGetActiveUsersQuery, useGetSessionsQuery } from "./monitoringApi";
import SessionsTable from "./SesstionsTable";
import DashboardCard, { ChangeType, DashboardCardProps, MetricNames, TimeRange } from "./DashboardCard";
import { calculateMetrics, formatDurationFromSecondsOrMinutes, getDaysForTimeRange } from "./utils";
import ActiveUsersCard from "./ActiveUsersCard";
import { MonitoringChartWrapper } from "./SessionsChartWrapper";
import SearchIcon from "@mui/icons-material/Search";
import { useGetAllUsersQuery } from "../accounts/accountsApi";
import LoaderWithProgress from "../components/LoaderWithProgress/LoaderWithProgress";
import { textFieldInputStl } from "../accounts/styles";

const SessionsPage: React.FC = () => {
  const { data: sessions, isLoading: isSessionsLoading } = useGetSessionsQuery();
  const [selectedRange, setSelectedRange] = React.useState<TimeRange>(TimeRange.P7D);
  const { data: activeUsers, isLoading: isActiveUsersLoading } = useGetActiveUsersQuery(
    getDaysForTimeRange(selectedRange),
    {
      //refetchOnMountOrArgChange: true,
    }
  );

  const { currentPeriod, previousPeriod, changes, changesType } = calculateMetrics(sessions || [], selectedRange);
  const { data: allUsers, isLoading: isAllUserLoading } = useGetAllUsersQuery();

  const [searchTerm, setSearchTerm] = React.useState("");
  const filteredSessions = React.useMemo(() => {
    if (!sessions) return [];

    const lowerSearch = searchTerm.toLowerCase();
    return sessions.filter((s) => {
      const fullName = `${s.user.firstName} ${s.user.lastName}`.toLowerCase();
      const email = s.user.email?.toLowerCase() || "";
      return fullName.includes(lowerSearch) || email.includes(lowerSearch);
    });
  }, [sessions, searchTerm]);

  const metrics: DashboardCardProps[] = useMemo(() => {
    const activeUsersCurrent = activeUsers?.currentActiveUsers ?? 0;
    const activeUsersPast = activeUsers?.pastActiveUsers ?? 0;
    const activeUsersDiff = activeUsersCurrent - activeUsersPast;
    const activeUsersChangeType = activeUsersDiff >= 0 ? ChangeType.Increase : ChangeType.Decrease;
    const totalUsersChange = activeUsers?.totalUsersChange ?? 0;
    const totalUsersChangeType = totalUsersChange >= 0 ? ChangeType.Increase : ChangeType.Decrease;
    const totalUsers = allUsers?.users.filter((u) => u.active === 1);
    return [
      {
        metricName: MetricNames.ActiveUsers,
        timeRange: selectedRange,
        value: activeUsersCurrent,
        change: activeUsersDiff,
        changeType: activeUsersChangeType,
      },
      {
        metricName: MetricNames.TotalUsers,
        timeRange: selectedRange,
        value: Number(totalUsers?.length),
        change: totalUsersChange,
        changeType: totalUsersChangeType,
      },
      {
        metricName: MetricNames.SessionDuration,
        timeRange: selectedRange,
        value: formatDurationFromSecondsOrMinutes(currentPeriod.averageSessionDuration),
        change: changes.averageSessionDuration,
        changeType: changesType.averageSessionDuration,
      },
      {
        metricName: MetricNames.Requests,
        timeRange: selectedRange,
        value: Math.round(currentPeriod.averageRequests),
        change: Math.round(changes.averageRequests),
        changeType: changesType.averageRequests,
      },
    ];
  }, [allUsers, selectedRange, activeUsers, isSessionsLoading]);

  if (isSessionsLoading || isAllUserLoading || isActiveUsersLoading) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <Box sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "16px" }}>
      <Grid container spacing={1}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <DashboardCard
              metricName={metric.metricName}
              timeRange={metric.timeRange}
              value={metric.value}
              change={metric.change}
              changeType={metric.changeType}
              subLabel={metric.subLabel}
              subValue={metric.subValue}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} height={"308px"}>
        <Grid item xs={12} md={3} height={"100%"}>
          <ActiveUsersCard
            timeRange={selectedRange}
            mostActiveUserSessions={currentPeriod.mostActiveUserSessions}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <MonitoringChartWrapper
            sessions={sessions || []}
            setSelectedRangeCallback={(newTimeRange: TimeRange) => {
              setSelectedRange(newTimeRange);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <TextField
            placeholder="Search user..."
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
              ...textFieldInputStl,
              width: "100%",
            }}
          />
        </Grid>
      </Grid>

      <SessionsTable sessions={filteredSessions || []} />
    </Box>
  );
};

export default SessionsPage;
