import React, { ReactNode } from "react";
import { Alert, AlertTitle, Box, IconButton, SxProps, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { colors } from "../../theme";
import { CheckBox } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";

interface BannerProps {
  title?: string;
  text: React.ReactNode | string;
  level: BannerLevelNames;
  visible: boolean;
  withoutCloseButton?: boolean;
  onClose?: () => void;
  style?: SxProps;
  action?: {
    text: string;
    action: (e: React.MouseEvent<HTMLSpanElement>) => void;
  };
  size?: "fullWidth" | "small";
}

interface LevelProps {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  icon: React.ReactNode;
}

export enum BannerLevelNames {
  URGENT,
  INFO,
  REMINDER,
  WARNING,
}

interface BannerLevel {
  [BannerLevelNames.URGENT]: LevelProps;
  [BannerLevelNames.INFO]: LevelProps;
  [BannerLevelNames.REMINDER]: LevelProps;
  [BannerLevelNames.WARNING]: LevelProps;
}

export const bannerLevels: BannerLevel = {
  [BannerLevelNames.INFO]: {
    textColor: colors.brandDefault,
    backgroundColor: colors.blue50,
    borderColor: colors.blue300,
    icon: <InfoIcon sx={{ color: colors.brandDefault }} />,
  },
  [BannerLevelNames.WARNING]: {
    textColor: colors.warning,
    backgroundColor: colors.statusWarningBackgroundSubtle,
    borderColor: colors.yellow300,
    icon: <WarningIcon sx={{ color: colors.warning }} />,
  },
  [BannerLevelNames.URGENT]: {
    textColor: colors.brightRed900,
    backgroundColor: colors.brightRed50,
    borderColor: colors.brightRed300,
    icon: <ErrorIcon sx={{ color: colors.brightRed300 }} />,
  },
  [BannerLevelNames.REMINDER]: {
    textColor: colors.successIcon,
    backgroundColor: colors.successBackground,
    borderColor: colors.secGreen100,
    icon: <CheckBox sx={{ color: colors.successIcon }} />,
  },
};

const Banner: React.FC<BannerProps> = ({
  title,
  text,
  visible,
  withoutCloseButton = false,
  onClose,
  level,
  action,
  style,
  size = "fullWidth",
}) => {
  if (!visible) return null;

  if (size === "small") {
    return (
      <Alert
        id="notification-banner-small"
        icon={bannerLevels[level].icon}
        sx={{
          backgroundColor: bannerLevels[level].backgroundColor,
          color: bannerLevels[level].textColor,
          display: "flex",
          ".MuiAlert-message": {
            width: "100%",
          },
          ".MuiAlert-action": {
            padding: "6px",
          },
          ...style,
        }}
        action={
          withoutCloseButton ? null : (
            <IconButton
              id="notificaiton-banner-close-btn"
              style={{ padding: 0 }}
              aria-label="close"
              color="inherit"
              size="large"
              onClick={onClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
      >
        {size === "small" && title && (
          <AlertTitle
            id="notificaiton-banner-title"
            sx={{
              color: bannerLevels[level].textColor,
              fontWeight: "bold",
              wordBreak: "break-word",
            }}
          >
            {title}
          </AlertTitle>
        )}
        <Box
          id="notification-banner-text"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
            wordBreak: "break-word",
          }}
        >
          <Typography sx={{ color: bannerLevels[level].textColor, wordBreak: "break-word" }}>{text}</Typography>
        </Box>
        {action && (
          <Box
            id="notification-banner-action-btn"
            onClick={action.action}
            style={{ padding: 0, margin: 0, width: "100%" }}
          >
            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
                textAlign: "end",
              }}
            >
              {action.text}
            </Typography>
          </Box>
        )}
      </Alert>
    );
  }
  return (
    <Alert
      id="notification-banner"
      icon={bannerLevels[level].icon}
      sx={{
        backgroundColor: bannerLevels[level].backgroundColor,
        color: bannerLevels[level].textColor,
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "8px 16px",
        borderRadius: "16px",
        border: `1px solid ${bannerLevels[level].borderColor}`,
        //height: "36px",
        ".MuiAlert-message": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        },
        ".MuiAlert-action": {
          padding: "0 10px",
        },
        ...style,
      }}
      action={
        withoutCloseButton ? null : (
          <IconButton
            id="notification-banner-close-btn"
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography id="notification-banner-text" sx={{ color: bannerLevels[level].textColor }}>
          {text}
        </Typography>
        {action && (
          <Box id="notification-banner-action-btn" onClick={action.action} style={{ padding: 0, margin: 0 }}>
            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              {action.text}
            </Typography>
          </Box>
        )}
      </Box>
    </Alert>
  );
};

export default Banner;
