import { CONSTANT } from "../constants/constants";
import { RootState } from "../store/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { checkVersion } from "../infrastructure/infrastructureHelpers";
import { IQuickActionsItem } from "./quickActionsUtils";

const SERVER_URL = CONSTANT.path.host;

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { getState } = api;
  const state = getState() as RootState;
  const token = state.users.loggedUser.token;
  if (!token) {
    return {
      error: {
        status: 401,
        data: { message: "No token available for authentication." },
      },
    } as { error: FetchBaseQueryError };
  }
  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/quick-actions`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  });

  const result: any = await baseQuery(args, api, extraOptions);

  if (result.data && "reactAppVersion" in result.data) {
    checkVersion((result.data as any).reactAppVersion);
  }

  return result;
};

export const quickActionsApi = createApi({
  reducerPath: "quickActionsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["QuickActions"],
  endpoints: (builder) => ({
    createQuickAction: builder.mutation<IQuickActionsItem, IQuickActionsItem>({
      query: (quickActionDto) => ({
        url: "",
        method: "POST",
        body: quickActionDto,
      }),
      async onQueryStarted(quickActionDto, { dispatch, queryFulfilled }) {
        // 2a) Optimistically update the cache
        const patchResult = dispatch(
          quickActionsApi.util.updateQueryData("getQuickActions", undefined, (draft) => {
            draft.push(quickActionDto);
            // This means the UI sees the new item immediately
          })
        );
        // 2b) Then wait for server response
        try {
          const { data: created } = await queryFulfilled;
          // 2c) Refine the item in the cache with the actual response (e.g. updated id)
          dispatch(
            quickActionsApi.util.updateQueryData("getQuickActions", undefined, (draft) => {
              // find the optimistically added item, update it with the returned data
              const index = draft.findIndex((d) => d.id === quickActionDto.id);
              if (index !== -1) {
                draft[index] = created;
              } else {
                // or push if it wasn't found
                draft.push(created);
              }
            })
          );
        } catch {
          // 2d) In case of error, roll back the optimistic update
          patchResult.undo();
        }
      },
    }),
    getQuickActions: builder.query<IQuickActionsItem[], void>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: ["QuickActions"],
    }),
    updateQuickAction: builder.mutation<IQuickActionsItem, IQuickActionsItem>({
      query: (updateData) => ({
        url: `/${updateData.id}`,
        method: "PATCH",
        body: updateData,
      }),
      async onQueryStarted(updateData, { dispatch, queryFulfilled }) {
        // 3a) Optimistically update the item in the cache
        const patchResult = dispatch(
          quickActionsApi.util.updateQueryData("getQuickActions", undefined, (draft) => {
            const index = draft.findIndex((d) => d.id === updateData.id);
            if (index !== -1) {
              draft[index] = { ...draft[index], ...updateData };
            }
          })
        );
        // 3b) Wait for the server response
        try {
          const { data: updated } = await queryFulfilled;
          // 3c) Finalize with the server’s return data (in case any fields changed)
          dispatch(
            quickActionsApi.util.updateQueryData("getQuickActions", undefined, (draft) => {
              const index = draft.findIndex((d) => d.id === updated.id);
              if (index !== -1) {
                draft[index] = updated;
              }
            })
          );
        } catch {
          // 3d) Roll back in case of failure
          patchResult.undo();
        }
      },
    }),
    deleteQuickAction: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // 4a) Optimistically remove from cache
        const patchResult = dispatch(
          quickActionsApi.util.updateQueryData("getQuickActions", undefined, (draft) => {
            const index = draft.findIndex((item) => item.id === id);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          })
        );
        // 4b) Wait for the server to confirm
        try {
          await queryFulfilled;
        } catch {
          // 4c) Revert if server call fails
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useCreateQuickActionMutation,
  useGetQuickActionsQuery,
  useUpdateQuickActionMutation,
  useDeleteQuickActionMutation,
} = quickActionsApi;
