import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { CONSTANT } from "../../constants/constants";
import {
  IBidderConfig,
  IBidderConfigCreate,
  IBidderConfigUpdate,
  ICountryBepp,
  IMediaType,
  IPartnerBepp,
  IRegionBepp,
} from "./interfaces";
import { checkVersion } from "../../infrastructure/infrastructureHelpers";
import { RootState } from "../../store/store";

const SERVER_URL = CONSTANT.path.host;

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { getState } = api;
  const state = getState() as RootState;
  const token = state.users.loggedUser.token;
  if (!token) {
    return {
      error: {
        status: 401,
        data: { message: "No token available for authentication." },
      },
    } as { error: FetchBaseQueryError };
  }
  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/bidders-config`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  });

  const result: any = await baseQuery(args, api, extraOptions);

  if (result.data && "reactAppVersion" in result.data) {
    checkVersion((result.data as any).reactAppVersion);
  }

  return result;
};

export const configurationsApi = createApi({
  reducerPath: "configurationsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Partners", "BidderConfig", "Countries", "Regions", "Mediatypes"],
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getAllPartnersConfiguration: builder.query<IPartnerBepp[], void>({
      query: () => `partners`,
      providesTags: ["Partners"],
    }),
    getAllConfiguration: builder.query<IBidderConfig[], void>({
      query: () => ``,
      providesTags: ["BidderConfig"],
    }),
    createBidderConfig: builder.mutation<IBidderConfig, IBidderConfigCreate>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["BidderConfig"],
    }),
    updateBidderConfig: builder.mutation<IBidderConfig, { id: number; data: IBidderConfigUpdate }>({
      query: ({ id, data }) => ({
        url: `${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BidderConfig"],
    }),
    deleteBidderConfig: builder.mutation<void, number>({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BidderConfig"],
    }),
    getCountries: builder.query<ICountryBepp[], void>({
      query: () => `countries`,
      providesTags: ["Countries"],
    }),
    getRegions: builder.query<IRegionBepp[], void>({
      query: () => `regions`,
      providesTags: ["Regions"],
    }),
    addRegion: builder.mutation<IRegionBepp, string>({
      query: (name: string) => ({
        url: `regions/${name}`,
        method: "POST",
      }),
      invalidatesTags: ["Regions"],
    }),
    getMediaTypes: builder.query<IMediaType[], void>({
      query: () => `mediatypes`,
      providesTags: ["Mediatypes"],
    }),
    createPartnersConfiguration: builder.mutation<void, Partial<IBidderConfig>>({
      query: (data) => {
        return {
          url: "",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
    deletePartnersConfigurationById: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
    updatePartnersConfigurationById: builder.mutation<void, { data: Partial<IBidderConfig>; id: number }>({
      query: ({ id, data }) => {
        return {
          url: `${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
    updatePartnersIsactiveById: builder.mutation<void, { data: { isServiceActive: number; pdids: number[] } }>({
      query: ({ data }) => {
        return {
          url: `isactive`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
  }),
});

export const {
  // Queries
  useGetAllPartnersConfigurationQuery,
  useGetAllConfigurationQuery,
  useGetCountriesQuery,
  useGetRegionsQuery,
  useGetMediaTypesQuery,

  // Mutations
  useCreateBidderConfigMutation,
  useUpdateBidderConfigMutation,
  useDeleteBidderConfigMutation,
  useDeletePartnersConfigurationByIdMutation,
  useCreatePartnersConfigurationMutation,
  useUpdatePartnersConfigurationByIdMutation,
  useUpdatePartnersIsactiveByIdMutation,
  useAddRegionMutation,
} = configurationsApi;
