import { useGetAllBiddersQuery } from "./bidderAdaptersApi";
import {
  useGetAllPartnersConfigurationQuery,
  useGetCountriesQuery,
  useGetMediaTypesQuery,
} from "./configurationsApi";
import {
  createBiddersMapping,
  createCountriesMapping,
  createMediatypesMapping,
  createPartnerMapping,
} from "./utils";

export const usePartnerMapping = () => {
  const { data: partners = [], isLoading, error, isSuccess } = useGetAllPartnersConfigurationQuery();
  const partnerMapping = createPartnerMapping(partners);
  return { partnerMapping, isLoading, error, isSuccess };
};

export const useBiddersMapping = () => {
  const { data: bidders = [], isLoading, error, isSuccess } = useGetAllBiddersQuery();
  const biddersMapping = createBiddersMapping(bidders);
  return { biddersMapping, isLoading, error, isSuccess };
};

export const useMediaTypesMapping = () => {
  const { data: mediaTypes = [], isLoading, error, isSuccess } = useGetMediaTypesQuery();
  const mediatypesMapping = createMediatypesMapping(mediaTypes);
  return { mediatypesMapping, isLoading, error, isSuccess };
};

export const useCountriesMapping = () => {
  const { data: countries = [], isLoading, error, isSuccess } = useGetCountriesQuery();
  const countriesMapping = createCountriesMapping(countries);
  return { countriesMapping, isLoading, error, isSuccess };
};
