import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { GridFilterInputValueProps, useGridRootProps } from "@mui/x-data-grid";
import SyncIcon from "@mui/icons-material/Sync";

const FilterNumericInputBetween: React.FC<GridFilterInputValueProps> = (props) => {
  const rootProps = useGridRootProps();
  const { item, applyValue, focusElementRef = null } = props;
  const filterTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [filterValueState, setFilterValueState] = useState<[string, string]>(item.value ?? ["", ""]);
  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? ["", ""];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound: string, upperBound: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, rootProps.filterDebounceMs);
  };

  const handleUpperFilterChange = (event: { target: { value: any } }) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };

  const handleLowerFilterChange = (event: { target: { value: any } }) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      className="number-filter-input"
      sx={{
        display: "flex",
        height: 48,
        // border: "1px solid red"
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={filterValueState[0] ?? ""}
        onChange={handleLowerFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ flex: 1, mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="To"
        label="To"
        variant="standard"
        value={filterValueState[1] ?? ""}
        onChange={handleUpperFilterChange}
        type="number"
        InputProps={isApplying ? { endAdornment: <SyncIcon /> } : {}}
        sx={{ flex: 1 }}
      />
    </Box>
  );
};

export default FilterNumericInputBetween;
