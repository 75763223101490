import React, { useMemo, useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import ABBarChart from "./ABBarChart";
import DropdownHandler from "../../../components/Dropdown/DropdownHandler";
import { CategoryType } from "../../../parametersConstancy/parametersConstancyTypes";
import { ICustomChartItem, AggregateCustomBaseDataOutput, CustomChartTypes, Item, CustomChartIds } from "../types";
import LineChartDisplay from "./LineChartDisplay";
import { colors } from "../../../theme";
import { EmptyData } from "../EmptyData";

const SPECIAL_TOP5_ID = "special_top5";
export const SPECIAL_TOP5_NAME = "Top 5";
interface CustomChartProps {
  chartItem: ICustomChartItem;
  aggregatedData: AggregateCustomBaseDataOutput;
  isLoading: boolean;
  currencySymbol: string;
}

const CustomChart: React.FC<CustomChartProps> = ({ chartItem, aggregatedData, isLoading, currencySymbol }) => {
  const chartType = CustomChartTypes[chartItem.id];
  const specialTop5Item = useMemo(() => {
    return { id: SPECIAL_TOP5_ID, name: SPECIAL_TOP5_NAME, isChecked: false };
  }, []);

  // Build the list of base options from aggregated data.
  const baseOptions: CategoryType[] = useMemo(() => {
    if (aggregatedData.items && aggregatedData.items.length > 0) {
      return aggregatedData.items.map((p: Item) => ({
        id: p.entity,
        name: p.entity,
      }));
    }
    return [];
  }, [aggregatedData.items]);

  const initialSelection: CategoryType[] = useMemo(() => {
    const preSel = aggregatedData.items?.filter((p: Item) => p.isPreselected);
    const selection = preSel.length > 0 ? preSel : aggregatedData.items;
    return selection.map((p: Item) => ({ id: p.entity, name: p.entity }));
  }, [aggregatedData.items]);

  const [selectedItems, setSelectedItems] = useState<CategoryType[]>([]);
  useEffect(() => {
    if (initialSelection.length > 0) {
      setSelectedItems(initialSelection);
    }
  }, [aggregatedData.items]);

  const [openFilter, setOpenFilter] = useState<string>("");

  const handleToggleOpen = (base: string) => {
    setOpenFilter((prev) => (prev === base ? "" : base));
  };

  const top5: CategoryType[] = useMemo(() => {
    if (aggregatedData.topEntities && aggregatedData.topEntities.length > 0) {
      return aggregatedData.topEntities.map((entity: string) => ({
        id: entity,
        name: entity,
      }));
    }
    return [];
  }, [aggregatedData.items]);

  const handleApply = (base: string, newSelected: CategoryType[]) => {
    setSelectedItems(newSelected);
    setOpenFilter("");
  };

  const selectedGroupNames = useMemo(() => {
    return selectedItems.map((item) => item.name);
  }, [selectedItems]);

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "hidden",
        padding: "16px 16px 16px 16px",
        borderBottom: `1px solid ${colors.navy50}`,
      }}
    >
      {chartItem.id !== CustomChartIds.IMPRESSIONS && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: colors.navy50,
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "32px",
            }}
          >
            <Typography variant="bodyMediumSemiboldNormal">{`${chartType.title}`}</Typography>
          </Box>
          {!isLoading && aggregatedData.items.length > 0 && (
            <DropdownHandler
              base={chartType.base}
              title={`Select ${chartType.base}...`}
              optionsData={baseOptions}
              valuesData={selectedItems}
              isDisabled={false}
              openFilter={openFilter}
              handleToggleOpen={handleToggleOpen}
              handleApply={handleApply}
              isMultiSelection={true}
              id="custom-chart-dropdown"
              specialItem={specialTop5Item}
              specialItemsPayload={top5}
              shouldBeSelected={true}
            />
          )}
        </Box>
      )}
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
          <CircularProgress />
        </Box>
      ) : aggregatedData.items.length === 0 ? (
        <EmptyData />
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            overflow: "hidden",
            paddingTop: "16px",
          }}
        >
          {chartItem.liftVisible && (
            <Box sx={{ flex: 1, minWidth: 0, borderRight: `1px solid ${colors.navy50}` }}>
              <LineChartDisplay
                chartItem={chartItem}
                aggregatedData={aggregatedData}
                groups={selectedGroupNames}
                chartType={chartType}
                currencySymbol={currencySymbol}
              />
            </Box>
          )}
          {chartItem.abVisible && (
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <ABBarChart
                chartItem={chartItem}
                aggregatedData={aggregatedData}
                groups={selectedGroupNames}
                chartType={chartType}
                currencySymbol={currencySymbol}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CustomChart;
