import React, { useEffect, useState } from "react";
import {
  generateUniqueId,
  IQuickActionsItem,
  IUniversalSelectItem,
  LogicRule,
  MAX_ACTION_NAME_LENGTH,
  PAGES_OPTIONS,
  QUICK_ACTION_ADD_PARAMETERS,
  QUICK_ACTION_ADD_PARAMETERS_MAP,
  QUICK_ACTION_ENTITIES,
  QUICK_ACTION_ENTITIES_MAP,
  QUICK_ACTION_ICONS,
  QUICK_ACTION_PARAMETERS,
  quickActionParamsUnselected,
} from "./quickActionsUtils";
import UniversalSelect from "../components/SelectItems/UniversalSelect";
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import QuickActionSettingsLogicRules from "./QuickActionSettingsLogicRules";
import { colors } from "../theme";
import { convertLocal } from "../utils";
import Banner, { BannerLevelNames } from "../components/Banner/Banner";

export interface QuickActionSettingsProps {
  actionItem: IQuickActionsItem;
  setActionItem: React.Dispatch<React.SetStateAction<IQuickActionsItem>>;
  actionItems: IQuickActionsItem[];
}
const QUICK_ACTION_CONFIG_BANNER_TEXT =
  "Create customized settings by selecting specific options. 'Quick actions' let you efficiently access data using filters and settings that you are interested in and frequently use";
const SUBJECT_TOOLTIP_TEXT = "Selection will define the type of subject for which the report will be created";
const METRIC_TOOLTIP_TEXT = "Pick the performance metric you want to analize in your report";
const DATEREG_TOOLTIP_TEXT = 'Search among partners created within the specified time frame. If left blank, the search will include all partners, regardless of their creation date';

const QuickActionSettings: React.FC<QuickActionSettingsProps> = ({ actionItem, setActionItem, actionItems }) => {
  const [actionParameters, setActionParameters] = useState<IUniversalSelectItem[]>(QUICK_ACTION_PARAMETERS);
  const [actionName, setActionName] = useState<string>(actionItem.name);
  const [logicRules, setLogicRules] = useState<LogicRule[]>([]);
  const [selectedIconId, setSelectedIconId] = useState<number | string>(actionItem.settings.iconId);
  const [selectedEntityId, setSelectedEntityId] = useState<number | string>(actionItem.settings.entityId);
  const [selectedParametersId, setSelectedParametersId] = useState<number | string>(
    actionItem.settings.parametersId
  );
  const [selectedAddParametersId, setSelectedAddParametersId] = useState<number | string>(
    actionItem.settings.addParametersId
  );
  const [pageId, setPageId] = useState<number | string>(actionItem.settings.pageId);
  const [description, setDescription] = useState<string>("");
  const actionItemsName = actionItems.map((action) => action.name) ?? [];
  useEffect(() => {
    setActionName(actionItem.name);
    setSelectedIconId(actionItem.settings.iconId);
    setPageId(actionItem.settings.pageId);
    setSelectedEntityId(actionItem.settings.entityId);
    setSelectedParametersId(actionItem.settings.parametersId);
    setSelectedAddParametersId(actionItem.settings.addParametersId);
    const initialRules = (actionItem.settings.logicRules ?? []).map((rule) => ({
      ...rule,
      id: rule.id ?? generateUniqueId(),
    }));
    setLogicRules(initialRules);
  }, [actionItem.id]);

  useEffect(() => {
    const selectedEntity = QUICK_ACTION_ENTITIES.find((entity) => entity.id === selectedEntityId);
    const selectedParameters = QUICK_ACTION_PARAMETERS.find(
      (parameters) => parameters.id === selectedParametersId
    );
    const prefix = selectedParameters?.prefix ?? "";
    const logicRulesStr = logicRules
      .map((rule) => {
        const minStr =
          rule.minValue !== 0 && rule.minValue ? ` from ${convertLocal(Number(rule.minValue))}${prefix}` : "";
        const maxStr =
          rule.maxValue !== 0 && rule.maxValue ? ` to ${convertLocal(Number(rule.maxValue))}${prefix}` : "";
        return minStr.concat(maxStr);
      })
      .join(",");

    const selectedAddParameters = QUICK_ACTION_ADD_PARAMETERS.find(
      (params) => params.id === selectedAddParametersId
    );
    const parts = selectedAddParameters?.name.split(/(\d+)/);
    const selectedAddParametersNameStr = parts?.filter(Boolean).join(" ");

    const selectedAddParametersStr =
      selectedAddParametersId !== QUICK_ACTION_ADD_PARAMETERS_MAP.unselect.id
        ? `registered in ${selectedAddParametersNameStr} `
        : "";

    const selectedParametersNameStr =
      +selectedParametersId !== +quickActionParamsUnselected.id ? `with ${selectedParameters?.name}` : "";
    const descript = `${selectedEntity?.name ?? ""} ${selectedAddParametersStr} ${selectedParametersNameStr}${
      !logicRulesStr ? prefix : ""
    }${logicRulesStr}, aggregated mode`;
    setDescription(descript);
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        description: descript,
      },
    }));
  }, [selectedEntityId, selectedParametersId, selectedAddParametersId, logicRules]);

  const handleSelectionActionsIcon = (event: SelectChangeEvent<string[] | string>) => {
    const value: number = +event.target.value;
    setSelectedIconId(value);
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        iconId: value,
      },
    }));
  };

  const handleSelectionEntity = (event: SelectChangeEvent<string[] | string>) => {
    const value: number = +event.target.value;
    setSelectedEntityId(value);
    if (value !== +QUICK_ACTION_ENTITIES_MAP.partners.id) {
      setSelectedAddParametersId(QUICK_ACTION_ADD_PARAMETERS_MAP.unselect.id);
    }
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        entityId: value,
        addParametersId:
          +value !== +QUICK_ACTION_ENTITIES_MAP.partners.id
            ? QUICK_ACTION_ADD_PARAMETERS_MAP.unselect.id
            : prev.settings.addParametersId,
      },
    }));
  };

  const handleSelectionParameters = (event: SelectChangeEvent<string[] | string>) => {
    const value: string = "" + event.target.value;
    setSelectedParametersId(value);
    const newInitLogicRule: LogicRule = {
      id: generateUniqueId(),
      minValue: "",
      maxValue: "",
    };
    if (quickActionParamsUnselected.id === +value) {
      setLogicRules([]);
    } else if (!logicRules.length) {
      setLogicRules([newInitLogicRule]);
    }
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        parametersId: value,
        logicRules:
          quickActionParamsUnselected.id === +value
            ? []
            : !logicRules.length
            ? [newInitLogicRule]
            : prev.settings.logicRules,
      },
    }));
  };

  const handleSelectionAddParameters = (event: SelectChangeEvent<string[] | string>) => {
    const value: number = +event.target.value;
    setSelectedAddParametersId(value);
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        addParametersId: value,
      },
    }));
  };

  const handleChangePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = +event.target.value;
    setPageId(value);
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        pageId: value,
      },
    }));
  };

  const handleChangeLogicRules = (logicRules: LogicRule[]) => {
    setActionItem((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        logicRules,
      },
    }));
  };

  const isDisabled = selectedEntityId !== QUICK_ACTION_ENTITIES_MAP.partners.id;
  const disabledStyle = { color: isDisabled ? "#bebebe" : undefined };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        flex: "1 0 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        <Typography variant="h3Medium600">Quick Action Report Configuration</Typography>
        <Banner
          text={QUICK_ACTION_CONFIG_BANNER_TEXT}
          level={BannerLevelNames.INFO}
          visible={true}
          withoutCloseButton={true}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: "24px",
            alignSelf: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: "24px",
              flex: "1 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                width: "100%",
                alignItems: "flex-start",
                flex: "1 0 0",
              }}
            >
              <Typography variant="body2XSmallMain400">
                Name of the action
                <Typography component="span" color="error">
                  {" *"}
                </Typography>
              </Typography>
              <TextField
                id="quick-actions-name"
                onChange={(e) => {
                  setActionName(e.target.value);
                  setActionItem((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
                value={actionName}
                inputProps={{ maxLength: MAX_ACTION_NAME_LENGTH }}
                InputProps={{
                  sx: { borderRadius: "8px", height: "48px" },
                }}
                fullWidth
                required
                placeholder="Type here"
                error={Boolean(
                  actionItems.find((action) => action.name === actionItem.name && action.id !== actionItem.id)
                )}
                helperText={
                  actionItems.find((action) => action.name === actionItem.name && action.id !== actionItem.id)
                    ? "Name dublication"
                    : ""
                }
              />
              <Typography id="group-name-input-hint" variant="bodySmallRegular">
                Number of characters: {MAX_ACTION_NAME_LENGTH - actionName.length}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <Typography variant="body2XSmallMain400">
                Icon
                <Typography component="span" color="error">
                  {" *"}
                </Typography>
              </Typography>
              <UniversalSelect
                id="icons-single-select"
                size="large"
                items={QUICK_ACTION_ICONS}
                value={`${selectedIconId}`}
                onChange={handleSelectionActionsIcon}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        <Typography variant="h3Medium600">Pages</Typography>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={pageId}
          onChange={handleChangePage}
        >
          {PAGES_OPTIONS.map((page) => (
            <FormControlLabel key={page.id} value={page.id} control={<Radio />} label={page.name} disabled />
          ))}
        </RadioGroup>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            flex: "1 0 0",
          }}
        >
          <Typography variant="h3Medium600">Choose Report Subject</Typography>
          <Typography variant="bodyLargeRegular">{SUBJECT_TOOLTIP_TEXT}</Typography>
          <UniversalSelect
            id="entities-single-select"
            items={QUICK_ACTION_ENTITIES}
            value={`${selectedEntityId}`}
            onChange={handleSelectionEntity}
            size="large"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            flex: "1 0 0",
          }}
        >
          <Typography variant="h3Medium600">Select Performance Metric</Typography>
          <Typography variant="bodyLargeRegular">{METRIC_TOOLTIP_TEXT}</Typography>
          <UniversalSelect
            id="parameters-single-select"
            items={actionParameters}
            value={`${selectedParametersId}`}
            onChange={handleSelectionParameters}
            placeholder="None"
            displayEmpty={true}
            menuSx={{ minWidth: "170px" }}
            size="large"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            flex: "1 0 0",
          }}
        >
          <Typography variant="h3Medium600" sx={disabledStyle}>Date of registration</Typography>
          <Typography variant="bodyLargeRegular" sx={disabledStyle}>{DATEREG_TOOLTIP_TEXT}</Typography>
          <UniversalSelect
            id="additional-parameters-single-select"
            disabled={isDisabled}
            items={QUICK_ACTION_ADD_PARAMETERS}
            value={`${selectedAddParametersId}`}
            onChange={handleSelectionAddParameters}
            size="large"
            placeholder="Select time frame"
            displayEmpty={true}
          />
        </Box>
      </Box>
      <QuickActionSettingsLogicRules
        logicRules={logicRules}
        setLogicRules={setLogicRules}
        handleChangeLogicRules={handleChangeLogicRules}
        addBtnDisabled={+selectedParametersId === +quickActionParamsUnselected.id}
      />
      <Box
        sx={{
          display: "flex",
          padding: "16px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          alignSelf: "stretch",
          background: colors.blue50,
          borderRadius: "16px",
        }}
      >
        <Typography variant="body1Small600">{description}</Typography>
      </Box>
    </Box>
  );
};

export default QuickActionSettings;
