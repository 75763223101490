import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import useCreateAccountBoard from "../helpers/useCreateAccountBoard";
import Popup from "./Popup";

export const btnStl = {
  width: "100%",
  padding: "1rem 0.75rem 1rem 0.75rem",
  borderRadius: "0.5rem",
  fontWeight: 600,
  fontSize: "1rem",
  backgroundColor: "secondary.lightBlue",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    color: "secondary.mainDark",
  },
};

function SideBoardWrapper({
  btnTitle = "Add",
  btnIcon = <AddIcon />,
  btnStyles,
  updateDataAndRows = () => {},
  value = {},
  type = "account",
  headerTitle = "Add new account",
  isDisabled = false,
  id,
}) {
  let { open, handleClickOpen, onClose } = useCreateAccountBoard();

  return (
    <div>
      <Button
        id={`${id}-btn`}
        onClick={handleClickOpen}
        // onClick={handleClick}
        sx={{ ...btnStl, ...btnStyles }}
        startIcon={btnIcon}
        style={{ position: "relative" }}
        disabled={isDisabled}
      >
        {btnTitle}
      </Button>

      <Popup
        open={open}
        onClose={onClose}
        updateDataAndRows={updateDataAndRows}
        value={value}
        type={type}
        headerTitle={headerTitle}
      />
    </div>
  );
}

export default SideBoardWrapper;
