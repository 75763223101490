import React from "react";
import { Box, Typography, Chip, SxProps } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { colors } from "../../theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { convertLocal, nFormatter } from "../../utils";

export enum WidgetMetricNames {
  Revenue = "Revenue",
  RevenueLift = "Revenue lift",
  TotalImpressions = "Total impressions",
  FillRateLift = "Fill rate lift",
  CPMLift = "CPM lift",
}

export interface DashboardWidgetCardProps {
  metricName: WidgetMetricNames;
  timeRangeString: string;
  value: number | string;
  previousPeriodChangePercents: number;
  currentPeriodPercents?: number;
}

const widgetCardSx: SxProps = {
  width: "100%",
  height: 116,
  p: "12px",
  backgroundColor: "transparent",
  borderTop: `1px solid ${colors.borderDefault}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 2,
};

// Reusable PercentageChip component
interface PercentageChipProps {
  percent: number;
  fixedDigits?: number;
}

const PercentageChip: React.FC<PercentageChipProps> = ({ percent, fixedDigits = 1 }) => {
  if (percent === undefined || percent === null) {
    return <></>;
  }
  const textColor = percent >= 0 ? colors.successIcon : "#FF4D4F";
  const backgroundColor = percent >= 0 ? colors.successBackground : "#FDECEF";

  return (
    <Chip
      label={
        <Typography variant="bodySmallMedium" color={textColor}>
          {percent >= 0 ? `+${nFormatter(percent, fixedDigits)}%` : `${nFormatter(percent, fixedDigits)}%`}
        </Typography>
      }
      sx={{
        backgroundColor,
        borderRadius: "8px",
        p: 0,
        // Override default label padding
        "& .MuiChip-label": {
          pl: 0.5,
          pr: 0.25,
        },
      }}
      icon={
        percent >= 0 ? (
          <TrendingUpIcon style={{ color: textColor }} />
        ) : (
          <TrendingDownIcon style={{ color: textColor }} />
        )
      }
    />
  );
};

const DashboardWidgetCard: React.FC<DashboardWidgetCardProps> = ({
  metricName,
  timeRangeString,
  value,
  previousPeriodChangePercents,
  currentPeriodPercents,
}) => {
  return (
    <Box sx={widgetCardSx}>
      <Box
        sx={{
          alignSelf: "stretch",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          borderRadius: "16px",
        }}
      >
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body2XSmallGray600">{metricName}</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
            <Typography variant="bodySmallMedium">{timeRangeString}</Typography>
            <KeyboardArrowDownIcon sx={{ color: colors.foreground }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography variant="h1XLargeDark600">{value}</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            {currentPeriodPercents !== undefined && (
              <PercentageChip percent={currentPeriodPercents} fixedDigits={2} />
            )}
            <PercentageChip percent={previousPeriodChangePercents} fixedDigits={1} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardWidgetCard;
