import React, { useEffect, useMemo, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceLine,
} from "recharts";
import { useSelector } from "react-redux";
import { AggregateCustomBaseDataOutput, ICustomChart, ICustomChartItem } from "./../types";
import { Box, Typography } from "@mui/material";
import CustomTooltipLegend from "./CustomTooltipLegend";
import { colors } from "../../../theme";
import { formatXAxis, formatYAxis } from "../utils";
import { CustomizedAxisTick } from "../../../components/chartTemplates/utils";

interface LineChartDisplayProps {
  aggregatedData: AggregateCustomBaseDataOutput;
  groups: string[];
  chartType: ICustomChart;
  currencySymbol: string;
  chartItem: ICustomChartItem;
}

const CustomLineDot = (props: any) => {
  const { cx, cy, stroke, selected } = props;
  if (cx == null || cy == null) return null;
  return (
    <circle
      cx={cx}
      cy={cy}
      r={selected ? 4 : 3}
      fill={selected ? stroke : "white"}
      stroke={stroke}
      strokeWidth={selected ? 3 : 2}
    />
  );
};

const LineChartDisplay: React.FC<LineChartDisplayProps> = ({
  aggregatedData,
  groups,
  chartType,
  currencySymbol,
  chartItem,
}) => {
  const [tooltipData, setTooltipData] = useState<{ label: string; payload: any[] } | null>(null);
  const [selectedData, setSelectedData] = useState<{ label: string; payload: any[] } | null>(null);
  const { dateGroupingMode } = useSelector(
    (state: any) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const rowData = useMemo(() => {
    const dataCols = aggregatedData.filteredData[chartType.metric];
    if (!dataCols || !dataCols.date) return [];
    const n = dataCols.date.length;
    const data: any[] = [];
    for (let i = 0; i < n; i++) {
      const row: any = { date: dataCols.date[i] };
      Object.keys(dataCols).forEach((key) => {
        if (key !== "date") {
          row[key] = dataCols[key][i];
        }
      });
      data.push(row);
    }
    return data;
  }, [aggregatedData.filteredData, chartType.metric, groups]);

  useEffect(() => {
    setSelectedData(null);
    setTooltipData(null);
  }, [groups]);

  const groupsToDisplay = aggregatedData.items.filter((p) => groups.includes(p.entity));

  const handleMouseMove = (state: any) => {
    if (!state) return;
    if (!selectedData && state.isTooltipActive) {
      setTooltipData({
        label: state.activeLabel,
        payload: state.activePayload,
      });
    }
  };

  const handleMouseLeave = () => {
    if (!selectedData) {
      setTooltipData(null);
    }
  };

  const handleChartClick = (state: any) => {
    if (state && state.activeLabel) {
      if (selectedData && selectedData.label === state.activeLabel) {
        setSelectedData(null); // Deselect if clicking the same vertical line.
      } else {
        setSelectedData({
          label: state.activeLabel,
          payload: state.activePayload,
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: chartItem.abVisible ? "column" : "row",
        width: "100%",
      }}
    >
      <Box sx={{ overflowX: "auto", width: "100%", overflowY: "hidden", height: "320px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={rowData}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onClick={handleChartClick}
            margin={{
              top: 20,
              right: 25,
              bottom: 5,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="" stroke={colors.navy50} />
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxis}
              stroke={colors.navy50}
              // tick={{ fill: colors.text02 }}
              tick={<CustomizedAxisTick dgm={dateGroupingMode} d={rowData} />}
            />
            <YAxis
              stroke={colors.navy50}
              tick={<CustomizedAxisTick />}
              //  tick={{ fill: colors.text02 }}
              //  tickFormatter={formatYAxis}
            />
            <Tooltip content={() => null} />
            {selectedData && (
              <ReferenceLine
                x={selectedData.label}
                stroke={colors.gray300}
                strokeDasharray="6 6"
                strokeWidth={2}
              />
            )}
            {groupsToDisplay.map((group) => (
              <Line
                key={group.entity}
                type="linear"
                dataKey={group.entity}
                stroke={group.color}
                strokeWidth={2}
                isAnimationActive={false}
                dot={(dotProps) => (
                  <CustomLineDot
                    {...dotProps}
                    selected={selectedData && selectedData.label === dotProps.payload.date}
                  />
                )}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box
        sx={{
          height: !chartItem.abVisible ? "300px" : "168px",
          borderTop: "1px solid #ccc",
          padding: "8px 12px",
          overflowY: "auto",
          backgroundColor: colors.gray50,
          width: !chartItem.abVisible ? "380px" : "auto",
        }}
      >
        <CustomTooltipLegend
          tooltipData={selectedData || tooltipData}
          currencySymbol={chartType.prefix === "%" ? "%" : currencySymbol}
        />
      </Box>
    </Box>
  );
};

export default LineChartDisplay;
