import { SxProps, Typography } from "@mui/material";
import { colors } from "../../theme";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SendIcon from "@mui/icons-material/Send";

export const MAX_TITLE_LENGTH = 100;
export const MAX_TEXT_LENGTH = 2000;

export interface SelectionItem {
  id: number;
  name: string;
  description?: string;
  color?: string;
  icon?: string | number;
}

export const NotificationContext = {
  SYSTEM: {
    id: 1,
    name: "System",
  } as SelectionItem,
  // loginPage: {
  //   id: 2,
  //   name: "Login page",
  // },
};
export const notificationContextSelection: SelectionItem[] = Object.values(NotificationContext);

export const NotificationLevel = {
  INIT: { id: 0, name: "Select level", color: "darkgray" } as SelectionItem,
  HIGH_PRIORITY: { id: 3, name: "High Priority", color: "#FFA740" } as SelectionItem,
  INFORMATIONAL: { id: 1, name: "Informational", color: "#1955AD" } as SelectionItem,
  REMINDER: { id: 2, name: "Reminder", color: colors.statusSuccessDefault } as SelectionItem,
};
export const notificationLevelSelection: SelectionItem[] = Object.values(NotificationLevel);

export const NotificationStatus = {
  DRAFT: { id: 1, name: "draft" } as SelectionItem,
  PENDING: { id: 2, name: "pending" } as SelectionItem,
  SENT: { id: 3, name: "sent" } as SelectionItem,
  RECEIVED: { id: 4, name: "received" } as SelectionItem,
  READ: { id: 5, name: "read" } as SelectionItem,
};

export enum NotificationActionType {
  CREATED = "created",
  RECEIVED = "received",
}

export enum NotificationStepType {
  COMMON = "common",
  RECIPIENTS = "recipients",
}

export enum NotificationRecipientTypes {
  SPECIFIC = "all",
  ALL = "specific",
}

export interface CreateNotificationDto {
  id?: number;
  context: SelectionItem;
  level: SelectionItem;
  // status: SelectionItem;
  title: string;
  text: string;
  recipientsIds: number[];
}
export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}
export interface UserNotificationType {
  recipient: UserType;
  status: SelectionItem;
  // updatedAt: Date;
}
export interface ResponseNotificationDto {
  id: number;
  title: string;
  text: string;
  usageContext: SelectionItem;
  status: SelectionItem;
  level: SelectionItem;
  actionType: NotificationActionType;
  createdAt: Date;
  // updatedAt: Date;
  createdBy: UserType;
  recipients: UserType[];
  userNotifications: UserNotificationType[];
}

export interface NoticeSwitchSelectorProps {
  id: number;
  title: string;
  isChecked: boolean;
  onChange: () => void;
}
export interface NoticeSwitchSelectorWrapperProps {
  types: SelectionItem[];
  selectedType: SelectionItem;
  handleChangeType: (noticeType: SelectionItem) => void;
}

export const notificationStepTypes = {
  common: {
    id: 1,
    name: "Step 1",
    base: NotificationStepType.COMMON,
    description: "common",
  },
  users: {
    id: 2,
    name: "Step 2",
    base: NotificationStepType.RECIPIENTS,
    description: "users",
  },
  // scheduling: {
  //   id: 3,
  //   name: "Step 3",
  //   description: "scheduling",
  // },
};
export const notificationSteps: SelectionItem[] = Object.values(notificationStepTypes);

export const notificationRecipientTypes = {
  specific: {
    id: 1,
    name: "Specific users",
    base: NotificationRecipientTypes.SPECIFIC,
  },
  // all: {
  //   id: 2,
  //   name: "All users",
  // },
};
export const notificationRecipientTypesSelection: SelectionItem[] = Object.values(notificationRecipientTypes);

export const defaultNoticeTextFieldStyle: SxProps = {
  margin: "6px 0",
  "& .MuiOutlinedInput-root": {
    height: "auto",
    borderRadius: "8px",
    "& fieldset": {
      border: `1px solid ${colors.borderDefault}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${colors.brandPressed}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${colors.brandPressed}`,
    },
  },
  "& .MuiInputBase-input": {
    height: "auto",
    padding: "12px 16px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px", // Set icon size
  },
};

export const getAccordionSummaryStyle = (isExpanded: boolean): SxProps => ({
  height: "52px",
  minHeight: "52px",
  maxHeight: "52px",
  display: "flex",
  padding: "2px 8px 2px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "16px",
  background: colors.blue50,
  ...(isExpanded
    ? {
        border: `1px solid ${colors.brandPressed}`,
      }
    : {
        border: "none",
      }),
  "& .MuiAccordionSummary-content": {
    margin: "0", // Remove default margin to ensure vertical centering
  },
  "&.Mui-expanded": {
    minHeight: "52px", // Explicitly set for expanded state
  },
});

export const customAccountsSelectionStyle = (isExpanded: boolean): SxProps => ({
  borderRadius: "24px",
  background: isExpanded ? colors.blue50 : colors.navy50,
  border: `none`,
  "& .MuiInputBase-root": {
    "& > fieldset": {
      border: "none",
    },
  },
  input: {
    paddingLeft: "12px 16px",
    // color: isDisabled ? "grey" : "secondary.mainDark", // Change the color when disabled
    fontSize: "1rem",
    "&::placeholder": {
      opacity: 1,
    },
  },
});

export const notificationColumns: GridColDef[] = [
  // { field: "id", headerName: "ID", flex: 1 },
  { field: "title", headerName: "Title", flex: 1 },
  {
    field: "context",
    headerName: "Context",
    flex: 1,
    valueGetter: ({ value }) => {
      return value?.name;
    },
    sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
    renderCell: ({ value, row }) => {
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: "level",
    headerName: "Level",
    flex: 1,
    valueGetter: ({ value }) => {
      return value?.name;
    },
    sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
    renderCell: ({ value, row }) => {
      return (
        <Typography
          sx={{
            // color: row.level?.color ? `color-mix(in srgb, ${row.level?.color}, transparent 70%)` : "inherit",
            color: row.level?.color ?? "inherit",
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    valueGetter: ({ value }) => {
      return value?.name;
    },
    sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
    renderCell: ({ value, row }) => {
      return (
        <Typography
          sx={{
            color: row.status?.id === NotificationStatus.READ.id ? colors.secGreen100 : "inherit",
            fontWeight: row.status?.id === NotificationStatus.READ.id ? 600 : "inherit",
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: "createdBy",
    headerName: "Created by",
    flex: 1,
    renderCell: ({ value }) => (
      <Typography
        sx={{
          color: value === "Me" ? colors.secGreen100 : "inherit",
          fontWeight: value === "Me" ? 600 : "inherit",
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    valueFormatter: (params) => format(new Date(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  // {
  //   field: "updatedAt",
  //   headerName: "Updated at",
  //   flex: 1,
  //   valueFormatter: (params) => format(new Date(params.value), "yyyy-MM-dd HH:mm:ss"),
  // },
  {
    field: "recipientName",
    headerName: "Recipient name",
    flex: 1,
    renderCell: ({ value }) => (
      <Typography
        sx={{
          color: value === "Me" ? colors.secGreen100 : "inherit",
          fontWeight: value === "Me" ? 600 : "inherit",
        }}
      >
        {value}
      </Typography>
    ),
  },
  { field: "recipientEmail", headerName: "Recipient email", flex: 1 },
  // {
  //   field: "actions",
  //   type: "actions",
  //   headerName: "Actions",
  //   width: 100,
  //   cellClassName: "actions",
  //   getActions: ({ row }) => {
  //     if (row.noticeActionType === NotificationActionType.CREATED) {
  //       return [
  //         <GridActionsCellItem
  //           icon={<EditIcon />}
  //           label="Edit"
  //           onClick={() => {
  //             console.log("Edit draft? notification");
  //           }}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<DeleteIcon />}
  //           label="Delete"
  //           onClick={() => {
  //             console.log("Delete draft? notification");
  //           }}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<SendIcon />}
  //           label="Delete"
  //           onClick={() => {
  //             console.log("Send draft? notification");
  //           }}
  //           color="inherit"
  //         />,
  //       ];
  //     }
  //     return [];
  //   },
  // },
];
