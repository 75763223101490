import { CONSTANT } from "../../constants/constants";
import { visitortRecognitionCategoriesFilters } from "../../core/performanceReports/advancedReport/advancedReportSlice";
import { setCurrentCommonParameterWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { setCurrentCommonParameter } from "../../parametersConstancy/platformParametersSlice";
import { dgmOptions } from "./GroupingMenu";

export const getValueByType = (type: string): number | undefined => {
  for (const key of Object.keys(CONSTANT.DATE_RANGE_TYPES)) {
    const entry = (CONSTANT.DATE_RANGE_TYPES as any)[key];
    if (entry.type === type) {
      return entry.value;
    }
  }
  return undefined; // Return undefined if no match is found
};

export const getTypeByValue = (value: number): string => {
  for (const key of Object.keys(CONSTANT.DATE_RANGE_TYPES)) {
    const entry = (CONSTANT.DATE_RANGE_TYPES as any)[key];
    if (entry.value === value) {
      return entry.type;
    }
  }
  return "";
};

export const getDGMNameById = (id: number): string => {
  const dgm = dgmOptions.find((dgm) => +dgm.id === id);
  return dgm ? dgm.name : "";
};

export const shouldResetupDGMToDay = (filters: any, dgm: number) => {
  if (dgm !== CONSTANT.DATE_GROUP_MODES.MONTH) return false;
  const checkedFilters: any = Object.entries(filters).filter(
    ([key, values]: any) =>
      CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base !== key &&
      visitortRecognitionCategoriesFilters.includes(key) &&
      values.filter((value: any) => value.isSelected === true).length > 0
  );
  const specialCategories = [
    CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base,
    CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base,
    CONSTANT.REPORTS.FILTERS_KEYS.DEVICE_TYPES.base,
  ];
  let specialCategoriesCount = 0;
  let otherCategoriesCount = 0;

  for (const [category, items] of checkedFilters) {
    if (specialCategories.includes(category)) {
      if (items.length > 0) {
        specialCategoriesCount++;
      }
    } else if (items.length > 0) {
      otherCategoriesCount++;
    }
  }

  return !(specialCategoriesCount === 1 && otherCategoriesCount === 0);
};

export const isDgmDisabledByFilters = (filters: any) => {
  const checkedFilters: any = Object.entries(filters).filter(
    ([key, values]: any) => values.filter((value: any) => value.isSelected === true).length > 0
  );
  const specialCategories = [
    CONSTANT.REPORTS.FILTERS_KEYS.PB_VERSIONS.base,
    CONSTANT.REPORTS.FILTERS_KEYS.DATA_SOURCES.base,
  ];
  for (const [category, items] of checkedFilters) {
    if (specialCategories.includes(category)) {
      if (items.length > 0) {
        return true;
      }
    }
  }
  return false;
};
