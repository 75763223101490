import { useState, useEffect } from "react";
import { Box, Typography, List, ListItem, TextField, Divider, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useGridApiContext } from "@mui/x-data-grid";
import SearchIconText from "../../../../../components/IconComponents/SearchIconText";
import CheckboxWrapper from "../../../../../components/CustomCheckbox/Checkbox";
import { listStl, listItemStl, defaultTextFieldStyle } from "./customGridColumnsPanelStl";
import { separateMatchedAndUnmatched } from "./utils";
import { colors } from "../../../../../theme";

function CustomGridColumnsPanel() {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const columns = apiRef.current.getAllColumns();
  const [term, setTerm] = useState("");
  const [list, setList] = useState(!!columns ? columns : []);
  const [filteredList, setFilteredList] = useState(!!columns ? columns : []);

  useEffect(() => {
    let res = list.filter((i) => {
      let lcase = i.headerName.toLowerCase();
      if (i.headerClassName === "group-header-child" && "groupColumnName" in i) {
        lcase = `${i.groupColumnName} ${i.headerName}`.toLowerCase();
      }
      return lcase.includes(term.toLowerCase());
    });
    setFilteredList(res);
  }, [term]);

  const getInputTerm = (e) => {
    setTerm(e.target.value);
  };

  const handleToggleColumn = (field) => {
    const columns = apiRef.current.getAllColumns();
    const visibleColumns = apiRef.current.getVisibleColumns();

    const { matched, unmatched } = separateMatchedAndUnmatched(visibleColumns, columns, "field");
    const columnVisibilityModel = { ...matched, ...unmatched };

    columnVisibilityModel[field] = !columnVisibilityModel[field];
    apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
  };

  const setColumnModel = (selection = true, field = "field") => {
    const columnVisibilityModel = {};
    const columns = apiRef.current.getAllColumns();
    for (let i of columns) columnVisibilityModel[i[field]] = selection;

    apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
  };

  const selectAll = () => setColumnModel();
  const resetAll = () => setColumnModel(false);

  return (
    <Box id="columns-dropdown" sx={{ width: "100% !important", padding: "0px !important" }}>
      <div
        style={{
          alignSelf: "center",
          background: colors.mainWhite,
          borderRadius: "16px",
        }}
      >
        <TextField
          autoComplete="off"
          sx={{ ...defaultTextFieldStyle, width: "100% !important" }}
          id="columns-dropdown-search-field"
          placeholder={`Search`}
          value={term}
          onChange={getInputTerm}
          InputProps={{
            startAdornment: <SearchIconText />,
          }}
        />
        <Divider />
      </div>
      <List component="nav" sx={listStl}>
        {filteredList.map((column, index) => {
          let selected = apiRef.current.getVisibleColumns().some((col) => col.field === column.field);
          let columnName = column.headerName;

          return (
            <ListItem
              id="column-dropdown-item"
              sx={listItemStl(selected)}
              key={index}
              onClick={() => handleToggleColumn(column.field)}
            >
              <CheckboxWrapper id="column-dropdown-item-checkbox" checked={selected} />
              <Typography
                style={{ textAlign: "left" }}
                sx={{
                  ...(selected ? theme.typography.selected : theme.typography.unselected),
                }}
              >
                {columnName}
              </Typography>
            </ListItem>
          );
        })}
      </List>
      <Box
        sx={{
          display: "flex",
          padding: "8px 16px 8px 0px",
          justifyContent: "space-between",
          alignItems: "flex-start",
          alignSelf: "stretch",
          borderRadius: "0px 0px 16px 16px",
          background: colors.blueishGrey75,
        }}
      >
        <Button id="column-dropdown-clear-btn" onClick={resetAll} variant="clearAll">
          Reset
        </Button>
        <Button id="column-dropdown-apply-btn" onClick={selectAll} variant="apply">
          Show all
        </Button>
      </Box>
    </Box>
  );
}
export default CustomGridColumnsPanel;
