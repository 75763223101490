import { Box, Typography } from "@mui/material";
import { CONSTANT } from "../constants/constants";
import { colors } from "../theme";
import {
  FireIcon,
  GraphArrowDownIcon,
  GraphArrowUpIcon,
  StarIcon,
  VectorArrowDownIcon,
} from "../components/IconComponents/QuickActionsIcons";

export const generateUniqueId = (): string => {
  return `${Date.now()}-${Math.random()}`;
};

export const MAX_ACTION_NAME_LENGTH = 25;
export enum ManageQuickActionType {
  CREATE,
  UPDATE,
  DELETE,
}

export interface LogicRule {
  id: string;
  minValue: number | "";
  maxValue: number | "";
}
export interface IQuickActionsItem {
  id: number | null;
  name: string;
  settings: {
    iconId: string | number;
    pageId: string | number;
    entityId: string | number;
    parametersId: string | number;
    addParametersId: string | number;
    logicRules: LogicRule[];
    description: string;
  };
}

export interface IUniversalSelectItem {
  id: string | number;
  name: string;
  titleItem: React.ReactNode;
  isSelected?: boolean;
  menuItem: React.ReactNode;
  value?: number | string;
  prefix?: string | null;
}

export const QUICK_ACTION_ICONS: IUniversalSelectItem[] = [
  {
    id: 0,
    name: "StarIcon",
    isSelected: true,
    titleItem: <StarIcon color="inherit" />,
    menuItem: <StarIcon />,
  },
  {
    id: 1,
    name: "FireIcon",
    isSelected: false,
    titleItem: <FireIcon color="inherit" />,
    menuItem: <FireIcon />,
  },

  {
    id: 2,
    name: "VectorArrowDownIcon",
    isSelected: false,
    titleItem: <VectorArrowDownIcon color="inherit" />,
    menuItem: <VectorArrowDownIcon />,
  },
  {
    id: 3,
    name: "GraphArrowUpIcon",
    isSelected: false,
    titleItem: <GraphArrowUpIcon color="inherit" />,
    menuItem: <GraphArrowUpIcon />,
  },
  {
    id: 4,
    name: "GraphArrowDownIcon",
    isSelected: false,
    titleItem: <GraphArrowDownIcon color="inherit" />,
    menuItem: <GraphArrowDownIcon />,
  },
];

export const QUICK_ACTION_ENTITIES_MAP = {
  partners: {
    id: 0,
    name: "Partners",
    isSelected: true,
    titleItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        Partners
      </Typography>
    ),
    menuItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
        Partners
      </Typography>
    ),
  },
  bidders: {
    id: 1,
    name: "Bidders",
    isSelected: true,
    titleItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        Bidders
      </Typography>
    ),
    menuItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
        Bidders
      </Typography>
    ),
  },
};
export const QUICK_ACTION_ENTITIES: IUniversalSelectItem[] = Object.values(QUICK_ACTION_ENTITIES_MAP);

const quickActionParams: IUniversalSelectItem[] = Object.values(CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES).map(
  (item) => {
    let iconTitleNode: JSX.Element | null = null;
    let iconMenuNode: JSX.Element | null = null;

    if (item.bodyIcon) {
      if (typeof item.bodyIcon === "function") {
        // bodyIcon is a function => call it with the color
        iconTitleNode = item.bodyIcon(colors.text01);
        iconMenuNode = item.bodyIcon(colors.foreground);
      } else {
        // bodyIcon is already a JSX element => use it directly
        // iconTitleNode = item.bodyIcon;
        // iconMenuNode = item.bodyIcon;
      }
    }
    return {
      id: item.id,
      name: item.title,
      isSelected: item.isSelected,
      prefix: item.prefix,
      titleItem: (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", width: "130px" }}>
          <Typography variant="body1Small400" sx={{ fontWeight: 500 }}>
            {item.title}
          </Typography>
          <Box sx={{ width: "24px", height: "24px", color: colors.text01 }}> {iconTitleNode}</Box>
        </Box>
      ),
      menuItem: (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
          <Typography variant="body1Small400">{item.title}</Typography>
          {iconMenuNode}
        </Box>
      ),
    };
  }
);
export const quickActionParamsUnselected = {
  id: -1,
  name: "None",
  isSelected: false,
  prefix: null,
  titleItem: (
    <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
      None
    </Typography>
  ),
  menuItem: (
    <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
      None
    </Typography>
  ),
};
quickActionParams.unshift(quickActionParamsUnselected);
export const QUICK_ACTION_PARAMETERS: IUniversalSelectItem[] = quickActionParams;
export const QUICK_ACTION_ADD_PARAMETERS_MAP = {
  unselect: {
    id: -1,
    name: "selectDate",
    isSelected: true,
    titleItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        Select date
      </Typography>
    ),
    menuItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
        None
      </Typography>
    ),
  },
  last7days: {
    id: 0,
    name: "last7days",
    isSelected: true,
    titleItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        Last 7 days
      </Typography>
    ),
    menuItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
        Last 7 days
      </Typography>
    ),
    value: 7,
  },
  last30days: {
    id: 1,
    name: "last30days",
    isSelected: true,
    titleItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        Last 30 days
      </Typography>
    ),
    menuItem: (
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
        Last 30 days
      </Typography>
    ),
    value: 30,
  },
  // custom: {
  //   id: 2,
  //   name: "custom",
  //   isSelected: true,
  //   titleItem: (
  //     <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
  //       Custom
  //     </Typography>
  //   ),
  //   menuItem: (
  //     <Typography variant="bodyLargeRegular" sx={{ fontWeight: 400 }}>
  //       Custom
  //     </Typography>
  //   ),
  // },
};

export const QUICK_ACTION_ADD_PARAMETERS: IUniversalSelectItem[] = Object.values(QUICK_ACTION_ADD_PARAMETERS_MAP);

export const PAGES_OPTIONS = [
  CONSTANT.PAGES.PERFORMANCE_REPORT,
  //  CONSTANT.PAGES.VISITOR_RECOGNITION
];

export const newQuickActionInitItem: IQuickActionsItem = {
  id: null,
  name: "",
  settings: {
    iconId: QUICK_ACTION_ICONS[0].id,
    pageId: CONSTANT.PAGES.PERFORMANCE_REPORT.id,
    entityId: QUICK_ACTION_ENTITIES_MAP.partners.id,
    parametersId: quickActionParamsUnselected.id,
    addParametersId: QUICK_ACTION_ADD_PARAMETERS_MAP.unselect.id,
    logicRules: [],
    description: "",
  },
};

export const mapQuickActionsToHeaderListItems = (actionItems: IQuickActionsItem[]): IUniversalSelectItem[] => {
  return actionItems.map((item) => {
    const itemIcon = QUICK_ACTION_ICONS.find((icon) => icon.id === item.settings.iconId);
    return {
      id: item.id ?? new Date().getMilliseconds(),
      name: item.name,
      titleItem: (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <Box sx={{ width: "24px", height: "24px", color: "inherit" }}> {itemIcon?.titleItem}</Box>
          <Typography variant="body1Small400" sx={{ fontWeight: 500, color: "inherit" }}>
            {item.name}
          </Typography>
        </Box>
      ),
      menuItem: (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <Box sx={{ width: "24px", height: "24px", color: colors.text01 }}> {itemIcon?.menuItem}</Box>
          <Typography variant="body1Small400" sx={{ fontWeight: 500 }}>
            {item.name}
          </Typography>
        </Box>
      ),
    };
  });
};
