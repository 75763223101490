import { ChangeType, TimeRange } from "./DashboardCard";
import { ISession } from "./monitoringApi";

export interface IMostActiveUsers {
  userName: string;
  sessions: number;
}

export type MetricsResult = {
  activeUsers: number;
  averageSessionDuration: number;
  averageRequests: number;
  mostActiveUserSessions: IMostActiveUsers[];
};

export interface MetricsComparison {
  currentPeriod: MetricsResult;
  previousPeriod: MetricsResult;
  changes: {
    activeUsers: number;
    averageSessionDuration: number;
    averageRequests: number;
  };
  changesType: {
    activeUsers: ChangeType;
    averageSessionDuration: ChangeType;
    averageRequests: ChangeType;
  };
}

export interface IDailyMetric {
  date: string; // e.g. "2024-12-19"
  averageSessionDuration: number; // in minutes
  activeUsers: number; // unique users by email
  totalRequests: number; // sum of requests for the day
}

function computeMetricsInRange(sessions: ISession[], start: Date, end: Date): MetricsResult {
  // Filter to sessions in [start, end)
  const filtered = sessions.filter((s) => {
    const date = new Date(s.startTime);
    return date >= start && date < end;
  });

  // activeUsers: unique user emails
  const activeUsersSet = new Set(filtered.map((session) => session.user.email));
  const activeUsers = activeUsersSet.size;
  const totalRequests = filtered.reduce((sum, s) => sum + s.numberOfRequests, 0);
  const closedSessions = filtered.filter((s) => s.duration != null);
  const totalClosedDurationSeconds = closedSessions.reduce((sum, s) => sum + (s.duration ?? 0), 0);
  const avgDurationMinutes = closedSessions.length
    ? Math.round(totalClosedDurationSeconds / 60 / closedSessions.length)
    : 0;

  const avgRequests = filtered.length ? totalRequests / filtered.length : 0;
  const userSessionCounts: Record<string, number> = {};
  filtered.forEach((session) => {
    const userName = session.user.firstName;
    userSessionCounts[userName] = (userSessionCounts[userName] || 0) + 1;
  });

  const mostActiveUserSessions = Object.entries(userSessionCounts)
    .map(([userName, sessionsCount]) => ({ userName, sessions: sessionsCount }))
    .sort((a, b) => b.sessions - a.sessions);

  return {
    activeUsers,
    averageSessionDuration: avgDurationMinutes,
    averageRequests: avgRequests,
    mostActiveUserSessions,
  };
}

function getChangeType(difference: number): ChangeType {
  return difference >= 0 ? ChangeType.Increase : ChangeType.Decrease;
}

export function calculateMetrics(sessions: ISession[], range: TimeRange): MetricsComparison {
  const days = getDaysForTimeRange(range);
  const now = new Date();
  const currentStart = new Date(now);
  currentStart.setDate(currentStart.getDate() - days);
  const currentPeriod = computeMetricsInRange(sessions, currentStart, now);
  const previousEnd = currentStart; // same as currentStart
  const previousStart = new Date(currentStart);
  previousStart.setDate(previousStart.getDate() - days);
  const previousPeriod = computeMetricsInRange(sessions, previousStart, previousEnd);
  const activeUsersDiff = currentPeriod.activeUsers - previousPeriod.activeUsers;
  const sessionDurationDiff = currentPeriod.averageSessionDuration - previousPeriod.averageSessionDuration;
  const averageRequestsDiff = currentPeriod.averageRequests - previousPeriod.averageRequests;

  return {
    currentPeriod,
    previousPeriod,
    changes: {
      activeUsers: activeUsersDiff,
      averageSessionDuration: sessionDurationDiff,
      averageRequests: averageRequestsDiff,
    },
    changesType: {
      activeUsers: getChangeType(activeUsersDiff),
      averageSessionDuration: getChangeType(sessionDurationDiff),
      averageRequests: getChangeType(averageRequestsDiff),
    },
  };
}
export const formatDurationFromSeconds = (duration: number | null): string => {
  if (duration === null) return "In Progress";

  const durationInMinutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  if (hours > 0) {
    return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
  }

  if (minutes > 0) {
    return `${minutes}m`;
  }

  return `${seconds}s`;
};

export const getDaysForTimeRange = (range: TimeRange): number => {
  switch (range) {
    case TimeRange.P14D:
      return 14;
    case TimeRange.P30D:
      return 30;
    case TimeRange.P7D:
    default:
      return 7;
  }
};

function formatDurationFromMinutes(durationInMinutes: number): string {
  if (durationInMinutes <= 0) {
    return "0m";
  }
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  if (hours > 0) {
    return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
  }
  return `${minutes}m`;
}

export function formatDurationFromSecondsOrMinutes(rawMinutes: number): string {
  return formatDurationFromMinutes(rawMinutes);
}

export const getDailyMetrics = (sessions: ISession[]): IDailyMetric[] => {
  const dailyMap = new Map<
    string,
    {
      totalDuration: number;
      sessionCount: number;
      activeUsersSet: Set<string>;
      totalRequests: number;
    }
  >();

  sessions.forEach((session) => {
    // Convert session startTime to date string (YYYY-MM-DD)
    const d = new Date(session.startTime);
    const dateKey = d.toISOString().slice(0, 10);

    if (!dailyMap.has(dateKey)) {
      dailyMap.set(dateKey, {
        totalDuration: 0,
        sessionCount: 0,
        activeUsersSet: new Set<string>(),
        totalRequests: 0,
      });
    }

    const dayData = dailyMap.get(dateKey)!;
    dayData.totalDuration += session.duration; // in seconds
    dayData.sessionCount += 1;
    dayData.activeUsersSet.add(session.user.email);
    dayData.totalRequests += session.numberOfRequests;
  });

  // Convert the dailyMap into an array
  const result: IDailyMetric[] = [];

  dailyMap.forEach((value, key) => {
    const avgDurationMinutes = value.sessionCount ? Math.round(value.totalDuration / 60 / value.sessionCount) : 0;

    result.push({
      date: key,
      averageSessionDuration: avgDurationMinutes,
      activeUsers: value.activeUsersSet.size,
      totalRequests: value.totalRequests,
    });
  });

  // Sort by date ascending (optional)
  result.sort((a, b) => (a.date > b.date ? 1 : -1));

  return result;
};
