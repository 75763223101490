import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { generateUniqueId, LogicRule } from "./quickActionsUtils";
import { InputNumber } from "antd";
import { colors } from "../theme";
export interface LogicRulesProps {
  logicRules: LogicRule[];
  setLogicRules: React.Dispatch<React.SetStateAction<LogicRule[]>>;
  handleChangeLogicRules: (logicRules: LogicRule[]) => void;
  addBtnDisabled?: boolean;
}

const QuickActionSettingsLogicRules: React.FC<LogicRulesProps> = ({
  logicRules,
  setLogicRules,
  handleChangeLogicRules,
  addBtnDisabled = false,
}) => {
  const handleMinChange = (id: string, newMin: number | "") => {
    setLogicRules((prev) => {
      const updated = prev.map((rule) => (rule.id === id ? { ...rule, minValue: newMin } : rule));
      handleChangeLogicRules(updated);
      return updated;
    });
  };

  const handleMaxChange = (id: string, newMax: number | "") => {
    setLogicRules((prev) => {
      const updated = prev.map((rule) => (rule.id === id ? { ...rule, maxValue: newMax } : rule));
      handleChangeLogicRules(updated);
      return updated;
    });
  };

  const handleAddRule = () => {
    const newRule: LogicRule = {
      id: generateUniqueId(),
      minValue: "",
      maxValue: "",
    };
    setLogicRules((prev) => {
      const updated = [...prev, newRule];
      handleChangeLogicRules(updated);
      return updated;
    });
  };

  const handleRemoveRule = (id: string) => {
    setLogicRules((prev) => {
      const updated = prev.filter((rule) => rule.id !== id);
      handleChangeLogicRules(updated);
      return updated;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
      }}
    >
      {/* <Typography variant="h3Medium600">Logic Rule</Typography> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        {logicRules.map((rule) => (
          <Box
            key={rule.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                alignSelf: "stretch",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  // width: "100%",
                  alignItems: "flex-start",
                  flex: "1 0 0",
                }}
              >
                <Typography variant="body2XSmallMain400">Min value</Typography>
                <InputNumber
                  id="quick-actions-name"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: colors.text01,
                    lineHeight: "24px",
                    fontStyle: "normal",
                  }}
                  // step={0.01}
                  onChange={(value) => {
                    let numericValue = value ?? "";
                    if (numericValue && isNaN(numericValue)) {
                      numericValue = 0;
                    }
                    handleMinChange(rule.id, numericValue);
                  }}
                  value={rule.minValue}
                  status={
                    rule.minValue !== 0 &&
                    rule.maxValue !== 0 &&
                    rule.minValue &&
                    rule.maxValue &&
                    rule.minValue >= rule.maxValue
                      ? "error"
                      : ""
                  }
                  required
                  placeholder="Not set"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  // width: "100%",
                  alignItems: "flex-start",
                  flex: "1 0 0",
                }}
              >
                <Typography variant="body2XSmallMain400">Max value</Typography>
                <InputNumber
                  id="quick-actions-name"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: colors.text01,
                    lineHeight: "24px",
                    fontStyle: "normal",
                  }}
                  // step={0.01}
                  onChange={(value) => {
                    let numericValue = value ?? "";
                    if (numericValue && isNaN(numericValue)) {
                      numericValue = 0;
                    }
                    handleMaxChange(rule.id, numericValue);
                  }}
                  value={rule.maxValue}
                  status={
                    rule.minValue !== 0 &&
                    rule.maxValue !== 0 &&
                    rule.minValue &&
                    rule.maxValue &&
                    rule.maxValue <= rule.minValue
                      ? "error"
                      : ""
                  }
                  required
                  placeholder="Not set"
                />
              </Box>
              <Button
                sx={{ alignSelf: "center", justifyContent: "center", color: "red", marginTop: "25px" }}
                onClick={() => handleRemoveRule(rule.id)}
              >
                - Remove
              </Button>
            </Box>
          </Box>
        ))}
        {logicRules.length < 1 && (
          <Button
            variant="link"
            sx={{
              width: "fit-content",
              minWidth: "fit-content",
            }}
            onClick={handleAddRule}
            disabled={addBtnDisabled}
          >
            + Add details
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default QuickActionSettingsLogicRules;
