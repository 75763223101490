import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { compareDesc, format, isToday, isYesterday, parseISO } from "date-fns";
import PointIcon from "../../components/IconComponents/PointIcon";
import { NotificationLevel, NotificationStatus, ResponseNotificationDto } from "./noticeTypes";
import { colors } from "../../theme";
import React, { useMemo } from "react";
import { useMarkNotificationAsReadMutation } from "./notificationsApi";
import TextWithLinks from "./TextWithLinks";
import { formatDate } from "../../utils";
import Banner, { BannerLevelNames } from "../../components/Banner/Banner";

interface ReceivedNotificationsListProps {
  notifications: ResponseNotificationDto[];
}

const formatCreatedAt = (dateString: string) => {
  const date = parseISO(dateString);
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    // return format(date, "dd.MM.yy");
    return formatDate(new Date(date)).fullFormat;
  }
};

const ReceivedNotificationsList: React.FC<ReceivedNotificationsListProps> = ({ notifications }) => {
  const [markAsRead] = useMarkNotificationAsReadMutation();
  const sortedNotifications = useMemo(() => {
    return [...notifications].sort((a, b) => {
      const isAHighPrioritySent =
        a.level.id === NotificationLevel.HIGH_PRIORITY.id && a.status.id === NotificationStatus.SENT.id;
      const isBHighPrioritySent =
        b.level.id === NotificationLevel.HIGH_PRIORITY.id && b.status.id === NotificationStatus.SENT.id;

      // First, check if either notification is high priority and sent
      if (isAHighPrioritySent && !isBHighPrioritySent) {
        return -1; // a is high priority and sent, b is not, so a comes first
      }
      if (isBHighPrioritySent && !isAHighPrioritySent) {
        return 1; // b is high priority and sent, a is not, so b comes first
      }
      // If both are high priority or both are not high priority, sort by date
      const dateA = parseISO("" + a.createdAt);
      const dateB = parseISO("" + b.createdAt);
      return compareDesc(dateA, dateB);
    });
  }, [notifications]);
  const handleClickMarkNoticeAsRead = async (
    event: React.MouseEvent<HTMLSpanElement>,
    noticeId: number
  ): Promise<void> => {
    event.stopPropagation();
    try {
      await markAsRead([noticeId]).unwrap();
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <List sx={{ width: "100%", bgcolor: colors.mainWhite }} id="notifications-list">
      {sortedNotifications.map((notice) =>
        notice.level.id === NotificationLevel.HIGH_PRIORITY.id &&
        notice.status.id === NotificationStatus.SENT.id ? (
          <ListItem
            id="notification-item"
            key={notice.id}
            alignItems="flex-start"
            sx={{
              height: "auto",
              padding: "6px",
              backgroundColor: colors.mainWhite,
              borderRadius: "16px",
              margin: "0 0 4px 6px",
              width: "calc(100% - 16px)",
              ":hover": {
                backgroundColor: colors.mainWhite,
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
              <Typography id="notification-item-date" variant="bodyMedium" sx={{ color: colors.textBaseTertiary }}>
                {formatCreatedAt("" + notice.createdAt)}
              </Typography>
              <Banner
                size="small"
                title={notice.title}
                text={notice.text}
                level={BannerLevelNames.WARNING}
                withoutCloseButton={true}
                visible={true}
                onClose={() => console.log("Close functionality of this banner")}
                action={{
                  text: "Mark as read",
                  action: (e: React.MouseEvent<HTMLSpanElement>) => handleClickMarkNoticeAsRead(e, notice.id),
                }}
                style={{
                  height: "",
                  width: "100%",
                  borderRadius: "16px",
                  border: "none",
                }}
              />
            </Box>
          </ListItem>
        ) : (
          <ListItem
            key={notice.id}
            alignItems="flex-start"
            sx={{
              height: "auto",
              padding: "6px",
              backgroundColor: colors.mainWhite,
              borderRadius: "16px",
              margin: "0 0 4px 6px",
              width: "calc(100% - 16px)",
              ":hover": {
                backgroundColor: colors.mainWhite,
              },
            }}
          >
            <ListItemAvatar
              sx={{
                marginTop: "8px",
                textAlign: "start",
                minWidth: "90px",
              }}
            >
              <Typography id="notification-item-date" variant="bodyMedium" sx={{ color: colors.textBaseTertiary }}>
                {formatCreatedAt("" + notice.createdAt)}
              </Typography>
            </ListItemAvatar>
            <ListItemText
              sx={{
                marginTop: "8px",
                textAlign: "start",
              }}
              primary={
                <Typography
                  id="notification-item-title"
                  variant="body2XSmallMain400"
                  sx={{
                    fontWeight: 600,
                    alignSelf: "flex-start",
                    lineHeight: "20px",
                    textAlign: "start",
                  }}
                >
                  {notice.title}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      id="notification-item-text"
                      variant="body2XSmallMain400"
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <TextWithLinks text={notice.text} />
                    </Typography>
                    {notice.status.id === NotificationStatus.SENT.id && (
                      // {notice.status === NotificationStatus.SENT && (
                      <Typography
                        id="notification-item-read-btn"
                        onClick={(e) => handleClickMarkNoticeAsRead(e, notice.id)}
                        variant="body2XSmall"
                        sx={{
                          pointerEvents: "auto",
                          fontWeight: 600,
                          lineHeight: "20px",
                          color: colors.brandDefault,
                          cursor: "pointer",
                        }}
                      >
                        Mark as read
                      </Typography>
                    )}
                  </Box>
                </React.Fragment>
              }
            />
            {notice.status.id === NotificationStatus.SENT.id && (
              <ListItemAvatar sx={{ marginTop: "6px", minWidth: "40px" }}>
                <PointIcon />
              </ListItemAvatar>
            )}
          </ListItem>
        )
      )}
    </List>
  );
};

export default ReceivedNotificationsList;
