import React, { useEffect, useState } from "react";
import { useGetQuickActionsQuery } from "./quickActionsApi";
import {
  IQuickActionsItem,
  mapQuickActionsToHeaderListItems,
  QUICK_ACTION_ADD_PARAMETERS,
  QUICK_ACTION_ADD_PARAMETERS_MAP,
  QUICK_ACTION_ENTITIES_MAP,
} from "./quickActionsUtils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UniversalSelect from "../components/SelectItems/UniversalSelect";
import { getStatsType } from "../core/performanceReports/advancedReport/getStats";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { CONSTANT } from "../constants/constants";
import { colors } from "../theme";
import { SelectChangeEvent } from "@mui/material";
import {
  setCombinedDataMode,
  setIsApplyQuickAction,
  setSelectedQuickActionId,
} from "../core/performanceReports/advancedReport/advancedReportSlice";
import { RootState } from "../store/store";
import { setCurrentCommonParameterWrapper } from "../parametersConstancy/parametersConstancyUtils";
import { prepareDateFormatNonUTC } from "../utils";
import { setCurrentCommonParameter } from "../parametersConstancy/platformParametersSlice";

function formatDate(date: Date) {
  return date.toISOString().split("T")[0]; // Extract YYYY-MM-DD
}

const SelectHeaderQuickActions: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();

  const [quickAction, setQuickAction] = useState<IQuickActionsItem | null>(null);
  const { data: actionItems, isSuccess: isActionItemsReceived } = useGetQuickActionsQuery();
  const selectedQuickAction = useSelector((state: RootState) => state.advancedReport.selectedQuickAction);
  let cdm = useSelector((state: RootState) => state.advancedReport.combinedDataMode);
  let { dateRange, customDateRange } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const quickActionsSelectItems = isActionItemsReceived ? mapQuickActionsToHeaderListItems(actionItems) : [];

  useEffect(() => {
    if (!selectedQuickAction) {
      setQuickAction(selectedQuickAction);
    }
  }, [selectedQuickAction]);

  const handleSelectQuickAction = (event: SelectChangeEvent<string[] | string>) => {
    const value = event.target.value;
    if (actionItems?.length) {
      const selectedQuickAction = actionItems.find((item) => Number(item.id) === +value);
      if (selectedQuickAction) {
        // const pagePath = Object.values(CONSTANT.PAGES).find(
        //   (page) => page.id === selectedQuickAction.settings.pageId
        // )?.path;
        // if (pagePath) {
        //   navigate(pagePath);
        // }
        let timeGrouping = dateRange.value;

        if (
          selectedQuickAction.settings.addParametersId != null &&
          selectedQuickAction.settings.addParametersId !== QUICK_ACTION_ADD_PARAMETERS_MAP.unselect.id
        ) {
          const addParamValue =
            QUICK_ACTION_ADD_PARAMETERS.find((param) => param.id === selectedQuickAction.settings.addParametersId)
              ?.value ?? QUICK_ACTION_ADD_PARAMETERS_MAP.last7days.value;
          const dateRange = CONSTANT.DATE_RANGE_TYPES.CUSTOM;
          if (dateRange) {
            timeGrouping = dateRange.value;
            dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", dateRange)));
            if (dateRange.value === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
              const today = new Date();
              const endDate = new Date(today);
              endDate.setDate(today.getDate() - 1);
              const startDate = new Date(today);
              startDate.setDate(today.getDate() - +addParamValue);

              customDateRange = {
                customDateStart: prepareDateFormatNonUTC(startDate),
                customDateEnd: prepareDateFormatNonUTC(endDate),
              };
              dispatch(
                setCurrentCommonParameter(setCurrentCommonParameterWrapper("customDateRange", customDateRange))
              );
            }
          }
        }
        if (selectedQuickAction.settings.entityId !== QUICK_ACTION_ENTITIES_MAP.partners.id) {
          dispatch(setCombinedDataMode(0));
          cdm = 0;
        } else {
          dispatch(setCombinedDataMode(1));
          cdm = 1;
        }
        setQuickAction(selectedQuickAction);
        dispatch(
          setCurrentCommonParameter(
            setCurrentCommonParameterWrapper("dateGroupingMode", CONSTANT.DATE_GROUP_MODES.AGGREGATED)
          )
        );
        dispatch(setIsApplyQuickAction(true));
        dispatch(setSelectedQuickActionId(selectedQuickAction));
        dispatch(
          getStatsType({
            quickActionId: selectedQuickAction.id,
            isApplyQuickAction: true,
            areaChartType: selectedQuickAction.settings.parametersId,
            timeGrouping,
            customDateRange,
            cdm,
            dgm: CONSTANT.DATE_GROUP_MODES.AGGREGATED,
          })
        );
      }
    }
  };
  return quickActionsSelectItems?.length ? (
    <UniversalSelect
      value={quickAction ? `${quickAction.id}` : ""}
      items={quickActionsSelectItems}
      onChange={handleSelectQuickAction}
      placeholder="Quick Actions"
      size="small"
      displayEmpty={true}
      selectSx={{
        background: colors.brandDefault,
        color: colors.mainWhite,
        "&.Mui-focused": {
          background: colors.brandPressed,
        },
        "&:focus-within": {
          background: colors.brandPressed,
        },
        "& .MuiSelect-select": {
          paddingRight: "0 !important",
          border: "none",
        },
        "& .MuiOutlinedInput-input": {
          padding: 0,
          border: "none",
          display: "flex",
          justifyContent: "center",
        },
        "&.MuiInputBase-input": {
          padding: 0,
          border: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    />
  ) : null;
};
export default SelectHeaderQuickActions;
