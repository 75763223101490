import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useStatsObserveMutation } from "./revenueApi";
import {
  aggregateCustomBaseData,
  aggregateDataForChartColumns,
  filterDataColumns,
  getAggregationTimeRange,
} from "./utils";
import { CustomChartTypes, ICustomChartItem, CustomChartIds } from "./types";
import { CONSTANT } from "../../constants/constants";

const preselectedCustomCharts: ICustomChartItem[] = [
  {
    id: CustomChartIds.REVENUE_LIFT_SITE,
    liftVisible: true,
    abVisible: true,
  },
];

// Empty raw data mapping
const emptyRawData: Record<string, any> = {
  [CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE]: null,
  [CONSTANT.OVERVIEW.CHART_BASES.SITE_URL]: null,
  [CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE]: null,
  [CONSTANT.OVERVIEW.CHART_BASES.SUMMARY]: null,
};

export const useRevenueData = (dateRange: any, dateGroupingMode: any, customDateRange: any) => {
  // Redux selectors
  const { partnersSelected } = useSelector((state: RootState) => state.role);
  const { uniqueCurrencyPctCombinations, clutterRemovalPercentage } = useSelector(
    (state: RootState) => state.revenue
  );

  const [rawDataMapping, setRawDataMapping] = useState<Record<string, any>>(emptyRawData);
  const [summaryChartData, setSummaryChartData] = useState<any>(null);
  const [siteChartData, setSiteChartData] = useState<any>(null);
  const [bidderChartData, setBidderChartData] = useState<any>(null);
  const [browserChartData, setBrowserChartData] = useState<any>(null);
  const [impressionsChartData, setImpressionsChartData] = useState<any>(null);
  const [selectedCustomCharts, setSelectedCustomCharts] = useState<ICustomChartItem[]>(preselectedCustomCharts);
  const [chartLoaders, setChartLoaders] = useState<Record<string, boolean>>({});
  const [statsObserve, { reset }] = useStatsObserveMutation();

  const { startDate, endDate } = useMemo(
    () => getAggregationTimeRange(dateRange, dateGroupingMode, customDateRange),
    [dateRange, dateGroupingMode, customDateRange]
  );

  // Helper: Update chartLoaders for all charts whose underlying base equals the provided one.
  const updateChartLoaderForBase = (base: string, isLoading: boolean) => {
    setChartLoaders((prev) => {
      const newLoaders = { ...prev };
      selectedCustomCharts.forEach((chart) => {
        if (CustomChartTypes[chart.id]?.base === base) {
          newLoaders[base] = isLoading;
        }
      });
      if (base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) {
        newLoaders[base] = isLoading;
      }
      return newLoaders;
    });
  };

  // Fetch data for a given base.
  const fetchDataForBase = async (base: string) => {
    const queryPayload: any = {
      rt: dateRange.value,
      dgm: dateGroupingMode,
      pid: partnersSelected.map((p: any) => p.dummy_id).join(","),
      task: CONSTANT.GetRevenueTaskType.OverviewReport,
      cstm_start:
        dateRange.value === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value
          ? customDateRange.customDateStart?.toString()
          : null,
      cstm_end:
        dateRange.value === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value
          ? customDateRange.customDateEnd?.toString()
          : null,
    };

    if (base !== CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) {
      if (base === CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE) {
        queryPayload.client_type = "*";
      } else if (base === CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE) {
        queryPayload.biddercode = "*";
      } else if (base === CONSTANT.OVERVIEW.CHART_BASES.SITE_URL) {
        queryPayload.site_id = "*";
      }
    }

    const response = await statsObserve(queryPayload).unwrap();
    if (response.data && response.data.length > 1) {
      const headers = response.data[0] as string[];
      const rows = response.data.slice(1) as (string | number)[][];

      const colIndices = headers.reduce((acc: Record<string, number>, header, index) => {
        acc[header] = index;
        return acc;
      }, {} as Record<string, number>);

      const rawColumns = headers.reduce((acc: Record<string, any[]>, header) => {
        acc[header] = [];
        return acc;
      }, {} as Record<string, any[]>);

      rows.forEach((row) => {
        headers.forEach((header) => {
          const idx = colIndices[header];
          if (
            header === "currency" ||
            header === "submitted_date" ||
            header === CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE ||
            header === CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE ||
            header === CONSTANT.OVERVIEW.CHART_BASES.SITE_URL
          ) {
            rawColumns[header].push(row[idx]);
          } else {
            rawColumns[header].push(+row[idx]);
          }
        });
      });

      const len = rawColumns["total_cpm2"].length;
      const total_cpm2Norm: number[] = new Array(len);
      const total_rows_sum: number[] = new Array(len);
      const cpm_lift: number[] = new Array(len);

      for (let i = 0; i < len; i++) {
        const pctValue = rawColumns["pct"][i] || 0;
        total_cpm2Norm[i] = (rawColumns["total_cpm2"][i] * pctValue) / (100 - pctValue);
        total_rows_sum[i] =
          rawColumns["total_rows1"][i] + rawColumns["total_rows2"][i] + rawColumns["total_rows3"][i];
        cpm_lift[i] = rawColumns["avg_cpma"][i] - rawColumns["avg_cpmb"][i];
      }

      rawColumns["total_cpm2Norm"] = total_cpm2Norm;
      rawColumns["total_rows_sum"] = total_rows_sum;
      rawColumns["cpm_lift"] = cpm_lift;

      return rawColumns;
    }
    return {};
  };

  const isBaseRequired = (base: string) => {
    if (base === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) return true;
    return selectedCustomCharts.some((chart) => CustomChartTypes[chart.id]?.base === base);
  };

  const siteSelectedCharts = useMemo(
    () =>
      selectedCustomCharts.filter(
        (chart) => CustomChartTypes[chart.id]?.base === CONSTANT.OVERVIEW.CHART_BASES.SITE_URL
      ),
    [selectedCustomCharts]
  );

  const bidderSelectedCharts = useMemo(
    () =>
      selectedCustomCharts.filter(
        (chart) => CustomChartTypes[chart.id]?.base === CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE
      ),
    [selectedCustomCharts]
  );

  const clientTypeSelectedCharts = useMemo(
    () =>
      selectedCustomCharts.filter(
        (chart) => CustomChartTypes[chart.id]?.base === CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE
      ),
    [selectedCustomCharts]
  );

  // Effect A: On core parameter changes, clear raw data and fetch all required bases.
  useEffect(() => {
    if (!partnersSelected || partnersSelected.length === 0) return;
    reset();
    setRawDataMapping({ ...emptyRawData });

    // Determine bases to fetch based on core parameters & current charts.
    const basesToFetch: string[] = [
      CONSTANT.OVERVIEW.CHART_BASES.SUMMARY,
      CONSTANT.OVERVIEW.CHART_BASES.SITE_URL,
      CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
      CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
    ].filter(isBaseRequired);
    // Set loading true for each base.
    basesToFetch.forEach((base) => updateChartLoaderForBase(base, true));

    Promise.all(
      basesToFetch.map(async (base) => {
        const data = await fetchDataForBase(base);
        return { base, data };
      })
    )
      .then((results) => {
        setRawDataMapping((prev) => {
          const newMapping = { ...prev };
          results.forEach(({ base, data }) => {
            newMapping[base] = data;
            updateChartLoaderForBase(base, false);
          });
          return newMapping;
        });
      })
      .catch((error) => {
        console.error("Error fetching raw data:", error);
        basesToFetch.forEach((base) => updateChartLoaderForBase(base, false));
      });
  }, [partnersSelected, dateRange, dateGroupingMode, customDateRange]);

  // Effect B: When selectedCustomCharts change, fetch any missing bases.
  useEffect(() => {
    if (!partnersSelected || partnersSelected.length === 0) return;
    // Only SITE, BIDDER, and BROWSER are conditional.
    const basesNeeded: string[] = [
      CONSTANT.OVERVIEW.CHART_BASES.SITE_URL,
      CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
      CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
    ].filter(isBaseRequired);
    const missingBases = basesNeeded.filter((base) => !rawDataMapping[base]);
    if (missingBases.length === 0) return;

    missingBases.forEach((base) => updateChartLoaderForBase(base, true));

    Promise.all(
      missingBases.map(async (base) => {
        const data = await fetchDataForBase(base);
        return { base, data };
      })
    )
      .then((results) => {
        setRawDataMapping((prev) => {
          const newMapping = { ...prev };
          results.forEach(({ base, data }) => {
            newMapping[base] = data;
            updateChartLoaderForBase(base, false);
          });
          return newMapping;
        });
      })
      .catch((error) => {
        console.error("Error fetching missing base data:", error);
        missingBases.forEach((base) => updateChartLoaderForBase(base, false));
      });
  }, [selectedCustomCharts]);

  // SUMMARY chart aggregation
  useEffect(() => {
    if (rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY]) {
      let baseData = rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY];
      const selectedCombo = uniqueCurrencyPctCombinations.find((combo: any) => combo.isSelected);
      if (selectedCombo) {
        baseData = filterDataColumns(
          baseData,
          (row) => row.currency === selectedCombo.currency && row.pct === selectedCombo.pct
        );
      }
      const aggregatedSummary = aggregateDataForChartColumns(
        baseData,
        startDate,
        endDate,
        {
          metrics: ["total_cpm1", "total_cpm2Norm"],
        },
        dateGroupingMode
      );
      setSummaryChartData(aggregatedSummary);
    }
  }, [
    rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY],
    startDate,
    endDate,
    dateGroupingMode,
    uniqueCurrencyPctCombinations,
  ]);

  // Impressions aggregation (from SUMMARY)
  useEffect(() => {
    if (rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY]) {
      const impData = aggregateCustomBaseData(
        rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY],
        CONSTANT.OVERVIEW.CHART_BASES.SUMMARY,
        startDate,
        endDate,
        uniqueCurrencyPctCombinations,
        [CustomChartIds.IMPRESSIONS],
        clutterRemovalPercentage.crpWithIIQ,
        clutterRemovalPercentage.deselectionPercentage,
        dateGroupingMode
      );
      setImpressionsChartData(impData);
    }
  }, [
    rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY],
    startDate,
    endDate,
    uniqueCurrencyPctCombinations,
    clutterRemovalPercentage,
  ]);

  // SITE chart aggregation
  useEffect(() => {
    if (rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SITE_URL] && siteSelectedCharts.length > 0) {
      const newSiteData = aggregateCustomBaseData(
        rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SITE_URL],
        CONSTANT.OVERVIEW.CHART_BASES.SITE_URL,
        startDate,
        endDate,
        uniqueCurrencyPctCombinations,
        siteSelectedCharts.map((chart) => chart.id),
        clutterRemovalPercentage.crpWithIIQ,
        clutterRemovalPercentage.deselectionPercentage,
        dateGroupingMode
      );
      setSiteChartData(newSiteData);
    }
  }, [
    rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.SITE_URL],
    startDate,
    endDate,
    uniqueCurrencyPctCombinations,
    clutterRemovalPercentage,
  ]);

  // BIDDER chart aggregation
  useEffect(() => {
    if (rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE] && bidderSelectedCharts.length > 0) {
      const newBidderData = aggregateCustomBaseData(
        rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE],
        CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
        startDate,
        endDate,
        uniqueCurrencyPctCombinations,
        bidderSelectedCharts.map((chart) => chart.id),
        clutterRemovalPercentage.crpWithIIQ,
        clutterRemovalPercentage.deselectionPercentage,
        dateGroupingMode
      );
      setBidderChartData(newBidderData);
    }
  }, [
    rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE],
    startDate,
    endDate,
    uniqueCurrencyPctCombinations,
    clutterRemovalPercentage,
  ]);

  // BROWSER chart aggregation
  useEffect(() => {
    if (rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE] && clientTypeSelectedCharts.length > 0) {
      const newBrowserData = aggregateCustomBaseData(
        rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE],
        CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
        startDate,
        endDate,
        uniqueCurrencyPctCombinations,
        clientTypeSelectedCharts.map((chart) => chart.id),
        clutterRemovalPercentage.crpWithIIQ,
        clutterRemovalPercentage.deselectionPercentage,
        dateGroupingMode
      );
      setBrowserChartData(newBrowserData);
    }
  }, [
    rawDataMapping[CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE],
    startDate,
    endDate,
    uniqueCurrencyPctCombinations,
    clutterRemovalPercentage,
  ]);

  return {
    summaryChartData,
    siteChartData,
    bidderChartData,
    browserChartData,
    impressionsChartData,
    selectedCustomCharts,
    setSelectedCustomCharts,
    isLoading: chartLoaders,
  };
};
