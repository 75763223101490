import React, { useMemo, useState, useCallback } from "react";
import { Box, IconButton, ButtonBase } from "@mui/material";
import Widgets from "./Widgets";
import DateRangePanel from "../../components/DateRangePanel/DateRangePanel";
import { getValueByType } from "../../components/DateRangePanel/utils";
import { DateRangeType } from "../../parametersConstancy/parametersConstancyTypes";
import { setCurrentCommonParameterWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { setCurrentCommonParameter } from "../../parametersConstancy/platformParametersSlice";
import { useRenderSelectedDate } from "../overview/helpers/useRenderSelectedDate";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useRevenueData } from "./useRevenueData";
import { CustomChartTypes, AggregateCustomBaseDataOutput, CustomChartIds } from "./types";
import UniversalSelect from "../../components/SelectItems/UniversalSelect";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TogglePopover from "./TogglePopover";
import SummaryPlot from "./charts/SummaryPlot";
import SettingsIcon from "@mui/icons-material/Settings";
import { ReportsOverviewSettingsDialog } from "./ReportsOverviewSettingsDialog";
import { colors } from "../../theme";
import CustomChartChips from "./CustomChartChips";
import {
  HeaderContainer,
  SidebarContainer,
  MainContentContainer,
  SummaryContainer,
  CustomChartContainer,
  CustomChartHeader,
} from "./styles";
import { CONSTANT } from "../../constants/constants";
import { CustomChartWrapper } from "./charts/CustomChartWrapper";

const emptyAggregatedData: AggregateCustomBaseDataOutput = {
  filteredData: {},
  items: [],
  topEntities: [],
};

const OverviewPage2: React.FC = () => {
  const dispatch = useDispatch();
  const { dateRange, dateGroupingMode, customDateRange } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const { uniqueCurrencyPctCombinations } = useSelector((state: RootState) => state.revenue);
  const activeRangeDate = dateRange.value;
  const [renderSelectedDate] = useRenderSelectedDate(dateRange.value, customDateRange);

  const [sliderState, setSliderState] = useState<{
    anchorEl: HTMLElement | null;
    chartId: string | null;
  }>({ anchorEl: null, chartId: null });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const {
    summaryChartData,
    siteChartData,
    bidderChartData,
    browserChartData,
    isLoading,
    selectedCustomCharts,
    setSelectedCustomCharts,
    impressionsChartData,
  } = useRevenueData(dateRange, dateGroupingMode, customDateRange);

  const handleOpenSliders = useCallback((event: React.MouseEvent<HTMLElement>, chartId: string) => {
    event.stopPropagation();
    setSliderState({ anchorEl: event.currentTarget, chartId });
  }, []);

  const handleCloseSliders = useCallback(() => {
    setSliderState({ anchorEl: null, chartId: null });
  }, []);

  const handleDateRangeButtons = useCallback(
    (type: string) => {
      const rangeType: DateRangeType = { type, value: getValueByType(type) as number };
      dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", rangeType)));
    },
    [dispatch]
  );

  const customChartItems = useMemo(
    () =>
      Object.entries(CustomChartTypes).map(([key, chartType]) => ({
        id: key,
        name: chartType.title,
        titleItem: chartType.title,
        menuItem: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1, width: "100%" }}>
              <span>{chartType.title}</span>
              {chartType?.icon}
            </Box>
            {!!chartType.bar1Base && (
              <IconButton size="small" onClick={(e) => handleOpenSliders(e, key)}>
                <KeyboardArrowRightIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
        ),
      })),
    [handleOpenSliders]
  );

  const handleCustomChartChange = useCallback(
    (event: any) => {
      const {
        target: { value },
      } = event;
      const selectedIds = typeof value === "string" ? value.split(",") : value;
      setSelectedCustomCharts((prevSelected) => {
        const existing = prevSelected.filter((chart) => selectedIds.includes(chart.id));
        const newIds = selectedIds.filter((id: string) => !prevSelected.some((chart) => chart.id === id));
        const newCharts = newIds.map((id: string) => {
          const chartType = CustomChartTypes[id];
          return { id, liftVisible: true, abVisible: !!chartType.bar1Base };
        });
        return [...newCharts, ...existing];
      });
    },
    [setSelectedCustomCharts]
  );

  const handleToggleSlider = useCallback(
    (chartId: string, field: "liftVisible" | "abVisible") => {
      setSelectedCustomCharts((prev) =>
        prev
          .map((item) => (item.id === chartId ? { ...item, [field]: !item[field] } : item))
          .filter((i) => i.abVisible || i.liftVisible)
      );
    },
    [setSelectedCustomCharts]
  );

  const aggregatedDataMap = useMemo(
    () => ({
      [CONSTANT.OVERVIEW.CHART_BASES.SITE_URL]: siteChartData || emptyAggregatedData,
      [CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE]: bidderChartData || emptyAggregatedData,
      [CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE]: browserChartData || emptyAggregatedData,
      [CONSTANT.OVERVIEW.CHART_BASES.SUMMARY]: impressionsChartData || emptyAggregatedData,
    }),
    [siteChartData, bidderChartData, browserChartData, impressionsChartData]
  );

  const getAggregatedData = useCallback(
    (chartKey: string) => {
      console.log("running getAggregatedData", chartKey);
      const chartType = CustomChartTypes[chartKey];
      if (!chartType) return emptyAggregatedData;
      return aggregatedDataMap[chartType.base] || emptyAggregatedData;
    },
    [aggregatedDataMap]
  );

  const currentChart = useMemo(
    () => selectedCustomCharts.find((chart) => chart.id === sliderState.chartId),
    [selectedCustomCharts, sliderState.chartId]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
        backgroundColor: colors.backgroundDefault,
      }}
    >
      <HeaderContainer>
        <DateRangePanel
          renderSelectedDate={renderSelectedDate}
          handleDateRangeButtons={handleDateRangeButtons}
          dateGroupingMode={dateGroupingMode}
          active_range_date={activeRangeDate}
        />
      </HeaderContainer>

      <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
        <SidebarContainer>
          <Widgets isLoading={isLoading[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY]} />
        </SidebarContainer>

        <MainContentContainer>
          <SummaryContainer>
            <SummaryPlot
              chartColumnsData={summaryChartData || {}}
              isLoading={isLoading[CONSTANT.OVERVIEW.CHART_BASES.SUMMARY]}
            />
          </SummaryContainer>

          <CustomChartContainer>
            <CustomChartHeader>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "240px" }}>
                  <UniversalSelect
                    id="custom-chart-select"
                    items={customChartItems}
                    value={selectedCustomCharts.map((c) => c.id)}
                    onChange={handleCustomChartChange}
                    multiSelect
                    placeholder="Custom Charts"
                    displayEmpty
                    menuSx={{
                      "& .Mui-selected": { backgroundColor: "white" },
                      "& .MuiMenuItem-root": { paddingRight: 0 },
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  <CustomChartChips
                    selectedCustomCharts={selectedCustomCharts}
                    setSelectedCustomCharts={setSelectedCustomCharts}
                  />
                </Box>
              </Box>
              <Box sx={{ margin: "8px" }}>
                <ButtonBase onClick={() => setIsSettingsOpen((prev) => !prev)}>
                  <SettingsIcon style={{ color: colors.mainDarkPrimary100 }} />
                </ButtonBase>
              </Box>
            </CustomChartHeader>

            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflowX: "hidden" }}>
              {selectedCustomCharts.map((chartItem) => {
                const chartType = CustomChartTypes[chartItem.id];
                return (
                  <CustomChartWrapper
                    key={chartItem.id}
                    chartItem={chartItem}
                    getAggregatedData={getAggregatedData}
                    isLoading={isLoading[chartType.base]}
                    uniqueCurrencyPctCombinations={uniqueCurrencyPctCombinations}
                  />
                );
              })}
            </Box>
          </CustomChartContainer>
        </MainContentContainer>
      </Box>

      <TogglePopover
        open={Boolean(sliderState.anchorEl)}
        anchorEl={sliderState.anchorEl}
        onClose={handleCloseSliders}
        liftVisible={currentChart?.liftVisible ?? false}
        abVisible={currentChart?.abVisible ?? false}
        onToggleLift={() => sliderState.chartId && handleToggleSlider(sliderState.chartId, "liftVisible")}
        onToggleAB={() => sliderState.chartId && handleToggleSlider(sliderState.chartId, "abVisible")}
      />
      <ReportsOverviewSettingsDialog open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </Box>
  );
};

export default OverviewPage2;
