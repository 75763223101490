import React from "react";
import { Box, TextField, Checkbox, FormControlLabel, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setClutterRemovalPercentage } from "./revenueSlice";
import { ReusableDialog } from "../configuration/ReusableDialog";
import Banner, { BannerLevelNames } from "../../components/Banner/Banner";
import LabeledTextField from "../configuration/LabeledTextField";
import PercentIcon from "@mui/icons-material/Percent";

interface IReportsOverviewSettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ReportsOverviewSettingsDialog: React.FC<IReportsOverviewSettingsDialogProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { clutterRemovalPercentage } = useSelector((state: RootState) => state.revenue);

  // Convert store fraction (0.007) to display percentage (0.7)
  const [localCrpWithIIQ, setLocalCrpWithIIQ] = React.useState<string>(
    (clutterRemovalPercentage.crpWithIIQ * 100).toString()
  );
  const [localDeselectionPercentage, setLocalDeselectionPercentage] = React.useState<string>(
    (clutterRemovalPercentage.deselectionPercentage * 100).toString()
  );
  const [showSettingsStrictly, setShowSettingsStrictly] = React.useState<boolean>(false);

  const handleApplySettings = () => {
    const crp = parseFloat(localCrpWithIIQ);
    const dsp = parseFloat(localDeselectionPercentage);

    dispatch(
      setClutterRemovalPercentage({
        crpWithIIQ: isNaN(crp) ? 0 : crp,
        deselectionPercentage: isNaN(dsp) ? 0 : dsp,
      })
    );
    // dispatch(setShowSettingsStrictly(showSettingsStrictly));
    onClose();
  };

  return (
    <ReusableDialog open={open} onClose={onClose} title="Reports overview settings" width="400px" height="auto">
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
        <Banner
          text={<>Please set up clutter removal percentage to remove from charts minor or very low values</>}
          level={BannerLevelNames.INFO}
          visible={true}
          withoutCloseButton
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3Medium600">Clutter Removal Settings</Typography>

          <LabeledTextField
            label="Clutter Removal Percentage With IIQ"
            value={localCrpWithIIQ}
            onChange={(e) => setLocalCrpWithIIQ(e.target.value)}
            startIcon={<PercentIcon />}
            // error={!!errors[`endpoint_url_${idx}`]}
            // helperText={errors[`endpoint_url_${idx}`] || ""}
            required
          />

          <LabeledTextField
            label="Default Filtration Deselection"
            value={localDeselectionPercentage}
            onChange={(e) => setLocalDeselectionPercentage(e.target.value)}
            startIcon={<PercentIcon />}
            // error={!!errors[`endpoint_url_${idx}`]}
            // helperText={errors[`endpoint_url_${idx}`] || ""}
            required
          />

          {/* <Typography variant="h3Medium600" sx={{ mt: 2 }}>
            Display Options
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={showSettingsStrictly}
                onChange={(e) => setShowSettingsStrictly(e.target.checked)}
              />
            }
            label="Show settings strictly"
          /> */}

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button variant="apply" onClick={handleApplySettings}>
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </ReusableDialog>
  );
};
