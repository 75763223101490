import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISummaryWidgetsResponse, IUniquePctCurrencyCombinations } from "./types";
import { getStats } from "../performanceReports/advancedReport/getStats";

export interface RevenueState {
  uniqueCurrencyPctCombinations: IUniquePctCurrencyCombinations[];
  summaryWidgets?: ISummaryWidgetsResponse;
  columnIndices?: Record<string, number>;
  clutterRemovalPercentage: {
    crpWithIIQ: number;
    deselectionPercentage: number;
  };
}

const initialState: RevenueState = {
  uniqueCurrencyPctCombinations: [],
  summaryWidgets: undefined,
  columnIndices: {},
  clutterRemovalPercentage: {
    crpWithIIQ: 0.001 * 0.01,
    deselectionPercentage: 0.7 * 0.01,
  },
};

const revenueSlice = createSlice({
  name: "revenue",
  initialState,
  reducers: {
    setUniqueCurrencyPctCombinations: (state, action: PayloadAction<IUniquePctCurrencyCombinations[]>) => {
      state.uniqueCurrencyPctCombinations = action.payload;
    },
    setCurrencyPct: (state, action: PayloadAction<string>) => {
      state.uniqueCurrencyPctCombinations = state.uniqueCurrencyPctCombinations.map((item) => ({
        ...item,
        isSelected: item.name === action.payload,
      }));
      return state;
    },
    setSummaryWidgets: (state, action: PayloadAction<ISummaryWidgetsResponse>) => {
      state.summaryWidgets = action.payload;
    },
    setColumnIndices: (state, action: PayloadAction<Record<string, number>>) => {
      state.columnIndices = action.payload;
    },
    setClutterRemovalPercentage: (
      state,
      action: PayloadAction<{ crpWithIIQ: number; deselectionPercentage: number }>
    ) => {
      state.clutterRemovalPercentage.crpWithIIQ = action.payload.crpWithIIQ;
      state.clutterRemovalPercentage.deselectionPercentage = action.payload.deselectionPercentage;
    },
  },
  extraReducers(builder) {
    builder.addCase(getStats.fulfilled, (state: any, action: any) => {
      console.log(action.payload.currencyModes);
      console.log(action.payload.pctModes);
      if (action.payload?.currencyModes !== undefined && action.payload?.pctModes !== undefined) {
        const matchedUniqueCurrencyPctCombinations = state.uniqueCurrencyPctCombinations.filter(
          (item: any) =>
            action.payload.currencyModes.some((currencyMode: any) => currencyMode === item.currency) &&
            action.payload.pctModes.some((pctMode: any) => pctMode === item.pct)
        );
        if (matchedUniqueCurrencyPctCombinations?.length !== 0) {
          state.uniqueCurrencyPctCombinations = state.uniqueCurrencyPctCombinations.map((item: any) => {
            const isSelected = matchedUniqueCurrencyPctCombinations.some(
              (matchedItem: any) => matchedItem.name === item.name
            );
            return {
              ...item,
              isSelected,
            };
          });
        }
      }
    });
  },
});

export const {
  setUniqueCurrencyPctCombinations,
  setCurrencyPct,
  setSummaryWidgets,
  setColumnIndices,
  setClutterRemovalPercentage,
} = revenueSlice.actions;

export const revenueReducer = revenueSlice.reducer;

export default revenueReducer;
