import React, { FC, useState, useMemo } from "react";
import { Box, Dialog, DialogTitle, DialogContent, IconButton, ButtonBase, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Cell } from "recharts";
import { ISession } from "../monitoring/monitoringApi";

import StyledDataGridWrapper from "../components/StyledDataGrid/StyledDataGrid";
import { scaleLinear } from "d3-scale";
import moment from "moment";
import { formatDurationFromSeconds } from "./utils";
import UserStatusChip from "../components/Chips/UserStatusChip";

interface SessionsTableProps {
  sessions: ISession[];
}
const colorScale = scaleLinear<string>().domain([0, 100]).range(["blue", "red"]);

const SessionsTable: FC<SessionsTableProps> = ({ sessions }) => {
  const [selectedSession, setSelectedSession] = useState<ISession | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleRowClick = (params: any) => {
    setSelectedSession(params.row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedSession(null);
  };

  const sessionColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "isActive",
        headerName: "Status",
        flex: 0.7,
        renderCell: (params: any) => (
          <Box>
            <UserStatusChip status={params.value} />
          </Box>
        ),
      },
      {
        field: "userName",
        headerName: "User Name",
        flex: 1,
        valueGetter: (params) => `${params.row.user.firstName} ${params.row.user.lastName}`,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1.2,
        valueGetter: (params) => params.row.user.email,
      },
      {
        field: "company",
        headerName: "Company",
        flex: 1,
        valueGetter: (params) => params.row.account.company,
      },

      {
        field: "startTime",
        headerName: "Start Time",
        flex: 1,
        valueFormatter: (params) => moment(params.value).format("DD.MM.YYYY | HH:mm"),
      },

      {
        field: "duration",
        headerName: "Duration",
        flex: 0.8,
        valueGetter: (params) => params.row.duration,
        renderCell: (params) => formatDurationFromSeconds(params.value),
        sortComparator: (v1, v2) => {
          // Handle null values
          if (v1 === null && v2 === null) return 0;
          if (v1 === null) return -1;
          if (v2 === null) return 1;
          return v1 - v2;
        },
      },

      {
        field: "sessionId",
        headerName: "Session ID",
        flex: 1.2,
        renderCell: (params) => (
          <ButtonBase onClick={() => handleRowClick(params)}>
            <Typography variant="body2XSmallMain400">{params.value}</Typography>
          </ButtonBase>
        ),
      },
      { field: "ipAddress", headerName: "IP Address", flex: 1.5 },
    ],
    []
  );

  const activityFrameColumns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "startTime",
        headerName: "Start Time",
        flex: 1,
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      {
        field: "endTime",
        headerName: "End Time",
        flex: 1,
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      {
        field: "duration",
        headerName: "Duration",
        flex: 1,
        valueGetter: (params) => {
          const startTime = new Date(params.row.startTime).getTime();
          const endTime = params.row.endTime ? new Date(params.row.endTime).getTime() : Date.now();
          const durationInSeconds = Math.max((endTime - startTime) / 1000, 0);
          return formatDurationFromSeconds(durationInSeconds);
        },
      },
      { field: "numberOfRequests", headerName: "Requests", flex: 1 },
      { field: "inactivityThreshold", headerName: "Inactivity Threshold", flex: 1 },
    ],
    []
  );
  const processData = selectedSession?.activityFrames?.map((frame) => {
    const startTime = new Date(frame.startTime);
    const endTime = frame.endTime ? new Date(frame.endTime) : new Date(); // Handles null end time
    const duration = (endTime.getTime() - startTime.getTime()) / 1000 / 60; // Duration in minutes
    const requests = Math.min(frame.numberOfRequests, 100);
    const color = colorScale(requests);
    return {
      ...frame,
      startTime: startTime.toLocaleTimeString(), // For display on X-axis
      duration,
      fill: color,
    };
  });

  return (
    <Box sx={{ width: "100%" }}>
      <StyledDataGridWrapper
        rows={sessions}
        columns={sessionColumns}
        initialState={{
          sorting: {
            sortModel: [{ field: "startTime", sort: "desc" }],
          },
        }}
        //onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        //getRowClassName={(params) => (params.row.sessionId === loggedUser.sessionId ? "highlight-session" : "")}
      />

      {/* Modal for Activity Frames */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle textAlign={"center"}>
          {`Activity Frames for session ${selectedSession?.sessionId}`}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedSession && (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ height: 400, marginBottom: 4, width: "100%" }}>
                <StyledDataGridWrapper
                  rows={selectedSession.activityFrames}
                  columns={activityFrameColumns}
                  getRowId={(row) => row.id}
                  sx={{ width: "100%", overflowX: "hidden" }}
                />
              </Box>

              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={processData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="startTime" />
                  <YAxis label={{ value: "Duration (minutes)", angle: -90, position: "insideLeft" }} />
                  <Tooltip />
                  <Bar dataKey="duration">
                    {processData?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.fill} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SessionsTable;
