import * as React from "react";
import { Box, Tabs, Tab, TextField, IconButton, InputAdornment, Button } from "@mui/material";
import { useAddUserToGitbookMutation, useGetAllUsersQuery } from "../accountsApi";
import { mainTableStyle } from "../../core/performanceReports/advancedReport/table/mainTableStyle";
import { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SessionTimeout from "../../components/SessionTimeout/SessionTimeout";
import RoleDetailsPopup from "../rolesMangementComponents/RoleDetailsPopup";
import { UsersTable } from "../tableComponents/UsersTable";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { setOrigin } from "./usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { pageContainerStyle, tabsContainerStyle, textFieldInputStlRAMP } from "../styles";
import { useLocation, useNavigate } from "react-router-dom";
import { GridFilterModel } from "@mui/x-data-grid";
import { colors } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { ReusableDialog } from "../../core/configuration/ReusableDialog";
import LabeledTextField from "../../core/configuration/LabeledTextField";
import { validateEmail } from "../../utils";
import { RootState } from "../../store/store";
import { CONSTANT } from "../../constants/constants";
import SideBoardWrapper, { btnStl } from "../sideBoard/SideBoardWrapper";

interface UsersPageProps {}

export const menuTabs = [
  {
    id: 1,
    title: "All users",
    isDisabled: false,
  },
  {
    id: 2,
    title: "Active users",
    isDisabled: false,
  },
  {
    id: 3,
    title: "Inactive users",
    isDisabled: false,
  },
];

const UsersPage: React.FC<UsersPageProps> = () => {
  const { data, isFetching, isLoading } = useGetAllUsersQuery();
  const users = data?.users;
  const dispatch = useDispatch();
  const [tabIdSelected, setTabIdSelected] = useState(1);
  const [filterText, setFilterText] = useState("");
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIdSelected(newValue);
  };
  const handleFilterChange = (event: any) => {
    setFilterText(event.target.value);
  };
  const [addUserToGitbook] = useAddUserToGitbookMutation();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputEmailError, setInputEmailError] = useState("");

  useEffect(() => {
    dispatch(setOrigin("users"));
  }, []);
  const location = useLocation();
  const { loggedUser } = useSelector((state: RootState) => state.users);

  const passedState = location.state as { role?: string };
  const [initialFilterModel, setInitialFilterModel] = React.useState<GridFilterModel>({ items: [] });
  const navigate = useNavigate();

  const handleGitbookClick = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setInputEmailError("");
    setInputEmail("");
  };

  const hanldeAddUserToGitbook = () => {
    const validate = validateEmail(inputEmail);
    if (!validate) setInputEmailError("Please enter valid email");
    else {
      setInputEmailError("");
      addUserToGitbook({ email: inputEmail });
      setOpenDialog(false);
      setInputEmail("");
    }
  };

  useEffect(() => {
    if (passedState?.role) {
      setInitialFilterModel({
        items: [{ field: "userRole", operator: "contains", value: passedState.role }],
      });
    }
    navigate(location.pathname, { replace: true, state: {} });
  }, []);

  const filteredData = React.useMemo(() => {
    if (!users) return [];

    let filteredUsers = users;
    if (tabIdSelected === 1) {
      filteredUsers = filteredUsers.filter((user) => user.active === 1);
    } else if (tabIdSelected === 2) {
      filteredUsers = filteredUsers.filter((user) => user.active === 0);
    }

    if (filterText) {
      filteredUsers = filteredUsers.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const partnerAccount = user?.account_id?.company?.toLowerCase() || "";
        return (
          fullName.includes(filterText.toLowerCase()) ||
          user?.email?.toLowerCase().includes(filterText.toLowerCase()) ||
          partnerAccount.includes(filterText.toLowerCase())
        );
      });
    }

    return filteredUsers;
  }, [users, tabIdSelected, filterText]);
  const conbinedDataRef = useRef([]);
  const roleName = useRef("");
  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <Box sx={pageContainerStyle}>
      <Box sx={tabsContainerStyle}>
        <Tabs value={tabIdSelected} onChange={handleTabChange} aria-label="user management tabs">
          {menuTabs.map((tab, index) => (
            <Tab
              id={`${tab.title.toLowerCase().replaceAll(" ", "-")}-tab`}
              label={tab.title}
              disabled={tab.isDisabled}
              key={tab.id}
            />
          ))}
        </Tabs>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <TextField
            id="search-input"
            placeholder="Search..."
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            fullWidth
            sx={{
              fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
              ...textFieldInputStlRAMP,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box>
            {loggedUser.canCreateAccount && loggedUser.account_id === CONSTANT.IIQ_ && (
              <Button
                onClick={handleGitbookClick}
                sx={{
                  ...btnStl,
                  backgroundColor: colors.mainDarkPrimary10,
                  color: colors.mainDarkPrimary100,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                startIcon={<AddIcon />}
                disabled={false}
              >
                Add to GitBook
              </Button>
            )}
          </Box>
          {loggedUser.modulesVisibility.includes(CONSTANT.MODULE.RMP_ADMIN) && (
            <Box>
              <SideBoardWrapper
                type="user"
                headerTitle="Add new user to account"
                btnTitle="Add new user"
                value={{}}
                btnStyles={{}}
                id="add-user-to-account"
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ ...mainTableStyle(), width: "100%" }}>
        {filteredData && <UsersTable users={filteredData} initialFilterModel={initialFilterModel}></UsersTable>}
      </Box>

      {open ? (
        <RoleDetailsPopup setOpen={setOpen} data={conbinedDataRef.current} roleName={roleName.current} />
      ) : null}
      <SessionTimeout />

      <ReusableDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={"Add new user to GitBook"}
        width="400px"
        height="auto"
      >
        <Box
          sx={{
            width: "100%",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <LabeledTextField
            label="Email"
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
            error={!!inputEmailError}
            helperText={inputEmailError}
            id="text-bidder-config-name"
            required
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button variant="apply" onClick={hanldeAddUserToGitbook}>
              Add user
            </Button>
          </Box>
        </Box>
      </ReusableDialog>
    </Box>
  );
};

export default UsersPage;
