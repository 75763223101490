import React from "react";
import { Popover, Box, FormControlLabel, Switch } from "@mui/material";
import { colors } from "../../theme";

export interface TogglePopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  liftVisible: boolean;
  abVisible: boolean;
  onToggleLift: () => void;
  onToggleAB: () => void;
}

// Reusable style objects
const popoverContainerSx = {
  p: 1.5,
  bgcolor: "var(--Background-card, white)",
  boxShadow: "0px 40px 40px rgba(16, 29, 65, 0.10)",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  gap: 1,
  width: "200px",
};

const labelContainerSx = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

const customSwitchSx = {
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: colors.brandDefault,
  },
};

const TogglePopover: React.FC<TogglePopoverProps> = ({
  open,
  anchorEl,
  onClose,
  liftVisible,
  abVisible,
  onToggleLift,
  onToggleAB,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: 0, horizontal: -20 }}
    >
      <Box sx={popoverContainerSx}>
        <FormControlLabel
          control={<Switch checked={liftVisible} onChange={onToggleLift} sx={customSwitchSx} size="small" />}
          label="Lift"
          labelPlacement="start"
          sx={labelContainerSx}
        />
        <FormControlLabel
          control={<Switch checked={abVisible} onChange={onToggleAB} sx={customSwitchSx} size="small" />}
          label="A/B"
          labelPlacement="start"
          sx={labelContainerSx}
        />
      </Box>
    </Popover>
  );
};

export default TogglePopover;
