import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { CONSTANT } from "../../constants/constants";
import { ISummaryWidgetsResponse, IUniquePctCurrencyCombinations, StatsObserveResponse } from "./types";
import { setColumnIndices, setUniqueCurrencyPctCombinations, setSummaryWidgets } from "./revenueSlice";

const SERVER_URL = CONSTANT.path.host;
const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { getState } = api;
  const state = getState() as RootState;
  const token = state.users?.loggedUser?.token;

  if (!token) {
    return {
      error: {
        status: 401,
        data: { message: "No token available for authentication." },
      },
    } as { error: FetchBaseQueryError };
  }

  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/statistics`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  });

  return baseQuery(args, api, extraOptions);
};

export const revenueApi = createApi({
  reducerPath: "statisticsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Statistics"],
  endpoints: (builder) => ({
    statsObserve: builder.mutation<
      StatsObserveResponse,
      {
        rt: number;
        dgm: number;
        pid: string;
        client_type?: string;
        device_type?: string;
        action_termination?: string;
        adType?: string;
        biddercode?: string;
        cdm?: number;
        country_id?: string;
        cstm_end?: string | null;
        cstm_start?: string | null;
        currency?: string;
        integrations?: string;
        js_version?: string;
        pb_version?: string;
        pct?: string;
        site_id?: string;
        task?: string;
      }
    >({
      query: (body) => ({
        url: "stats_observe",
        method: "POST",
        body,
      }),
      extraOptions: { abortOnUnmount: true },
      invalidatesTags: ["Statistics"],
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const state = getState() as RootState;

          const { data } = await queryFulfilled;
          if (data.data && data.data.length > 0) {
            const headers = data.data[0];
            const columnIndices: Record<string, number> = {};
            headers.forEach((header, index) => {
              columnIndices[header] = index;
            });
            dispatch(setColumnIndices(columnIndices));
          }
          if (data.summaryWidgets && Object.keys(data.summaryWidgets).length === 0) {
            dispatch(setSummaryWidgets({}));
            dispatch(setUniqueCurrencyPctCombinations([]));
            return;
          }

          if (data.summaryWidgets !== null && data.summaryWidgets !== undefined) {
            const summaryWidgets = data.summaryWidgets as Record<
              string,
              { totalImpressions?: { currentPeriod: number } }
            >;
            const uniqueCombinations = Object.keys(summaryWidgets).map((key, index) => {
              const [currency, pctStr] = key.split("-");
              const totalImpressions = summaryWidgets[key]?.totalImpressions?.currentPeriod ?? 0;
              return {
                currency,
                pct: parseFloat(pctStr),
                name: `${currency} - ${pctStr}`,
                id: key,
                totalImpressions,
              };
            });
            const comboWithMaxImpressions = uniqueCombinations.reduce((prev, current) =>
              prev.totalImpressions > current.totalImpressions ? prev : current
            );
            const prevSelected = state.revenue.uniqueCurrencyPctCombinations.find((item) => item.isSelected);
            const prevSelectedAvailable = uniqueCombinations.find((item) => item.id === prevSelected?.id);
            if (prevSelectedAvailable) {
              dispatch(
                setUniqueCurrencyPctCombinations(
                  uniqueCombinations.map((combo) => ({
                    ...combo,
                    isSelected: combo.id === prevSelectedAvailable.id,
                  }))
                )
              );
            } else {
              dispatch(
                setUniqueCurrencyPctCombinations(
                  uniqueCombinations.map((combo) => ({
                    ...combo,
                    isSelected: combo.id === comboWithMaxImpressions.id,
                  }))
                )
              );
            }
            dispatch(setSummaryWidgets(data.summaryWidgets));
          }
        } catch (error) {
          console.error("Failed to process stats_observe response:", error);
        }
      },
    }),
  }),
});

export const { useStatsObserveMutation } = revenueApi;
