import { JSAgentFile } from "./jsAgentApi";

export interface TreeViewItem {
  id: string;
  label: string; // Keep label as string for compatibility
  children?: TreeViewItem[] | null;
}

// Step 1: Traverse or create nodes for each part of the path
const traversePath = (root: Record<string, any>, path: string[], fileName: string): void => {
  let current = root;

  // Skip files with empty paths
  if (!path || path.length === 0 || path[0] === "") {
    return; // Skip processing this file
  }

  path.forEach((part, index) => {
    if (!current[part]) {
      current[part] = { children: {} };
    }

    // If it's the last part of the path, add the file
    if (index === path.length - 1) {
      if (!current[part].children[fileName]) {
        current[part].children[fileName] = {
          label: fileName || "Unknown File", // Use "Unknown File" if fileName is missing
          children: null,
        };
      }
    }

    current = current[part].children;
  });
};

// Step 2: Convert the intermediate structure into TreeView format
const convertToTreeView = (
  obj: Record<string, any>,
  handleDownloadJSAgent: (e: React.MouseEvent<HTMLButtonElement>, fullPath: string, fileName: string) => void,
  parentId = ""
): TreeViewItem[] =>
  Object.entries(obj).map(([key, value]) => {
    const uniqueId = parentId ? `${parentId}/${key}` : key; // Generate unique ID

    if (value.children) {
      // It's a folder
      return {
        id: uniqueId,
        label: key, // Use the folder name as the label
        children: convertToTreeView(value.children, handleDownloadJSAgent, uniqueId), // Recursively process children
      };
    } else {
      // It's a file
      return {
        id: uniqueId,
        label: key, // Use file name as string
        children: null,
      };
    }
  });

// Step 3: Main function to build the tree
export const buildTree = (
  files: JSAgentFile[],
  handleDownloadJSAgent: (e: React.MouseEvent<HTMLButtonElement>, fullPath: string, fileName: string) => void
): TreeViewItem[] => {
  const root: Record<string, any> = {}; // Temporary structure to build the tree

  files.forEach(({ path, fileName }) => {
    const pathParts = path.split("/").filter(Boolean); // Split the path into its components and ignore empty strings
    traversePath(root, pathParts, fileName);
  });

  return convertToTreeView(root, handleDownloadJSAgent, ""); // Convert the intermediate structure into TreeView format
};
