import * as React from "react";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridValueGetterParams,
  GridCellParams,
  MuiEvent,
} from "@mui/x-data-grid";
import {
  columnTablePanelStyle,
  mainTableStyle,
} from "../../core/performanceReports/advancedReport/table/mainTableStyle";
import { useState, useRef } from "react";
import moment from "moment";
import UserStatusChip from "../../components/Chips/UserStatusChip";
import { useLocation, useNavigate } from "react-router-dom";
import UserRoleLabel from "../../components/Chips/UserRoleLabel";
import { dataGridSx } from "../styles";
import { CustomPaginationWrapper } from "../AccountsPage";
import SessionTimeout from "../../components/SessionTimeout/SessionTimeout";
import { getRoleDetails } from "../api/accounts-api";
import RoleDetailsPopup from "../rolesMangementComponents/RoleDetailsPopup";
import { CustomColumnMenuComponent } from "../../core/performanceReports/advancedReport/table/MainTable";
import { IUser } from "../users/interfaces";
import { tablesRowsAmountPerPageOptions } from "../../parametersConstancy/parametersConstancyTypes";
import { addAlpha, nameToColor } from "../../core/performanceReports/common-reports-helpers";

interface UsersTableProps {
  users: IUser[];
  disabledColumns?: string[];
  initialFilterModel?: GridFilterModel;
}

const defaultColumns = (handleRoleClick: any): GridColDef[] => [
  {
    field: "name",
    headerName: "Name",
    flex: 1.2,
    renderCell: (params: any) => (
      <Box
        id="user-name"
        sx={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: 600, color: "#131330" }}
      >
        {`${params.row.firstName} ${params.row.lastName}`}
      </Box>
    ),
    sortComparator: (v1, v2) => v1.localeCompare(v2, "en", { sensitivity: "base" }),
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`.trim(),
  },
  { field: "email", headerName: "Email", flex: 1 },
  {
    field: "partnerAccount",
    headerName: "Account",
    renderCell: (params: any) => {
      return params.row?.account_id?.company ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              marginRight: "0px",
              padding: "4px 12px",
              borderRadius: "6px",
              color: params.row?.account_id?.company === "IIQ" ? "#FFF" : "#0a4779",
              background:
                params.row?.account_id?.company === "IIQ"
                  ? "#0a4779"
                  : addAlpha(nameToColor(params.row?.account_id?.company), 0.3),
            }}
            id="user-account"
          >
            {params.row?.account_id?.company}
          </Box>
        </Box>
      ) : null;
    },
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      (cellParams1.api.getRow(cellParams1.id).account_id?.company || "").localeCompare(
        cellParams2.api.getRow(cellParams2.id).account_id?.company || ""
      ),
    valueGetter: (params: GridValueGetterParams) => params.row?.account_id?.company || "",

    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "createdAt",
    headerName: "Registration date",
    renderCell: (params: any) => {
      return moment(params.row.createdAt).format("DD.MM.YYYY | HH:mm");
    },
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lastLogin",
    headerName: "Last login",
    renderCell: (params: any) => {
      return params.row.lastLogin ? moment(params.row.lastLogin).format("DD.MM.YYYY | HH:mm") : "-";
    },
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "userRole",
    headerName: "User role",
    renderCell: (params: any) => {
      const length = params.row.rls.length;
      if (length === 0) return null;
      return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
          <div
            id="main-user-role"
            onClick={(event) => {
              handleRoleClick(params.row.rls[0].id, params.row.rls[0].name);
              event.stopPropagation();
            }}
          >
            <UserRoleLabel label={params.row.rls[0].name} />
          </div>
          {length > 1 && (
            <div id="additional-user-roles">
              <UserRoleLabel label={`+ ${length - 1}`} />
            </div>
          )}
        </Box>
      );
    },

    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const role1 = cellParams1.api.getRow(cellParams1.id).rls[0]?.name || "";
      const role2 = cellParams2.api.getRow(cellParams2.id).rls[0]?.name || "";
      return role1.localeCompare(role2);
    },
    valueGetter: (params: GridValueGetterParams) => {
      // Return a comma-separated list of role names for filtering purposes
      return params.row?.rls.map((r: any) => r.name).join(", ");
    },
    flex: 1.5,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params: any) => <UserStatusChip status={params.row.active} />,
    flex: 0.8,
    headerAlign: "center",
    align: "center",
    sortComparator: (v1, v2, param1: any, param2: any) =>
      param2.api.getRow(param2.id).active - param1.api.getRow(param1.id).active,
  },
];

export const UsersTable: React.FC<UsersTableProps> = ({ users, disabledColumns, initialFilterModel }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleRowClick = (params: any) => {
    if (params.field === "partnerAccount") {
      navigate(`/accounts/${params.row.account_id.id}`);
      return;
    }
    navigate(`/accounts/user_details/${params.row.id}`);
  };
  const conbinedDataRef = useRef([]);
  const roleName = useRef("");
  const handleRoleClick = (id: number, name: string) => {
    getRoleDetails(id).then((data) => {
      conbinedDataRef.current = data;
      roleName.current = name;
      setOpen(true);
    });
  };
  const columns = React.useMemo(() => {
    const allColumns = defaultColumns(handleRoleClick);
    return allColumns;
  }, [setOpen]);

  const defaultHiddenColumns = ["createdAt"];

  const columnsToHide = disabledColumns ? defaultHiddenColumns.concat(disabledColumns) : defaultHiddenColumns;

  const columnVisibilityModel = columnsToHide.reduce((acc: any, columnName: string) => {
    acc[columnName] = false;
    return acc;
  }, {});

  return (
    <>
      <Box id="table-container" sx={{ ...mainTableStyle(), width: "100%" }}>
        {users && (
          <DataGrid
            // onRowClick={(params: any) => handleRowClick(params.row.id)}
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
            }}
            sx={dataGridSx}
            onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
              event.defaultMuiPrevented = true;
              handleRowClick(params);
            }}
            key={users.length}
            rows={users}
            columns={columns}
            autoHeight={true}
            rowHeight={36}
            slots={{
              footer: () => <CustomPaginationWrapper rowsPerPageOptions={tablesRowsAmountPerPageOptions} />,
              columnMenu: CustomColumnMenuComponent,
            }}
            initialState={{
              columns: {
                columnVisibilityModel,
              },
              filter: {
                filterModel: initialFilterModel,
              },
            }}
            slotProps={{
              panel: {
                sx: columnTablePanelStyle,
              },
            }}
          />
        )}
      </Box>

      {open ? (
        <RoleDetailsPopup setOpen={setOpen} data={conbinedDataRef.current} roleName={roleName.current} />
      ) : null}
      <SessionTimeout />
    </>
  );
};
