import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../theme";
import {
  IQuickActionsItem,
  ManageQuickActionType,
  newQuickActionInitItem,
  QUICK_ACTION_ADD_PARAMETERS_MAP,
  quickActionParamsUnselected,
} from "./quickActionsUtils";
import ListQuickActionsItems from "./ListQuickActionsItems";
import QuickActionSettings from "./QuickActionSettings";
import {
  useCreateQuickActionMutation,
  useDeleteQuickActionMutation,
  useGetQuickActionsQuery,
  useUpdateQuickActionMutation,
} from "./quickActionsApi";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { message } from "antd";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import AddIcon from "../components/IconComponents/AddIcon";

const QuickActionsPage: React.FC = () => {
  const { data: actionItems, isSuccess } = useGetQuickActionsQuery();
  const [createQuickAction, { status: creationStatus, error: creationError }] = useCreateQuickActionMutation();
  const [updateQuickAction, { status: updateStatus, error: updateError }] = useUpdateQuickActionMutation();
  const [deleteQuickAction, { status: deleteStatus, error: deleteError }] = useDeleteQuickActionMutation();
  const [actionItem, setActionItem] = useState<IQuickActionsItem>(newQuickActionInitItem);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    if (isSuccess && actionItems.length > 0) {
      setActionItem(actionItems[0]);
    } else {
      setActionItem(newQuickActionInitItem);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (creationStatus === QueryStatus.fulfilled) {
      message.success(`The quick action was created successfully.`);
    } else if (creationStatus === QueryStatus.rejected) {
      console.error("Failed to create quick action:", creationError);
      message.error(`Error to create quick action.`);
    }
  }, [creationStatus, creationError]);
  useEffect(() => {
    if (updateStatus === QueryStatus.fulfilled) {
      message.success(`The quick action was updated successfully.`);
    } else if (updateStatus === QueryStatus.rejected) {
      console.error("Failed to update quick action:", updateError);
      message.error(`Error to update quick action.`);
    }
  }, [updateStatus, updateError]);
  useEffect(() => {
    if (deleteStatus === QueryStatus.fulfilled) {
      message.success(`The quick action was deleted successfully.`);
    } else if (deleteStatus === QueryStatus.rejected) {
      console.error("Failed to delete quick action:", deleteError);
      message.error(`Error to delete quick action.`);
    }
  }, [deleteStatus, deleteError]);

  const handleClickManageQuickAction = async (type: ManageQuickActionType) => {
    try {
      if (type === ManageQuickActionType.CREATE) {
        const createdQuickAction = await createQuickAction(actionItem).unwrap();
        setActionItem(createdQuickAction);
      } else if (type === ManageQuickActionType.UPDATE) {
        await updateQuickAction(actionItem).unwrap();
      } else if (type === ManageQuickActionType.DELETE) {
        if (actionItem.id && actionItems) {
          const quickActions = [...actionItems];
          const removedActionIndex: number = actionItems.findIndex((item) => item.id === actionItem.id) ?? 0;
          await deleteQuickAction(actionItem.id);
          let newSelectedQuickActionIndex = removedActionIndex;

          quickActions.splice(removedActionIndex, 1);

          if (removedActionIndex >= quickActions.length) {
            newSelectedQuickActionIndex--;
          }
          const newSelectedQuickAction =
            newSelectedQuickActionIndex < 0 ? newQuickActionInitItem : quickActions[newSelectedQuickActionIndex];
          setActionItem(newSelectedQuickAction);
        }
      }
    } catch (error) {
      console.error("Failed to manage quick action: ", error);
    }
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <Box
      sx={{
        marginTop: "14px",
        display: "flex",
        padding: "24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        gap: "32px",
        alignSelf: "stretch",
        borderRadius: "16px",
        border: `1px solid ${colors.navy50}`,
        background: colors.foreground01,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "348px",
            alignItems: "flex-start",
            gap: "24px",
            alignSelf: "stretch",
          }}
        >
          <Button
            variant="tertiary"
            size="large"
            onClick={() => {
              setActionItem(newQuickActionInitItem);
            }}
          >
            <AddIcon />
            <Typography variant="body1Small600">Add new</Typography>
          </Button>
          <Box sx={{ width: "100%" }}>
            <Typography variant="bodyLargeRegular">Saved Configurations</Typography>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {isSuccess && (
            <ListQuickActionsItems
              items={actionItems}
              selectedItem={actionItem}
              setActionItem={setActionItem}
              creationStatus={creationStatus}
            />
          )}
        </Box>
        <QuickActionSettings
          actionItem={actionItem}
          setActionItem={setActionItem}
          actionItems={actionItems ?? []}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        {actionItem.id != null && (
          <Button variant="secondary" onClick={handleOpenConfirmationDialog} disabled={!actionItem.name}>
            Remove action
          </Button>
        )}
        <Button
          variant="apply"
          onClick={() => {
            const type = actionItem.id != null ? ManageQuickActionType.UPDATE : ManageQuickActionType.CREATE;
            handleClickManageQuickAction(type);
          }}
          disabled={
            !actionItem.name ||
            Boolean(
              actionItems?.find((action) => action.name === actionItem.name && action.id !== actionItem.id)
            ) ||
            (+actionItem.settings.parametersId === +quickActionParamsUnselected.id &&
              +actionItem.settings.addParametersId === +QUICK_ACTION_ADD_PARAMETERS_MAP.unselect.id) ||
            (actionItem.settings.logicRules.length > 0 &&
              actionItem.settings.logicRules.some((rule) => {
                return (
                  (!rule.minValue && !rule.maxValue) ||
                  (rule.minValue !== 0 && rule.maxValue !== 0 && rule.minValue && rule.maxValue
                    ? rule.minValue >= rule.maxValue
                    : false)
                );
              }))
          }
        >
          {actionItem.id != null ? "Save changes" : "Create action"}
        </Button>
      </Box>
      {actionItem.id != null && (
        <ConfirmationDialog
          isOpen={openConfirmationDialog}
          title="Remove quick action"
          body="Are you sure about removing this quick action?"
          confirmButtonTitle="Yes, remove action"
          declineButtonTitle="Cancel"
          onConfirm={() => {
            handleCloseConfirmationDialog();
            handleClickManageQuickAction(ManageQuickActionType.DELETE);
          }}
          onDecline={handleCloseConfirmationDialog}
        />
      )}
    </Box>
  );
};

export default QuickActionsPage;
