import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { colors } from "../theme";
import { useEffect, useState } from "react";
import JSAgentStatsCard from "./JSAgentStatsCard";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { message } from "antd";
import {
  AgentTags,
  handleDownload,
  JSAgentStatsRequestDto,
  selectJSAgentById,
  useGetJSAgentsQuery,
  useGetJSAgentStatsMutation,
} from "./jsAgentApi";
import { buildTree, TreeViewItem } from "./agent-utils";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";

const JSAgentDetailsPage: React.FC = () => {
  const { data: jsAgentsData } = useGetJSAgentsQuery(); //needed if user diractly load/reload this page
  const [fetchJSAgentStatsData, { data: jsAgentStats, isSuccess }] = useGetJSAgentStatsMutation();
  const token = useSelector((state: RootState) => state.users.loggedUser.token) ?? "";
  const params = useParams();
  const [downloadResult, setDownloadResult] = useState<{ type: number; message: string } | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  let agentVersion = `${params.id}`;
  const agent = useSelector((state: RootState) => selectJSAgentById(state, agentVersion));

  useEffect(() => {
    if (agentVersion) {
      getAgentStatsData();
    }
  }, [agentVersion]);

  useEffect(() => {
    if (downloadResult) {
      if (downloadResult.type) {
        messageApi.open({
          type: "success",
          content: downloadResult.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: downloadResult.message,
        });
      }
    }
    setDownloadResult(null);
  }, [downloadResult]);

  const getAgentStatsData = async () => {
    if (agentVersion !== undefined && !isNaN(Number(agentVersion))) {
      const requestDto: JSAgentStatsRequestDto = {
        version: agent?.tag === AgentTags.GA ? `${agentVersion}`.replaceAll(`-${AgentTags.GA}`, "") : agentVersion,
      };
      try {
        await fetchJSAgentStatsData(requestDto).unwrap();
      } catch (error) {
        console.error("Failed to fetch JS Agent stats:", error);
      }
    }
  };

  const handleDownloadJSAgent = (e: React.MouseEvent<HTMLButtonElement>, fullPath: string, fileName: string) => {
    e.stopPropagation();
    handleDownload(
      { version: agent?.tag === AgentTags.GA ? AgentTags.GA : agentVersion, path: fullPath, fileName },
      token,
      setDownloadResult
    );
  };

  const gapElements = "10px";
  const statsDataContent = isSuccess && jsAgentStats && jsAgentStats?.length > 0 && (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flexStart",
        marginTop: "20px",
        gap: "20px",
      }}
    >
      <Typography variant="bodyXLarge700">Impressions</Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: gapElements,
        }}
      >
        {jsAgentStats?.map((item) => (
          <JSAgentStatsCard key={item.partnerId} item={item} />
        ))}
      </Box>
    </Box>
  );
  const splitTreeData = (treeData: TreeViewItem[]) => {
    const halfLength = Math.ceil(treeData.length / 2);

    return {
      tree1: treeData.slice(0, halfLength),
      tree2: treeData.slice(halfLength),
    };
  };

  const treeData: TreeViewItem[] = (agent?.files && buildTree(agent?.files, handleDownloadJSAgent)) ?? [];

  const { tree1, tree2 } = splitTreeData(treeData);

  const renderTree = (nodes: TreeViewItem[]) =>
    nodes.map((node) => (
      <TreeItem
        key={node.id}
        label={
          node.children ? (
            node.label.toUpperCase() // Render folder label as string
          ) : (
            <Button
              variant="text"
              sx={{
                color: colors.brandDefault,
                fontWeight: 700,
              }}
              onClick={(e) => handleDownloadJSAgent(e, node.id, node.label)} // Pass full path
            >
              Download {node.label.replace(".js", "")}
            </Button>
          )
        }
        itemId={node.id}
      >
        {node.children ? renderTree(node.children) : null}
      </TreeItem>
    ));

  return (
    <Box sx={{ marginBottom: "20px" }}>
      <Box sx={{ minHeight: 352, minWidth: 250 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ flex: 1, padding: 2 }}>
            <SimpleTreeView>{renderTree(tree1)}</SimpleTreeView>
          </Box>
          <Box sx={{ flex: 1, padding: 2 }}>
            <SimpleTreeView>{renderTree(tree2)}</SimpleTreeView>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        {/* <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: "16px" }}>
          {agent?.files.map((file) => (
            <Box
              key={`${file.path}_${file.fileName}`}
              sx={{ display: "flex", flexDirection: "column", width: "calc(50% - 8px)", gap: "8px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  border: `1px solid ${colors.borderDefault}`,
                  borderRadius: "24px",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {file.path} {file.fileName}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.Obfuscated);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          ))}
          {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              {agentVersion}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                border: `1px solid ${colors.borderDefault}`,
                borderRadius: "24px",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Typography variant="bodyLargeRegular" color={colors.text01}>
                {initJsAgentVersionTypes.obfuscated}
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: colors.brandDefault,
                  fontWeight: 700,
                }}
                onClick={(e) => {
                  handleDownloadJSAgent(e, JSAgentTypes.Obfuscated);
                }}
              >
                Download
              </Button>
            </Box>
          </Box> */}
        {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              ES6
            </Typography>
            <Box sx={{ border: `1px solid ${colors.borderDefault}`, borderRadius: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES6.minified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES6Minified);
                  }}
                >
                  Download
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES6.unminified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES6Unminified);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box> 
       </Box> */}
        {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              {initJsAgentVersionTypes.sync}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                border: `1px solid ${colors.borderDefault}`,
                borderRadius: "24px",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Typography variant="bodyLargeRegular" color={colors.text01}>
                {initJsAgentVersionTypes.sync}
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: colors.brandDefault,
                  fontWeight: 700,
                }}
                onClick={(e) => {
                  handleDownloadJSAgent(e, JSAgentTypes.Sync);
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              ES5
            </Typography>
            <Box sx={{ border: `1px solid ${colors.borderDefault}`, borderRadius: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES5.minified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES5Minified);
                  }}
                >
                  Download
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES5.unminified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES5Unminified);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>
      {statsDataContent}
      {contextHolder}
    </Box>
  );
};

export default JSAgentDetailsPage;
