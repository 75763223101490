import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  MenuItem,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { IBidderAdapter, IMediaType, IOverrideRegion } from "./interfaces";
import LabeledTextField from "./LabeledTextField";
import { CategoryType } from "../../parametersConstancy/parametersConstancyTypes";
import DropdownHandler from "../../components/Dropdown/DropdownHandler";
import { IFormErrors, validateBidderAdapterForm } from "./utils";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { useMediaTypesMapping } from "./useMappings";
import { useAddRegionMutation } from "./configurationsApi";
import Banner, { BannerLevelNames } from "../../components/Banner/Banner";
import { colors } from "../../theme";
import { SimpleDropdown } from "../../components/SimpleDropdown/SimpleDropdown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface ISimpleDropdownOption {
  id: number;
  name: string;
}

export const emptyRegionId = -1;

interface BidderAdapterFormProps {
  initialFormData?: Partial<IBidderAdapter>;
  onSubmit: (formData: IBidderAdapter) => void;
  availableMediatypes: IMediaType[];
  availableRegions: { id: number; name: string }[];
  isBidderAdapterFormLoading: boolean;
  existingBidderAdapters: IBidderAdapter[];
}

const BidderAdapterForm: React.FC<BidderAdapterFormProps> = ({
  initialFormData,
  onSubmit,
  availableMediatypes,
  availableRegions,
  isBidderAdapterFormLoading,
  existingBidderAdapters,
}) => {
  const [formData, setFormData] = useState<IBidderAdapter>({
    id: initialFormData?.id || 0,
    bidderName: initialFormData?.bidderName || "",
    partnerPrebidName: initialFormData?.partnerPrebidName || "",
    mediatypesIds: initialFormData?.mediatypesIds || [],
    regions: initialFormData?.regions || [],
  });
  const { mediatypesMapping, isLoading: mediaTypesIsLoading } = useMediaTypesMapping();
  const [errors, setErrors] = useState<IFormErrors>({});
  const [openFilter, setOpenFilter] = useState("");
  const mediaOptions: CategoryType[] = availableMediatypes.map((m) => ({
    id: m.id,
    name: m.name,
  }));
  const [mediaValues, setMediaValues] = useState<CategoryType[]>([]);
  const [localRegions, setLocalRegions] = useState<{ id: number; name: string }[]>(availableRegions);
  const [newRegionInputs, setNewRegionInputs] = useState<{ [key: number]: string }>({});
  const [addRegion] = useAddRegionMutation();
  const handleFieldChange = (field: keyof IBidderAdapter, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };
  const [expanded, setExapnded] = useState(false);
  const handleExpand = () => {
    setExapnded(!expanded);
  };

  useEffect(() => {
    if (formData.mediatypesIds?.length) {
      setMediaValues(
        formData.mediatypesIds.map((m) => ({
          id: m,
          name: mediatypesMapping[m],
        }))
      );
    } else {
      setMediaValues([]);
    }
  }, [formData.mediatypesIds, mediaTypesIsLoading]);

  useEffect(() => {
    setLocalRegions(availableRegions);
  }, [availableRegions]);

  // useEffect(() => {
  //   if (formData.regions.length === 0) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       regions: [{ id: emptyRegionId, name: "", endpoint: "" }],
  //     }));
  //   }
  // }, [formData.regions]);

  const handleToggleOpen = (base: string) => {
    setOpenFilter((prev) => (prev === base ? "" : base));
  };

  const handleApplyMediatypes = (base: string, selectedItems: CategoryType[]) => {
    setMediaValues(selectedItems);
    const updatedMediatypes = selectedItems.map((sel) => {
      const found = availableMediatypes.find((m) => m.id === sel.id);
      return found || { id: sel.id, name: sel.name };
    });
    handleFieldChange(
      "mediatypesIds",
      updatedMediatypes.map((m) => m.id)
    );
    setOpenFilter("");
  };

  const handleAddEndpoint = () => {
    setFormData((prev) => ({
      ...prev,
      regions: [...prev.regions, { id: emptyRegionId, name: "", endpoint: "" }],
    }));
  };

  const handleRemoveEndpoint = (idx: number) => {
    setFormData((prev) => ({
      ...prev,
      regions: prev.regions.filter((_, i) => i !== idx),
    }));
  };

  const handleEndpointChange = (idx: number, key: keyof IOverrideRegion, value: any) => {
    setFormData((prev) => ({
      ...prev,
      regions: prev.regions.map((row, i) => (i === idx ? { ...row, [key]: value } : row)),
    }));
  };

  const handleNewRegionInputChange = (idx: number, value: string) => {
    setErrors((prev) => ({ ...prev, newRegionName: "" }));
    setNewRegionInputs((prev) => ({ ...prev, [idx]: value }));
  };

  const handleAddNewRegion = (idx: number) => {
    const newRegionName = newRegionInputs[idx];
    if (!newRegionName || newRegionName.trim() === "") return;
    if (localRegions.find((r) => r.name === newRegionName)) {
      setErrors((prev) => ({ ...prev, newRegionName: "Duplicate region" }));
      return;
    }
    const newId = Math.max(...localRegions.map((r) => r.id), 0) + 1;
    const newRegion = { id: newId, name: newRegionName };
    setLocalRegions((prev) => [...prev, newRegion]);
    handleEndpointChange(idx, "id", newId);
    handleEndpointChange(idx, "name", newRegionName);
    setNewRegionInputs((prev) => ({ ...prev, [idx]: "" }));
    addRegion(newRegionName);
  };

  const handleSave = () => {
    setErrors({});
    const validationErrors = validateBidderAdapterForm(formData);

    if (
      existingBidderAdapters.some(
        (bidder) => bidder.bidderName === formData.bidderName && bidder.id !== formData.id
      )
    ) {
      validationErrors.bidderName = "Bidder name must be unique";
    }
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      onSubmit(formData);
    }
  };

  if (isBidderAdapterFormLoading)
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );

  return (
    <Box
      sx={{
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      <Banner
        text={
          <>
            Bidders must be created in accordance with their descriptions and specifications detailed in the{" "}
            <a
              href="https://github.com/prebid/prebid-server/tree/master/static/bidder-info"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prebid Server GitHub repository
            </a>
            .
          </>
        }
        level={BannerLevelNames.INFO}
        visible={true}
        withoutCloseButton
      />

      <Typography variant="body1Small600">Main info</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <LabeledTextField
          label="Bidder name"
          value={formData.bidderName}
          onChange={(e) => handleFieldChange("bidderName", e.target.value)}
          error={!!errors.bidderName}
          helperText={errors.bidderName}
          required
        />
        <LabeledTextField
          label="Partner Prebid name"
          value={formData.partnerPrebidName || ""}
          onChange={(e) => handleFieldChange("partnerPrebidName", e.target.value)}
        />
        <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: "4px" }}>
            Mediatypes
          </Typography>
          <Typography component="span" color="error">
            {" *"}
          </Typography>
        </Box>
        <DropdownHandler
          base="mediatypes"
          title="Select mediatype"
          optionsData={mediaOptions}
          valuesData={mediaValues}
          openFilter={openFilter}
          handleToggleOpen={handleToggleOpen}
          handleApply={handleApplyMediatypes}
          isMultiSelection={true}
          filtersIsLoading={{}}
          shouldBeSelected={false}
          isApplyDisabled={false}
          notification=""
          id="mediatypes-dropdown"
        />
        {errors.mediatypesIds && (
          <Typography variant="body1Small400" sx={{ color: colors.error }}>
            {errors.mediatypesIds}
          </Typography>
        )}
      </Box>

      <Accordion
        sx={{
          "&:before": {
            display: "none",
          },
        }}
        expanded={expanded}
        onChange={handleExpand}
        elevation={0}
        disableGutters
      >
        <AccordionSummary
          sx={{ padding: "0 8px 0 8px", border: "none" }}
          expandIcon={<ExpandMoreIcon />}
          id="add-custom-endpoint-group-accordion"
        >
          <Typography variant="body1Small600">{`Custom endpoints`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0 8px 0 8px" }}>
          <Box>
            {formData.regions.map((endpoint, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <SimpleDropdown
                    label="Region"
                    value={endpoint.id}
                    onChange={(selectedRegionId: any) => {
                      const regionData = localRegions.find((r) => r.id === Number(selectedRegionId));
                      handleEndpointChange(idx, "id", Number(selectedRegionId));
                      handleEndpointChange(idx, "name", regionData?.name || "");
                    }}
                    options={localRegions.map((r) => ({ id: r.id, name: r.name }))}
                    error={!!errors[`endpoint_region_${idx}`]}
                    helperText={errors[`endpoint_region_${idx}`]}
                    renderCustomItem={() => (
                      <Box>
                        <MenuItem disableRipple sx={{ minHeight: 48 }}>
                          <Box sx={{ display: "flex", alignItems: "center", width: "200px" }}>
                            <TextField
                              placeholder="type here"
                              value={newRegionInputs[idx] || ""}
                              fullWidth
                              inputProps={{
                                onMouseDown: (e) => e.stopPropagation(),
                                onMouseUp: (e) => e.stopPropagation(),
                                onClick: (e) => e.stopPropagation(),
                                onKeyDown: (e) => e.stopPropagation(),
                                onKeyUp: (e) => e.stopPropagation(),
                              }}
                              onChange={(e) => handleNewRegionInputChange(idx, e.target.value)}
                            />
                            <IconButton
                              sx={{ marginLeft: "8px" }}
                              aria-label="add-region"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddNewRegion(idx);
                              }}
                              onMouseDown={(e) => e.stopPropagation()}
                              onMouseUp={(e) => e.stopPropagation()}
                            >
                              <AddIcon />
                            </IconButton>
                          </Box>
                        </MenuItem>
                        {errors?.newRegionName && (
                          <Box sx={{ marginLeft: "1rem" }}>
                            <Typography color="error">{errors?.newRegionName}</Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  />
                </Box>
                <Box sx={{ flex: 3 }}>
                  <LabeledTextField
                    label="Endpoint URL"
                    value={endpoint.endpoint}
                    onChange={(e) => handleEndpointChange(idx, "endpoint", e.target.value)}
                    error={!!errors[`endpoint_url_${idx}`]}
                    helperText={errors[`endpoint_url_${idx}`] || ""}
                    required
                  />
                </Box>

                <IconButton aria-label="delete-endpoint" onClick={() => handleRemoveEndpoint(idx)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "8px" }}>
              <Button
                variant="outlinedFullRoundFullWidth"
                onClick={handleAddEndpoint}
                sx={{ alignSelf: "flex-start", width: "auto" }}
              >
                Add custom endpoint
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "16px" }}>
        <Button variant="contained" onClick={handleSave} sx={{ borderRadius: "50px" }}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default BidderAdapterForm;
