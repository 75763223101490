import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CONSTANT } from "../../constants/constants";
import { useAuth0 } from "@auth0/auth0-react";

const LoginAutotest: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState<string>("");

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginWithRedirect({
      authorizationParams: {
        login_hint: email,
        organization: CONSTANT.auth0.autotestOrganization,
        connection: CONSTANT.auth0.usernamePasswordAuthenticationName,
      },
    });
  };

  return (
    <form onSubmit={handleLogin} style={{ display: "flex", flexDirection: "column", gap: "20px", width: "300px" }}>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        required
      />

      <Button type="submit" variant="contained" color="primary">
        Login with Autotest
      </Button>
    </form>
  );
};

export default LoginAutotest;
