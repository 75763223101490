import { Box, FormLabel, FormControlLabel, Checkbox } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import { colors } from "../../theme";

interface ModulePermissionsCheckboxesProps {
  modulesTypes: { [key: string]: [number, number, string] };
  value: { [key: string]: number | null };
  handleChangeCheckbox: (moduleKey: string, permissionValue: number) => void;
  colors: { lightBlue: string };
  loggedUser?: { account_id: number; configurationAdministrator: number;}
  targetAccountId?: number;
}

export let modulesTypesArray: [string, number|null, number|null, string][] = [
  ["rmp", CONSTANT.MODULE.RMP_ADMIN, CONSTANT.MODULE.RMP_VIEW, "Roles management"],
  ["dashboard", CONSTANT.MODULE.DASHBOARD_ADMIN, CONSTANT.MODULE.DASHBOARD_VIEW, "Dashboard"],
  ["billing", CONSTANT.MODULE.BILLING_ADMIN, CONSTANT.MODULE.BILLING_VIEW, "Billing"],
  ["bidders_configuration", CONSTANT.MODULE.BIDDERS_CONFIGURATION_ADMIN, CONSTANT.MODULE.BIDDERS_CONFIGURATION_VIEW, "Bidder configuration"],
  ["bid_adapter", CONSTANT.MODULE.BID_ADAPTER_ADMIN, CONSTANT.MODULE.BID_ADAPTER_VIEW, "Bid adapter management"],
  ["tech_doc_access",  null, CONSTANT.MODULE.TECH_DOCS_ACCESS_VIEW, "Tech docs access"],
];

export const ModulePermissionsCheckboxes: React.FC<ModulePermissionsCheckboxesProps> = ({
  value,
  handleChangeCheckbox,
  loggedUser,
  targetAccountId
}) => {

  const isLoggedIIQ = loggedUser?.account_id === CONSTANT.IIQ_;
  // let updatedModulesArray = [...modulesTypesArray]; 

  // TODO: show it in the list ?
  // if (isLoggedIIQ) { 
  //    updatedModulesArray.push(["bid_adapter", CONSTANT.MODULE.BID_ADAPTER_ADMIN, CONSTANT.MODULE.BILLING_VIEW, "Bid adapter management"]); 
  //  }
  
    return (
    <Box sx={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
      {modulesTypesArray.map(([moduleKey, adminPermission, viewPermission, label]) => (
        <Box key={moduleKey} sx={{ display: "contents" }}>
          <FormLabel sx={{ lineHeight: "35px", paddingRight: "1rem" }} id={`${moduleKey}-checkbox-group-label`}>
            {label}
          </FormLabel>

          {[adminPermission, viewPermission].map((permissionValue, index) => {

            const isAdmin = permissionValue === adminPermission;
            const labelType = isAdmin ? "Admin" :  "View";
              
            return (
              <FormControlLabel
                // id={`${moduleKey}-${permissionValue % 2 === 0 ? "admin" : "view"}-label`}
                id={`${moduleKey}-${labelType}-label`}
                key={`${moduleKey}-${index}`}
                control={
                  permissionValue !== null ? (
                  <Checkbox
                    id={`${moduleKey}-${labelType}-checkbox`}
                    // id={`${moduleKey}-${permissionValue % 2 === 0 ? "admin" : "view"}-checkbox`}
                    checked={value[moduleKey] === permissionValue}
                    onChange={() => handleChangeCheckbox(moduleKey, permissionValue)}
                    name={moduleKey}
                    disabled={moduleKey === "bid_adapter" && (!isLoggedIIQ || !!(targetAccountId !== CONSTANT.IIQ_ACCOUNT_ID ? 1 : 0))}
                    sx={{
                      "&.Mui-checked": {
                        color: colors.lightBlue,
                      },
                    }}
                  />
                ) : (
                  <Checkbox
                    id={`${moduleKey}-${labelType}-checkbox`}
                    disabled // Non-functional checkbox for null
                    checked={false}
                  />
                )
                }
                label={labelType}
                // label={permissionValue % 2 === 0 ? "Admin" : "View"}
                sx={{
                  justifySelf: "end",
                }}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
};
