import { Close } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";

const badgeStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0.25rem 0.75rem",
  background: "#001738",
  margin:"0px 2px",
  borderRadius: "1rem",
  color: "#fff",
  fontWeight: 600,
};

const closeBadgeStyle: SxProps = {
  cursor: "pointer",
  marginLeft: "8px",
  color: "#fff",
};

export interface NotificationBadgeProps {
  selectedCellName?: string;
  filterTerms?: string;
  resetSort?: () => void;
  resetColumnFilter?: () => void;
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  selectedCellName,
  filterTerms,
  resetSort,
  resetColumnFilter,
}) => {
  return (
    <Box id="notification_badge">
      <Box style={{ display: "flex" }}>
        {selectedCellName && (
          <Box id="notification-badge-sorting" sx={badgeStyle}>
            {selectedCellName}
            {resetSort && (
              <Close
                id="notification-badge-clear-sorting-btn"
                onClick={() => resetSort()}
                sx={closeBadgeStyle}
                fontSize="small"
              />
            )}
          </Box>
        )}
        {filterTerms && (
          <Box id="notification-badge-filter" sx={badgeStyle}>
            {filterTerms}{" "}
            {resetColumnFilter && (
              <Close
                onClick={() => resetColumnFilter()}
                id="notification-badge-clear-filter-btn"
                sx={closeBadgeStyle}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NotificationBadge;
