import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSortModelGen } from "../../advancedReportSlice";

export default function useSortModel(res) {
  const dispatch = useDispatch();

  const { sortModelGen } = useSelector((state) => state.advancedReport);

  const handleSort = useCallback(
    (newSortModel) => {
      if (newSortModel.length === 0) {
        dispatch(
          setSortModelGen({
            model: newSortModel,
            selectedCell: null,
            cellName: "",
          })
        );
        return;
      }

      const newObject = newSortModel.reduce((acc, curr) => {
        acc.field = curr.field;
        acc.sort = curr.sort;
        return acc;
      }, {});

      let orderStr = newObject.sort === "asc" ? "lowest first" : "highest first";
      let name = res.filter((i) => i.field === newObject.field)[0];

      let lcase = name.headerName.toLowerCase();

      if (name.headerClassName === "group-header-child" && "groupColumnName" in name) {
        lcase = `${name.groupColumnName} ${name.headerName}`.toLowerCase();
      }

      dispatch(
        setSortModelGen({
          model: newSortModel,
          selectedCell: newObject,
          cellName: lcase + ": " + orderStr,
        })
      );
    },
    [dispatch, res]
  );

  const resetSort = useCallback(() => {
    let defaultModel = [
      {
        field: "submitted_date",
        sort: "desc",
      },
    ];

    dispatch(
      setSortModelGen({
        model: defaultModel,
        selectedCell: null,
        cellName: "",
      })
    );
  }, [dispatch]);

  return {
    sortModel: sortModelGen.model,
    handleSort,
    selectedCell: sortModelGen.selectedCell,
    selectedCellName: sortModelGen.cellName,
    resetSort,
  };
}
