import moment from "moment";
import { getAllTimeFirstDate, sortByDate } from "../../../summary/utils";
import { sumObjectsByKey, prepareDateFormat, getDateAgo, getPrevMonthDates, substractHours } from "../../../utils";
import { CONSTANT } from "../../../constants/constants";
import {
  abRevLiftCalc,
  calcAbFillRateLiftToSummaryRow,
  calcAvgCpmRateLiftToSummaryRow,
  calcAvgCpmToSummaryRow,
  calcDeploymentRevLiftToSummaryRow,
  getValues,
} from "./table/table-helpers";
import { validateSelection } from "../../../components/DatePicker/datePickerValidator";
import { selectionChartTypeItemIds } from "./reportCharts/areaChart/AreaChartWrapper";
const LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;

const prepareTemplate = (start, end) => {
  let arr = new Array();
  let dt = new Date(start);
  let template = {
    date: "",
    revenue: 0,
    revenueLift: 0,
    cpmLift: 0,
    fillRateLift: 0,
  };
  while (dt.toValue() <= end.toValue()) {
    arr.push({ ...template, date: prepareDateFormat(dt) });
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};

const prepareTemplateMonthlyGrouped = (start, endDate) => {
  let arr = new Array();
  let template = {
    date: "",
    revenue: 0,
    revenueLift: 0,
    cpmLift: 0,
    fillRateLift: 0,
  };

  let dt = new Date(start);
  let m = dt.getMonth() + 1;
  let end = new Date(endDate).getMonth() + 1;
  let monthsArray = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  for (let i = m; i <= end; i++) {
    arr.push({
      ...template,
      date: prepareDateFormat(dt),
      nameMonth: monthsArray[i - 1],
    });
    dt.setMonth(dt.getMonth() + 1);
  }

  return arr;
};

const prepareTemplateHours = (start, end) => {
  let arr = new Array();
  let dt = start;
  let template = {
    date: "",
    revenue: 0,
    revenueLift: 0,
    cpmLift: 0,
    fillRateLift: 0,
  };

  while (dt <= end) {
    arr.push({ ...template, date: dt.valueOf() });
    dt = moment(dt).add(60, "m").valueOf();

    // dt = moment(dt).add(60, 'm').toDate();
    // dt = new Date(dt.getTime() + 60 * 60000);
  }
  return arr;
};

const calcFillRateLift = ({ fillRateLift: agregatedValues, date }, pct) => {
  let normalizedGrB =
    pct === 100 ? pct * agregatedValues.total_rows2 : (pct / (100 - pct)) * agregatedValues.total_rows2;
  if (normalizedGrB === 0) {
    return 100;
  }
  let res = 100 * (agregatedValues.total_rows1 / normalizedGrB - 1);
  return res;
};

const calcCPMLift = (i) => {
  let sum_avg_cpma = 0;
  let sum_avg_cpmb = 0;

  if (+i.total_cpm1 !== 0 || +i.total_rows1 !== 0) {
    sum_avg_cpma = (1000 * +i.total_cpm1) / +i.total_rows1;
  }

  if (+i.total_cpm2 !== 0 || +i.total_rows2 !== 0) {
    sum_avg_cpmb = (1000 * +i.total_cpm2) / +i.total_rows2;
  }

  return sum_avg_cpma - sum_avg_cpmb;
};
const compare = (dataObj, i, h) => {
  return dataObj.setHours(0, 0, 0, 0) === new Date(i.submitted_date).setHours(0, 0, 0, 0);
};

const convertToWidgetAreaChartData = (template, data, pct = 95, timeGrouping) => {
  // NOT IN USE
  for (let j of template) {
    let dataObj = timeGrouping === CONSTANT.DATE_RANGE_TYPES.HOURLY.value ? j.date : new Date(j.date);
    for (let i of data) {
      // if (dataObj.setHours(0, 0, 0, 0) == new Date(i.submitted_date).setHours(0, 0, 0, 0)) {
      if (compare(dataObj, i, timeGrouping)) {
        let fillRateObj = {
          total_rows1: +i.total_rows1,
          total_rows2: +i.total_rows2,
        };

        let cpmLift = calcCPMLift(i);

        j.date = timeGrouping === CONSTANT.DATE_RANGE_TYPES.HOURLY.value ? dataObj : i.submitted_date.toString();
        j.revenue += i.total_cpm1 + i.total_cpm2;
        j.revenueLift += i.total_cpm1 - i.total_cpm2 * (i.pct / (100 - i.pct));
        j.cpmLift += cpmLift;
        //agregate values - then calcFillRateLift
        let res = sumObjectsByKey(j.fillRateLift, fillRateObj);
        j.fillRateLift = res;
      }
    }

    if (Object.keys(j.fillRateLift).length) {
      j.fillRateLift = calcFillRateLift(j, pct);
    }
  }

  return sortByDate(template);
};

const generateDateToAreaChart = (state) => {
  // generate template and prepare data for area chart
  let reportDateStart = undefined;
  let chartLastDate = undefined;
  let date = new Date();
  let dateCopy = new Date(date);

  if (state.timeGrouping === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
    reportDateStart = { startDate: state.customDateRange.customDateStart };
    chartLastDate = +new Date(state.customDateRange.customDateEnd);
  } else if (state.timeGrouping === CONSTANT.DATE_RANGE_TYPES.HOURLY.value) {
    let substractedDate = substractHours(state.timeSelection);
    reportDateStart = { startDate: substractedDate };
    chartLastDate = moment(date).valueOf();
  } else {
    if (state.timeGrouping === LAST_MONTH) {
      let { start, end } = getPrevMonthDates();
      reportDateStart = { startDayNumber: 1, startDate: start };
      chartLastDate = new Date(end);
    } else {
      reportDateStart = getDateAgo(date, state.timeGrouping);
      chartLastDate = dateCopy.setDate(date.getDate() - 1);
    }
  }

  return {
    reportDateStart,
    chartLastDate,
  };
};

export const generateDateRange = (
  rt,
  customDateStart,
  customDateEnd,
  dgm,
  timeSelection,
  firstDateOfAllTime,
  yestardayDateIsAbsent = false
) => {
  if (dgm === CONSTANT.DATE_GROUP_MODES.AGGREGATED) return [customDateStart];
  const dates = [];
  let endDate = new Date();
  endDate.setDate(endDate.getDate() - 1);
  let startDate = new Date();

  const yestarday = new Date();
  yestarday.setDate(yestarday.getDate() - 1);
  const yestardayStr = yestarday.toISOString().split("T")[0];

  // Adjust the start and end dates based on the rt parameter
  switch (rt) {
    case CONSTANT.DATE_RANGE_TYPES.CUSTOM.value: // Custom date range
      startDate = new Date(customDateStart);
      endDate = new Date(customDateEnd);
      break;
    case CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value: // All time date range
      if (firstDateOfAllTime) {
        startDate = new Date(firstDateOfAllTime);
      } else {
        startDate = new Date(customDateStart);
      }
      break;
    case CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value: // Last 30 days (approx. last month)
      startDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth() - 1, 1));
      endDate = new Date();
      endDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), 0));
      break;
    case CONSTANT.DATE_RANGE_TYPES.HOURLY.value: // Last 'timeSelection' hours
      startDate = new Date(Date.now() - timeSelection * 60 * 60 * 1000);
      // Adjust to the start of the hour
      startDate.setMinutes(0, 0, 0);
      break;
    default: // Last 'rt' days
      startDate = new Date(
        Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() - rt)
      );
  }

  let currentDate = startDate;
  while (currentDate <= endDate) {
    // Format date based on dgm and add it to the dates array
    const formattedDate = formatDate(currentDate, dgm, rt);
    dates.push(formattedDate);
    // Increment currentDate based on dgm
    currentDate = incrementDate(currentDate, dgm, rt);
  }

  if (yestardayDateIsAbsent && dates.includes(yestardayStr)) {
    dates.pop();
  }

  return dates;
};

const formatDate = (date, dgm, rt) => {
  if (dgm === CONSTANT.DATE_GROUP_MODES.HOUR) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return date.toISOString().split("T")[0];
  }
};

const incrementDate = (date, dgm, rt) => {
  switch (dgm) {
    case 1: // Daily
      return new Date(date.getTime() + 24 * 60 * 60 * 1000);
    case 2: // Monthly
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1));
    case 3: // Quarterly
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 3, 1));
    case 5: // Hourly
      return new Date(date.getTime() + 1 * 60 * 60 * 1000);
    default:
      return date;
  }
};

const processStatsToAreaChart = (
  stats,
  rt,
  currencyArray,
  customDateStart,
  customDateEnd,
  dgm,
  timeSelection,
  yestardayDateIsAbsent
) => {
  if (rt === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value || dgm === CONSTANT.DATE_GROUP_MODES.AGGREGATED) {
    customDateStart = getAllTimeFirstDate(stats);
  }

  const dateRange = generateDateRange(
    rt,
    customDateStart,
    customDateEnd,
    dgm,
    timeSelection,
    null,
    yestardayDateIsAbsent
  );
  const result = {
    revenue: {}, //
    revenueLiftNum: {},
    revenueLiftPer: {}, //
    cpm: {}, //
    cpmLiftNum: {}, //
    cpmLiftPer: {},
    fillRate: {},
    impressions: {}, //
  };

  const perPartnerResult = perPartnerCalculation(dateRange, currencyArray, stats);
  // Initialize objects for each date
  dateRange.forEach((date) => {
    result.revenue[date] = { date, submitted_date: date };
    result.revenueLiftNum[date] = { date, submitted_date: date };
    result.revenueLiftPer[date] = { date, submitted_date: date };
    result.cpm[date] = { date, submitted_date: date };
    result.cpmLiftNum[date] = { date, submitted_date: date };
    result.cpmLiftPer[date] = { date, submitted_date: date };
    result.fillRate[date] = { date, submitted_date: date };
    result.impressions[date] = { date, submitted_date: date };

    currencyArray.forEach((currency) => {
      result.revenue[date][currency] = 0;
      result.revenueLiftNum[date][currency] = 0;
      result.revenueLiftPer[date][currency] = 0;
      result.cpm[date][currency] = 0;
      result.cpmLiftNum[date][currency] = 0;
      result.cpmLiftPer[date][currency] = 0;
      result.fillRate[date][currency] = 0;
      result.impressions[date][currency] = 0;

      const currentDateStats = stats.filter((s) => s.submitted_date === date);
      let summary = getValues(currentDateStats, currencyArray);
      let { sum_avg_cpma, sum_avg_cpmb, sum_total_cpm1, sum_total_cpm2 } = calcAvgCpmToSummaryRow(
        summary,
        currencyArray
      );

      const additionalChartsCalculation = chartsAdditionalCalculation(currentDateStats, currencyArray);

      let sum_avg_cpmRateLift = calcAvgCpmRateLiftToSummaryRow(sum_avg_cpma, sum_avg_cpmb);
      let abfillRate = calcAbFillRateLiftToSummaryRow(summary);
      const { sum_abRevActualRateLift } = calcDeploymentRevLiftToSummaryRow(summary, currencyArray);

      const abRevLift = abRevLiftCalc(currentDateStats, currencyArray);

      result.revenue[date][currency] = additionalChartsCalculation[currency].revenueSum;
      result.revenueLiftNum[date][currency] = abRevLift[currency].value;
      result.revenueLiftPer[date][currency] = sum_abRevActualRateLift[currency].value || 0;
      result.cpm[date][currency] =
        (summary.total_cpm1[currency] * 1000) / summary.totalRows1ByCurrency[currency] || 0;
      result.cpmLiftPer[date][currency] = sum_avg_cpmRateLift[currency].value || 0;
      result.cpmLiftNum[date][currency] =
        (result.cpmLiftPer[date][currency] / 100) * (sum_avg_cpmb[currency].value || 0);
      result.fillRate[date][currency] = abfillRate;
      result.impressions[date][currency] =
        (summary.totalRows1ByCurrency[currency] || 0) + (summary.totalRows2ByCurrency[currency] || 0);
    });
  });

  const res = {
    summary: {
      revenue: Object.values(result.revenue),
      revenueLiftNum: Object.values(result.revenueLiftNum),
      revenueLiftPer: Object.values(result.revenueLiftPer),
      cpm: Object.values(result.cpm),
      cpmLiftNum: Object.values(result.cpmLiftNum),
      cpmLiftPer: Object.values(result.cpmLiftPer),
      fillRate: Object.values(result.fillRate),
      impressions: Object.values(result.impressions),
    },
    perPartner: perPartnerResult,
  };

  return res;
};

export const chartsAdditionalCalculation = (rows, currencyArray) => {
  let res = {};
  currencyArray.forEach((cur) => {
    const rowsWithCurrency = rows.filter((row) => row.currency === cur);
    const revenueSum = rowsWithCurrency.reduce(
      (sum, current) => sum + parseFloat(current.total_cpm1) + parseFloat(current.total_cpm2),
      0
    );
    res[cur] = {
      revenueSum,
    };
  });
  return res;
};

const perPartnerCalculation = (dateRange, currencyArray, stats) => {
  const result = {};
  const summaryValues = {};

  // Initialize the result structure
  for (let i = 0; i < stats.length; i++) {
    const { pName, partner_id } = stats[i];
    const partnerName = pName + CONSTANT.COMBINED_STRING_KEY + partner_id;
    if (!result[partnerName]) {
      result[partnerName] = {
        revenue: {},
        revenueLiftNum: {},
        revenueLiftPer: {},
        cpm: {},
        cpmLiftNum: {},
        cpmLiftPer: {},
        fillRate: {},
        impressions: {},
      };
      summaryValues[partnerName] = {
        totalRevenueA: {},
        totalRevenueB: {},
        totalRevenueBNorm: {},
        totalImpressionsA: {},
        totalImpressionsB: {},
        totalImpressionsBNorm: {},
      };
    }
    for (let j = 0; j < dateRange.length; j++) {
      initializeMetrics(result[partnerName], dateRange[j], currencyArray);
      initializeMetrics(summaryValues[partnerName], dateRange[j], currencyArray);
    }
  }

  // Process all stats in one loop
  for (let i = 0; i < stats.length; i++) {
    const {
      submitted_date,
      currency,
      pName,
      partner_id,
      total_rows1,
      total_rows2,
      total_cpm1,
      total_cpm2,
      total_cpm2Norm,
      abRevLift,
      total_rows2Norm,
    } = stats[i];
    const partnerName = pName + CONSTANT.COMBINED_STRING_KEY + partner_id;

    // Helper calculations
    const totalRevenueA = parseFloat(total_cpm1);
    const totalRevenueB = parseFloat(total_cpm2);
    const totalRevenueBNorm = parseFloat(total_cpm2Norm);
    const totalImpressionsA = parseInt(total_rows1);
    const totalImpressionsB = parseInt(total_rows2);
    const totalImpressionsBNorm = parseInt(total_rows2Norm);

    // Calculate metrics
    const impressions = parseInt(total_rows1) + parseInt(total_rows2);
    const revenue = parseFloat(total_cpm1) + parseFloat(total_cpm2);
    const revenueLiftNum = parseFloat(abRevLift);

    // Update per-partner metrics
    updateMetrics(result[partnerName], submitted_date, currency, {
      impressions,
      revenue,
      revenueLiftNum,
    });

    // Update summary values
    updateMetrics(summaryValues[partnerName], submitted_date, currency, {
      totalRevenueA,
      totalRevenueB,
      totalRevenueBNorm,
      totalImpressionsA,
      totalImpressionsB,
      totalImpressionsBNorm,
    });
  }

  // Calculate and fill result from summaryValues
  const partnerKeys = Object.keys(result);
  for (let i = 0; i < partnerKeys.length; i++) {
    const partner = partnerKeys[i];
    const dateKeys = Object.keys(result[partner].cpm);
    for (let j = 0; j < dateKeys.length; j++) {
      const date = dateKeys[j];
      for (let k = 0; k < currencyArray.length; k++) {
        const currency = currencyArray[k];
        // Get the corresponding totals from summaryValues
        const totalRevenueA = summaryValues[partner].totalRevenueA[date][currency] || 0;
        const totalRevenueB = summaryValues[partner].totalRevenueB[date][currency] || 0;
        const totalRevenueBNorm = summaryValues[partner].totalRevenueBNorm[date][currency] || 0;
        const totalImpressionsA = summaryValues[partner].totalImpressionsA[date][currency];
        const totalImpressionsB = summaryValues[partner].totalImpressionsB[date][currency];
        const totalImpressionsBNorm = summaryValues[partner].totalImpressionsBNorm[date][currency];

        // Calculate CPM
        const cpmValue = totalImpressionsA ? (totalRevenueA * 1000) / totalImpressionsA : totalRevenueA * 1000;
        const cpmValueB = totalImpressionsB ? (totalRevenueB * 1000) / totalImpressionsB : totalRevenueB * 1000;

        // Fill in the result object
        result[partner].revenueLiftPer[date][currency] = totalRevenueB
          ? ((totalRevenueA + totalRevenueB) / (totalRevenueBNorm + totalRevenueB) - 1) * 100
          : 0;
        result[partner].cpm[date][currency] = cpmValue;
        result[partner].cpmLiftPer[date][currency] = cpmValueB ? (cpmValue / cpmValueB - 1) * 100 : 0;
        result[partner].cpmLiftNum[date][currency] = cpmValueB ? cpmValue - cpmValueB : 0;
        result[partner].fillRate[date][currency] = { totalImpressionsA, totalImpressionsBNorm };
      }
    }
  }

  // Convert result objects to arrays
  const finalResult = {};
  const finalPartnerKeys = Object.keys(result);
  for (let i = 0; i < finalPartnerKeys.length; i++) {
    const partner = finalPartnerKeys[i];
    finalResult[partner] = convertToArrays(result[partner]);
  }

  return finalResult;
};

// Helper function to initialize metrics for a given date
const initializeMetrics = (obj, date, currencyArray) => {
  const metricKeys = Object.keys(obj);
  for (let i = 0; i < metricKeys.length; i++) {
    const metric = metricKeys[i];
    if (!obj[metric][date]) {
      obj[metric][date] = { date, submitted_date: date };
      for (let j = 0; j < currencyArray.length; j++) {
        const currency = currencyArray[j];
        obj[metric][date][currency] = 0;
      }
    }
  }
};

// Helper function to update metrics
const updateMetrics = (obj, date, currency, values) => {
  const metricKeys = Object.keys(values);
  for (let i = 0; i < metricKeys.length; i++) {
    const metric = metricKeys[i];
    if (obj[metric] && obj[metric][date] && obj[metric][date][currency] !== undefined) {
      obj[metric][date][currency] += values[metric];
    }
  }
};

// Helper function to convert objects to arrays
const convertToArrays = (obj) => {
  const result = {};
  const metricKeys = Object.keys(obj);
  for (let i = 0; i < metricKeys.length; i++) {
    const metric = metricKeys[i];
    result[metric] = Object.values(obj[metric]);
  }
  return result;
};

function transformColumnBaseToRowBase(data) {
  const headers = data[0];
  let transformed = [];
  for (let i = 1; i < data.length; i++) {
    let entry = {};
    for (let j = 0; j < headers.length; j++) {
      entry[headers[j]] = data[i][j];
    }
    transformed.push(entry);
  }
  return transformed;
}

function insertAsteriskValues(filtersStored, filters) {
  for (const [key, values] of Object.entries(filtersStored)) {
    for (const value of values) {
      if (value.id === "*") {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          filters[key] = [value];
        }
      }
    }
  }
  return filters;
}

const checkAvailableDgmByDateRange = (dateRange, dateGroupingMode, customDateRange) => {
  switch (dateRange.value) {
    case CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value: {
      if (
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH ||
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.AGGREGATED
      ) {
        return true;
      }
      break;
    }
    case CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value: {
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
        return true;
      }
      break;
    }
    case CONSTANT.DATE_RANGE_TYPES.MTD.value: {
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
        return true;
      }
      break;
    }
    case CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value: {
      return dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER;
    }
    case CONSTANT.DATE_RANGE_TYPES.CUSTOM.value: {
      const range = [{ startDate: customDateRange.customDateStart, endDate: customDateRange.customDateEnd }];
      return !validateSelection(dateGroupingMode, range).isValid;
    }
    default: {
      return false;
    }
  }
};

const arePartnersEqual = (partnerList1, partnerList2) => {
  const set1 = new Set(partnerList1.map((p) => p.dummy_id));
  const set2 = new Set(partnerList2.map((p) => p.dummy_id));
  const allInSet2 = [...set1].every((id) => set2.has(id));
  const allInSet1 = [...set2].every((id) => set1.has(id));
  return allInSet2 && allInSet1;
};

export {
  convertToWidgetAreaChartData,
  prepareTemplate,
  prepareTemplateMonthlyGrouped,
  prepareTemplateHours,
  generateDateToAreaChart,
  processStatsToAreaChart,
  transformColumnBaseToRowBase,
  insertAsteriskValues,
  checkAvailableDgmByDateRange,
  arePartnersEqual,
};
