import auth from "../auth/auth-helper";
import { postLog } from "../components/ErrorBoundary/backendLogApi";
import { CONSTANT } from "../constants/constants";
export const checkVersion = async (newVersion: string) => {
  const storedVersion = localStorage.getItem("reactAppVersion");
  if (+newVersion === CONSTANT.REACT_APP_VERSION_MAINTENANCE) {
    if (auth.isAuthenticated().data?.accessToken)
      postLog(
        { level: "error", message: "Redirecting user to maintenance page..." },
        auth.isAuthenticated().data.accessToken
      );

    window.location.href = "/maintenance";
  }
  if (!storedVersion || storedVersion === "undefined") {
    localStorage.setItem("reactAppVersion", newVersion);
  } else {
    if (storedVersion !== newVersion) {
      console.log("App version updated, reloading the page...");
      localStorage.setItem("reactAppVersion", newVersion);
      window.location.reload();
      auth.clearJWT(() => {});
    }
  }
};
