import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Area, Tooltip } from "recharts";
import SummaryLegend from "./SummaryLegend";
import { colors } from "../../../theme";
import { CustomChartIds } from "./../types";
import { formatXAxis } from "../utils";
import { CustomizedAxisTick } from "../../../components/chartTemplates/utils";
import CircularProgress from "@mui/material/CircularProgress";
import { EmptyData } from "../EmptyData";
import { CustomTooltip } from "../../performanceReports/advancedReport/reportCharts/areaChart/CustomAreaChartTooltips";

export interface ChartProps {
  chartColumnsData: { [key: string]: any };
  isLoading: boolean;
  customChartId?: CustomChartIds;
}

const SummaryPlot: React.FC<ChartProps> = ({ chartColumnsData, isLoading }) => {
    const { selectedCurrency: currency, apt } = useSelector((state: any) => state.report);
  const { dateGroupingMode, dateRange } = useSelector(
    (state: any) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const rowData = useMemo(() => {
    if (!chartColumnsData || !chartColumnsData.date) return [];
    const n = chartColumnsData.date.length;
    const data = [];
    for (let i = 0; i < n; i++) {
      data.push({
        date: chartColumnsData.date[i],
        total_cpm1: chartColumnsData.total_cpm1[i],
        total_cpm2Norm: chartColumnsData.total_cpm2Norm[i],
      });
    }
    return data;
  }, [chartColumnsData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%", padding: "16px" }}>
      <Typography variant="body1Small600">Summary</Typography>
      <SummaryLegend
        items={[
          { color: colors.peaceBlue, label: "Revenue with IIQ" },
          { color: colors.brandDefault, label: "Extrapolation revenue without IIQ" },
        ]}
      />
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 300 }}>
          <CircularProgress />
        </Box>
      ) : rowData.length === 0 ? (
        <EmptyData />
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            margin={{
              top: 15,
              right: 25,
              bottom: 5,
              left: 0,
            }}
            data={rowData}
          >
            <defs>
              <linearGradient id="gradientPeaceBlue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors.peaceBlue} stopOpacity={1} />
                <stop offset="100%" stopColor={colors.peaceBlue} stopOpacity={0.3} />
              </linearGradient>
              <linearGradient id="gradientBrandDefault" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors.brandDefault} stopOpacity={1} />
                <stop offset="100%" stopColor={colors.brandDefault} stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="" stroke={colors.navy50} />
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxis}
              stroke={colors.navy50}
              // tick={{ fill: colors.text02 }}
              tick={<CustomizedAxisTick dgm={dateGroupingMode} d={rowData} />}
            />
            <YAxis
              // tickFormatter={formatYAxis}
              stroke={colors.navy50}
              tick={<CustomizedAxisTick />}
              // tick={{ fill: colors.text02 }}
            />
            <Tooltip 
              content={
                <CustomTooltip
                  currency={currency}
                  apt={1} //TODO: fix for apt param
                  // apt={apt}
                  active_range_date={dateRange.value}
                />
              }
            />
            <Area
              type="linear"
              dataKey="total_cpm1"
              stroke={colors.peaceBlue}
              fill="url(#gradientPeaceBlue)"
              name="Revenue with IIQ"
              strokeWidth={2}
            />
            <Area
              type="linear"
              dataKey="total_cpm2Norm"
              stroke={colors.brandDefault}
              fill="url(#gradientBrandDefault)"
              name="Extrapolation revenue without IIQ"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default SummaryPlot;
