import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { convertLocal } from "../../utils";
import { getGridNumericOperators, getGridStringOperators } from "@mui/x-data-grid";
import { CONSTANT } from "../../constants/constants";
import FilterNumericInputBetween from "./tablesComponents/FilterNumericInpuntBetween";

export const tablePartnerMenuOptions = (titleItem: ReactNode) => {
  return {
    visiblePartners: {
      id: "visiblePartners",
      name: "visiblePartners",
      isSelected: true,
      titleItem: titleItem,
      menuItem: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
            width: "100%",
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12Z"
              fill="#101D41"
            />
          </svg>
          <Typography variant="body1Small400">Select visible partners</Typography>
        </Box>
      ),
    },
    thisPartner: {
      id: "thisPartner",
      name: "thisPartner",
      isSelected: true,
      titleItem: titleItem,
      menuItem: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
            width: "100%",
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M8 9C8.28333 9 8.52083 8.90417 8.7125 8.7125C8.90417 8.52083 9 8.28333 9 8C9 7.71667 8.90417 7.47917 8.7125 7.2875C8.52083 7.09583 8.28333 7 8 7C7.71667 7 7.47917 7.09583 7.2875 7.2875C7.09583 7.47917 7 7.71667 7 8C7 8.28333 7.09583 8.52083 7.2875 8.7125C7.47917 8.90417 7.71667 9 8 9ZM8 13C8.28333 13 8.52083 12.9042 8.7125 12.7125C8.90417 12.5208 9 12.2833 9 12C9 11.7167 8.90417 11.4792 8.7125 11.2875C8.52083 11.0958 8.28333 11 8 11C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13ZM8 17C8.28333 17 8.52083 16.9042 8.7125 16.7125C8.90417 16.5208 9 16.2833 9 16C9 15.7167 8.90417 15.4792 8.7125 15.2875C8.52083 15.0958 8.28333 15 8 15C7.71667 15 7.47917 15.0958 7.2875 15.2875C7.09583 15.4792 7 15.7167 7 16C7 16.2833 7.09583 16.5208 7.2875 16.7125C7.47917 16.9042 7.71667 17 8 17ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H15.175C15.4417 3 15.6958 3.05 15.9375 3.15C16.1792 3.25 16.3917 3.39167 16.575 3.575L20.425 7.425C20.6083 7.60833 20.75 7.82083 20.85 8.0625C20.95 8.30417 21 8.55833 21 8.825V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V9H16C15.7167 9 15.4792 8.90417 15.2875 8.7125C15.0958 8.52083 15 8.28333 15 8V5H5V19Z"
              fill="#101D41"
            />
          </svg>
          <Typography variant="body1Small400">Report for this partner</Typography>
        </Box>
      ),
    },
    partnerDatails: {
      id: "partnerDatails",
      name: "partnerDatails",
      isSelected: true,
      titleItem: titleItem,
      menuItem: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
            width: "100%",
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M9 18H15C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9042 17.5208 16 17.2833 16 17C16 16.7167 15.9042 16.4792 15.7125 16.2875C15.5208 16.0958 15.2833 16 15 16H9C8.71667 16 8.47917 16.0958 8.2875 16.2875C8.09583 16.4792 8 16.7167 8 17C8 17.2833 8.09583 17.5208 8.2875 17.7125C8.47917 17.9042 8.71667 18 9 18ZM9 14H15C15.2833 14 15.5208 13.9042 15.7125 13.7125C15.9042 13.5208 16 13.2833 16 13C16 12.7167 15.9042 12.4792 15.7125 12.2875C15.5208 12.0958 15.2833 12 15 12H9C8.71667 12 8.47917 12.0958 8.2875 12.2875C8.09583 12.4792 8 12.7167 8 13C8 13.2833 8.09583 13.5208 8.2875 13.7125C8.47917 13.9042 8.71667 14 9 14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 8V4H6V20H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8Z"
              fill="#101D41"
            />
          </svg>
          <Typography variant="body1Small400">Show partner details</Typography>
        </Box>
      ),
    },
  };
};

export const renderCurrencyStringInOrder = (cellValue: number) => {
  return (
    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
      {convertLocal(Number(cellValue).toFixed(2))}
    </Typography>
  );
};
export const renderMainTableCell = (cellValue: number, cellUnit: string) => {
  return (
    <Box
      title={Number(cellValue).toFixed(2)}
      sx={{
        display: "flex",
        justifyContent: "left",
      }}
    >
      {renderCurrencyStringInOrder(cellValue)}
    </Box>
  );
};
export const renderSecondaryTableCell = (cellValue: number, digitsFixed = 2) => {
  return (
    <Box
      title={Number(cellValue).toFixed(digitsFixed)}
      sx={{
        display: "flex",
        justifyContent: "left",
      }}
    >
      {convertLocal(Number(cellValue).toFixed(2))}
    </Box>
  );
};

export type CutomFilterType = "item" | "numeric";
export interface CustomFilterProps {
  type: CutomFilterType;
  field: string;
}
export const customFilter = (column: CustomFilterProps) => {
  if (column.type === "numeric") {
    const operator = getGridNumericOperators()[0];
    return [
      {
        ...operator,
        label: "Between",
        value: "between",
        requiresFilterValue: true,
        getApplyFilterFn: (filterItem: any) => {
          if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
            return null;
          }
          let [min, max] = filterItem.value;
          if (min != null && isNaN(Number(min))) {
            min = null;
          }
          if (max != null && isNaN(Number(max))) {
            max = null;
          }
          if (min == null && max == null) return null;
          return (value: any) => {
            const val = value.value;
            if (val == null) return false;
            if (min == null && max != null) return val <= max;
            if (max == null && min != null) return val >= min;
            if (min != null && max != null) return min <= val && val <= max;
            return true;
          };
        },
        InputComponent: FilterNumericInputBetween,
      },
    ];
  }
  return getGridStringOperators().map((operator) => {
    const newOperator = { ...operator };
    const newGetApplyFilterFn = (filterItem: any, column: any) => {
      return (params: any) => {
        const filterValue = filterItem?.value?.toLowerCase() ?? "";
        const cellValue = String(params.value ?? "").toLowerCase();
        const formattedValue = String(params.formattedValue ?? "").toLowerCase();

        // For the "partners" base, we can also check params.row.pid and params.row.dummy_id
        const isPartnersColumn = column.base === CONSTANT.REPORTS.PARTNERS.base;
        const partnerId = String(params.row?.pid ?? "").toLowerCase();
        const dummyId = String(params.row?.dummy_id ?? "").toLowerCase();

        switch (filterItem.operator.toLowerCase()) {
          case "equals": {
            if (!filterValue) return true;
            const baseEquals = cellValue === filterValue || formattedValue === filterValue;
            const partnerEquals = isPartnersColumn && (partnerId === filterValue || dummyId === filterValue);
            return baseEquals || partnerEquals;
          }

          case "startswith": {
            if (!filterValue) return true;
            const baseStarts = cellValue.startsWith(filterValue) || formattedValue.startsWith(filterValue);
            const partnerStarts =
              isPartnersColumn && (partnerId.startsWith(filterValue) || dummyId.startsWith(filterValue));
            return baseStarts || partnerStarts;
          }
          case "endswith": {
            if (!filterValue) return true;
            const baseEnds = cellValue.endsWith(filterValue) || formattedValue.endsWith(filterValue);
            const partnerEnds =
              isPartnersColumn && (partnerId.endsWith(filterValue) || dummyId.endsWith(filterValue));
            return baseEnds || partnerEnds;
          }
          case "isempty": {
            return !cellValue.trim() && !formattedValue.trim();
          }
          case "isnotempty": {
            return cellValue.trim() !== "" || formattedValue.trim() !== "";
          }
          case "isanyof": {
            const filterArray = Array.isArray(filterItem.value)
              ? filterItem.value.map((val: any) => val.toLowerCase())
              : [];
            if (!filterArray.length) return true;
            const baseAny = filterArray.includes(cellValue) || filterArray.includes(formattedValue);
            const partnerAny =
              isPartnersColumn && (filterArray.includes(partnerId) || filterArray.includes(dummyId));

            return baseAny || partnerAny;
          }
          case "contains": {
            if (!filterValue) return true;
            const baseContains = cellValue.includes(filterValue) || formattedValue.includes(filterValue);
            const partnerContains =
              isPartnersColumn && (partnerId.includes(filterValue) || dummyId.includes(filterValue));
            return baseContains || partnerContains;
          }
          default:
            throw new Error("Invalid operator");
        }
      };
    };
    newOperator.getApplyFilterFn = newGetApplyFilterFn;
    return newOperator;
  });
};
