import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { colors } from "../../theme";

// Shared style object for boxes
export const reusableBoxStyle = {
  width: "100%",
  borderRadius: "16px",
  padding: 1,
  borderWidth: 1,
  border: `1px solid ${colors.navy50}`,
  backgroundColor: colors.mainWhite,
};

// --- Styled Layout Components --- //

export const HeaderContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: "72px",
  zIndex: 1100,
  backgroundColor: colors.backgroundDefault,
}));

export const SidebarContainer = styled(Box)(({ theme }) => ({
  ...reusableBoxStyle,
  width: "306px",
  padding: "0px",
  height: "fit-content",
  flexShrink: 0,
  position: "sticky",
  top: "130px",
  zIndex: 1050,
}));

export const MainContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  justifyContent: "start",
  overflowX: "hidden",
}));

export const SummaryContainer = styled(Box)(({ theme }) => ({
  ...reusableBoxStyle,
}));

export const CustomChartContainer = styled(Box)(({ theme }) => ({
  ...reusableBoxStyle,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  overflowX: "hidden",
  padding: 0,
}));

export const CustomChartHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  borderBottom: `1px solid ${colors.navy50}`,
  padding: "16px",
}));
