import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../core/performanceReports/advancedReport/timeRangeSelection/custom-date-range.css";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { singleBtn, activeBtn, stylesWidget } from "../../core/overview/styles";
import { useSendRequests } from "../../core/overview/helpers/useSendRequests";
import { setDays, prepareDateFormat, prepareDateFormatNonUTC } from "../../utils";
import { WrapperMonthPicker } from "./WrapperMonthPicker";
import { MonthPicker } from "./MonthPicker";
import { handleReset, monthPickChange, getMaxSelectableDateWithPreviousPeriod } from "./monthPicker-utils";
import { CONSTANT } from "../../constants/constants";
import { validateSelection } from "./datePickerValidator";
import { setCurrentCommonParameter } from "../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { BasicTooltip } from "../BasicTooltip/BasicTooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  styleDatePicker,
  adaptStyleDatePicker,
  styleDatePickerFooter,
  styleDatePickerBtn,
  styleMonthPickerWrapper,
} from "../../core/performanceReports/advancedReport/timeRangeSelection/dateRangeStyle";

export const adjustForTimezone = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export default function DatePicker(props) {
  const matches = useMediaQuery("(min-width:1280px)");
  const { filterBase, summaryCompleteData } = useSelector((state) => state.report);
  const active_range_date = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value
  );
  const dateGroupingMode = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode
  );
  const customDateRange = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange ?? {
        customDateStart: null,
        customDateEnd: null,
      }
  );

  const defaultEndDate = new Date();
  const defaultStartDate = setDays(defaultEndDate, -2);
  const min = new Date(getMaxSelectableDateWithPreviousPeriod(CONSTANT.MAX_SELECTABLE_DATE).maxSelectableDate);

  min.setHours(0, 0, 0, 0);
  const max = new Date();
  max.setHours(0, 0, 0, 0);
  const contRef = useRef(null);
  const refModal = useRef(null);
  const selectInnerRef = useRef(dateGroupingMode);
  const selectSliceRef = useRef(dateGroupingMode);
  const warnRef = useRef(false);
  const [warnMessage, setWarnMessage] = useState("");
  const [showWarn, setShowWarn] = useState(false);
  const [dateGroupingModeState, setGroupingMode] = useState(dateGroupingMode);
  const [dateRange, setDateRange] = useState([
    {
      startDate: !!customDateRange.customDateStart
        ? adjustForTimezone(customDateRange.customDateStart)
        : defaultStartDate,
      endDate: !!customDateRange.customDateEnd ? adjustForTimezone(customDateRange.customDateEnd) : defaultEndDate,
      key: "selection",
    },
  ]);
  const [checked, setChecked] = useState(true);
  const [reset, setReset] = useState(true);
  const savedRawData = useSelector((state) => state.savedRawData[-1]);
  const dispatch = useDispatch();
  const [request] = useSendRequests();

  useEffect(() => {
    /**
     * close datepicker if clicked on outside of element (on blured bg)
     */

    const handleClickOutside = (event) => {
      if (warnRef.current) {
        setShowWarn(false);
        warnRef.current = false;
      }
      if (
        refModal.current &&
        (!refModal.current.contains(event.target) || !contRef.current.contains(event.target))
      ) {
        if (selectInnerRef.current !== selectSliceRef.current) setGroupingMode(selectSliceRef.current);
        props.setOpenDate(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModal]);

  useEffect(() => {
    selectInnerRef.current = dateGroupingMode;
    selectSliceRef.current = dateGroupingMode;
    setGroupingMode(dateGroupingMode);
  }, [dateGroupingMode]);

  useEffect(() => {
    if (!matches) {
      setChecked(true);
      props.setOpenDate(false);
    }
  }, [matches]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleApplyClick = () => {
    let { isValid, warnMessage } = validateSelection(dateGroupingModeState, dateRange);
    setWarnMessage(warnMessage);
    if (!isValid) {
      warnRef.current = true;
      setShowWarn(true);
      return;
    }
    props.setOpenDate(false);
    const { startDate, endDate } = dateRange[0];
    let customDateRange = {
      customDateStart: prepareDateFormatNonUTC(startDate),
      customDateEnd: prepareDateFormatNonUTC(endDate),
    };
    dispatch(
      setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", CONSTANT.DATE_RANGE_TYPES.CUSTOM))
    );
    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("customDateRange", customDateRange)));
  };

  return (
    <div className="lsItem" style={{ position: "relative" }}>
      {props.isOpen && (
        <div ref={contRef}>
          <div
            id="date-picker-container"
            style={{
              ...styleDatePicker,
              // ...adaptStyleDatePicker(checked, matches),
              // width: checked ? "38.43rem" : "62.5rem",
              backgroundColor: "#fff",
              right: "-30vw",
              height: "420px",
              width: "580px",
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
            }}
            ref={refModal}
          >
            <div id="wrapper-input-lable" style={{ display: "flex", flexWrap: "wrap", paddingTop: "2rem" }}>
              <div className="flex-item-left" style={{ flex: "50%", padding: "0 0 0.5rem 0.75rem" }}>
                Start date
              </div>
              <div className="flex-item-right" style={{ flex: "50%", padding: "0 0 0.5rem 0.75rem" }}>
                End date
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {/* working functionality, temp hidden */}
              {/* <div style={styleMonthPickerWrapper}>
                <MonthPicker
                  className="MonthPicker"
                  initialRange={{
                    // start: { year: 2023, month: 4 },
                    // end: { year: 2023, month: 6 }
                    start: {
                      year: null,
                      month: null,
                    },
                    end: {
                      year: null,
                      month: null,
                    },
                  }}
                  onChange={(selectedRange) => {
                    monthPickChange(selectedRange, setDateRange);
                  }}
                  onReset={reset}
                  darkMode={false}
                />
              </div> */}
              {/* <WrapperMonthPicker> */}
              <DateRange
                minDate={min}
                maxDate={max}
                editableDateInputs={true}
                onChange={(item) => {
                  const startDate = new Date(item.selection.startDate);
                  const endDate = new Date(item.selection.endDate);

                  if (startDate < min || startDate > max || endDate > max || endDate < min) {
                    setWarnMessage("Please, adjust selection range min: " + min + " max: " + max);
                    warnRef.current = true;
                    setShowWarn(true);
                    return false;
                  }
                  if (!warnMessage) {
                    setWarnMessage("");
                  }

                  // Valid range: the provided dates are within the allowed range.
                  handleReset(setReset);
                  setDateRange([item.selection]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                direction={"horizontal"}
                months={2}
                // months={checked ? 1 : 2}
              />
              {/* </WrapperMonthPicker> */}
            </div>

            <div
              style={{
                ...styleDatePickerFooter,
                borderRadius: "1rem",
                display: "flex",
                padding: "0px 16px 10px",
                justifyContent: "space-between",
              }}
            >
              {/* <Button
                id="date-picker-reset-month-btn"
                type="button"
                onClick={() => handleReset(setReset)}
                sx={{ marginRight: "2rem" }}
              >
                Reset months
              </Button> */}
              <Button
                id="date-picker-cancel-btn"
                type="button"
                onClick={() =>
                  setDateRange([
                    {
                      startDate: defaultEndDate,
                      endDate: defaultEndDate,
                      key: "selection",
                    },
                  ])
                }
                // onClick={() => props.setOpenDate(false)}
              >
                Clear
              </Button>
              <Button
                id="date-picker-apply-btn"
                variant="apply"
                type="button"
                onClick={handleApplyClick}
                disabled={!dateRange[0].startDate && !dateRange[0].endDate}
              >
                Apply
              </Button>

              {/* <BasicTooltip tooltipText={checked ? "Expand calendar" : "Show only one month"} delay={1500}>
                <Box>
                  <Checkbox
                    id="date-picker-expand-checkbox"
                    sx={stylesWidget.tickSize}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
              </BasicTooltip> */}
            </div>
            {showWarn ? (
              <Fade
                in={true}
                // style={{ transformOrigin: '0 0 0' }}
                timeout={1300}
              >
                <Box
                  sx={{
                    padding: "15px",
                    fontSize: "1rem",
                    color: "coral",
                    width: "75%",
                    background: "#fff",
                    borderRadius: "1rem",
                    boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {warnMessage}
                </Box>
              </Fade>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
