import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilterModelGen } from "../../advancedReportSlice";
//POC
export default function useFilterModel(res) {
  const dispatch = useDispatch();

  const { filterModelGen } = useSelector((state) => state.advancedReport);

  const handleColumnFilter = useCallback(
    (newModel) => {
      const updatedModel = {
        ...newModel,
        items: newModel.items.map((item) => ({ ...item })),
      };

      const newObject = newModel.items.reduce((acc, curr) => {
        acc.field = curr.field;
        acc.value = curr.value;
        acc.operator = curr.operator;
        return acc;
      }, {});

      if (
        newObject.operator === "between" &&
        (!newObject.value || (newObject.value && !newObject.value[0] && !newObject.value[1]))
      ) {
        newObject.value = null;
        const modifiedItemIndex = updatedModel.items.findIndex((item) => item.operator === "between");
        if (modifiedItemIndex !== -1) {
          updatedModel.items[modifiedItemIndex].value = null;
        }
      }
      const isInitModel = !Boolean(newObject.value) || !updatedModel.items.length;

      if (isInitModel) {
        dispatch(
          setFilterModelGen({
            model: updatedModel,
            terms: "",
          })
        );
        return;
      }

      let name = res.filter((i) => i.field === newObject.field)[0];
      let lcase = name.headerName.toLowerCase();
      if (name.headerClassName === "group-header-child" && "groupColumnName" in name) {
        lcase = `${name.groupColumnName} ${name.headerName}`.toLowerCase();
      }
      let terms = lcase + " " + newObject.operator + " " + newObject.value;
      if (newObject.operator === "between") {
        terms = `${lcase} ${newObject.value[0] ? ` from ${newObject.value[0]}` : ""} 
            ${newObject.value[1] ? ` to ${newObject.value[1]}` : ""}
            `;
      }
      dispatch(
        setFilterModelGen({
          model: updatedModel,
          terms,
        })
      );
    },
    [res, dispatch]
  );

  const resetColumnFilter = useCallback(() => {
    dispatch(
      setFilterModelGen({
        model: { items: [] },
        terms: "",
      })
    );
  }, [dispatch]);

  return {
    filterModel: filterModelGen.model,
    filterTerms: filterModelGen.terms,
    handleColumnFilter,
    resetColumnFilter,
  };
}
