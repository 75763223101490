import React from "react";
import { Box, TextField, Typography } from "@mui/material";

interface LabeledTextFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  id?: string;
  required?: boolean;
  startIcon?: React.ReactNode;
}

const LabeledTextField: React.FC<LabeledTextFieldProps> = ({
  label,
  value,
  onChange,
  placeholder = "Type here",
  type = "text",
  fullWidth = true,
  error = false,
  helperText = "",
  disabled = false,
  id,
  required = false,
  startIcon = <></>,
}) => {
  return (
    <Box sx={{}}>
      <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: "4px" }}>
        {label ?? ""}
        {required && (
          <Typography component="span" color="error">
            {" *"}
          </Typography>
        )}
      </Typography>
      <TextField
        value={value ?? ""}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        fullWidth={fullWidth}
        variant="outlined"
        error={error}
        helperText={helperText}
        disabled={disabled}
        FormHelperTextProps={{
          sx: {
            fontSize: "1rem",
            minHeight: "1.5em",
            lineHeight: "1.5em",
          },
        }}
        InputProps={{
          sx: {
            borderRadius: "8px",
            fontSize: "16px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "red" : "#E0E0E0",
            },
          },
          startAdornment: startIcon,
        }}
        id={id}
      />
    </Box>
  );
};

export default LabeledTextField;
