import CashIcon from "../../components/IconComponents/CashIcon";
import PercentIcon from "../../components/IconComponents/PercentIcon";
import { CONSTANT } from "../../constants/constants";

export interface StatsObserveResponse {
  entity: null | { name: string; ids: string[] };
  data: [StatsHeaders, ...(string | number)[][]];
  notification?: string;
  summaryWidgets?: ISummaryWidgetsResponse;
}
export interface Item {
  entity: string;
  isPreselected: boolean;
  totalCpm1: number;
  totalCpm2: number;
  totalCpm2Norm: number;
  totalRows1: number;
  totalRows2: number;
  color: string;
  [key: string]: any;
}

export interface AggregateCustomBaseDataOutput {
  filteredData: Record<string, any>;
  items: Item[];
  topEntities: string[];
}

export type StatsHeaders = [
  "client_type",
  "browser",
  "biddercode",
  "submitted_date",
  "pct",
  "abRevLift",
  "abRevActualRateLift",
  "abFillRateLift",
  "avgCpmRateLift",
  "avg_cpma",
  "avg_cpmb",
  "total_rows1",
  "total_rows2",
  "total_rows3",
  "currency",
  "total_cpm1",
  "total_cpm2"
];

export interface IWidgetMecric {
  currentPeriod: number;
  currentPeriodPercents?: number;
  previousPeriodChangePercents: number;
}
export interface ISummaryWidgetsResponse {
  [key: string]: IWidgetMecricForCurrencyPct;
}

export interface IUniquePctCurrencyCombinations {
  pct: number;
  currency: string;
  name: string;
  id: string;
  isSelected: boolean;
  totalImpressions?: number;
}

export interface IWidgetMecricForCurrencyPct {
  revenue: IWidgetMecric;
  revenueLift: IWidgetMecric;
  totalImpressions: IWidgetMecric;
  abFillRateLift: IWidgetMecric;
  cpmLift: IWidgetMecric;
}

export interface ICurrencyPctCombination {
  currency: string;
  pct: number;
  name: string;
}

export enum CustomChartIds {
  REVENUE_LIFT_SITE = "REVENUE_LIFT_SITE",
  REVENUE_LIFT_BIDDER = "REVENUE_LIFT_BIDDER",
  REVENUE_LIFT_BROWSER = "REVENUE_LIFT_BROWSER",
  CMP_LIFT_SITE = "CMP_LIFT_SITE",
  CMP_LIFT_BIDDER = "CMP_LIFT_BIDDER",
  CMP_LIFT_BROWSER = "CMP_LIFT_BROWSER",
  FILL_RATE_LIFT_SITE = "FILL_RATE_LIFT_SITE",
  FILL_RATE_LIFT_BIDDER = "FILL_RATE_LIFT_BIDDER",
  FILL_RATE_BROWSER = "FILL_RATE_BROWSER",
  IMPRESSIONS = "IMPRESSIONS",
}

export interface ICustomChart {
  icon?: React.ReactNode;
  title: string;
  base: string;
  metric: string;
  prefix: string | null;
  bar1Base?: string;
  bar2Base?: string;
  bar3Base?: string;
  lineChartVisible?: boolean;
  barChartVisible?: boolean;
}

export interface ICustomChartType {
  [key: string]: ICustomChart;
}

export interface ICustomChartItem {
  id: string;
  liftVisible?: boolean;
  abVisible?: boolean;
}

export const CustomChartTypes: ICustomChartType = {
  REVENUE_LIFT_SITE: {
    base: CONSTANT.OVERVIEW.CHART_BASES.SITE_URL,
    title: "Revenue Lift / Site",
    metric: "abRevLift",
    prefix: "$",
    icon: <CashIcon />,
    bar1Base: "totalCpm1",
    bar2Base: "totalCpm2",
    bar3Base: "totalCpm2Norm",
  },
  REVENUE_LIFT_BIDDER: {
    base: CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
    title: "Revenue Lift / Bidder",
    metric: "abRevLift",
    prefix: "$",
    icon: <CashIcon />,
    bar1Base: "totalCpm1",
    bar2Base: "totalCpm2",
    bar3Base: "totalCpm2Norm",
  },
  REVENUE_LIFT_BROWSER: {
    base: CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
    metric: "abRevLift",
    title: "Revenue Lift / Browser",
    prefix: "$",
    icon: <CashIcon />,
    bar1Base: "totalCpm1",
    bar2Base: "totalCpm2",
    bar3Base: "totalCpm2Norm",
  },
  CMP_LIFT_SITE: {
    base: CONSTANT.OVERVIEW.CHART_BASES.SITE_URL,
    title: "CPM Lift / Site",
    metric: "cpm_lift",
    prefix: "$",
    icon: <CashIcon />,
    bar1Base: "avgCpma",
    bar3Base: "avgCpmb",
  },
  CMP_LIFT_BIDDER: {
    base: CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
    title: "CPM Lift / Bidder",
    metric: "cpm_lift",
    prefix: "$",
    icon: <CashIcon />,
    bar1Base: "avgCpma",
    bar3Base: "avgCpmb",
  },
  CMP_LIFT_BROWSER: {
    base: CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
    metric: "cpm_lift",
    title: "CPM Lift / Browser",
    prefix: "$",
    icon: <CashIcon />,
    bar1Base: "avgCpma",
    bar3Base: "avgCpmb",
  },
  FILL_RATE_LIFT_SITE: {
    base: CONSTANT.OVERVIEW.CHART_BASES.SITE_URL,
    title: "Fill Rate Lift / Site",
    metric: "abFillRateLift",
    icon: <PercentIcon />,
    prefix: "%",
  },
  FILL_RATE_LIFT_BIDDER: {
    base: CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE,
    title: "Fill Rate Lift / Bidder",
    metric: "abFillRateLift",
    prefix: "%",
    icon: <PercentIcon />,
  },
  FILL_RATE_BROWSER: {
    base: CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE,
    metric: "abFillRateLift",
    title: "Fill Rate Lift / Browser",
    prefix: "%",
    icon: <PercentIcon />,
  },
  IMPRESSIONS: {
    prefix: null,
    base: CONSTANT.OVERVIEW.CHART_BASES.SUMMARY,
    metric: "total_rows1",
    title: "Impressions",
  },
};
