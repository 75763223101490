import { CONSTANT } from "../../../../../constants/constants";
import { Box, Divider, Typography } from "@mui/material";
import { convertLocal } from "../../../../../utils";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import { colors } from "../../../../../theme";
import { LINE_COLORS_CURRENCIES_MAP } from "./AreaChartWidget";

export const HoverTooltip = (
  // { active, payload, label, coordinate },
  { props, children }
) => {
  // const [ fixed, setFixed ] = useState({
  // 	x: 0,
  // 	y: 0,
  // });

  return (
    props.active &&
    props.payload &&
    props.payload.length && (
      <Box
        sx={{
          pointerEvents: "all",
          cursor: "pointer",
        }}
        onClick={() => {
          //   console.log(props);
          //   handleFixedTooltips({
          //     ...props,
          //     coordinate: {
          //       x: props.coordinate.x,
          //       y: 500,
          //     },
          //   });
        }}
      >
        {children}
      </Box>
    )
  );
};

// In your chart component
export const CustomTooltipPerPartner = (props) => {
  let { payload, apt, activeAreaChartType, dgm, chartColors, isPresentationModeEnabled } = props;
  const isImpressions = activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id;
  const isFillRate = activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id;

  if (payload && payload.length) {
    const date = payload[0].payload.date;
    let formattedDate =
      dgm === CONSTANT.DATE_GROUP_MODES.HOUR ? moment(date).format("DD.MM-HH:mm") : moment(date).format("DD/MM");

    // Group payload by partner
    const groupedPayload = payload.reduce((acc, entry) => {
      const partner = isPresentationModeEnabled
        ? entry.dataKey.split(CONSTANT.COMBINED_STRING_KEY)[1].split(CONSTANT.COMBINED_STRING_KEY2)[0]
        : entry.dataKey.split(CONSTANT.COMBINED_STRING_KEY)[0];
      const currency = isImpressions || isFillRate ? null : entry.dataKey.split(CONSTANT.COMBINED_STRING_KEY2)[1];

      if (!acc[partner]) {
        acc[partner] = [];
      }
      acc[partner].push({ ...entry, currency });
      return acc;
    }, {});

    return (
      <Box
        id="area-chart-tooltip"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.mainWhite,
          gap: 0.5,
          borderRadius: "8px",
          color: "#00385D",
          padding: "5px 0",
          maxHeight: "200px",
          boxShadow: "0px 12px 12px 0px rgba(16, 29, 65, 0.10)",
        }}
      >
        <Typography
          id="area-chart-tooltip-date"
          variant="body3XXSmall"
          sx={{ textAlign: "left", fontWeight: 600, padding: "5px 0 0 10px" }}
        >
          {formattedDate}
        </Typography>
        <Divider variant="fullWidth" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0 10px",
            backgroundColor: "#fff",
            minWidth: "170px",
            overflow: "auto",
            maxHeight: "170px",
          }}
        >
          {Object.entries(groupedPayload).map(([partner, entries], partnerIndex) => (
            <Box
              id="area-chart-tooltip-item"
              key={`partner_${partnerIndex}`}
              sx={{
                display: "flex",
                fontSize: "12px",
                marginBottom: "5px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "space-between",
                textAlign: "center",
                gap: "30px",
              }}
            >
              <Typography
                id="area-chart-tooltip-item-name"
                variant="body3XXSmall600"
                sx={{
                  marginRight: "5px",
                  textAlign: "left",
                }}
              >
                {partner}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {entries.map((entry, entryIndex) => (
                  <Box
                    key={`entry_${entryIndex}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      background: colors.backgroundBaseTertiary,
                      gap: "4px",
                      borderRadius: "8px",
                      padding: "0 4px",
                      // marginBottom: "2px",
                    }}
                  >
                    <Typography
                      id="area-chart-tooltip-item-sign"
                      variant="body3XXSmall"
                      sx={{ marginRight: "2px" }}
                    >
                      {entry.currency && `${getSymbolFromCurrency(entry.currency) || entry.currency}`}
                    </Typography>

                    <Typography id="area-chart-tooltip-item-value" variant="body3XXSmall600">
                      {apt
                        ? `${convertLocal(entry.value.toFixed(2))} ${
                            isImpressions ? "" : isFillRate || props.currency === "%" ? "%" : ""
                          }`
                        : null}
                    </Typography>
                    <Typography
                      id="area-chart-tooltip-item-icon"
                      variant="body3XXSmall"
                      sx={{
                        color: chartColors[activeAreaChartType][entry.dataKey],
                        marginRight: "2px",
                        paddingBottom: "2px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "6px", // Adjust this value to change dot size
                        textAlign: "center",
                      }}
                    >
                      &#11044;
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};
export const CustomTooltip = (props) => {
  let { payload, apt } = props;
  if (
    props.activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id ||
    props.activeAreaChartType === CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id
  )
    payload = [payload[0]];
  if (payload && payload.length) {
    const date = payload[0].payload.date;
    let formattedDate;
    if (props.dgm === CONSTANT.DATE_GROUP_MODES.HOUR) formattedDate = moment(date).format("DD.MM-HH:mm");
    else formattedDate = moment(date).format("DD/MM");

    return (
      <Box
        id="area-chart-tooltip"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          //width: "100%",
          gap: 0.5,
          borderRadius: "8px",
          border: `1px solid ${colors.borderDefault}`,
          color: "#00385D",
          padding: "5px 0",
          maxHeight: "200px",
        }}
      >
        <Typography
          id="area-chart-tooltip-date"
          variant="body3XXSmall"
          sx={{ textAlign: "left", fontWeight: 600, padding: "10px 0 0 10px" }}
        >
          {formattedDate}
        </Typography>
        <Divider variant="fullWidth" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0 10px",
            backgroundColor: "#fff",
            minWidth: "170px",
            maxHeight: "170px",
            flexFlow: "column",
          }}
        >
          {payload.map((entry, index) => {
            return (
              <Box
                id="area-chart-tooltip-item"
                key={"chart_key_" + index}
                variant="body3XXSmall"
                sx={{ display: "flex", fontSize: "12px", justifyContent: "space-between" }}
              >
                {props.activeAreaChartType !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.FILL_RATE.id &&
                  props.activeAreaChartType !== CONSTANT.ADVANCED_REPORT.AREA_CHART_TYPES.IMPRESSIONS.id && (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        id="area-chart-tooltip-item-icon"
                        variant="body3XXSmall"
                        sx={{
                          textAlign: "left",
                          color: Boolean(LINE_COLORS_CURRENCIES_MAP[entry.name])
                            ? LINE_COLORS_CURRENCIES_MAP[entry.name]
                            : entry?.color,
                          marginRight: "5px",
                        }}
                      >
                        &#11044;
                      </Typography>
                      <Typography
                        id="area-chart-tooltip-item-name"
                        variant="body3XXSmall600"
                        sx={{
                          textAlign: "left",
                          marginRight: "5px",
                        }}
                      >
                        {apt ? `${getSymbolFromCurrency(entry.name) || entry.name}` : null}
                      </Typography>
                    </Box>
                  )}
                <Typography
                  id="tooltip-item-value"
                  variant="body3XXSmall600"
                  sx={{
                    marginRight: "5px",
                  }}
                >
                  {apt ? `${convertLocal(entry.value.toFixed(2))} ${props.currency === "%" ? "%" : ""}` : null}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
  return null;
};
