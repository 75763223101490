import { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridColumnMenu, GridToolbarColumnsButton } from "@mui/x-data-grid";
import { CustomPagination } from "./pagination/CustomPagination";
import { mainTableStyle, columnTablePanelStyle } from "./mainTableStyle";
import CheckboxWrapper from "../../../../components/CustomCheckbox/Checkbox";
import { CustomSummary } from "./CustomSummary";
import ExportMenu from "./exportCsv/ExportMenu";
import { singleBtn } from "../styles";
import ColumnsIcon from "../../../../components/IconComponents/ColumnsIcon";
import { columnGroupingModel } from "./tableColumnsModel";
import CustomGridColumnsPanel from "./customGridColumnsPanel/CustomGridColumnsPanel";
import NotificationBadge from "./sortingNotification/NotificationBadge";
import { tablesRowsAmountPerPageOptions } from "../../../../parametersConstancy/parametersConstancyTypes";
import { setCurrentPlatformParameter } from "../../../../parametersConstancy/platformParametersSlice";
import { setParametersConstancyWrapper } from "../../../../parametersConstancy/parametersConstancyUtils";
import { CONSTANT } from "../../../../constants/constants";
import { colors } from "../../../../theme";

export function CustomColumnMenuComponent(props) {
  const { tableRef, setFilterButtonEl, hideMenu, colDef, color, ...other } = props;
  useEffect(() => {
    if (tableRef?.current && colDef.field) {
      const anchorEl = tableRef.current.querySelector(`[role="columnheader"][data-field="${colDef.field}"]`);
      setFilterButtonEl(anchorEl);
    }
  }, [colDef.field]);
  return (
    <GridColumnMenu
      {...other}
      sx={{
        color: colors.mainDarkPrimary100,
        fontSize: "14px",
        boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        "& svg": {
          fill: colors.primaryCta100,
        },
      }}
      // ref={setFilterButtonEl}
      hideMenu={hideMenu}
      colDef={colDef}
    />
  );
}

function CustomToolbar(props) {
  let { setFilterButtonEl, notificationSortingBadge, ...rest } = props;
  const { filters } = useSelector((state) => state.advancedReport);
  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)])
  );
  const selectedQuickAction = useSelector((state) => state.advancedReport.selectedQuickAction);

  return (
    <GridToolbarContainer {...rest} id="table-toolbar">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <GridToolbarColumnsButton
            startIcon={null}
            sx={{
              ...singleBtn,
            }}
            endIcon={<ColumnsIcon />}
            onClick={(e) => {
              setFilterButtonEl(e.currentTarget);
            }}
            ref={setFilterButtonEl}
            id="table-columns-button"
          />
          <ExportMenu activeFilters={activeFilters} hCols={props.hc} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
          {selectedQuickAction && (
            <NotificationBadge
              selectedCellName={selectedQuickAction.settings.description}
              filterTerms={""}
              resetSort={null}
              resetColumnFilter={null}
            />
          )}
          {notificationSortingBadge}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}

function MainTable1(props, ref) {
  const dispatch = useDispatch();
  let {
    columnsModel,
    filterModel,
    filterTerms,
    resetColumnFilter,
    sortModel,
    handleSort,
    resetSort,
    selectedCell,
    selectedCellName,
    handleColumnFilter,
    hiddenColumns: HC,
    setHC,
    paginationDisabled,
    pct,
    currency,
  } = props;
  const { mainTableUtils } = useSelector((state) => state.advancedReport);
  const columnVisibilityModel = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.performanceReportParameters.columnVisibilityModel ?? {}
  );
  const selectedQuickAction = useSelector((state) => state.advancedReport.selectedQuickAction);
  const highlightColumsByQuickAction =
    CONSTANT.ADVANCED_REPORT.MAP_AREA_CHART_TYPES_TABLE_COLUMNS_FIELDS[
      selectedQuickAction?.settings.parametersId
    ] ?? [];

  let { rows2, summaryRows } = mainTableUtils;

  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState(
    Object.keys(columnVisibilityModel).length > 0 ? columnVisibilityModel : HC
  );
  const tableRef = useRef(true);
  const initialRender = useRef(true);

  useEffect(() => {
    return () => {
      saveToSliceHiddenColumnsModel();
    };
  }, [hiddenColumns]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    return () => {};
  }, [pct, currency, hiddenColumns]);

  const saveToSliceHiddenColumnsModel = () => {
    setHC(hiddenColumns);
  };

  function CustomFooter(p) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", position: "relative" }}>
        {!paginationDisabled && (
          <CustomPagination rows={rows2} rowsPerPageOptions={tablesRowsAmountPerPageOptions} />
        )}
      </Box>
    );
  }

  const notificationSortingBadge = (
    <NotificationBadge
      selectedCellName={selectedCellName}
      filterTerms={filterTerms}
      resetSort={resetSort}
      resetColumnFilter={resetColumnFilter}
    />
  );

  const patchedColumns = columnsModel.map((col) => {
    if (col.headerClassName === "group-header-child") {
      col.renderHeader = () => <Typography variant="body2XSmallMain400">{col.headerName}</Typography>;
    }
    return {
      ...col,
      headerName:
        col.headerClassName === "group-header-child" && "groupColumnName" in col
          ? `${col.groupColumnName} ${col.headerName}`
          : col.headerName,
    };
  });

  return (
    <Box>
      <Box
        id="main-table-container"
        sx={{
          background: colors.mainWhite,
          borderRight: `1px solid ${colors.mainDarkPrimary10}`,
          borderLeft: `1px solid ${colors.mainDarkPrimary10}`,
          borderBottom: `1px solid ${colors.mainDarkPrimary10}`,
          boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <Box
          sx={{
            ...mainTableStyle("baseline", true),
            height: "auto",
            position: "relative",
          }}
        >
          <DataGrid
            //onRowClick={handleRowClick}
            // autoHeight={rows2.length < 25}
            ref={tableRef}
            autoHeight={true}
            rowHeight={36}
            rows={rows2}
            // sx={{ display: "flex", flexDirection: "column-reverse" }}
            columns={patchedColumns} //represent column name and key to use
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
            }}
            // sortingOrder={["desc", "asc"]}
            sortModel={sortModel}
            onSortModelChange={handleSort}
            getCellClassName={(params) => {
              if (selectedCell || highlightColumsByQuickAction.length) {
                return (selectedCell && selectedCell.field === params.field) ||
                  highlightColumsByQuickAction.includes(params.field)
                  ? "highlight-cell"
                  : "highlight-cell-b";
              } else {
                return "";
              }
            }}
            filterModel={filterModel}
            onFilterModelChange={handleColumnFilter}
            initialState={{
              columns: {
                // dont show columns which have been hidden by user, the other columns will remain visible
                columnVisibilityModel: {
                  ...(Object.keys(columnVisibilityModel).length > 0 ? columnVisibilityModel : HC),
                },
              },
              sorting: {
                sortModel: [{ field: "submitted_date", sort: "desc" }],
              },

              // pagination: paginationDisabled
              //   ? paginationDisabled
              //   : {
              //       paginationModel: {
              //         pageSize: tablesRowsAmountPerPageOptions[0],
              //       },
              //     },
            }}
            onStateChange={(state) => {
              let hiddenColumns1 = state.columns.columnVisibilityModel;
              // if (Object.keys(hiddenColumns1).length > 0) {
              setHiddenColumns(hiddenColumns1);
              ref.current = hiddenColumns1;
              // }
            }}
            slots={{
              columnMenu: CustomColumnMenuComponent,
              baseCheckbox: CheckboxWrapper,
              toolbar: CustomToolbar,
              footer: CustomFooter,
              loadingOverlay: (props) => <CustomSummary {...props} columnsModel={columnsModel} />,
              columnsPanel: CustomGridColumnsPanel,
            }}
            loading
            slotProps={{
              baseIconButton: {
                title: "",
              },
              columnMenu: {
                sx: {
                  background: colors.mainWhite,
                },
                setFilterButtonEl,
                tableRef,
              },
              panel:
                filterButtonEl instanceof HTMLElement
                  ? {
                      anchorEl: filterButtonEl,
                      placement: "bottom-start",
                      sx: columnTablePanelStyle,
                      disablePortal: false,
                    }
                  : undefined,
              footer: { summaryRowsT: summaryRows, hc: hiddenColumns },
              loadingOverlay: {
                summaryRowsT: summaryRows,
                hc: hiddenColumns,
              },
              toolbar: {
                colmod: columnsModel,
                setFilterButtonEl,
                hc: hiddenColumns,
                notificationSortingBadge: notificationSortingBadge,
              },
              columnsPanel: {},
              filterPanel: {
                sx: {
                  "& .MuiDataGrid-filterForm": {
                    flexDirection: "column",
                    "&:has(.MuiDataGrid-filterFormValueInput .number-filter-input) .MuiDataGrid-filterFormOperatorInput":
                      {
                        display: "none !important",
                      },
                  },
                  "& .MuiFormControl-root": {
                    width: "300px !important",
                    "& .MuiFormLabel-root": { color: "#108be2 !important" },
                  },
                  "& .MuiDataGrid-filterFormDeleteIcon": { display: "none", alignItems: "end" },
                  "& .MuiIconButton-root": { width: "30px !important" },
                  // "& .MuiDataGrid-filterFormColumnInput": { display: "none !important" }, //hide "columns" input
                },
              },

              basePopper: {
                sx: {
                  "& .MuiPaper-root": {
                    borderRadius: "16px",
                    boxShadow: "1px 30px 30px rgb(0, 0, 0,0.25 )",
                  },
                },
              },
            }}
            onColumnVisibilityModelChange={(props) => {
              dispatch(
                setCurrentPlatformParameter(
                  setParametersConstancyWrapper("performanceReportParameters", "columnVisibilityModel", props)
                )
              );
            }}
            hideFooterPagination={false}
            checkboxSelection={false}
            disableRowSelectionOnClick
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
          />
        </Box>
      </Box>
    </Box>
  );
}

const MainTable = forwardRef(MainTable1);
export default MainTable;
