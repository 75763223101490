import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { CONSTANT } from "../constants/constants";
import { RootState } from "../store/store";
import { checkVersion } from "../infrastructure/infrastructureHelpers";
import { saveFile } from "../utils";
import { colors } from "../theme";
import { createSelector } from "@reduxjs/toolkit";

export enum AgentTags {
  GA = "GA",
  POC = "POC",
}
export interface AgentTagColors {
  [tagType: string]: {
    background: string;
    textColor: string;
  };
}
export const agentTagColors: AgentTagColors = {
  GA: {
    background: colors.purpleLight,
    textColor: colors.purpleDark,
  },
  POC: {
    background: "#FBE7ED",
    textColor: "#5B0621",
  },
};
export interface DownloadAgentParams {
  version: number | string;
  path: string;
  fileName: string;
}

export const JSAgentUnversalAgentPath = "UniversalID";

export enum JSAgentTypes {
  Universal = "Universal",
  Obfuscated = "Obfuscated",
  Sync = "Sync",
  ES6Minified = "ES6Minified",
  ES6Unminified = "ES6Unminified",
  ES5Minified = "ES5Minified",
  ES5Unminified = "ES5Unminified",
}

export const AGENT_FILENAMES = { new: "IIQAgent.js", old: "IIQUniversalID.js" };

export interface JSAgentFile {
  path: string;
  fileName: string;
}

export interface JSAgent {
  version: number;
  tag?: string;
  usagesCount: number;
  releaseNotes?: string;
  releaseDate?: string;
  files: JSAgentFile[];
}
export interface JSAgentStatsDataType {
  partnerId: number;
  partnerName: string;
  impressions: number;
}
export interface JSAgentStatsRequestDto {
  version: number | string;
}

const SERVER_URL = CONSTANT.path.host;

export const handleDownload = async (
  params: DownloadAgentParams,
  token: string,
  setDownloadResult: React.Dispatch<React.SetStateAction<{ type: number; message: string } | null>>
) => {
  const { version, path } = params;

  try {
    // Use fetch to get the file from the server
    const response = await fetch(`${SERVER_URL}/agents/download/${version}?path=${path}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      setDownloadResult({ type: 0, message: "Failed to download file" });
      throw new Error("Failed to download file");
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Generate the file name based on params
    const fileName = params.fileName;

    // Save the file
    saveFile(blob, fileName);
    setDownloadResult({ type: 1, message: "File downloaded successfully" });
  } catch (error) {
    setDownloadResult({ type: 0, message: "Failed to download file" });
    console.error("Error downloading file:", error);
  }
};
const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const { getState } = api;
  const state = getState() as RootState;
  const token = state.users.loggedUser.token;
  if (!token) {
    return {
      error: {
        status: 401,
        data: { message: "No token available for authentication." },
      },
    } as { error: FetchBaseQueryError };
  }
  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/agents`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  });
  const result: any = await baseQuery(args, api, extraOptions);
  if (result.data && "reactAppVersion" in result.data) {
    checkVersion((result.data as any).reactAppVersion);
  }
  return result;
};

export const jsAgentsApi = createApi({
  reducerPath: "jsAgentsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["JSAgents", "JSAgentStats"],
  endpoints: (builder) => ({
    getJSAgents: builder.query<JSAgent[], void>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: ["JSAgents"],
    }),
    getJSAgentStats: builder.mutation<JSAgentStatsDataType[], JSAgentStatsRequestDto>({
      query: (requestDto) => ({
        url: `/${requestDto.version}`,
        method: "GET",
      }),
      invalidatesTags: ["JSAgentStats"],
    }),
  }),
});
const selectJSAgentsResult = jsAgentsApi.endpoints.getJSAgents.select();
export const selectJSAgentById = createSelector(
  [selectJSAgentsResult, (state, id: number | string) => id],
  (jsAgentsResult, id) => {
    return jsAgentsResult?.data?.find((agent) => agent.version === id);
  }
);

export const { useGetJSAgentsQuery, useGetJSAgentStatsMutation } = jsAgentsApi;
