import React, { useState } from "react";
import { colors } from "../../theme";
import { Box, MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";
import ExpandLessIcon from "../IconComponents/ExpandLessIcon";
import ExpandMoreIcon from "../IconComponents/ExpandMoreIcon";
import { IUniversalSelectItem } from "../../quickActions/quickActionsUtils";
import CheckboxWrapper from "../CustomCheckbox/Checkbox";

export type UniversalSelectSize = "small" | "medium" | "large";

export interface UniversalSelectProps {
  items: IUniversalSelectItem[];
  onChange: (event: SelectChangeEvent<string[] | string>) => void;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  value: string[] | string;
  multiSelect?: boolean;
  selectSx?: SxProps;
  menuSx?: SxProps;
  size?: UniversalSelectSize;
  displayEmpty?: boolean;
  isCustomArrow?: boolean;
  /**
   * Optionally override the default renderValue function.
   * This function receives the selected value(s) and must return a React node.
   */
  renderValue?: (selectedValue: string | string[]) => React.ReactNode;
}

const IconComponent = ({
  selectSx,
  open,
  toggleOpen,
}: {
  selectSx: SxProps | null;
  open: boolean;
  toggleOpen: () => void;
}) => {
  const iconColor = (selectSx as any)?.color ?? colors.text01;
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={(e) => {
        e.stopPropagation(); // prevent default MUI toggle
        toggleOpen();
      }}
    >
      {open ? <ExpandLessIcon color={iconColor} /> : <ExpandMoreIcon color={iconColor} />}
    </Box>
  );
};

const UniversalSelect: React.FC<UniversalSelectProps> = ({
  items,
  onChange,
  placeholder = "",
  disabled = false,
  id = "universal-select",
  value,
  multiSelect = false,
  size = "medium",
  displayEmpty = false,
  selectSx,
  menuSx,
  isCustomArrow = true,
  renderValue, // optional prop to override default renderValue
}) => {
  const [open, setOpen] = useState(false);

  let selectHeight = "40px";
  let selectPadding = `8px ${isCustomArrow && !disabled ? "12px" : "16px"} 8px 16px`;

  if (size === "small") {
    selectHeight = "32px";
    selectPadding = `4px ${isCustomArrow && !disabled ? "12px" : "16px"} 4px 16px`;
  } else if (size === "large") {
    selectHeight = "48px";
    selectPadding = `12px ${isCustomArrow && !disabled ? "12px" : "16px"} 12px 16px`;
  }

  // Default renderValue function.
  const defaultRenderValue = (selectedValue: string | string[]): React.ReactNode => {
    if (!multiSelect) {
      const selectedId = `${selectedValue}`;
      const item = items.find((i) => `${i.id}` === selectedId);
      return item && item.id !== -1 ? item.titleItem : placeholder;
    } else {
      const selectedIds = Array.isArray(selectedValue) ? selectedValue : [];
      const selectedItems = items.filter((item) => selectedIds.includes(`${item.id}`));
      const itemsLimit = 1;
      if (selectedItems.length === 0) {
        return placeholder;
      }
      if (selectedItems.length === itemsLimit) {
        return selectedItems[0].titleItem;
      }
      // if (selectedItems.length === 2) {
      //   return (
      //     <>
      //       {selectedItems[0].titleItem}, {selectedItems[1].titleItem}
      //     </>
      //   );
      // }
      if (selectedItems.length > itemsLimit) {
        return (
          <>
            {selectedItems[0].titleItem} + {selectedItems.length - itemsLimit}
          </>
        );
      }
    }
    return placeholder;
  };

  function MyIconWrapper() {
    return !disabled ? (
      <IconComponent
        selectSx={selectSx ?? null}
        open={open}
        toggleOpen={() => {
          setOpen(!open);
        }}
      />
    ) : null;
  }

  // Use provided renderValue prop if available; otherwise fallback to default.
  const finalRenderValue = renderValue || defaultRenderValue;

  return (
    <Select
      placeholder={placeholder}
      id={id}
      multiple={multiSelect}
      disabled={disabled}
      displayEmpty={displayEmpty}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(event: any) => {
        // For multi-select, remain open if user clicks a MenuItem (LI); otherwise close.
        if (event.target.tagName !== "LI" || !multiSelect) {
          setOpen(false);
        }
      }}
      value={value ?? ""}
      onChange={(event) => {
        onChange(event);
        if (!multiSelect) {
          setOpen(false);
        } else {
          event.preventDefault();
        }
      }}
      renderValue={finalRenderValue}
      IconComponent={isCustomArrow || disabled ? MyIconWrapper : undefined}
      sx={{
        borderRadius: "24px",
        background: colors.navy50,
        display: "flex",
        padding: selectPadding,
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        height: selectHeight,
        gap: "8px",
        "&.Mui-focused": {
          background: colors.navy100,
        },
        "&:focus-within": {
          background: colors.navy100,
        },
        "& .MuiSelect-select": {
          paddingRight: isCustomArrow ? "0 !important" : "none",
          border: "none",
        },
        "& .MuiOutlinedInput-input": {
          padding: 0,
          border: "none",
          display: "flex",
          justifyContent: "center",
        },
        "&.MuiInputBase-input": {
          padding: 0,
          border: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ...selectSx,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        slotProps: {
          paper: {
            sx: {
              marginTop: "8px",
              border: `1px solid ${colors.borderDefault}`,
              borderRadius: "24px",
              boxShadow: "0 40px 40px 0px rgba(16, 29, 65, 0.10)",
              background: colors.mainWhite,
              padding: "8px",
              ...menuSx,
            },
          },
        },
        MenuListProps: {
          sx: {
            padding: 0,
          },
        },
      }}
    >
      {items.map((item) => {
        const isSelected = Array.isArray(value) ? value.includes(`${item.id}`) : value === `${item.id}`;
        const backGround = isSelected ? colors.backgroundBaseTertiary : colors.mainWhite;
        return (
          <MenuItem
            key={item.id}
            value={item.id}
            sx={{
              padding: "0 12px 0 0",
              borderRadius: "8px",
              "&.MuiMenuItem-root:focus": {
                backgroundColor: backGround,
              },
              "&.Mui-selected": {
                backgroundColor: backGround,
              },
              "&.MuiMenuItem-root:hover": {
                backgroundColor: colors.backgroundBaseTertiary,
              },
              ...menuSx,
            }}
          >
            <Box
              id="dropdown-item"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "8px",
                gap: "12px",
                borderRadius: "24px",
                margin: "0",
                textAlign: "left",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {multiSelect && <CheckboxWrapper id="dropdown-item-checkbox" checked={isSelected} />}
              {item.menuItem}
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default UniversalSelect;
