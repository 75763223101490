import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { columnTablePanelStyle, mainTableStyle } from "../advancedReport/table/mainTableStyle";
import { RootState } from "../../../store/store";
import { VRTableProps, defaultVRColumns, optionalVRColumns } from "./tableColumnsModel";
import { visitortRecognitionCategoriesFilters } from "../advancedReport/advancedReportSlice";
import { ActiveFiltersType, tablePartnerMenuOptionsActionType } from "../types";
import { CONSTANT } from "../../../constants/constants";
import { colors as COLORS, colors } from "../../../theme";
import { CustomPagination } from "../advancedReport/table/pagination/CustomPagination";
import { useState } from "react";
import { convertLocal, nFormatter } from "../../../utils";
import { BasicTooltip } from "../../../components/BasicTooltip/BasicTooltip";
import ConditionalWrapper from "../../../components/chartTemplates/chartHelpers/ConditionalWrapper";
import { EllipsisCell } from "../advancedReport/table/CustomSummary";
import ColumnsIcon from "../../../components/IconComponents/ColumnsIcon";
import { singleBtn } from "../advancedReport/styles";
import ExportReportIcon from "../../../components/IconComponents/ExportReportIcon";
import { CategoryType } from "../../../parametersConstancy/parametersConstancyTypes";
import { setCurrentPlatformParameter } from "../../../parametersConstancy/platformParametersSlice";
import { setParametersConstancyWrapper } from "../../../parametersConstancy/parametersConstancyUtils";
import CustomGridColumnsPanel from "../advancedReport/table/customGridColumnsPanel/CustomGridColumnsPanel";
import { useNavigate } from "react-router-dom";
import { applyPartnersByIds } from "../../../role/roleSlice";
import NotificationBadge from "../advancedReport/table/sortingNotification/NotificationBadge";
import useSortModel from "../advancedReport/table/sortingNotification/useSortModel";
import useFilterModel from "../advancedReport/table/sortingNotification/useFilterModel";

function CustomToolbar(props: any) {
  let { setFilterButtonEl, notificationSortingBadge, ...rest } = props; //removing non-existent prop of GridToolbarContainer (removing console warnings)

  return (
    <GridToolbarContainer {...rest} id="table-toolbar">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <GridToolbarColumnsButton
            startIcon={null}
            endIcon={<ColumnsIcon />}
            sx={{
              ...singleBtn,
            }}
            ref={props.setFilterButtonEl}
            id="table-columns-button"
          />
          {/* EXPORT is working , DO NOT REMOVE, not in use*/}
          <GridToolbarExport
            id="table-export-btn"
            printOptions={{ disableToolbarButton: true }}
            startIcon={null}
            endIcon={<ExportReportIcon />}
            sx={{
              ...singleBtn,
            }}
            columnmodel={props.colmod}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
          {notificationSortingBadge}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}

const SummaryRow = (props: {
  data: any[];
  summaryColumns: GridColDef<any>[];
  // rowsAmountPerPage: (string | number | { label: string; value: number })[];
  hiddedColumns: Record<string, boolean>;
  vrDataSummary: any;
}) => {
  const { data, summaryColumns, hiddedColumns, vrDataSummary } = props;

  let modifiedSummaryColumns = [...summaryColumns];
  let summaryData: any = {};

  modifiedSummaryColumns.forEach((column: GridColDef) => {
    if (column.type === "item") {
      summaryData[column.field] = new Set(data.map((row) => row[column.field]?.id)).size;
    }
    if (column.type === "date") {
      summaryData[column.field] = new Set(data.map((row) => row[column.field])).size;
    }
    if (column.type === "number" || column.type === "percent") {
      summaryData[column.field] = vrDataSummary.totalSummary[column.field];
    }
  });

  summaryData.id = -7; // unique id for the summary row
  modifiedSummaryColumns = modifiedSummaryColumns.filter((column) => {
    // Check if the column's field exists in hiddedColumns and its value is false
    return !(column.field in hiddedColumns && hiddedColumns[column.field] === false);
  });

  return (
    <Box
      id="table-total-row"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "sticky",
        //top: "52px",
        zIndex: 1000,
        backgroundColor: colors.primaryCta10,
        borderBottom: "1px solid #ddd",
        overflow: "visible",
        //paddingBottom: "6px",
      }}
    >
      <Box sx={{ display: "flex", width: "100%", paddingLeft: "0px" }}>
        {Object.keys(modifiedSummaryColumns).length && (
          <Grid
            container
            wrap="nowrap"
            sx={{ padding: "7px 0 7px 0", width: "100%", display: "flex", height: "36px" }}
          >
            {modifiedSummaryColumns?.map((modifiedColumn, index) => {
              // Check if the column should be visible
              const { field, flex, type } = modifiedColumn;
              let value = Number(summaryData[field]);
              const isPercentValue = modifiedColumn.type === "percent";
              let tooltipText = isPercentValue
                ? nFormatter(value, 3, false, true) + "%"
                : "" + (value > 0 ? nFormatter(value, 2, false, true) : 0);
              const renderValue = isPercentValue ? value.toFixed(0) + "%" : convertLocal(value);
              //if (field === "submitted_date") content = "Total: ";
              let backgroundColor = "white";
              if (type === "date") {
                backgroundColor = "transparent";
                tooltipText = "";
              }
              // const tooltipText = "" + (content ? nFormatter(+content, 2, false, true) : 0);
              return (
                <Grid
                  id="table-total-cell"
                  data-field={field}
                  item
                  key={index + modifiedColumn.field}
                  sx={{ flex }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: type === "date" ? "flex-start" : type === "item" ? "center" : "flex-end",
                      alignItems: "center",
                      paddingRight: type === "item" ? "0" : "10px",
                      paddingLeft: type === "date" ? "10px" : "0",
                      backgroundColor,
                      borderRadius: "5px",
                      margin: "0 3px",
                      height: "100%",
                      boxShadow: type === "date" ? 0 : "2px 2px 2px " + colors.mainDarkPrimary10,
                    }}
                    key={index}
                  >
                    <ConditionalWrapper
                      condition={null}
                      wrapper={(children: any) => <EllipsisCell>{children}</EllipsisCell>}
                    >
                      <BasicTooltip tooltipText={tooltipText}>
                        <Typography
                          variant="body2XSmallMain600"
                          sx={{
                            cursor: "default",
                            color: `${
                              isPercentValue
                                ? Number(value.toFixed(0)) < 26
                                  ? COLORS.secRed100
                                  : COLORS.secGreen100
                                : COLORS.mainDarkPrimary100
                            }`,
                          }}
                        >
                          {type === "date" ? "Total:" : renderValue}
                        </Typography>
                      </BasicTooltip>
                    </ConditionalWrapper>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

const VRTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let {
    filters,
    vrData,
    vrDataSummary,
    tablePartnersIds,
  }: { filters: ActiveFiltersType; vrData: any[]; vrDataSummary: any; tablePartnersIds: number[] } = useSelector(
    (state: RootState) => state.advancedReport
  );
  const columnVisibilityModelVR: GridColumnVisibilityModel = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.performanceReportParameters.columnVisibilityModelVR ?? {}
  );
  const isPresentationModeEnabled = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters?.isPresentationModeEnabled ??
      false
  );
  const userAccountId = useSelector((state: RootState) => state.users.loggedUser.account_id);
  const isPartnerMenuDisabled = userAccountId !== CONSTANT.IIQ_;
  const dgm = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters?.dateGroupingMode ??
      CONSTANT.DATE_GROUP_MODES.DAY
  );
  const partnersList: any[] = useSelector((state: RootState) => state.role.partnersList);
  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit: CategoryType) => unit.isSelected)])
  );

  const [hiddedColumns, setHidedColumns] = useState<Record<string, boolean>>({});
  // const data = vrData.map((row) => ({
  //   ...row,
  //   isPresentationModeEnabled: isPresentationModeEnabled,
  // }));
  const [filterButtonEl, setFilterButtonEl] = useState(null);

  // let rowsAmountPerPage: number[] = [-1]; // Initialize with default values

  let rowsAmountPerPage: (string | number | { label: string; value: number })[] = [25, 50, 100]; // Initialize with default values
  // if (vrData.length < 101) {
  //   rowsAmountPerPage.push({ label: "All", value: vrData.length });
  // }
  // Check if the length of the data array is less than the maximum rows per page constant
  const totalRecordsSummary = vrData.reduce((acc, row) => acc + +row.totalRecords, 0);

  const handlePartnerTableClick = (event: SelectChangeEvent<string[] | string>, partnerId: number) => {
    const actionType = ("" + event.target.value) as tablePartnerMenuOptionsActionType;
    const actionFn = actionsMap[actionType];
    if (typeof actionFn === "function") {
      actionFn(partnerId);
    } else {
      console.error("No matching function for action type:", actionType);
    }
  };
  const applyVisiblePartners = (params: number) => {
    dispatch(applyPartnersByIds(tablePartnersIds));
  };
  const reportParticularPartner = (partnerId: number) => {
    dispatch(applyPartnersByIds([partnerId]));
  };
  const showPartnerDatails = (partnerId: number) => {
    const accountId = partnersList.find((partner: any) => partner.dummy_id === partnerId)?.acnt_id;
    if (accountId) {
      navigate(`${CONSTANT.PAGES.ACCOUNTS.path}/${accountId}`);
    }
  };

  const actionsMap: Record<tablePartnerMenuOptionsActionType, (partnerId: number) => void> = {
    visiblePartners: applyVisiblePartners,
    thisPartner: reportParticularPartner,
    partnerDatails: showPartnerDatails,
  };

  const columnsProps: VRTableProps = {
    isPresentationModeEnabled,
    totalRecordsSummary,
    dgm,
    handlePartnerTableClick,
    isPartnerMenuDisabled,
  };
  let columns = [...defaultVRColumns(columnsProps)];
  if (activeFilters.actionTerminations.length === 0 && activeFilters.platforms.length === 0) {
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalUniqueCookies);
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalUniqueClusters);
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalOnlineRecords);
    columns.splice(3, 0, optionalVRColumns(columnsProps).dataSendTotalRecords);
  } else if (activeFilters.actionTerminations.length > 0) {
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalUniqueCookies);
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalUniqueClusters);
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalOnlineRecords);
  } else if (activeFilters.platforms.length > 0) {
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalRequests);
    columns.splice(3, 0, optionalVRColumns(columnsProps).transactionsCoverage);
    columns.splice(3, 0, optionalVRColumns(columnsProps).totalTransactionsWith3rdParty);
  }
  visitortRecognitionCategoriesFilters.forEach((category) => {
    if (
      (activeFilters[category as keyof typeof activeFilters] as Array<Object>).length &&
      category !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base
    ) {
      columns.splice(2, 0, optionalVRColumns(columnsProps)[category]);
    }
  });

  const summaryColumns = [...columns];

  const { sortModel, handleSort, selectedCell, selectedCellName, resetSort } = useSortModel(summaryColumns);
  const { filterTerms, handleColumnFilter, resetColumnFilter } = useFilterModel(summaryColumns);

  const notificationSortingBadge = (
    <NotificationBadge
      selectedCellName={selectedCellName}
      filterTerms={filterTerms}
      resetSort={resetSort}
      resetColumnFilter={resetColumnFilter}
    />
  );
  return (
    <Box>
      <Box
        id="vr-table-container"
        sx={{
          // padding: "20px 20px 0px 20px",
          background: "#FFFFFF",
          borderRight: "1px solid #E0E2E7",
          borderLeft: "1px solid #E0E2E7",
          borderBottom: "1px solid #E0E2E7",
          boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <Box
          sx={{
            ...mainTableStyle(),

            ".MuiDataGrid-root": {
              " .MuiDataGrid-columnHeaderDraggableContainer ": {
                display: "flex",
                flexDirection: "row !important",
                paddingRight: "10px",
              },
              " .MuiDataGrid-columnHeader": {
                borderLeft: `1px solid ${colors.border}`,
                height: "auto !important",
                //removes left border in table column header  when clicking on it
                "&:first-of-type": {
                  border: "none",
                },
              },
            },
            ".MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
              display: "flex",
              flexDirection: "row !important",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px",
              // "&:focus ": {
              //   border: "none",
              // },
            },
          }}
        >
          <DataGrid
            autoHeight={true}
            rows={vrData}
            columns={columns}
            hideFooterPagination={true}
            // disableRowSelectionOnClick
            scrollbarSize={1}
            rowHeight={32}
            localeText={{
              toolbarExport: "Export file",
            }}
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
            }}
            onColumnVisibilityModelChange={(props) => {
              dispatch(
                setCurrentPlatformParameter(
                  setParametersConstancyWrapper("performanceReportParameters", "columnVisibilityModelVR", props)
                )
              );
              setHidedColumns(props);
            }}
            getCellClassName={(params) => {
              if (selectedCell) {
                return selectedCell && selectedCell.field === params.field ? "highlight-cell" : "highlight-cell-b";
              } else {
                return "";
              }
            }}
            columnVisibilityModel={columnVisibilityModelVR}
            sortModel={sortModel}
            onFilterModelChange={handleColumnFilter}
            onSortModelChange={handleSort}
            sortingOrder={["desc", "asc"]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: typeof rowsAmountPerPage[0] === "number" ? rowsAmountPerPage[0] : -1,
                },
              },
              sorting: {
                sortModel: [{ field: "dateCreated", sort: "desc" }],
              },
            }}
            loading
            slots={{
              toolbar: CustomToolbar,
              footer: () => <CustomPagination rowsPerPageOptions={rowsAmountPerPage} />,
              loadingOverlay: (props) => (
                <SummaryRow
                  {...props}
                  data={vrData}
                  summaryColumns={summaryColumns}
                  rowsAmountPerPage={rowsAmountPerPage}
                  hiddedColumns={
                    Object.keys(columnVisibilityModelVR).length > 0 ? columnVisibilityModelVR : hiddedColumns
                  }
                  vrDataSummary={vrDataSummary}
                />
              ),
              columnsPanel: CustomGridColumnsPanel,
            }}
            slotProps={{
              baseIconButton: {
                title: "",
              },
              panel: {
                anchorEl: filterButtonEl,
                placement: "bottom-start",
                sx: columnTablePanelStyle,
              },

              loadingOverlay: {
                sx: { position: "sticky !important", top: 0 },
              },
              toolbar: { setFilterButtonEl, notificationSortingBadge: notificationSortingBadge },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VRTable;
