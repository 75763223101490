import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { IQuickActionsItem, newQuickActionInitItem, QUICK_ACTION_ICONS } from "./quickActionsUtils";
import { colors } from "../theme";
import AddIcon from "@mui/icons-material/Add";
import { QueryStatus } from "@reduxjs/toolkit/query";

export interface ListQuickActionsItemsProps {
  items: IQuickActionsItem[];
  selectedItem: IQuickActionsItem;
  setActionItem: React.Dispatch<React.SetStateAction<IQuickActionsItem>>;
  creationStatus: QueryStatus;
}

const ListQuickActionsItems: React.FC<ListQuickActionsItemsProps> = ({
  items,
  selectedItem,
  setActionItem,
  creationStatus,
}) => {
  const newQuickActionItemNode = selectedItem.id === null && creationStatus !== QueryStatus.pending && (
    <Box
      key={selectedItem.id}
      sx={{
        display: "flex",
        padding: "12px",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        borderRadius: "8px",
        background: selectedItem.id === null ? colors.backgroundBaseSecondary : colors.mainWhite,
        cursor: "pointer",
      }}
      onClick={() => setActionItem(selectedItem)}
    >
      {QUICK_ACTION_ICONS.find((icon) => icon.id === selectedItem.settings.iconId)?.titleItem}
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        {selectedItem.name}
      </Typography>
    </Box>
  );

  const quickActionItems = items.map((item) => (
    <Box
      key={item.id}
      sx={{
        display: "flex",
        padding: "12px",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        borderRadius: "8px",
        background:
          selectedItem.id != null && selectedItem.id === item.id
            ? colors.backgroundBaseSecondary
            : colors.mainWhite,
        cursor: "pointer",
      }}
      onClick={() => setActionItem(item)}
    >
      {QUICK_ACTION_ICONS.find((icon) => icon.id === item.settings.iconId)?.titleItem}
      <Typography variant="bodyLargeRegular" sx={{ fontWeight: 500 }}>
        {item.name}
      </Typography>
    </Box>
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "2px",
        alignSelf: "stretch",
      }}
    >
      {quickActionItems}
      {newQuickActionItemNode}
    </Box>
  );
};

export default ListQuickActionsItems;
