import React, { useCallback } from "react";
import { Chip } from "@mui/material";

import { CustomChartTypes, ICustomChartItem } from "./types";

import ClearIcon from "@mui/icons-material/Clear";
import { colors } from "../../theme";
interface CustomChartChipsProps {
  selectedCustomCharts: ICustomChartItem[];
  setSelectedCustomCharts: React.Dispatch<React.SetStateAction<ICustomChartItem[]>>;
}

interface IChip {
  label: string;
  type: "ab" | "lift";
}

const CustomChartChips: React.FC<CustomChartChipsProps> = ({ selectedCustomCharts, setSelectedCustomCharts }) => {
  const handleDelete = useCallback(
    (chartId: string, type: "lift" | "ab") => {
      setSelectedCustomCharts((prev) =>
        prev
          .map((item) =>
            item.id === chartId
              ? {
                  ...item,
                  abVisible: type === "ab" ? false : item.abVisible ?? false,
                  liftVisible: type === "lift" ? false : item.liftVisible ?? false,
                }
              : item
          )
          .filter((item) => (item.abVisible ?? false) || (item.liftVisible ?? false))
      );
    },
    [setSelectedCustomCharts]
  );

  return (
    <>
      {selectedCustomCharts.map((chartItem) => {
        // Default to false if undefined
        const liftActive = chartItem.liftVisible ?? false;
        const abActive = chartItem.abVisible ?? false;
        const chartDef = CustomChartTypes[chartItem.id];
        const chips: IChip[] = [];
        if (liftActive) chips.push({ label: `${chartDef.title} (Lift)`, type: "lift" });
        if (abActive) chips.push({ label: `${chartDef.title} (A/B)`, type: "ab" });
        return chips.map((chip) => (
          <Chip
            key={`${chartItem.id}-${chip.type}`}
            label={chip.label}
            onDelete={() => handleDelete(chartItem.id, chip.type)}
            deleteIcon={<ClearIcon />}
            sx={{
              backgroundColor: colors.navy50,
              padding: "8px 8px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontSize: "16px",
              color: colors.text02,
              "& .MuiChip-deleteIcon": { width: "16px", height: "16px" },
            }}
          />
        ));
      })}
    </>
  );
};

export default CustomChartChips;
