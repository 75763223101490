import { colors } from "../../theme";

export default function ExportReportIcon({ color = colors.backgroudSelected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9 10.8C1.39706 10.8 1.8 11.203 1.8 11.7L1.8 15.3C1.8 15.5387 1.89482 15.7677 2.0636 15.9364C2.23239 16.1052 2.46131 16.2 2.7 16.2L15.3 16.2C15.5387 16.2 15.7676 16.1052 15.9364 15.9364C16.1052 15.7677 16.2 15.5387 16.2 15.3V11.7C16.2 11.203 16.6029 10.8 17.1 10.8C17.5971 10.8 18 11.203 18 11.7V15.3C18 16.0161 17.7155 16.7029 17.2092 17.2092C16.7028 17.7156 16.0161 18 15.3 18L2.7 18C1.98392 18 1.29716 17.7156 0.790812 17.2092C0.284463 16.7029 0 16.0161 0 15.3L0 11.7C0 11.203 0.402944 10.8 0.9 10.8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86361 6.56365C4.21508 6.21218 4.78493 6.21218 5.1364 6.56365L9.00001 10.4273L12.8636 6.56365C13.2151 6.21218 13.7849 6.21218 14.1364 6.56365C14.4879 6.91512 14.4879 7.48497 14.1364 7.83645L9.6364 12.3364C9.28493 12.6879 8.71508 12.6879 8.36361 12.3364L3.86361 7.83645C3.51214 7.48497 3.51214 6.91512 3.86361 6.56365Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00001 0C9.49706 0 9.90001 0.402944 9.90001 0.9L9.90001 11.7C9.90001 12.1971 9.49706 12.6 9.00001 12.6C8.50295 12.6 8.10001 12.1971 8.10001 11.7L8.10001 0.9C8.10001 0.402944 8.50295 0 9.00001 0Z"
        fill={color}
      />
    </svg>
  );
}
