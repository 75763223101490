interface IconProps {
  color?: string;
}

const JSAgentsIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? "white";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.425 9.47499L11.15 3.39999C11.25 3.23332 11.375 3.11249 11.525 3.03749C11.675 2.96249 11.8333 2.92499 12 2.92499C12.1667 2.92499 12.325 2.96249 12.475 3.03749C12.625 3.11249 12.75 3.23332 12.85 3.39999L16.575 9.47499C16.675 9.64165 16.725 9.81665 16.725 9.99999C16.725 10.1833 16.6833 10.35 16.6 10.5C16.5167 10.65 16.4 10.7708 16.25 10.8625C16.1 10.9542 15.925 11 15.725 11H8.275C8.075 11 7.9 10.9542 7.75 10.8625C7.6 10.7708 7.48333 10.65 7.4 10.5C7.31667 10.35 7.275 10.1833 7.275 9.99999C7.275 9.81665 7.325 9.64165 7.425 9.47499ZM17.5 22C16.25 22 15.1875 21.5625 14.3125 20.6875C13.4375 19.8125 13 18.75 13 17.5C13 16.25 13.4375 15.1875 14.3125 14.3125C15.1875 13.4375 16.25 13 17.5 13C18.75 13 19.8125 13.4375 20.6875 14.3125C21.5625 15.1875 22 16.25 22 17.5C22 18.75 21.5625 19.8125 20.6875 20.6875C19.8125 21.5625 18.75 22 17.5 22ZM3 20.5V14.5C3 14.2167 3.09583 13.9792 3.2875 13.7875C3.47917 13.5958 3.71667 13.5 4 13.5H10C10.2833 13.5 10.5208 13.5958 10.7125 13.7875C10.9042 13.9792 11 14.2167 11 14.5V20.5C11 20.7833 10.9042 21.0208 10.7125 21.2125C10.5208 21.4042 10.2833 21.5 10 21.5H4C3.71667 21.5 3.47917 21.4042 3.2875 21.2125C3.09583 21.0208 3 20.7833 3 20.5ZM17.5 20C18.2 20 18.7917 19.7583 19.275 19.275C19.7583 18.7917 20 18.2 20 17.5C20 16.8 19.7583 16.2083 19.275 15.725C18.7917 15.2417 18.2 15 17.5 15C16.8 15 16.2083 15.2417 15.725 15.725C15.2417 16.2083 15 16.8 15 17.5C15 18.2 15.2417 18.7917 15.725 19.275C16.2083 19.7583 16.8 20 17.5 20ZM5 19.5H9V15.5H5V19.5ZM10.05 8.99999H13.95L12 5.84999L10.05 8.99999Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default JSAgentsIcon;
